import {Entity} from '../../../components/entity';

export class PriceList extends Entity {
  name: string;

  constructor({id, name, createdAt, updatedAt}: Partial<PriceList>) {
    super({id, createdAt, updatedAt});
    this.name = name ?? '';
  }

  getNameProperty(): string {
    return 'name';
  }
}
