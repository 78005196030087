<section class="l-siteContent" id="theTop" *ngIf="items$ | async as stores">
  <section class="contact">
    <header>
      <img class="contact-titleDecorTop" src="/assets/images/bomo_angel.svg" alt="Bomo Art Budapest">
      <h1>{{ 'webshop.Bomo Art Budapest Kft.' | transloco }}</h1>
      <p>{{ 'webshop.1052 Budapest, Régiposta utca 14.' | transloco }}</p>
      <img class="contact-titleDecorBottom" src="/assets/images/menu_heading_friz_decor.svg" alt="decor">
    </header>
    <section *ngIf="googleApi.apiLoaded | async">
      <header class="sectionHeader g-row-extrathick">
        <h2><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.További információk' | transloco }}<img
          src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
      </header>
      <div class="row text-center">
        <div class="small-12 xlarge-6 column">
          <p>{{ 'webshop.contact.question' | transloco }}<br>
            {{ 'webshop.admin@bomoart.com' | transloco }}<span class="g-left g-right">•</span>{{ 'webshop.+36 70 362 0631' | transloco }}</p>
        </div>
        <div class="small-12 xlarge-6 column">
          <p>{{ 'webshop.contact.common' | transloco }}<br> shop@bomoart.com <span
            class="g-left g-right">•</span>{{ 'webshop.+36 70 362 0631' | transloco }}</p>
        </div>
      </div>
      <div class="row g-top-extrathick" data-equalizer data-equalize-on="medium">
        <div class="small-12 xlarge-6 column" *ngFor="let store of stores">
          <h2>{{ store.title }}</h2>
          <div class="decorBorder decorBorder--block">
            <div>
              <google-map [width]="null" [height]="null" [ngClass]="{'contact-map-content': true, 'store-map': true }"
                          [options]="options"
                          [center]="center(store)">
                <map-marker
                  [title]="store.title"
                  [label]="{text: store.title, fontSize: '16px', fontWeight: 'bold', color: '#444'}"
                  [position]="center(store)"></map-marker>
              </google-map>
            </div>
          </div>
          <div class="contact-address g-bottom-thin" data-equalizer-watch>
            <p><strong [innerHTML]="store.address"></strong><br>
            <p><strong [innerHTML]="store.phoneNumber"></strong><br>
              <span [innerHTML]="store.description"></span></p>
            <p><strong><span class="h-upperCase">{{ 'webshop.Nyitva' | transloco }}:</span></strong><br>
              <span [innerHTML]="store.openingTime"></span></p>
          </div>
          <app-gallery *ngIf="store.gallery.id" [gallery]="store.gallery"></app-gallery>

        </div>

      </div>
    </section>
    <section>
      <header class="sectionHeader g-row-extrathick">
        <h2><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Irna nekünk?' | transloco }}<img
          src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
      </header>
      <div class="row align-center">
        <div class="small-12 medium-10 large-6 xlarge-5 column">

          <p
            *ngIf="submitted">{{ 'webshop.Köszönjük, hogy üzenetet küldtél nekünk. Hamarosan kapcsolatba lépünk veled!' | transloco }}</p>


          <form class="form form--contact form--inputTextTransformNone" data-abide novalidate
                *ngIf="!submitted">
            <div data-abide-error class="alert callout h-hidden">
              <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
            </div>
            <label>{{ 'webshop.Név' | transloco }}
              <input type="text" [placeholder]="'webshop.Név' | transloco" [formControl]="nameControl">
              <span class="form-error"
                    *ngIf="nameControl.hasError('required') && emailControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
            </label>
            <label>{{ 'webshop.E-mail' | transloco }}
              <input type="text" [placeholder]="'webshop.E-mail' | transloco" [formControl]="emailControl">
              <span class="form-error"
                    *ngIf="emailControl.hasError('required') && emailControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
              <span class="form-error"
                    *ngIf="emailControl.hasError('email') && emailControl.touched">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco }}</span>
            </label>
            <label>{{ 'webshop.Megjegyzés' | transloco }}
              <textarea [formControl]="contentControl" rows="8" [placeholder]="'webshop.Megjegyzés' | transloco"></textarea>
              <span class="form-error"
                    *ngIf="contentControl.hasError('required') && emailControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
            </label>
            <div class="g-top text-right">
              <img class="l-hand" src="/assets/images/bomo_hand_gray.svg" alt="tovább">
              <button class="btn btn--basic" (click)="sendMail()">{{ 'webshop.Küldés' | transloco }}</button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="g-top-extrathick">
      <div class="row">
        <div class="small-12 column">
          <header class="sectionHeader g-row-extrathick">
            <h2><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Viszonteladónk lenne?' | transloco }}<img
              src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
          </header>
          <p class="text-center"><strong [innerHTML]="'webshop.Szeretne \<span class=\'h-upperCase\'\>viszonteladónk\</span\> lenni?' | transloco"></strong><br>
            {{ 'webshop.Információkért látogassa meg viszonteladói oldalunkat!' | transloco }}<br>
            <a [routerLink]="['/wholesale'] | localize">{{ 'webshop.Tovább' | transloco }} »</a></p>
        </div>
      </div>
    </section>
  </section>

  <script>
    function initMap() {

      $(".store-map").each(function (key, element) {

        var myLatLng = {
          lat: parseFloat($(element).attr('latitude')),
          lng: parseFloat($(element).attr('longitude'))
        };
        var map = new google.maps.Map(element, {
          zoom: 15,
          center: myLatLng,
          styles: [{
            "featureType": "all",
            "elementType": "geometry.fill",
            "stylers": [{
              "weight": "2.00"
            }]
          }, {
            "featureType": "all",
            "elementType": "geometry.stroke",
            "stylers": [{
              "color": "#9c9c9c"
            }]
          }, {
            "featureType": "all",
            "elementType": "labels.text",
            "stylers": [{
              "visibility": "on"
            }]
          }, {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{
              "color": "#f2f2f2"
            }]
          }, {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#ffffff"
            }]
          }, {
            "featureType": "landscape.man_made",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#ffffff"
            }]
          }, {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{
              "saturation": -100
            }, {
              "lightness": 45
            }]
          }, {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#eeeeee"
            }]
          }, {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#7b7b7b"
            }]
          }, {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#ffffff"
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [{
              "visibility": "simplified"
            }]
          }, {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "featureType": "water",
            "elementType": "all",
            "stylers": [{
              "color": "#46bcec"
            }, {
              "visibility": "on"
            }]
          }, {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#c8d7d4"
            }]
          }, {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#070707"
            }]
          }, {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#ffffff"
            }]
          }]
        });

        var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
          title: $(element).attr('title'),
          label: {
            text: $(element).attr('title'),
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#444'
          }
        });
      });


    }
  </script>
  <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDhLOEz8kO5p_Md46_62-8tGAGvj8KdRI4&callback=initMap"
          async defer></script>
</section>
