import {Component, ComponentFactoryResolver, EventEmitter, Injector, Output, ViewChild} from '@angular/core';
import {EntityState, EntityStore, getEntityType, getIDType, QueryEntity} from '@datorama/akita';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {EntityService} from '../entity-service';
import {BaseEntity, Entity} from '../entity';
import {first, map, switchMap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';
import {DynamicFormComponent} from '../form/dynamic-form';
import {DynamicFormHostDirective} from '../form/dynamic-form-host.directive';
import {HttpAddConfig} from "@datorama/akita-ng-entity-service";

export interface validationError {
  title: string;
  violations: constraintError[];
}

export interface constraintError {
  propertyPath: string;
  title: string;
}

@Component({
  selector: 'app-entity-detail',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class EntityDetailComponent<T extends Entity, S extends EntityState, St extends EntityStore<S>> {

  @ViewChild(DynamicFormComponent) form: DynamicFormComponent | undefined;
  @ViewChild(DynamicFormHostDirective, {static: true}) formHost: DynamicFormHostDirective | undefined;

  @Output() formClose = new EventEmitter<boolean>();

  entity$: Observable<getEntityType<S>>;
  refreshForm: EventEmitter<any>;
  id: string;
  savedEntity: T | null = null;

  protected router: Router;
  protected route: ActivatedRoute;
  protected snackBar: MatSnackBar;
  protected translationService: TranslocoService;
  protected resolver: ComponentFactoryResolver;

  private entityPipe: Observable<getEntityType<S>> | undefined;
  protected reloader = new BehaviorSubject(false);
  protected reloaderObs = this.reloader.asObservable();

  public constructor(
    public injector: Injector,
    public service: EntityService<St, S>,
    protected query: QueryEntity<S>
  ) {
    this.id = 'create';
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.snackBar = injector.get(MatSnackBar);
    this.translationService = injector.get(TranslocoService);
    this.resolver = injector.get(ComponentFactoryResolver);
    this.refreshForm = new EventEmitter();

    this.entity$ = of('').pipe(
      switchMap(() =>
      this.route.params.pipe(switchMap((params) => {
      this.id = params.id ?? 'create';
      if (this.id !== '' && this.id !== 'create') {

        if (!this.entityPipe) {
          this.entityPipe = this.service.get(this.id as getIDType<S>).pipe(
            //first(),
            map(items => items.length > 0 ? items[0] : (undefined as getEntityType<S>))
          );
        }

        return this.entityPipe;
      }

      return of(new BaseEntity({}) as getEntityType<S>);
    }))));
  }

  prePersist(_entity: Entity) {
    return;
  }

  onSubmit(_entity: Entity) {
    const entity = _entity as getEntityType<S>;
    this.prePersist(entity);
    const formData = new FormData();

    this.service.getFields().forEach(field => {
      if (field.type === 'image') {
        if (entity[field.name]) {
          if (entity[field.name]['uploaded']) {
            formData.append(field.name, entity[field.name]['uploaded']);
          } else {
            if (!Object.getOwnPropertyNames(entity[field.name]).some(propName => entity[field.name][propName] !== "")) {
              entity[field.name] = null;
              delete entity[field.name];
            }
          }
        }
      }
    });

    if (this.id === 'create') {
      this.service.add<T>(entity, {body: formData} as HttpAddConfig).subscribe(entity => {
        this.savedEntity = entity;
        this.snackBar.open(this.translationService.translate('dialog.added'), '', {
            duration: 1000,
            panelClass: ['background-success']
          }
        );

        this.formClose.emit();
        if (!this.formClose.observers.length) {
          this.navigateToParent();
        }
      }, (error) => {

        if (this.formHost?.group) {
          const validationErrorObj = error.error as validationError;

          validationErrorObj.violations.forEach((_error) => {
            this.formHost?.group?.get(_error.propertyPath)?.setErrors({custom: {message: this.translationService.translate(_error.title)}});
          });
        }
      });
    } else {
      this.service.update<T>(this.id as getIDType<S>, entity, {body: formData} as HttpAddConfig).subscribe((entity) => {
        this.savedEntity = entity;

        this.snackBar.open(this.translationService.translate('dialog.updated'), '', {
            duration: 1000,
            panelClass: ['background-success']
          }
        );
        this.formClose.emit();
        if (!this.formClose.observers.length) {
          this.navigateToParent();
        }
      }, (error) => {
        if (this.formHost?.group) {
          const validationErrorObj = error.error as validationError;
          validationErrorObj.violations.forEach((_error) => {
            this.formHost?.group?.get(_error.propertyPath)?.setErrors({custom: {message: this.translationService.translate(_error.title)}});
          });
        }
      });
    }
  }

  onCancel() {

    this.formClose.emit();
    if (!this.formClose.observers.length) {
      this.navigateToParent();
    }
  }

  navigateToParent() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

  getTitle(entity: Partial<T> | null): string {
    if (entity !== null) {
      return Object.getOwnPropertyNames(entity).join(' ');
    }

    return '';
  }

}
