import {AfterViewInit, Component, Injector} from '@angular/core';
import {ProductState, Store} from './state/store';
import {Product} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {ProductQuery} from './state/query';
import {ProductService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class ProductListComponent extends EntityListComponent<Product, ProductState, Store> {

  translationDomain = 'product';

  constructor(
    public injector: Injector,
    protected service: ProductService,
    query: ProductQuery,
    private ProductFilterService: FilterService) {
    super(injector, service, query, ProductFilterService);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.dataSource?.sort) {
      this.dataSource.setDefaultSort('name', 'asc');
    }

  }

}
