<section class="g-top-thick" *ngIf="(user$ | async) as user">
    <div class="row align-center" *ngIf="(cart$ | async) as cart">
        <div class="shrink column">
            <div class="h-relative">
                <img class="anim anim--fromLeft decorImg decorImg--checkoutPaymentModeLeft" src="/assets/images/decor/cookie.png" alt="decor">
                <img class="anim anim--fromRight decorImg decorImg--checkoutPaymentModeRight" src="/assets/images/decor/cup.png" alt="decor">
            </div>
            <header class="l-headFrizDecor">
                <h2>{{ 'webshop.Fizetési mód' | transloco }}</h2>
            </header>
            <form class="form form--deliveryMode" data-abide novalidate (ngSubmit)="save(cart)" [formGroup]="group">
                <div data-abide-error class="alert callout" *ngIf="modeControl.hasError('required') && modeControl.touched">
                    <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
                </div>
                <div class="row collapse align-center">
                    <div class="row collapse align-center">
                        <div class="shrink column">
                            <div *ngFor="let payment of (modes$ | async)">
                              <input type="radio" [attr.id]="payment.id" [formControlName]="'mode'" [value]="payment">
                                <label [attr.for]="payment.id">{{ payment.title }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="l-divider l-divider--flexCard">
                <div class="g-top-extrathick text-center">
                    <img class="l-hand" src="/assets/images/bomo_hand_gray.svg" alt="tovább">
                    <button class="btn btn--basic" type="submit">{{ 'webshop.Tovább' | transloco }}</button>
                </div>
            </form>
        </div>
    </div>
</section>
