import {Component} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {first} from "rxjs/operators";
import {tap} from "rxjs";
import {StoreService} from "../../../webshop/store/state/service";
import {ActivatedRoute} from "@angular/router";
import {FormService} from "../../../components/service/form.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";

@Component({
  templateUrl: './wholesale.html'
})
export class WebshopContactWholesaleComponent {
  submitted: boolean = false;
  storeControl: FormControl;
  nameControl: FormControl;
  knownControl: FormControl;
  descriptionControl: FormControl;
  emailControl: FormControl;
  contactForm: FormGroup;
  constructor(
    service: StoreService,
    route: ActivatedRoute,
    private formService: FormService,
    fb: FormBuilder,
    private http: HttpClient,
  ) {

    this.descriptionControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.nameControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.emailControl = fb.control('', formService.bindValidations(formService.getValidations(['required', 'email'])));
    this.knownControl = fb.control(null, formService.bindValidations(formService.getValidations(['required'])));
    this.storeControl = fb.control('', formService.bindValidations(formService.getValidations([])));
    this.contactForm = fb.group({});

    this.contactForm.addControl('name', this.nameControl);
    this.contactForm.addControl('email', this.emailControl);
    this.contactForm.addControl('content', this.descriptionControl);
    this.contactForm.addControl('known', this.knownControl);
    this.contactForm.addControl('store', this.storeControl);

  }

  sendMail() {
    if (this.contactForm.valid) {
      this.http.post(environment.apiUrl + '/contact/wholesale', {
        email: this.emailControl.value,
        content: this.descriptionControl.value,
        name: this.nameControl.value,
        store: this.storeControl.value,
        known: this.knownControl.value
      }).pipe(
        first(),
        tap(() => this.submitted = true)
      ).subscribe();
    } else {
      this.formService.validateAllFormFields(this.contactForm);
    }
  }
}
