import {Injectable} from '@angular/core';
import {BlogCategoryState, Store as BlogCategoryStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {BlogCategory} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {BlogCategoryQuery} from './query';

@Injectable({ providedIn: 'root' })
export class BlogCategoryService extends EntityService<BlogCategoryStore, BlogCategoryState> {

  blogCategories$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new BlogCategory(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('blogCategory.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('blogCategory.slug'),
        inputType: 'text',
        name: 'slug',
        listed: false,
        editable: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('blogCategory.name'),
        inputType: 'text',
        name: 'name',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: BlogCategoryQuery,
    protected store: BlogCategoryStore) {
    super(store, BlogCategory);
  }

  get resourceName(): string {
    return 'content/blog-category';
  }

}
