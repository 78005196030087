import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {TransporterDiscount} from './model';

export interface TransporterDiscountState extends EntityState<TransporterDiscount> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'TransporterDiscount' })
export class Store extends EntityStore<TransporterDiscountState> {

  constructor() {
    super();
  }

}
