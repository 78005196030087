import {Injectable} from '@angular/core';
import {CartState, Store as CartStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Cart} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {isString, TranslocoService} from '@ngneat/transloco';
import {catchError, first, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {CartQuery} from './query';
import {Product} from "../../../products/product/state/model";
import {ProductVariant} from "../../../products/product-variant/state/model";
import {OrderItem} from "../../order/state/model";
import {Observable, of} from "rxjs";
import {getEntityType, getIDType} from "@datorama/akita";
import {HttpUpdateConfig} from "@datorama/akita-ng-entity-service";
import {Entity} from "../../../components/entity";
import {isNumeric} from "ngx-bootstrap/positioning/utils";
import {ProductParameterTypeCount} from "../../../products/product-parameter-type-count/state/model";

@Injectable({providedIn: 'root'})
export class CartService extends EntityService<CartStore, CartState> {

  cart$: Observable<Cart> = of('').pipe(
    switchMap(() => {
      if (!localStorage.getItem('cartId')) {
        return of('');
      }
      return this.get(localStorage.getItem('cartId')).pipe(
      );
    }),
    switchMap(() => this.query.selectAll()),
    map(items => items.length ? items.map(item => new Cart(item))[0] : new Cart({})),
    catchError(() => of(new Cart({}))),
    tap(cart => {
      localStorage.setItem('cartId', cart.id as string)
    }),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('cart.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('cart.name'),
        inputType: 'text',
        name: 'paymentMode',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('cart.name'),
        inputType: 'text',
        name: 'shippingMode',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('cart.slug'),
        inputType: 'text',
        name: 'items',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('cart.slug'),
        inputType: 'text',
        name: 'billingAddress',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('cart.slug'),
        inputType: 'text',
        name: 'billingCountry',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('cart.slug'),
        inputType: 'text',
        name: 'shippingAddress',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('cart.quantitativeUnit'),
        inputType: 'text',
        name: 'shippingCountry',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('cart.quantitativeUnit'),
        inputType: 'text',
        name: 'store',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('cart.quantitativeUnit'),
        inputType: 'text',
        name: 'coupon',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: CartQuery,
    protected store: CartStore) {
    super(store, Cart);
  }

  /**
   * @inheritDoc
   */
  update<T>(id: getIDType<CartState>, entity: Partial<getEntityType<CartState>>, config?: HttpUpdateConfig<T>): Observable<T> {
    if (!id) {
      return this.add(entity as Cart);
    }
    const _entity = this.clearEntity(entity);

    _entity.id = id;
    // @ts-ignore
    return super.update<T>(id, _entity as unknown as Partial<getEntityType<CartState>>, config).pipe(first()).pipe(
      switchMap(() => this.get(id)),
      map(items => items.length ? items.map(item => new Cart(item))[0] : new Cart({})),
    );
  }

  deleteProductFromCart(orderItem: OrderItem, cart: Cart) {
    cart.items = cart.items.filter(item => !(item.productVariant.id === orderItem.productVariant.id && item.product.id === orderItem.product.id && orderItem.captionSpecial === item.captionSpecial && orderItem.caption === item.caption));
  }

  clearCart() {
    this.store.set([new Cart({})]);
  }

  addProductToCart(product: Product, productVariant: ProductVariant, caption: string, captionSpecial: string, quantity: number, cart: Cart) {
    productVariant.id = '';
    if (product.selectorProductParameter.id && !product.setCount) {

      productVariant.productParameterTypeCounts.filter(pptc => pptc.productParameterType.productParameter.id === product.selectorProductParameter.id).forEach(_pptc => {

        const _productVariant = new ProductVariant(productVariant);
        _productVariant.id = '';

        _productVariant.productParameterTypeCounts = productVariant.productParameterTypeCounts.filter(pptc => pptc.productParameterType.productParameter.id !== product.selectorProductParameter.id || pptc.productParameterType.id === _pptc.productParameterType.id);
        _productVariant.productParameterTypeCounts = _productVariant.productParameterTypeCounts.map(pptc => {
          let ____pptc = new ProductParameterTypeCount(pptc);
          if (pptc.productParameterType.productParameter.id !== product.selectorProductParameter.id || pptc.productParameterType.id === _pptc.productParameterType.id) {
            ____pptc.quantity = 0;
          }
          return ____pptc;
        });

        const orderItem = new OrderItem({
          quantity: _pptc.quantity,
          productVariant: _productVariant,
          product: product,
          caption: caption,
          captionSpecial: captionSpecial,
          price: 0
        });

        this.incrementOrderItem(orderItem, _pptc.quantity, cart);
      });

    } else {
      const orderItem = new OrderItem({
        quantity: quantity,
        productVariant: productVariant,
        product: product,
        caption: caption,
        captionSpecial: captionSpecial,
        price: 0
      });

      this.incrementOrderItem(orderItem, quantity, cart);
    }
  }

  findOrderItemInCart(orderItem: OrderItem, cart: Cart) {
    return cart.items.find(item =>
      (
        (item.productVariant.id && item.productVariant.id === orderItem.productVariant.id) ||
        item.productVariant.productParameterTypeCounts.every(ptc => orderItem.productVariant.productParameterTypeCounts.find(_ptc => _ptc.productParameterType.id === ptc.productParameterType.id && _ptc.quantity === ptc.quantity))
      )
      && item.product.id === orderItem.product.id && orderItem.captionSpecial === item.captionSpecial && orderItem.caption === item.caption)
  }

  incrementOrderItem(orderItem: OrderItem, quantity: number, cart: Cart) {
    const availableItem = this.findOrderItemInCart(orderItem, cart);
    if (availableItem) {
      availableItem.quantity += quantity;
    } else {
      cart.items.push(
        orderItem
      );
    }
  }

  decrementOrderItem(orderItem: OrderItem, quantity: number, cart: Cart) {
    const availableItem = this.findOrderItemInCart(orderItem, cart);
    if (availableItem) {
      if (availableItem.quantity > quantity) {
        availableItem.quantity -= quantity;
      } else {
        cart.items = cart.items.filter(item => item !== availableItem);
      }
    }
  }

  simplifyEntity(entity: Entity) {
    if (!entity || isString(entity)) {
      return entity;
    }
    if (entity.hasOwnProperty('id') && !(entity instanceof OrderItem) && !(entity instanceof ProductParameterTypeCount) && entity.id) {
      entity = {id: entity.id} as unknown as Entity;
    } else {
      if (entity.hasOwnProperty('id') && !entity.id || (entity instanceof ProductParameterTypeCount)) {
        delete entity['id'];
      }
      const names = Object.getOwnPropertyNames(entity);
      for (const _entityKey in names) {
        if (Array.isArray(entity[_entityKey])) {
          for (const __entityKey in (entity[_entityKey] as Entity[])) {
            entity[_entityKey][__entityKey] = this.simplifyEntity(entity[_entityKey][__entityKey]);
          }
        } else {
          entity[names[_entityKey]] = this.simplifyEntity(entity[names[_entityKey]] as Entity);
        }
      }
    }

    return entity;
  }

  get resourceName(): string {
    return 'webshop/cart';
  }

}
