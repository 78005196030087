import {Injectable} from '@angular/core';
import {PopupState, Store as PopupStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Popup} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {PopupQuery} from './query';
import {FormService} from "../../../components/service/form.service";

@Injectable({providedIn: 'root'})
export class PopupService extends EntityService<PopupStore, PopupState> {

  popup$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Popup(item))),
    map(popups => popups.length > 0 ? (popups.filter(popup => !localStorage.getItem('popup' + popup.id)) ?? [new Popup({})]) : [new Popup({})]),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('popup.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('popup.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'chips',
        label: this.translocoService.selectTranslate('popup.urls'),
        name: 'urls',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('popup.description'),
        inputType: 'text',
        name: 'description',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('popup.image'),
        name: 'image',
        validations: this.formService.getValidations(['requiredImage'])
      }),

      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('popup.from'),
        name: 'from',
        listed: true,
        validations: []
      }),

      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('popup.to'),
        name: 'to',
        listed: true,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: PopupQuery,
    private formService: FormService,
    protected store: PopupStore) {
    super(store, Popup);
  }

  get resourceName(): string {
    return 'content/popup';
  }

}
