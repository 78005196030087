import {Injectable} from '@angular/core';
import {PressVideoService} from './state/service';
import {PressVideoQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {PressVideo} from './state/model';
import {PressVideoState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<PressVideo, PressVideoState, PressVideoQuery, Store> {
  constructor(protected query: PressVideoQuery, protected api: PressVideoService) {
    super(query, api);
  }
}
