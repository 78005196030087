import {Injectable} from '@angular/core';
import {CategoryService} from './state/service';
import {CategoryQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Category} from './state/model';
import {CategoryState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Category, CategoryState, CategoryQuery, Store> {
  constructor(protected query: CategoryQuery, protected api: CategoryService) {
    super(query, api);
  }
}
