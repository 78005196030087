<section>
  <h1 class="l-sectionHeading">{{ 'webshop.Látogasd meg boltjainkat' | transloco }} <span>{{ 'webshop.Pesten' | transloco }}</span> {{ 'webshop.vagy' | transloco}} <span>{{ 'webshop.Budán' | transloco }}!</span></h1>
  <div class="row align-center">
    <div class="small-12 medium-10 large-6 column" *ngFor="let store of (stores$ | async)">
      <div class="decorBorder decorBorder--block">
        <div>
          <a class="contactPreview" [routerLink]="['shops'] | localize">
            <img [src]="store.image1.original" alt="{{ store.title }}">
            <div class="contactPreview-text">
              <h2>{{ store.title }}</h2>
              <p><strong [innerHTML]="store.address"></strong></p>
              <p><strong [innerHTML]="store.phoneNumber"></strong></p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
