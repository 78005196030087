import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {Cart} from "../../../webshop/cart/state/model";
import {CartService} from "../../../webshop/cart/state/service";
import {User} from "../../../authentication/user/state/model";
import {AuthService} from "../../../authentication/service/auth.service";

@Component({
  templateUrl: './component.html'
})
export class CheckoutComponent {
  cart$: Observable<Cart> = this.cartService.cart$;
  user$: Observable<User> = this.authService.user$;
  active = 'delivery_address';

  constructor(
    private cartService: CartService,
    private authService: AuthService
  ) {
  }

  setActive(active: string) {
    this.active = active;
  }

  next() {
    switch(this.active) {
      case 'delivery_address':
        this.active = 'billing_address';
        break;
      case 'delivery_mode':
        this.active = 'billing_mode';
        break;
      case 'billing_address':
        this.active = 'delivery_mode';
        break;
      case 'billing_mode':
        this.active = 'sum';
        break;
    }
  }
}
