import {Injectable} from '@angular/core';
import {CountryService} from './state/service';
import {CountryQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Country} from './state/model';
import {CountryState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Country, CountryState, CountryQuery, Store> {
  constructor(protected query: CountryQuery, protected api: CountryService) {
    super(query, api);
  }
}
