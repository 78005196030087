import {Component, Injector} from '@angular/core';
import {OrderService as OrderService} from './state/service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldConfig} from "../../components/form/field.interface";
import {of} from "rxjs";
import * as moment from 'moment';
import {first, shareReplay} from "rxjs/operators";

@Component({
  templateUrl: 'export.html',
  styleUrls: ['export.scss']
})
export class ManufacturableExportComponent {

  name = 'Gyártandók exportálása';
  group: FormGroup;
  field: FieldConfig = FieldConfig.create({
    label: of('Időszak'),
    name: "range",
    type: ""
  });

  constructor(
    public injector: Injector,
    private service: OrderService,
    protected formBuilder: FormBuilder) {

    this.group = formBuilder.group({});

    this.group.addControl('rangeFrom', formBuilder.control(''));
    this.group.addControl('rangeTo', formBuilder.control(''));

  }

  exportXls() {
    this.service.exportManufacturable$({
      from: moment(this.group.get('rangeFrom').value).toISOString(),
      to: moment(this.group.get('rangeTo').value).toISOString(),
    }).pipe(
      first(),
      shareReplay(),
    )
      .subscribe();
  }
}
