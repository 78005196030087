import {Address, Entity} from '../../../components/entity';
import {Country} from "../../country/state/model";
import {User} from "../../../authentication/user/state/model";

export class InvoiceAddress extends Entity {
  address: Address;
  country: Country;
  user: User;
  isDefault: boolean;

  constructor({
                id,
                isDefault,
                user,
                address,
                country,
                createdAt, updatedAt
              }: Partial<InvoiceAddress>) {
    super({id, createdAt, updatedAt});

    this.user = new User(user ?? {});
    this.address = new Address(address ?? {});
    this.country = new Country(country ?? {});
    this.isDefault = isDefault ?? false;

  }

  getNameProperty(): string {
    return 'address';
  }

  toString(): string {
    return  this.address.toString();
  }
}
