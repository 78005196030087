<section class="l-siteContent" id="theTop" *ngIf="items$ | async as items">
  <div class="row align-center" data-equalizer data-equalize-on="large">
    <div class="small-12 medium-9 large-6 column" *ngFor="let item of items">
      <header class="l-headFrizDecor l-headFrizDecor--pressDownloads" data-equalizer-watch>
        <h2 [innerHTML]="item.title"></h2>
      </header>
      <div class="g-bottom text-center">
        <a class="btn btn--basic" href="{{ item.url}}" [attr.target]="this.isOuterUrl(item.url) ? '_blank' :null">{{ 'webshop.Tovább' | transloco }}</a>
      </div>
      <div class="decorBorder decorBorder--block">
        <div><img src="{{ item.image1.default_image_medium}}" alt="kép címe"></div>
      </div>
    </div>
  </div>
</section>
