<section class="l-siteContent" id="theTop" *ngIf="item$ | async as item">
  <section class="post">
    <div class="row">
      <div class="small-12 column">
        <header class="post-head">
          <h1>{{ item.title }}</h1>
          <img src="/assets/images/menu_heading_decor.svg" alt="decor">
        </header>
        <article class="l-videoEmbed">
          <section>
            <p [innerHTML]="item.description"></p>
          </section>
          <section class="post-img" *ngIf="item.image.reseller_image_medium">
            <div class="row">
              <div class="small-12 medium-12 column">
                <!-- width: 550px -->
                <div class="decorBorder">
                  <div>
                    <div><img [src]="item.image.reseller_image_medium" alt="kép címe"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </article>
        <div class="g-row">
          <div class="row">
            <div class="small-12 large-6 column">
              <section>
                <!-- AddThis Button BEGIN -->
                <div class="addthis_toolbox addthis_default_style addthis_32x32_style">
                  <a class="addthis_button_facebook"></a>
                  <a class="addthis_button_instagram"></a>
                  <a class="addthis_button_vimeo"></a>
                  <a class="addthis_button_compact"></a>
                  <a class="addthis_counter addthis_bubble_style"></a>
                </div>
                <!-- AddThis Button END -->
              </section>
            </div>
            <div class="small-12 large-6 column">
              <div class="g-top g-top-hide-large-up text-right">
                <a class="l-moreBig" [href]="item.url" [attr.target]="this.isOuterUrl(item.url) ? '_blank' :null">{{ 'webshop.Tovább a viszonteladóhoz' | transloco }}</a>
              </div>
            </div>
          </div>
        </div>
        <app-gallery></app-gallery>
      </div>
    </div>
  </section>
</section>
