import {Component} from '@angular/core';
import {BlogService} from "../../../content/blog/state/service";
import {BehaviorSubject, Observable} from "rxjs";
import {Blog} from "../../../content/blog/state/model";
import {map, switchMap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {HttpResponse} from "@angular/common/http";
import {HttpGetConfig} from "@datorama/akita-ng-entity-service/lib/types";
import {HashMap} from "@datorama/akita";

@Component({
  templateUrl: './list.html'
})
export class WebshopBlogListComponent {
  posts$: Observable<Blog[]>;
  page$ = new BehaviorSubject<number>(1);
  maxPage$ = new BehaviorSubject<number>(999);
  perPage$ = new BehaviorSubject<number>(15);

  constructor(
    private blogService: BlogService,
    route: ActivatedRoute
  ) {
    this.posts$ = route.params.pipe(
      switchMap(() => this.page$.pipe(
        switchMap(page => this.perPage$.pipe(
          switchMap((perPage) => this.blogService.get<Blog[]>(undefined, {
              params: {
                c: perPage,
                p: page,
                s: '-createdAt'
              } as HashMap<any>,
              mapResponseFn: (res: HttpResponse<Blog[]>): Blog[] | null => {
                this.maxPage$.next(Math.ceil((Number(res.headers.get('X-Total-Count'))) / perPage));
                return res.body;
              },
              observe: 'response',
            } as HttpGetConfig<Blog[]>).pipe(
              map(items => {
                return items;
              }))
          )
        ))
      )),
    );
  }

  paging($event: number) {
    this.page$.next($event);
  }
}
