<section class="l-siteContent" id="theTop" *ngIf="items$ | async as items">
  <section>
    <header class="l-headFrizDecor">
      <h1>{{ 'webshop.Megjelenések' | transloco }}</h1>
    </header>
    <div class="row">
      <div class="small-12 column">
        <div class="row small-up-1 medium-up-2 large-up-3">
          <div class="column" *ngFor="let item of items">
            <div class="blockCard">
              <a class="blockCard-img" [routerLink]="[item.slug] | localize">
                <img src="{{ item.image1.default_image_medium}}" alt="kép címe">
              </a>
              <a class="blockCard-title" [routerLink]="[item.slug] | localize">
                {{ item.title }}</a>
              <div class="blockCard-text" [innerHTML]="item.content1">
                <a class="blockCard-more" [routerLink]="[item.slug] | localize">{{ 'webshop.Tovább' | transloco }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
