<div class="modal-open">
  <div class="reveal notificationModal without-overlay" id="notificationModal" data-reveal="" data-overlay="false"
       data-v-offset="0" data-close-on-click="true" data-animation-in="slide-in-down ease"
       data-animation-out="slide-out-up" role="dialog" aria-hidden="false" data-yeti-box="notificationModal"
       data-resize="notificationModal" data-t="56zsxt-t" style="display: block; top: 0px; left: 450px; margin: 0px;"
       tabindex="-1" data-events="resize">
    <div class="notificationModal-content">
      <button class="notificationModal-close" data-close="" type="button"
              (click)="onButtonClick()"></button>
      <p>  {{ this.message$ | async }}</p>
    </div>
  </div>
</div>
