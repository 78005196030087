import {Component} from '@angular/core';
import {Observable, of} from "rxjs";
import {PressVideo} from "../../../content/press-video/state/model";
import {PressVideoService} from "../../../content/press-video/state/service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  templateUrl: './listVideos.html'
})
export class WebshopPressVideosComponent {
  items$: Observable<PressVideo[]>;

  constructor(
    private service: PressVideoService,
    private sanitizer: DomSanitizer
  ) {
    this.items$ = service.items$;
  }


  safeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
