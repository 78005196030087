import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ProductVariantState, Store} from './store';

@Injectable({ providedIn: 'root' })
export class ProductVariantQuery extends QueryEntity<ProductVariantState> {

  constructor(protected store: Store) {
    super(store);
  }

}
