<div *ngIf="this.authService.user$ | async"></div>
<div *ngIf="(loading$ | async)" id="overlay">
  <mat-spinner></mat-spinner>
</div>

<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <app-side-nav (sidenavClose)="sidenav.close()"></app-side-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-navigation (sidenavToggle)="sidenav.toggle()"></app-navigation>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
