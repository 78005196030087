import {Injectable} from '@angular/core';
import {PressNewsService} from './state/service';
import {PressNewsQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {PressNews} from './state/model';
import {PressNewsState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<PressNews, PressNewsState, PressNewsQuery, Store> {
  constructor(protected query: PressNewsQuery, protected api: PressNewsService) {
    super(query, api);
  }
}
