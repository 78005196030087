<mat-toolbar-row>
  {{ service.getEntityTitle() | async }} {{ 'form.editing' | transloco }}
</mat-toolbar-row>
<mat-toolbar-row *ngIf="(entity$ | async) as product">
  <button *ngIf="product.multipart || true" style="margin-right: 15px" mat-raised-button [color]="activeTab === 'main' ? 'primary' : 'accent'"
          (click)="activeTab = 'main'">Termék adatlap
  </button>
  <button *ngIf="product.multipart" style="margin-right: 15px" mat-raised-button [color]="activeTab === 'images' ? 'primary' : 'accent'"
          (click)="activeTab = 'images'">Összerakó képek
  </button>
  <button *ngIf="product.multipart" style="margin-right: 15px" mat-raised-button [color]="activeTab === 'variant' ? 'primary' : 'accent'"
          (click)="activeTab = 'variant'">
    Alapértelmezett kombináció
  </button>
  <button mat-raised-button style="margin-right: 15px" [color]="activeTab === 'barcode' ? 'primary' : 'accent'" (click)="activeTab = 'barcode'">
    Vonalkódok
  </button>
  <button mat-raised-button [color]="'accent'" (click)="webshopPage()">
    Megtekintés webshopban
  </button>
</mat-toolbar-row>
<div style="
  display: flex;
  justify-content: center;">
  <div *ngIf="activeTab === 'main'" formHost [entity$]="entity$" [entityService]="service" [subForm]="false"
       (submit)="onSubmit($event)"
       (cancel)="onCancel()">
  </div>
  <div *ngIf="activeTab === 'images'">
    <ng-container *ngIf="(entity$ | async) as product">
      <ng-container *ngFor="let pparam of product.productParameters">
        <ng-container *ngFor="let type of (parameterTypes$(pparam, product) | async)">
          <ng-container *ngFor="let format of pparam.formats">
            <p>
              <app-upload-image [group]="customImagesGroup" [control]="getImageControl$(format, type) | async"
                                [field]="getFieldConfig(format, type)"></app-upload-image>
            </p>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveImages()">
        Mentés
      </button>
    </mat-card-actions>
  </div>
  <ng-container *ngIf="activeTab === 'variant'">
    <mat-card *ngIf="(entity$ | async) as product">
      <mat-card-content *ngIf="(entity$ | async) as product" [formGroup]="defaultVariantGroup">
        <p *ngFor="let pparam of product.productParameters">
          <mat-form-field>
            <mat-select [placeholder]="pparam.name"
                        [formControl]="getControl(pparam)"
                        [compareWith]="formService.compareById">
              <mat-option [value]="null">{{ 'form.selectNone' | transloco }}</mat-option>
              <mat-option *ngFor="let item of (parameterTypes$(pparam, product) | async)"
                          [value]="item">{{ item }}</mat-option>
            </mat-select>
          </mat-form-field>
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="saveProductVariant(product)">
          Mentés
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="activeTab === 'barcode'">
    <ng-container *ngIf="(entity$ | async) as product">
      <ng-container *ngIf="(barcodes$ | async) as barcodes">
        <mat-card>
          <mat-card-content [formGroup]="barcodesGroup">
            <p *ngIf="!barcodeProductParameterControl.value.length">
              <mat-form-field appearance="fill">
                <mat-label>Vonalkód</mat-label>
                <input matInput [formControl]="getBarcodeControl()">
              </mat-form-field>
            </p>
            <p *ngIf="product.productParameters.length">
              <mat-form-field>
                <mat-select [placeholder]="'Termékparaméter típus'"
                            [formControl]="barcodeProductParameterControl"
                            [multiple]="true"
                            [compareWith]="formService.compareById">
                  <!--mat-option [value]="[]">{{ 'form.selectNone' | transloco }}</mat-option-->
                  <mat-option *ngFor="let pparam of product.productParameters"
                              [value]="pparam">{{ pparam }}</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
            <table mat-table [dataSource]="barcodes$ | async" class="mat-elevation-z1 align-center text-center">
              <ng-container *ngFor="let pp of barcodeProductParameterControl.value" [matColumnDef]="getString(pp.id)">
                <th mat-header-cell *matHeaderCellDef>{{ pp }}</th>
                <td mat-cell *matCellDef="let barcodeRow">
                  {{ getProductParameterFromBarcode(barcodeRow, pp) }}
                </td>
              </ng-container>
              <ng-container matColumnDef="barcode">
                <th mat-header-cell *matHeaderCellDef>Vonalkód</th>
                <td mat-cell *matCellDef="let barcodeRow">
                  <p>
                    <mat-form-field appearance="fill">
                      <mat-label *ngIf="false">Vonalkód</mat-label>
                      <input matInput [formControl]="getBarcodeControl(barcodeRow.productParameterTypes)">
                    </mat-form-field>
                  </p>
                </td>
              </ng-container>
              <ng-container matColumnDef="stock">
                <th mat-header-cell *matHeaderCellDef>Készlet</th>
                <td mat-cell *matCellDef="let barcodeRow">
                  <p>
                    <mat-form-field appearance="fill">
                      <mat-label *ngIf="false">Készlet</mat-label>
                      <input matInput [disabled]="true" [value]="barcodeRow.stock">
                    </mat-form-field>
                  </p>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="getBarcodeColumns()"></tr>
              <tr mat-row *matRowDef="let row; columns: getBarcodeColumns();"></tr>
            </table>
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary" (click)="saveBarCodes()">
              Mentés
            </button>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
