import {Component, EventEmitter, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Cart} from "../../../webshop/cart/state/model";
import {CartService} from "../../../webshop/cart/state/service";
import {User} from "../../../authentication/user/state/model";
import {AuthService} from "../../../authentication/service/auth.service";
import {PaymentModeService} from "../../../webshop/payment-mode/state/service";
import {switchMap, tap} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {PaymentMode} from "../../../webshop/payment-mode/state/model";

@Component({
  selector: 'app-checkout-billing-mode',
  templateUrl: './billing-mode.html'
})
export class BillingModeComponent {
  cart$: Observable<Cart>;
  user$: Observable<User> = this.authService.user$;
  @Output() next = new EventEmitter();
  modeControl: FormControl;
  modes$ = this.paymentModeService.paymentModes$;
  group: FormGroup;

  constructor(
    private cartService: CartService,
    private authService: AuthService,
    public paymentModeService: PaymentModeService,
    private fb: FormBuilder
  ) {
    this.modeControl = fb.control(new PaymentMode({}));
    this.group = fb.group({});

    this.group.addControl('mode', this.modeControl);

    this.cart$ = this.modes$.pipe(
      switchMap(modes => this.cartService.cart$.pipe(
        tap(cart => {
          if (cart.paymentMode.id) {
            this.modeControl.setValue(modes.find(tr => tr.id === cart.paymentMode.id));
          }
        }))
      )
    );
  }

  save(cart: Cart) {
    if (this.modeControl.value?.id) {
      cart.paymentMode = this.modeControl.value;
      this.cartService.update(cart.id, cart)
        .pipe(
          tap(() => this.next.emit())
        )
        .subscribe();
    }
  }
}
