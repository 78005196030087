import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Currency} from './model';

export interface CurrencyState extends EntityState<Currency> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Currency' })
export class Store extends EntityStore<CurrencyState> {

  constructor() {
    super();
  }

}
