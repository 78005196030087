import {Component, Injector} from '@angular/core';
import {ProductParameterTypeState, Store as ProductParameterTypeStore} from './state/store';
import {ProductParameterType} from './state/model';
import {ProductParameterTypeService as ProductParameterTypeService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ProductParameterTypeQuery} from './state/query';
import {ProductParameterFormat} from "../product-parameter-format/state/model";
import {Observable, of, switchMap, tap} from "rxjs";
import {first, map, shareReplay, take} from "rxjs/operators";
import {ProductParameterFormatService} from "../product-parameter-format/state/service";
import {FieldConfig} from "../../components/form/field.interface";
import {ProductParameterFormatImage} from "../product-parameter-format-image/state/model";
import {ProductParameterImage} from "../product-parameter-image/state/model";
import {ProductParameterFormatImageService} from "../product-parameter-format-image/state/service";
import {Image} from "../../components/entity";


interface imageControl {
  format: ProductParameterFormat,
  control: FormControl,
  loaded: boolean
}

@Component({
  templateUrl: 'detail.html',
  styleUrls: [
    '../../components/entity-detail/component.scss',
    'detail.scss'
  ]
})
export class ProductParameterTypeDetailComponent extends EntityDetailComponent<ProductParameterType, ProductParameterTypeState, ProductParameterTypeStore> {

  activeTab = 'main';
  imageControls: imageControl[] = [];

  productParameterType: ProductParameterType | undefined;

  customImagesGroup: FormGroup;
  customImages: ProductParameterFormatImage[];

  customImages$ = this.entity$.pipe(
    switchMap(ppt => this.pptService.getImagesByProductParameterType(ppt)),
    tap(images => {
      this.customImages = images;
    }),
    shareReplay(1),
    take(1)
  );
  formats$ = this.entity$.pipe(
    map(ppt => ppt.productParameter.formats),
    shareReplay(1)
  );

  constructor(
    public injector: Injector,
    private pptService: ProductParameterTypeService,
    service: ProductParameterTypeService,
    private formatService: ProductParameterFormatService,
    private imageService: ProductParameterFormatImageService,
    protected query: ProductParameterTypeQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);

    this.customImagesGroup = formBuilder.group({});

    this.entity$ = this.entity$.pipe(
      tap(entity => {
        this.productParameterType = entity;
      })
    )

  }

  navigateToParent() {
    if (this.productParameterType.id && !this.savedEntity?.id) {
      this.savedEntity = this.productParameterType;
    }
    this.router.navigate(['../' + this.savedEntity?.id], {relativeTo: this.route}).then(() => {
      window.location.reload();
    });
  }

  getImageControl$(format: ProductParameterFormat) {
    let ctrl = this.imageControls.find(_ctrl => _ctrl.format.id === format.id);
    if (!ctrl) {
      ctrl = {
        format: format,
        control: this.formBuilder.control(''),
        loaded: false
      }
      this.customImagesGroup.addControl(format.name, ctrl.control);

      this.imageControls.push(ctrl);
    }

    return this.customImages$.pipe(
      switchMap(images => {
        const _img = images.find(item => item.format.id === format.id);
        if (!ctrl.loaded) {
          ctrl.loaded = true;
          if (_img && !ctrl.control.value.uploaded) {
            ctrl.control.setValue(_img.image);
          }

          if (!_img && !ctrl.control.value.uploaded) {
            ctrl.control.setValue(new Image({}));
          }
        }
        return of('');
      }),
      map(() => ctrl.control),
      shareReplay(1)
    );
  }

  getFieldConfig(format: ProductParameterFormat) {
    return FieldConfig.create({
      name: format.name,
      label: of(format.name),
      type: "image"
    })
  }

  saveImages() {

    let obs: Observable<any> = of('');
    this.imageControls.filter(item => item.control.value.uploaded).forEach(item => {

      if (item.control.value.uploaded) {
        const orig = this.customImages.find(img => img.format.id === item.format.id);
        if (orig) {
          orig.image = item.control.value;
          orig.productParameterType = this.productParameterType;
          obs = obs.pipe(switchMap(() => this.imageService.update(orig.id, orig as ProductParameterFormatImage)));
        } else {
          const ent = new ProductParameterImage({
            format: item.format,
            productParameterType: this.productParameterType,
            image: item.control.value
          });
          obs = obs.pipe(switchMap(() => this.imageService.add(ent)));
        }
      }
    });

    this.customImages.forEach(img => {
      const item = this.imageControls.find(_item => img.format.id === _item.format.id);
      if(!item) {
        obs = obs.pipe(switchMap(() => this.imageService.delete(img.id)));
        return;
      }
      if (!item.control.value.original) {
        obs = obs.pipe(switchMap(() => this.imageService.delete(img.id)));
      }
    });
    obs.pipe(tap(() => this.navigateToParent())).subscribe();
  }
}
