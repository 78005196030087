import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Observable, skip, tap} from "rxjs";
import {distinctUntilChanged, map, switchMap} from "rxjs/operators";
import {TranslocoService} from "@ngneat/transloco";
import {GoogleAnalyticsService} from "./google-analytics/service/analytics.service";
import {CartService} from "./webshop/cart/state/service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bomo';
  translationChange$: Observable<boolean>;

  constructor(private router: Router,
              private translationService: TranslocoService,
              private cartService: CartService,
              private route: ActivatedRoute,
  ) {


    this.translationChange$ = this.translationService.langChanges$.pipe(
      distinctUntilChanged(),
      skip(1),
      tap((locale) => {
        if (!window['noRedir']) {
          localStorage.setItem('language', locale);
          this.cartService.clearCart();
          if (window.location.pathname.includes('admin')) {
            window.location.reload();
          } else {
            window.location.pathname = '/' + locale;
          }
        }
      }),
      map(() => true)
    );
  }

  ngOnInit() {
  }
}
