import {AkitaFiltersPlugin} from 'akita-filters-plugin';
import {EntityState, EntityStore, HashMap, QueryEntity} from '@datorama/akita';
import {HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {EntityService} from './entity-service';
import {Entity} from './entity';
import {HttpGetConfig} from '@datorama/akita-ng-entity-service/lib/types';
import {catchError, map} from 'rxjs/operators';
// @ts-ignore
import * as moment from "moment-timezone";

export class FilterService<T extends Entity, S extends EntityState, Q extends QueryEntity<S>, St extends EntityStore<S>> extends AkitaFiltersPlugin<S> {
  constructor(
    protected query: QueryEntity<S>,
    protected api: EntityService<any, any>) {
    super(query);
    this.withServer(this.getOnChangeFilter(), {withSort: true, sortByKey: 's', sortByOrderKey: 'o'});

  }

  protected _total$ = new BehaviorSubject<number>(0);

  lastCall$: Observable<any> | null = null;

  get total$(): Observable<number> {
    return this._total$.asObservable();
  }

  public deletedItem(): void {
    this._total$.next(this._total$.value - 1);
  }

  public getFiltersObject(filtersNormalized: string | HashMap<any>) {
    const filtersObject = filtersNormalized as HashMap<any>;

    // clear filters
    filtersObject['q[]'] = [];
    const obj = this;

    Object.keys(filtersObject).filter(
      item => item ? !['q[]', 'p', 'page', 'c', 's', 'o', 'size'].includes(item) : false)
      ?.forEach((key) => {
        if (!Array.isArray(filtersObject[key])) {
          if (obj.api.getFields().find(field => field.name === key)?.type === 'date') {
            if (filtersObject[key].hasOwnProperty('from') && filtersObject[key].from) {
              let date = moment(filtersObject[key].from);
              date = date.startOf('day');
              date.tz('Europe/Budapest');

              filtersObject['q[]'].push(key + ':gte:' + date.subtract(date.utcOffset() * 2, 'minutes').toISOString());
            }
            if (filtersObject[key].hasOwnProperty('to') && filtersObject[key].to) {
              let date = moment(filtersObject[key].to);


              date = date.add(1 , 'day').startOf('day');
              date.tz('Europe/Budapest');

              filtersObject['q[]'].push(key + ':lte:' + date.subtract(date.utcOffset() * 2, 'minutes').toISOString());
            }
          } else if (typeof filtersObject[key] === 'object') {
            const entity = filtersObject[key] as Entity;
            filtersObject['q[]'].push(key + ':eq:' + entity.id);
          } else if (obj.api.getFields().find(field => field.name === key)?.inputType === 'boolean') {
            filtersObject['q[]'].push(key + ':eq:' + (filtersObject[key] ? 'true' : 'false'));
          } else if ((!isNaN(+filtersObject[key]) && filtersObject[key] !== null && filtersObject[key]) || (typeof filtersObject[key] == "boolean") || (typeof filtersObject[key] == "number")) {
            filtersObject['q[]'].push(key + ':eq:' + filtersObject[key]);
          } else {
            filtersObject['q[]'].push(key + ':like:' + filtersObject[key].trim());
          }
        } else {
          const inArray: string[] = [];
          filtersObject[key].forEach((item: any) => {
            if (item instanceof Entity) {
              inArray.push(item.id as string);
            }
          });

          if (inArray.length > 0) {
            filtersObject['q[]'].push(key + ':in:' + inArray.join(','));
          }
        }
        delete filtersObject[key];
      });

    if (filtersObject?.page || filtersObject?.page === 0) {
      filtersObject.p = filtersObject.page + 1;
      delete filtersObject.page;
    }
    if (filtersObject?.size) {
      filtersObject.c = filtersObject.size;
      delete filtersObject.size;
    }

    if (filtersObject?.s && this.api.getEntityParams()[filtersObject.s]) {
      if ((typeof this.api.getEntityParams()[filtersObject.s].value) === 'object') {
        if (this.api.getEntityNameParam(filtersObject.s)) {
          filtersObject.s = filtersObject.s + '.' + this.api.getEntityNameParam(filtersObject.s);
        } else {
        }
      }

      if (filtersObject.o && filtersObject.o === 'desc') {
        filtersObject.s = '-' + filtersObject.s;
      }
    }

    return filtersObject;
  }

  public getOnChangeFilter() {
    return (filtersNormalized: string | HashMap<any>) => {
      const filtersObject = this.getFiltersObject(filtersNormalized);
      if (!filtersObject.c) {
        if (this.lastCall$) {
          return this.lastCall$
        }
        return of([]);
      } else {
        this.lastCall$ = this.callApi<T>(filtersObject);
        return this.lastCall$;
      }
    };
  }

  protected callApi<T>(filtersObject: HashMap<any>) {
    return this.api.get<T>(undefined, {
      params: filtersObject,
      mapResponseFn: (res: HttpResponse<T[]>): T[] | null => {
        this._total$.next(Number(res.headers.get('X-Total-Count')));
        return res.body;
      },
      observe: 'response',
    } as HttpGetConfig<T>).pipe(
      map(items => {
      return items;
    }));
  }
}
