import {Injectable} from '@angular/core';
import {PressDownloadService} from './state/service';
import {PressDownloadQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {PressDownload} from './state/model';
import {PressDownloadState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<PressDownload, PressDownloadState, PressDownloadQuery, Store> {
  constructor(protected query: PressDownloadQuery, protected api: PressDownloadService) {
    super(query, api);
  }
}
