import {Injectable} from '@angular/core';
import {NewsService} from './state/service';
import {NewsQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {News} from './state/model';
import {NewsState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<News, NewsState, NewsQuery, Store> {
  constructor(protected query: NewsQuery, protected api: NewsService) {
    super(query, api);
  }
}
