<section class="l-siteContent" id="theTop">
  <section class="feedbackMessage">
    <div class="row align-center">
      <div class="small-12 medium-10 large-6 xlarge-4 column"  *ngIf="(login$ | async)">
        <h1>{{ 'webshop.Köszönjük, hogy regisztráltál.' | transloco}}</h1>
        <img src="/assets/images/menu_heading_decor.svg" alt="decor">
        <div class="feedbackMessage-content g-top">
          <p>{{ 'webshop.Nemsokára e-mail-t kaptál tőlünk. A levélben található linkre kattintva tudod megerősíteni regisztrációdat.' | transloco}}</p>
          <p>{{ 'webshop.Ez után ne feledd el befejezni a regisztrációs folyamatot és kitölteni a vásárláshoz szükséges egyéb adatid (számlázási cím, szállítási cím, stb)' | transloco}}</p>
          <div class="g-top">
            <p>{{ 'webshop.Köszönjük:' | transloco}}</p>
            <span class="feedbackMessage-signature">{{ 'webshop.Bomo Art csapata' | transloco}}</span>
          </div>
          <img class="anim anim--fromLeft decorImg decorImg--signUpAfterLeft" src="/assets/images/decor/balloon_01.png" alt="decor">
          <img class="anim anim--fromRight decorImg decorImg--signUpAfterRight" src="/assets/images/decor/balloon_02.png" alt="decor">
        </div>
      </div>
      <div class="small-12 medium-10 large-6 xlarge-4 column"  *ngIf="!(login$ | async)">
        <h1>{{ 'webshop.Onetime login failed cím' | transloco}}</h1>
        <img src="/assets/images/menu_heading_decor.svg" alt="decor">
        <div class="feedbackMessage-content g-top">
          <p>{{ 'webshop.Onetime login failed leírás' | transloco}}</p>
          <div class="g-top">
            <p>{{ 'webshop.Köszönjük:' | transloco}}</p>
            <span class="feedbackMessage-signature">{{ 'webshop.Bomo Art csapata' | transloco}}</span>
          </div>
          <img class="anim anim--fromLeft decorImg decorImg--signUpAfterLeft" src="/assets/images/decor/balloon_01.png" alt="decor">
          <img class="anim anim--fromRight decorImg decorImg--signUpAfterRight" src="/assets/images/decor/balloon_02.png" alt="decor">
        </div>
      </div>
    </div>
  </section>
</section>
