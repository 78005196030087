import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable} from 'rxjs';
import {Entity} from '../../entity';

@Component({
  selector: 'app-input',
  template: `
    <mat-form-field *ngIf="field.name && field.inputType && group?.get(field.name)" [formGroup]="group" appearance="fill">
      <mat-label>{{ field.label | async | default: '' }}</mat-label>
      <input matInput [formControlName]="field.name" [attr.disabled]="field.disabled"
             [placeholder]="''"
             [type]="field.inputType">
      <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group?.get(field.name)?.hasError(validation.name)">{{validation.message$ | async}}</mat-error>
      </ng-container>
      <ng-container ngProjectAs="mat-error">
        <mat-error
          *ngIf="group?.get(field.name)?.hasError('custom')">{{group?.get(field.name)?.getError('custom').message}}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styleUrls: ['common.scss'],
})
export class InputComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  parentEntity$ = new Observable<Entity>();

  constructor() {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }

  ngOnInit() {
    if (this.field.disabled) {
      this.group.get(this.field.name)?.disable();
    }
  }
}
