import {Component} from '@angular/core';
import {AuthService} from "../../../authentication/service/auth.service";
import {WallpaperService} from "../../../content/wallpaper/state/service";
import {Observable} from "rxjs";
import {Wallpaper} from "../../../content/wallpaper/state/model";

@Component({
  selector: 'app-webshop-wallpaper',
  styleUrls: ['component.scss'],
  templateUrl: './component.html'
})
export class WallpaperComponent {

  item$: Observable<Wallpaper> = this.wallpaperService.current$;

  constructor(
    public authService: AuthService,
    private wallpaperService: WallpaperService
  ) {
  }
}
