import {Component} from '@angular/core';

@Component({
  templateUrl: './component.html'
})
export class AdminComponent {

  constructor(
  ) {
  }
}
