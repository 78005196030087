import {Entity, Image} from '../../../components/entity';

export class PressVideo extends Entity {
  url: string;
  title: string;
  image: Image;

  constructor({ id, image, url, title, createdAt, updatedAt }: Partial<PressVideo>) {
    super({ id, createdAt, updatedAt });
    this.url = url ?? '';
    this.title = title ?? '';
    this.image = new Image(image ?? {});
  }

  getNameProperty(): string {
    return 'title';
  }
}
