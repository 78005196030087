import {Component, Injector} from '@angular/core';
import {RoleState, Store as RoleStore} from './state/store';
import {Role} from './state/model';
import {RoleService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {RoleQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class RoleDetailComponent extends EntityDetailComponent<Role, RoleState, RoleStore> {

  constructor(
    public injector: Injector,
    service: RoleService,
    protected query: RoleQuery
  ) {
    super(injector, service, query);
  }
}
