import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {ShippingAddress} from './model';

export interface ShippingAddressState extends EntityState<ShippingAddress> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ShippingAddress' })
export class Store extends EntityStore<ShippingAddressState> {

  constructor() {
    super();
  }

}
