import {ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {DynamicFormHostDirective} from '../dynamic-form-host.directive';
import {Entity} from '../../entity';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';

interface subEntityElement {
  group: FormGroup;
  entity$: Observable<Entity>;
}

@Component({
  selector: 'app-sub-entity',
  template: `

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ field.subEntityTitle$ | async }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button (click)="addEntity()" type="button" mat-raised-button color="primary">{{ field.label | async | default: '' }}</button>

      <div *ngFor="let element of entityList$ | async" formHost [group]="element.group" [entity$]="element.entity$"
           [entityService]="this.field.subEntityService" [subForm]="true">
      </div>
    </mat-expansion-panel>
  `,
  styleUrls: ['common.scss'],
})

export class SubEntityComponent implements OnInit, OnDestroy {

  @ViewChildren(DynamicFormHostDirective) formHosts!: QueryList<DynamicFormHostDirective>;
  field: FieldConfig;
  private _group: FormGroup;

  entityList = new BehaviorSubject<subEntityElement[]>([]);
  entityList$ = this.entityList.asObservable();
  private _parentEntity$ = new Observable<Entity>();
  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this._group = new FormGroup({});
    this.field = new FieldConfig();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  get parentEntity$(): Observable<Entity> {
    return this._parentEntity$;
  }

  set parentEntity$(value: Observable<Entity>) {
    this._parentEntity$ = value;

    this.subscriptions.push(this._parentEntity$.subscribe(entity => {
      const valArr = entity[this.field.name] as Entity[];
      valArr.forEach(entity => {
        const group = this.formBuilder.group({});
        (this._group.get(this.field.name) as FormArray).push(group);
        const _current = this.entityList.value;
        _current.push({
            entity$: of(entity),
            group
          }
        );
        this.entityList.next(_current);
      });
      this.cdr.detectChanges();
    }));
  }

  get group(): FormGroup {
    return this._group;
  }

  set group(value: FormGroup) {
    this._group = value;
  }

  addEntity() {
    const group = this.formBuilder.group({});
    (this._group.get(this.field.name) as FormArray).push(group);
    const _current = this.entityList.value;
    _current.push({
        entity$: of(this.field.subEntityService?.factory() as Entity),
        group
      }
    );
    this.entityList.next(_current);
    this.cdr.detectChanges();
  }
}
