import {Entity} from '../../../components/entity';

export class BlogCategory extends Entity {
  slug: string;
  name: string;

  constructor({id, slug, name, createdAt, updatedAt}: Partial<BlogCategory>) {
    super({id, createdAt, updatedAt});
    this.slug = slug ?? '';
    this.name = name ?? '';
  }

  getNameProperty(): string {
    return 'name';
  }
}
