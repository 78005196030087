import {Component, Injector} from '@angular/core';
import {PriceListState, Store} from './state/store';
import {PriceList} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {PriceListQuery} from './state/query';
import {PriceListService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class PriceListListComponent extends EntityListComponent<PriceList, PriceListState, Store> {

  translationDomain = 'priceList';

  constructor(
    public injector: Injector,
    protected service: PriceListService,
    query: PriceListQuery,
    private PriceListFilterService: FilterService) {
    super(injector, service, query, PriceListFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
