import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {CommonModule, formatCurrency, getCurrencySymbol} from '@angular/common';
import {RoutingModule} from './routing';
import {MaterialModule} from '../material/material.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {Module as ComponentsModule} from '../components/module';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {IndexComponent} from "./main/index/component";
import {InstagramComponent} from "./main/instagram/component";
import {PopupComponent} from "./main/popup/component";
import {ShopsComponent} from "./main/shops/component";
import {WallpaperComponent} from "./main/wallpaper/component";
import {WebshopHeaderComponent} from "./header/component";
import {WebshopFooterComponent} from "./footer/component";
import {WebshopSearchComponent} from "./search/component";
import {WebshopMenuComponent} from "./menu/component";
import {RegistrationComponent} from "./usermanager/registration/component";
import {ShopsViewComponent} from "./webshop/shops/view";
import {ShopsResellerComponent} from "./webshop/shops/reseller";
import {WebshopCategoryListComponent} from "./webshop/categories/component";
import {ActivateComponent} from "./usermanager/activate/component";
import {ForgottenPasswordComponent} from "./usermanager/forgotten-password/component";
import {LoginComponent} from "./usermanager/login/component";
import {LoginPanelComponent} from "./usermanager/login-panel/component";
import {NewsletterSignUpComponent} from "./usermanager/newsletter-sign-up/component";
import {OneTimeComponent} from "./usermanager/onetime/component";
import {ProfileComponent} from "./usermanager/profile/component";
import {PaymentComponent} from "./webshop/payment/component";
import {CartComponent} from "./webshop/cart/component";
import {WebshopContactComponent} from "./content/contact/view";
import {WebshopBlogListComponent} from "./content/blog/list";
import {WebshopBlogViewComponent} from "./content/blog/view";
import {WebshopContactWholesaleComponent} from "./content/contact/wholesale";
import {WebshopNewsListComponent} from "./content/news/list";
import {WebshopNewsViewComponent} from "./content/news/view";
import {WebshopNewsletterListComponent} from "./content/newsletter/list";
import {WebshopNewsletterViewComponent} from "./content/newsletter/view";
import {WebshopNewsletterSubscribeComponent} from "./content/newsletter/subscribe";
import {WebshopPageViewComponent} from "./content/page/view";
import {WebshopPressNewsComponent} from "./content/press/listNews";
import {WebshopPressDownloadsComponent} from "./content/press/listDownloads";
import {WebshopPressViewComponent} from "./content/press/view";
import {WebshopPressViewNewsComponent} from "./content/press/viewNews";
import {WebshopPressVideosComponent} from "./content/press/listVideos";
import {GalleryComponent} from "./gallery";
import {ImageComponent} from "./image";
import {EditAddressComponent} from "./editAddress";
import {LocalizeRouterModule} from "@penleychan/ngx-transloco-router";
import {WebshopWebshopComponent} from "./webshop/webshop/component";
import {WebshopProductListComponent} from "./webshop/products/list";
import {WebshopProductComponent} from "./webshop/products/component";
import {InsideViewportDirective} from "./inside-viewport.directive";
import {WebshopNotFoundComponent} from "./not-found/component";
import {BillingAddressComponent} from "./webshop/checkout/billing-address";
import {BillingModeComponent} from "./webshop/checkout/billing-mode";
import {DeliveryModeComponent} from "./webshop/checkout/delivery-mode";
import {DeliveryAddressComponent} from "./webshop/checkout/delivery-address";
import {CheckoutLoginComponent} from "./webshop/checkout/login";
import {SumComponent} from "./webshop/checkout/sum";
import {FormsModule} from "@angular/forms";
import {CheckoutComponent} from "./webshop/checkout/component";
import {FoundationModule} from "../foundation/foundation.module";
import {NgxPhotoswipeModule} from "@fnxone/ngx-photoswipe";
import {WebshopWallpaperViewComponent} from "./content/wallpaper/view";
import {NgxInstaModule} from "ngx-insta";
import {CheckoutRegistrationComponent} from "./webshop/checkout/registration-completed";
import {GoogleMapsModule} from "@angular/google-maps";
import {WebshopBlogMainComponent} from "./content/blog/main";
import {WebshopCookieComponent} from "./footer/cookie";
import {WebshopPagerComponent} from "./pager/component";
import {ConfigService} from "../webshop/config/state/service";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'orderBy'
})
export class SortPipe implements PipeTransform {
  transform<T>(_value: T[], propName: string): T[] {
    const value = Object.assign([], _value);
    let order = 1;
    if (propName.charAt(0) === '-') {
      order = -1;
      propName = propName.substring(1);
    }
    return value.sort((a, b) => {
      if (a[propName] < b[propName]) {
        return -1 * order;
      } else if (a[propName] === b[propName]) {
        return 0;
      } else if (a[propName] > b[propName]) {
        return 1 * order;
      }
      if (a[propName] > b[propName]) return 1
      else return 1;
    });

  }
}


@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: 'linkify'})
export class LinkifyPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string) {
    return replaceAll(value,'<a href="http', '<a target="_blank" href="http');
  }
}
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function orderByWeight(value: any[], order: number, propName: string) {
  return value.sort((a, b) => {
    if (a['weight'] || b['weight']) {
      if (a['weight'] && b['weight'] === null) {
        return 1 * order;
      }
      if (a['weight'] === null && b['weight']) {
        return -1 * order;
      }
      if (a['weight'] !== null && b['weight'] !== null) {
        return -1 * order * (a['weight'] < b['weight'] ? 1 : -1);
      }
    }
    if (a[propName] < b[propName]) {
      return -1 * order;
    } else if (a[propName] === b[propName]) {
      return 0;
    } else if (a[propName] > b[propName]) {
      return 1 * order;
    }
    if (a[propName] > b[propName]) return 1
    else return 1;
  });
}

@Pipe({
  name: 'orderByWeight'
})
export class WeightPipe implements PipeTransform {
  transform<T>(_value: T[], propName: string): T[] {
    const value = Object.assign([], _value);
    let order = 1;
    if (propName.charAt(0) === '-') {
      order = -1;
      propName = propName.substring(1);
    }
    return orderByWeight(value, order, propName);

  }
}

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform<T>(value: T[], properties: object): T[] {

    if (!value) {
      return [];
    }
    return value.filter(item => {
      return Object.getOwnPropertyNames(properties).every(property => {
        return item[property] === properties[property];
      });
    });

  }
}

@Pipe({
  name: 'webshopCurrency',
})
export class WebshopCurrencyPipe implements PipeTransform {

  constructor(
    private configService: ConfigService,
    private translatorService: TranslocoService) {
  }

  transform(
    value: number,
    currencyCode: string = 'Ft',
    display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol',
    digitsInfo: string = '3.2-2',
    quantity: number = 1,
    locale: string = 'hu',
  ): Observable<string | null> {

    if (currencyCode) {
      return of(formatCurrency(
        value,
        this.translatorService.getActiveLang(),
        getCurrencySymbol(currencyCode, 'wide'),
        currencyCode,
        digitsInfo,
      ));
    }
    return this.configService.config$.pipe(
      map(config => {
        currencyCode = 'Ft';
        if (this.translatorService.getActiveLang() !== 'hu') {
          currencyCode = 'EUR';
          if (quantity > 0) {
            value = (Math.round((value / quantity / (config.eur ?? 1)) * 10) / 10) * quantity;
          } else {
          }
        }

        return formatCurrency(
          value,
          this.translatorService.getActiveLang(),
          getCurrencySymbol(currencyCode, 'wide'),
          currencyCode,
          digitsInfo,
        );
      })
    )


  }
}


@NgModule({
  declarations: [
    CheckoutComponent,
    IndexComponent,
    InstagramComponent,
    PopupComponent,
    ShopsComponent,
    WallpaperComponent,
    WebshopHeaderComponent,
    WebshopFooterComponent,
    WebshopSearchComponent,
    WebshopMenuComponent,
    WebshopCategoryListComponent,
    RegistrationComponent,
    ShopsViewComponent,
    ShopsResellerComponent,
    ActivateComponent,
    ForgottenPasswordComponent,
    LoginComponent,
    LoginPanelComponent,
    NewsletterSignUpComponent,
    OneTimeComponent,
    ProfileComponent,
    PaymentComponent,
    CartComponent,
    WebshopContactComponent,
    WebshopBlogListComponent,
    WebshopBlogViewComponent,
    WebshopBlogMainComponent,
    WebshopContactWholesaleComponent,
    WebshopNewsListComponent,
    WebshopNewsViewComponent,
    WebshopNewsletterListComponent,
    WebshopNewsletterViewComponent,
    WebshopNewsletterSubscribeComponent,
    WebshopPageViewComponent,
    WebshopPressNewsComponent,
    WebshopPressDownloadsComponent,
    WebshopPressViewComponent,
    WebshopPressViewNewsComponent,
    WebshopPressVideosComponent,
    WebshopWallpaperViewComponent,
    GalleryComponent,
    ImageComponent,
    EditAddressComponent,
    SortPipe,
    FilterPipe,
    WeightPipe,
    WebshopCurrencyPipe,
    WebshopWebshopComponent,
    WebshopProductListComponent,
    WebshopProductComponent,
    InsideViewportDirective,
    WebshopNotFoundComponent,
    BillingAddressComponent,
    BillingModeComponent,
    DeliveryModeComponent,
    DeliveryAddressComponent,
    CheckoutLoginComponent,
    SumComponent,
    CheckoutRegistrationComponent,
    WebshopCookieComponent,
    WebshopPagerComponent,
    SafeHtmlPipe,
    LinkifyPipe
  ],
    exports: [
        WebshopFooterComponent,
        WebshopHeaderComponent,
        WebshopMenuComponent,
        WebshopSearchComponent,
        SortPipe,
        FilterPipe,
        InsideViewportDirective,
        LoginPanelComponent,
        PopupComponent,
        WebshopCurrencyPipe,
    ],
  imports: [
    ComponentsModule,
    CommonModule,
    MaterialModule,
    RoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslocoModule,
    LocalizeRouterModule,
    FormsModule,
    FoundationModule,
    NgxPhotoswipeModule,
    NgxInstaModule,
    GoogleMapsModule,
  ],
})
export class WebshopClientModule {
}
