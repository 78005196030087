import {Component} from '@angular/core';
import {AuthService} from '../../authentication/service/auth.service';

@Component({
  selector: 'app-webshop-cookie',
  styleUrls: ['cookie.scss'],
  templateUrl: './cookie.html'
})
export class WebshopCookieComponent {

  visible: boolean = true;

  constructor(
    public authService: AuthService,
  ) {
    this.visible = localStorage.getItem('cookie') !== '1';
  }

  close() {
    this.visible = false;
    localStorage.setItem('cookie', '1');
  }
}
