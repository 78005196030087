import {Component, Injector} from '@angular/core';
import {PressVideoState, Store} from './state/store';
import {PressVideo} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {PressVideoQuery} from './state/query';
import {PressVideoService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class PressVideoListComponent extends EntityListComponent<PressVideo, PressVideoState, Store> {

  translationDomain = 'pressVideo';

  constructor(
    public injector: Injector,
    protected service: PressVideoService,
    query: PressVideoQuery,
    private PressVideoFilterService: FilterService) {
    super(injector, service, query, PressVideoFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
