import {Entity, Image} from '../../../components/entity';
import {Gallery} from "../../../content/gallery/state/model";

export class Config extends Entity {
  webshopOpen: boolean;
  slider1Url: string;
  slider2Url: string;
  slider3Url: string;
  offer1Url: string;
  offer2Url: string;
  news1Url: string;
  news2Url: string;
  hero1Url: string;
  hero2Url: string;
  slider1Image: Image;
  slider2Image: Image;
  slider3Image: Image;
  offer1Gallery: Gallery;
  offer2Gallery: Gallery;
  news1Image: Image;
  news2Image: Image;
  hero1Image: Image;
  hero2Image: Image;
  slider1Title: string;
  slider2Title: string;
  slider3Title: string;
  slider1Subtitle: string;
  slider2Subtitle: string;
  slider3Subtitle: string;
  offer1Title: string;
  offer2Title: string;
  //offer3Title: string;
  offer1Subtitle: string;
  offer2Subtitle: string;
  //offer3Subtitle: string;
  news1Title: string;
  news2Title: string;
  news1Subtitle: string;
  news2Subtitle: string;
  hero1Title: string;
  hero2Title: string;
  hero1Subtitle: string;
  hero2Subtitle: string;
  captionPrice: number;
  eur: number;

  constructor({
                id,
                webshopOpen,
                slider1Title,
                slider2Title,
                slider3Title,
                slider1Url,
                slider2Url,
                slider3Url,
                slider1Subtitle,
                slider2Subtitle,
                slider3Subtitle,
                slider1Image,
                slider2Image,
                slider3Image,
                news1Title,
                news2Title,
                news1Subtitle,
                news2Subtitle,
                news1Image,
                news2Image,
                news1Url,
                news2Url,
                hero1Title,
                hero2Title,
                hero1Subtitle,
                hero2Subtitle,
                hero1Image,
                hero2Image,
                captionPrice,
                offer1Gallery,
                offer2Gallery,
                offer1Title,
                offer2Title,
                offer1Subtitle,
                offer2Subtitle,
                offer1Url,
                offer2Url,
                hero1Url,
                hero2Url,
                eur,
                createdAt, updatedAt
              }: Partial<Config>) {
    super({id, createdAt, updatedAt});

    this.captionPrice = captionPrice ?? 0;
    this.eur = eur ?? 0;
    this.webshopOpen = webshopOpen ?? false;

    this.offer1Gallery = new Gallery(offer1Gallery ?? {});
    this.offer2Gallery = new Gallery(offer2Gallery ?? {});

    this.hero1Title = hero1Title ?? '';
    this.hero2Title = hero2Title ?? '';
    this.news1Title = news1Title ?? '';
    this.news2Title = news2Title ?? '';
    this.offer1Title = offer1Title ?? '';
    this.offer2Title = offer2Title ?? '';
    this.slider1Title = slider1Title ?? '';
    this.slider2Title = slider2Title ?? '';
    this.slider3Title = slider3Title ?? '';
    this.offer1Url = offer1Url ?? '';
    this.offer2Url = offer2Url ?? '';

    this.slider1Url = slider1Url ?? '';
    this.slider2Url = slider2Url ?? '';
    this.slider3Url = slider3Url ?? '';

    this.news1Url = news1Url ?? '';
    this.news2Url = news2Url ?? '';

    this.hero1Subtitle = hero1Subtitle ?? '';
    this.hero2Subtitle = hero2Subtitle ?? '';
    this.news1Subtitle = news1Subtitle ?? '';
    this.news2Subtitle = news2Subtitle ?? '';
    this.offer1Subtitle = offer1Subtitle ?? '';
    this.offer2Subtitle = offer2Subtitle ?? '';
    this.slider1Subtitle = slider1Subtitle ?? '';
    this.slider2Subtitle = slider2Subtitle ?? '';
    this.slider3Subtitle = slider3Subtitle ?? '';


    this.hero1Url = hero1Url ?? '';
    this.hero2Url = hero2Url ?? '';

    this.hero1Image = new Image(hero1Image ?? {});
    this.hero2Image = new Image(hero2Image ?? {});
    this.news1Image = new Image(news1Image ?? {});
    this.news2Image = new Image(news2Image ?? {});
    this.slider1Image = new Image(slider1Image ?? {});
    this.slider2Image = new Image(slider2Image ?? {});
    this.slider3Image = new Image(slider3Image ?? {});
  }

  getNameProperty(): string {
    return '';
  }
}
