import {Component, Injector} from '@angular/core';
import {PressUserState, Store as PressUserStore} from './state/store';
import {PressUser} from './state/model';
import {PressUserService as PressUserService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {PressUserQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class PressUserDetailComponent extends EntityDetailComponent<PressUser, PressUserState, PressUserStore> {

  constructor(
    public injector: Injector,
    service: PressUserService,
    protected query: PressUserQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
