import {Entity} from '../../../components/entity';

export class Page extends Entity {
  slug: string;
  title: string;
  subtitle: string;
  content: string;

  constructor({
                id,
                slug,
                title,
                subtitle,
                content,
                createdAt, updatedAt
              }: Partial<Page>) {
    super({id, createdAt, updatedAt});
    this.slug = slug ?? '';
    this.title = title ?? '';
    this.content = content ?? '';
    this.subtitle = subtitle ?? '';
  }

  getNameProperty(): string {
    return 'title';
  }
}
