import {Component, HostListener} from '@angular/core';
import {Observable, of} from 'rxjs';
import {MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class NotificationComponent {
  message$: Observable<string> = of('');
  class = '';
  hasButton = false;
  buttonText$: Observable<string> = of('');

  constructor(
    private snackBarRef: MatSnackBarRef<NotificationComponent>,
  ) {
  }

  public onButtonClick() {
  }

  @HostListener('click')
  public dismiss() {
    this.snackBarRef.dismiss();
  }
}
