import {Address, Entity} from '../../../components/entity';
import {Product} from "../../../products/product/state/model";
import {ProductVariant} from "../../../products/product-variant/state/model";
import {PaymentMode} from "../../payment-mode/state/model";
import {User} from "../../../authentication/user/state/model";
import {Country} from "../../country/state/model";
import {Store} from "../../store/state/model";
import {Transporter} from "../../transporter/state/model";
import {Coupon} from "../../coupon/state/model";
import * as moment from "moment";

export class OrderLog extends Entity {

  content: string;

  constructor({
                content,
                createdAt,
                updatedAt,
                id
              }: Partial<OrderLog>) {
    super({id, createdAt, updatedAt});
    this.content = content ?? '';
  }

  getNameProperty(): string {
    return "";
  }
}

export class OrderItem extends Entity {
  product: Product;
  quantity: number;
  productVariant: ProductVariant;
  caption: string;
  captionSpecial: string;
  price: number;

  constructor({
                product,
                quantity,
                productVariant,
                caption,
                captionSpecial,
                price,
                createdAt,
                updatedAt,
                id
              }: Partial<OrderItem>) {
    super({id, createdAt, updatedAt});
    this.productVariant = new ProductVariant(productVariant ?? {});
    this.product = new Product(product ?? {});
    this.caption = caption ?? '';
    this.captionSpecial = captionSpecial ?? '';
    this.price = price ?? 0;
    this.quantity = quantity ?? 0;
  }

  getNameProperty(): string {
    return "";
  }
}

export class Order extends Entity {
  items: OrderItem[];
  sumPrice: number;
  shippingPrice: number;
  currency: string;
  productsDiscount: number;
  couponDiscount: number;
  billingAddress: Address;
  shippingAddress: Address;
  paymentMode: PaymentMode;
  user: User;
  coupon: Coupon;
  shippingCountry: Country;
  billingCountry: Country;
  orderId: number;
  sumDiscountedPrice: number;
  store: Store;
  trackingCode: string;
  paymentUrl: string;
  shippingMode: Transporter;
  innerDescription: string;
  status: number;
  labelGenerated: boolean;


  constructor({
                id,
                trackingCode,
                innerDescription,
                paymentUrl,
                orderId,
                coupon,
                sumDiscountedPrice,
                store,
                shippingMode,
                items,
                sumPrice,
                status,
                shippingPrice,
                productsDiscount,
                couponDiscount,
                billingAddress,
                shippingAddress,
                paymentMode,
                currency,
                labelGenerated,
                user,
                shippingCountry,
                billingCountry,
                createdAt, updatedAt
              }: Partial<Order>) {
    super({id, createdAt, updatedAt});

    this.labelGenerated = labelGenerated ?? false;
    this.coupon = new Coupon(coupon ?? {});
    this.status = status ?? 0;
    this.sumDiscountedPrice = sumDiscountedPrice ?? 0;
    this.store = new Store(store ?? {});
    this.orderId = orderId ?? 0;
    this.shippingMode = new Transporter(shippingMode ?? {});
    this.paymentUrl = paymentUrl ?? '';
    this.currency = currency ?? 'HUF';
    this.trackingCode = trackingCode ?? '';
    this.innerDescription = innerDescription ?? '';
    this.items = (items ?? []).map(item => new OrderItem(item ?? {}));
    this.sumPrice = sumPrice ?? 0;
    this.shippingAddress = new Address(shippingAddress ?? {});
    this.billingAddress = new Address(billingAddress ?? {});
    this.paymentMode = new PaymentMode(paymentMode ?? {});
    this.shippingPrice = shippingPrice ?? 0;
    this.couponDiscount = couponDiscount ?? 0;
    this.user = new User(user ?? {});
    this.shippingCountry = new Country(shippingCountry ?? {});
    this.billingCountry = new Country(billingCountry ?? {});
    this.productsDiscount = productsDiscount ?? 0;
  }

  getNameProperty(): string {
    return 'user';
  }

  toString() {
    return this.user.toString() + ' - ' + moment(this.createdAt).format('YYYY-MM-DD');
  }
}
