<section class="l-search" [ngClass]="{ 'l-search--opened': opened }">
  <button class="l-search-close" aria-label="Bezár" type="button" (click)="toggle()">{{ 'webshop.Bezár' | transloco }}</button>
  <form class="form form--offContent form--searchMain">
    <p>{{ 'webshop.„I don\'t search\, I find”' | transloco }} <span>{{ 'webshop.- Pablo Picasso' | transloco }}</span></p>
    <div class="row collapse large-unstack form--searchMain-rows">
      <div class="columns">
        <div class="form--searchMain-input">
          <img src="/assets/images/bomo_hand_gray.svg" alt="keresés">
          <input [formControl]="termControl" type="text" [placeholder]="'webshop.Írd ide, mit keresel...' | transloco">
        </div>
      </div>
      <div class="shrink"><button (click)="search()" class="btn btn--light" type="submit">{{ 'webshop.Keresés' | transloco }}</button></div>
    </div>
  </form>
</section>
