<mat-toolbar-row>
  {{ service.getEntityTitle() | async }} {{ 'form.editing' | transloco }}
</mat-toolbar-row>
<mat-toolbar-row>
  <button style="margin-right: 15px" mat-raised-button [color]="activeTab === 'main' ? 'primary' : 'accent'"
          (click)="activeTab = 'main'">Termék adatlap
  </button>
  <button style="margin-right: 15px" mat-raised-button [color]="activeTab === 'images' ? 'primary' : 'accent'"
          (click)="activeTab = 'images'">Összerakó képek
  </button>
</mat-toolbar-row>
<div style="
  display: flex;
  justify-content: center;">
  <div *ngIf="activeTab === 'main'" formHost [entity$]="entity$" [entityService]="service" [subForm]="false"
       (submit)="onSubmit($event)"
       (cancel)="onCancel()">
  </div>
  <div *ngIf="activeTab === 'images'">
    <mat-card *ngIf="(entity$ | async) as productParameterType">
      <mat-card-content>
        <ng-container *ngFor="let format of (formats$ | async)">
          <p>
            <app-upload-image [group]="customImagesGroup" [control]="(getImageControl$(format) | async)"
                              [field]="getFieldConfig(format)"></app-upload-image>
          </p>
        </ng-container>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="saveImages()">
          Mentés
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
