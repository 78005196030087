import {Component, Injector} from '@angular/core';
import {Store, TagState} from './state/store';
import {Tag} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {TagQuery} from './state/query';
import {TagService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class TagListComponent extends EntityListComponent<Tag, TagState, Store> {

  translationDomain = 'tag';

  constructor(
    public injector: Injector,
    protected service: TagService,
    query: TagQuery,
    private TagFilterService: FilterService) {
    super(injector, service, query, TagFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
