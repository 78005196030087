import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable} from 'rxjs';
import {Entity} from '../../entity';

class ImageSnippet {
  pending: boolean = false;
  status: string = 'init';

  constructor(public src: string, public file: File) {
  }
}

@Component({
  selector: 'app-upload-image',
  styleUrls: ['image.scss'],
  template: `
    <ng-container [formGroup]="group" *ngIf="!field.multiple && control">
      <label class="image-upload-container btn btn-bwm" [ngClass]="{ 'error': control.hasError('requiredImage') && control.touched }" [attr.for]="field.label | async">
        <span>{{ 'image.upload' | transloco }} - {{ field.label | async }}</span>
        <input #imageInput
               type="file"
               accept="image/*"
               (change)="processFile(imageInput)" [attr.id]="field.label | async">
      </label>

      <div *ngIf="selectedFile" class="img-preview-container">

        <div class="ms-auto me-auto img-preview"
             [ngStyle]="{'background-image': 'url('+ selectedFile.src + ')'}">
        </div>
        <mat-icon (click)="reset()" color="warn">delete</mat-icon>

        <div *ngIf="selectedFile.pending && false" class="img-loading-overlay">
          <div class="img-spinning-circle"></div>
        </div>
      </div>
      <div *ngIf="!selectedFile && control.value?.original && !deleted" class="img-preview-container">
        <div class="ms-auto me-auto img-preview"
             [ngStyle]="{'background-image': 'url('+ control.value?.original + ')'}">
        </div>
        <mat-icon (click)="reset()" color="warn">delete</mat-icon>
      </div>

    </ng-container>
    <ng-container [formGroup]="group" *ngIf="field.multiple && control">
      <ng-container *ngFor="let image of control.value">

        <label class="image-upload-container btn btn-bwm">
          <span>{{ 'image.upload' | transloco }} - {{ field.label | async }}</span>
          <input #imageInput
                 type="file"
                 accept="image/*"
                 (change)="processFile(imageInput)">
        </label>

        <div *ngIf="selectedFile" class="img-preview-container">

          <div class="ms-auto me-auto img-preview"
               [ngStyle]="{'background-image': 'url('+ selectedFile.src + ')'}">
          </div>

          <div *ngIf="selectedFile.pending && false" class="img-loading-overlay">
            <div class="img-spinning-circle"></div>
          </div>
        </div>
        <div *ngIf="!selectedFile && control.value?.original" class="img-preview-container">
          <div class="ms-auto me-auto img-preview"
               [ngStyle]="{'background-image': 'url('+ control.value?.original + ')'}">
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
  styles: []
})
export class ImageComponent implements OnInit, OnDestroy {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  parentEntity$ = new Observable<Entity>();

  @Input() control: FormControl;
  selectedFile: ImageSnippet;

  deleted = false;

  constructor(
    private fb: FormBuilder,
  ) {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }

  ngOnInit(): void {
    if (!this.control) {
      this.control = this.group.get(this.field.name) as FormControl;
    }
    if (!this.control) {
      this.control = this.fb.control('');
    }
    this.control.setErrors([]);
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
  }

  reset() {
    if (this.selectedFile) {
      this.selectedFile = undefined;
    }
    this.control.setValue({
      original: ''
    });

    this.deleted = true;
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);
      let actValue = Object.assign({}, this.control.value);
      actValue.uploaded = this.selectedFile.src;

      this.control.setValue(actValue);

      this.selectedFile.pending = true;
    });

    this.deleted = false;

    reader.readAsDataURL(file);
  }


}
