import {Component, Injector} from '@angular/core';
import {TransporterState, Store} from './state/store';
import {Transporter} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {TransporterQuery} from './state/query';
import {TransporterService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class TransporterListComponent extends EntityListComponent<Transporter, TransporterState, Store> {

  translationDomain = 'Transporter';

  constructor(
    public injector: Injector,
    protected service: TransporterService,
    query: TransporterQuery,
    private TransporterFilterService: FilterService) {
    super(injector, service, query, TransporterFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
