import {Component, ViewChild} from '@angular/core';
import {AuthService} from "../../../authentication/service/auth.service";
import {BehaviorSubject, Observable, of, switchMap, tap} from "rxjs";
import {User} from "../../../authentication/user/state/model";
import {Order} from "../../../webshop/order/state/model";
import {Address} from "../../../components/entity";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {OrderService} from "../../../webshop/order/state/service";
import {InvoiceAddressService} from "../../../webshop/invoice-address/state/service";
import {ShippingAddressService} from "../../../webshop/shipping-address/state/service";
import {ShippingAddress} from "../../../webshop/shipping-address/state/model";
import {InvoiceAddress} from "../../../webshop/invoice-address/state/model";
import {map, shareReplay, take} from "rxjs/operators";
import {TranslocoService} from "@ngneat/transloco";
import {EditAddressComponent} from "../../editAddress";
import {FormService} from "../../../components/service/form.service";
import {UserService} from "../../../authentication/user/state/service";

@Component({
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class ProfileComponent {

  @ViewChild('invoiceAddressComponent') invoiceAddressForm: EditAddressComponent;
  @ViewChild('shippingAddressComponent') shippingAddressForm: EditAddressComponent;

  userGroup: FormGroup;
  userEmail: FormControl;
  userFirstName: FormControl;
  userLastName: FormControl;
  userPhone: FormControl;
  userPassword: FormControl;
  userPasswordRe: FormControl;

  user$: Observable<User>;
  logs$: Observable<any[]> = of([]);
  lastOrders$: Observable<Order[]>;
  tab: string = 'personal_data';
  selectedOrder: Order = new Order({});
  deliveryAddress: ShippingAddress = new ShippingAddress({});
  invoiceAddress: InvoiceAddress = new InvoiceAddress({});
  invoiceAddresses$: Observable<InvoiceAddress[]>;
  shippingAddresses$: Observable<ShippingAddress[]>;
  defaultInvoiceAddress: FormControl;
  defaultShippingAddress: FormControl;
  copyControl: FormControl;
  reload = new BehaviorSubject(false);
  reload$ = this.reload.asObservable();

  constructor(
    private authService: AuthService,
    private invoiceAddressService: InvoiceAddressService,
    private shippingAddressService: ShippingAddressService,
    private orderService: OrderService,
    private formService: FormService,
    private translateService: TranslocoService,
    private userService: UserService,
    private fb: FormBuilder
  ) {

    this.defaultInvoiceAddress = fb.control(false);
    this.defaultShippingAddress = fb.control(false);
    this.copyControl = fb.control(true);

    this.userGroup = fb.group({});
    this.userEmail = fb.control('', this.formService.bindValidations(this.formService.getValidations(['email', 'required'])));
    this.userEmail.disable();
    this.userFirstName = fb.control('', this.formService.bindValidations(this.formService.getValidations(['required'])));
    this.userLastName = fb.control('', this.formService.bindValidations(this.formService.getValidations(['required'])));
    this.userPassword = fb.control('', this.formService.bindValidations(this.formService.getValidations([])));
    this.userPhone = fb.control('', this.formService.bindValidations(this.formService.getValidations(['required'])));
    this.userPasswordRe = fb.control('', this.formService.bindValidations(this.formService.getValidations([])));

    this.userGroup.addControl('email', this.userEmail);
    this.userGroup.addControl('firstName', this.userFirstName);
    this.userGroup.addControl('lastName', this.userLastName);
    this.userGroup.addControl('password', this.userPassword);
    this.userGroup.addControl('passwordRe', this.userPasswordRe);
    this.userGroup.addControl('phone', this.userPhone);

    this.user$ = authService.user$.pipe(
      tap(user => {
        this.userFirstName.setValue(user.firstName);
        this.userLastName.setValue(user.lastName);
        this.userEmail.setValue(user.email);
        this.userPhone.setValue(user.phone);
      })
    );

    this.shippingAddresses$ = this.user$.pipe(
      switchMap(user => this.reload$.pipe(map(() => user))),
      switchMap(user => this.shippingAddressService.getByUser(user)),
      map(addresses => addresses.sort((a, b) => {
        if (a.country.id !== b.country.id) {
          return a.country.name > b.country.name ? 1 : -1;
        }
        if (a.address.city !== b.address.city) {
          return a.address.city > b.address.city ? 1 : -1;
        }

        return a.address.street > b.address.street ? 1 : -1;
      }))
    );

    this.invoiceAddresses$ = this.user$.pipe(
      switchMap(user => this.reload$.pipe(map(() => user))),
      switchMap(user => this.invoiceAddressService.getByUser(user)),
      map(addresses => addresses.sort((a, b) => {
        if (a.country.id !== b.country.id) {
          return a.country.name > b.country.name ? 1 : -1;
        }
        if (a.address.city !== b.address.city) {
          return a.address.city > b.address.city ? 1 : -1;
        }

        return a.address.street > b.address.street ? 1 : -1;
      }))
    );


    this.lastOrders$ = this.user$.pipe(
      switchMap(user => this.reload$.pipe(map(() => user))),
      switchMap(user => this.orderService.getByUser(user)),
      shareReplay(1)
    );
  }

  orderStatus$(order: Order) {
    return this.translateService.selectTranslate('order.status-item.' + order.status);
  }

  switchTab(personalData: string) {
    this.tab = personalData;
  }

  logout() {
    this.authService.logout();
  }

  modifyUser() {
    if (this.userGroup.valid) {
      this.authService.user$.pipe(
        take(1),
        switchMap(_user => {
          const user = new User(_user);
          user.email = this.userEmail.value;
          user.firstName = this.userFirstName.value;
          user.lastName = this.userLastName.value;
          user.phone = this.userPhone.value.toString();
          user.active = true;

          if (this.userPassword.value && this.userPasswordRe.value) {
            if (!this.userPassword.value === this.userPasswordRe.value) {
              this.userPassword.setErrors({'same': true});
              return of('');
            } else {
              user.password = this.userPassword.value;
            }
          }

          return this.userService.update(user.id, user);
        })
      ).subscribe();

    } else {
      this.formService.validateAllFormFields(this.userGroup);
    }
  }

  deleteInvoiceAddress(address: InvoiceAddress) {
    this.invoiceAddressService.delete(address.id).pipe(
      tap(() => this.reload.next(!this.reload.value))
    ).subscribe();
  }

  editInvoiceAddress(address: InvoiceAddress | null = null) {
    if (!address) {
      address = new InvoiceAddress({});
    }
    this.invoiceAddress = address;
    this.defaultInvoiceAddress.setValue(address.isDefault);
    this.tab = 'billingAddress_form';
  }

  editDeliveryAddress(address: ShippingAddress | null = null) {
    if (!address) {
      address = new ShippingAddress({});
    }
    this.deliveryAddress = address;
    console.log(this.deliveryAddress);
    this.defaultShippingAddress.setValue(address.isDefault);
    this.tab = 'delivery_address_form';
  }

  deleteDeliveryAddress(address: ShippingAddress) {
    this.shippingAddressService.delete(address.id).pipe(
      tap(() => this.reload.next(!this.reload.value))
    ).subscribe();
  }

  saveDeliveryAddress() {
    const _address = this.shippingAddressForm.getAddress();
    if (_address) {
      _address.isDefault = this.defaultShippingAddress.value;
      this.user$.pipe(
        switchMap(user => {
          _address.user = user;


          let otherPipe = of('');
          if (this.copyControl.value) {
            const __address = new InvoiceAddress({
              user: user,
              isDefault: _address.isDefault,
              address: _address.address,
              country: _address.country
            });

            otherPipe = this.invoiceAddressService.add(__address);
          }

          if (_address.id) {
            return this.shippingAddressService.update(_address.id, _address).pipe(
              switchMap(() => otherPipe)
            );;
          } else {
            return this.shippingAddressService.add(_address).pipe(
              switchMap(() => otherPipe)
            );;
          }
        }),
        tap(() => this.reload.next(!this.reload.value)),
        tap(() => this.tab = 'delivery_address')
      ).subscribe();
    }
  }

  showOrder(order: any) {
    this.selectedOrder = order;
    this.tab = 'previous_order_details';
  }

  saveInvoiceAddress() {
    const _address = this.invoiceAddressForm.getAddress();

    if (_address) {
      _address.isDefault = this.defaultInvoiceAddress.value;
      this.user$.pipe(
        switchMap(user => {
          _address.user = user;


          let otherPipe = of('');
          if (this.copyControl.value) {
            const __address = new ShippingAddress({
              user: user,
              isDefault: _address.isDefault,
              address: _address.address,
              country: _address.country
            });

            otherPipe = this.shippingAddressService.add(__address);
          }

          if (_address.id) {
            return this.invoiceAddressService.update(_address.id, _address).pipe(
              switchMap(() => otherPipe)
            );
          } else {
            return this.invoiceAddressService.add(_address).pipe(
              switchMap(() => otherPipe)
            );
          }
        }),
        tap(() => this.reload.next(!this.reload.value)),
        tap(() => {
          this.tab = 'billingAddress';
          this.copyControl.setValue(false);
        })
      ).subscribe();
    }
  }

}
