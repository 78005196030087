import {Component, Injector} from '@angular/core';
import {PageState, Store} from './state/store';
import {Page} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {PageQuery} from './state/query';
import {PageService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class PageListComponent extends EntityListComponent<Page, PageState, Store> {

  translationDomain = 'page';

  constructor(
    public injector: Injector,
    protected service: PageService,
    query: PageQuery,
    private PageFilterService: FilterService) {
    super(injector, service, query, PageFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
