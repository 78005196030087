import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {switchMap} from "rxjs/operators";
import {Wallpaper} from "../../../content/wallpaper/state/model";
import {WallpaperService} from "../../../content/wallpaper/state/service";

@Component({
  templateUrl: './view.html'
})
export class WebshopWallpaperViewComponent {
  public item$: Observable<Wallpaper>;

  constructor(
    service: WallpaperService,
    route: ActivatedRoute
  ) {
    this.item$ = route.params.pipe(
      switchMap(params => service.getBySlug(params.slug))
    );
  }

}
