<ng-container *ngIf="(item$ | async) as item">
  <section class="l-siteContent" id="theTop" [style.background-color]="'#'+item.color">
    <div class="wallpaper">
      <div class="row">
        <div class="small-12 column">
          <section>
            <div class="text-center">
              <h1>{{ item.title }}</h1>
              <h2>{{ item.subtitle }}</h2>
              <p [innerHTML]="item.description"></p>
              <app-image
                [small]="item.image.original"
                [medium]="item.image.original"
                [large]="item.image.original"
                [xlarge]="item.image.original"
              ></app-image>
            </div>
          </section>
          <section class="wallpaper-resolutions">
            <div class="g-top-extrathick">
              <header>
                <h1>{{ 'webshop.Válassz méretet' | transloco }}</h1>
                <img src="/assets/images/menu_heading_decor.svg" alt="decor">
              </header>
              <div class="row">
                <div class="small-6 large-3 column">
                  <h3>Standard</h3>
                  <ul class="">
                    <li><a target="_blank" [href]="item.image1.wallpaper_standard_small">800x600</a>
                    </li>
                    <li><a target="_blank" [href]="item.image1.wallpaper_standard_normal">1024x768</a></li>
                    <li><a target="_blank" [href]="item.image1.wallpaper_standard_large">1024x768</a></li>
                    <li><a target="_blank" [href]="item.image1.wallpaper_standard_xlarge">1600x1200</a></li>
                  </ul>
                </div>
                <div class="small-6 large-3 column">
                  <h3>Tablet</h3>
                  <ul class="">
                    <li><a target="_blank" [href]="item.image2.wallpaper_tablet_small">1024x1024</a>
                    </li>
                    <li><a target="_blank" [href]="item.image2.wallpaper_tablet_normal">1280x1280</a></li>
                    <li><a target="_blank" [href]="item.image2.wallpaper_tablet_large">1920x1920</a></li>
                    <li><a target="_blank" [href]="item.image2.wallpaper_tablet_xlarge">2048x2048</a></li>
                  </ul>
                </div>
                <div class="small-6 large-3 column">
                  <h3>Widescreen</h3>
                  <ul class="">
                    <li><a target="_blank" [href]="item.image3.wallpaper_widescreen_small">1280x800</a>
                    </li>
                    <li><a target="_blank" [href]="item.image3.wallpaper_widescreen_normal">1440x900</a></li>
                    <li><a target="_blank" [href]="item.image3.wallpaper_widescreen_large">1920x1200</a></li>
                    <li><a target="_blank" [href]="item.image3.wallpaper_widescreen_xlarge">2560x1600</a></li>
                  </ul>
                </div>
                <div class="small-6 large-3 column">
                  <h3>HD</h3>
                  <ul class="">
                    <li><a target="_blank" [href]="item.image4.wallpaper_hd_small">1280x720</a>
                    </li>
                    <li><a target="_blank" [href]="item.image4.wallpaper_hd_normal">1366x768</a></li>
                    <li><a target="_blank" [href]="item.image4.wallpaper_hd_large">1600x900</a></li>
                    <li><a target="_blank" [href]="item.image4.wallpaper_hd_xlarge">1920x1080</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</ng-container>
