<mat-toolbar>
  {{ service.getEntityTitle() | async }} {{ 'form.editing' | transloco }}
</mat-toolbar>
<div style="
  display: flex;
  justify-content: center;">
  <div formHost [entity$]="entity$" [entityService]="service" [subForm]="false" (submit)="onSubmit($event)"
       (cancel)="onCancel()">
  </div>
</div>
