import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../authentication/service/auth.service';
import {interval, Observable, of, skip, startWith, tap, timeout} from "rxjs";
import {LoadingService} from "../service/loading.service";
import {debounce, distinctUntilChanged, map, shareReplay} from "rxjs/operators";
import {WebshopMenuComponent} from "../../webshop-client/menu/component";
import {WebshopSearchComponent} from "../../webshop-client/search/component";
import {DOCUMENT} from "@angular/common";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  templateUrl: './component.html',
  styleUrls: ['./../../../webshop-client.scss'
  ]
})
export class WebshopLayoutComponent implements OnInit {

  loginPanelOpen = false;
  loading$: Observable<boolean>;
  @ViewChild(WebshopMenuComponent) menu: WebshopMenuComponent;
  @ViewChild(WebshopSearchComponent) search: WebshopSearchComponent;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _loading: LoadingService,
    public authService: AuthService,
  ) {

    this.loading$ = this._loading.loadingSub
      .pipe(
        distinctUntilChanged(),
        debounce((value) => {
          return of('');
        }),
        shareReplay(1)
      );
  }

  ngOnInit() {
    this.document.body.classList.forEach(item => {
      this.document.body.classList.remove(item);
    });
  }

  toggleLoginPanel() {
    this.loginPanelOpen = !this.loginPanelOpen;
  }

}
