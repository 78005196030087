import {Component, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {Reseller} from "../../../webshop/reseller/state/model";
import {ResellerService} from "../../../webshop/reseller/state/service";
import {StoreService} from "../../../webshop/store/state/service";
import {Store} from "../../../webshop/store/state/model";
import {GoogleMap} from "@angular/google-maps";
import {GoogleService} from "../../../google-maps/service/maps.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {FormService} from "../../../components/service/form.service";
import {environment} from "../../../../environments/environment";

@Component({
  templateUrl: './view.html',
  styleUrls: ['view.scss']
})
export class ShopsViewComponent {
  stores$: Observable<Store[]>;
  resellers$: Observable<Reseller[]>;
  @ViewChild(GoogleMap) googleMap: GoogleMap | undefined;
  options: google.maps.MapOptions = {
    zoom: 15,
    styles: [{
      "featureType": "all",
      "elementType": "geometry.fill",
      "stylers": [{
        "weight": "2.00"
      }]
    }, {
      "featureType": "all",
      "elementType": "geometry.stroke",
      "stylers": [{
        "color": "#9c9c9c"
      }]
    }, {
      "featureType": "all",
      "elementType": "labels.text",
      "stylers": [{
        "visibility": "on"
      }]
    }, {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [{
        "color": "#f2f2f2"
      }]
    }, {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#ffffff"
      }]
    }, {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#ffffff"
      }]
    }, {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "road",
      "elementType": "all",
      "stylers": [{
        "saturation": -100
      }, {
        "lightness": 45
      }]
    }, {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#eeeeee"
      }]
    }, {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#7b7b7b"
      }]
    }, {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [{
        "color": "#ffffff"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [{
        "visibility": "simplified"
      }]
    }, {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "water",
      "elementType": "all",
      "stylers": [{
        "color": "#46bcec"
      }, {
        "visibility": "on"
      }]
    }, {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#c8d7d4"
      }]
    }, {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#070707"
      }]
    }, {
      "featureType": "water",
      "elementType": "labels.text.stroke",
      "stylers": [{
        "color": "#ffffff"
      }]
    }]
  };

  group: FormGroup;
  nameControl: FormControl;
  emailControl: FormControl;
  descriptionControl: FormControl;

  constructor(
    private http: HttpClient,
    fb: FormBuilder,
    public googleApi: GoogleService,
    public formService: FormService,
    route: ActivatedRoute,
    service: StoreService,
    resellerService: ResellerService
  ) {
    this.stores$ = service.storesWithGallery$;
    this.resellers$ = resellerService.resellers$;

    this.nameControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.emailControl = fb.control('', formService.bindValidations(formService.getValidations(['required', 'email'])));
    this.descriptionControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));

    this.group = fb.group({});
    this.group.addControl('email', this.emailControl);
    this.group.addControl('name', this.nameControl);
    this.group.addControl('description', this.descriptionControl);
  }

  filterResellers(resellers: Reseller[] | null, isLocal, isPrior): Reseller[] {
    if (!resellers) {
      return [];
    }
    return resellers.filter(reseller => reseller.isLocal === isLocal && reseller.isPrior === isPrior);
  }

  center(store: Store): google.maps.LatLngLiteral {
    return {lat: store.latitude, lng: store.longitude};
  }

  sendMail() {
    if (this.group.valid) {
      this.http.post(environment.apiUrl + '/contact', {
        email: this.emailControl.value,
        name: this.nameControl.value,
        description: this.descriptionControl.value
      }).subscribe();
    } else {
      this.formService.validateAllFormFields(this.group);
    }
  }
}
