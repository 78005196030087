import {Component, Injector} from '@angular/core';
import {TransporterDiscountState, Store as TransporterDiscountStore} from './state/store';
import {TransporterDiscount} from './state/model';
import {TransporterDiscountService as TransporterDiscountService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {TransporterDiscountQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class TransporterDiscountDetailComponent extends EntityDetailComponent<TransporterDiscount, TransporterDiscountState, TransporterDiscountStore> {

  constructor(
    public injector: Injector,
    service: TransporterDiscountService,
    protected query: TransporterDiscountQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
