import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../user/state/model';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {BehaviorSubject, interval, Observable, of, startWith, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import jwtDecode from 'jwt-decode';
import {Router} from '@angular/router';
import {UserQuery} from '../user/state/query';
import {getIDType} from '@datorama/akita';
import {UserState} from '../user/state/store';
import {UserService} from '../user/state/service';
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";

interface ResponseTokens {
  token: string;
  refresh_token: string;
}

interface TokenData {
  exp: number;
  roles: [];
  username: string;
  id: string;
}

export enum RoleList {
  Admin = 'admin',
}

@Injectable()
export class AuthService {

  private _expiration: moment.Moment = moment('');
  private _refresh_expiration: moment.Moment = moment('');
  private _user_id = '';
  private user_id = new BehaviorSubject<string>('');
  private roles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public user$: Observable<User>;
  private emitUserChange = new EventEmitter();
  private logoutTimeout: any = null;
  public logoutEvent = new EventEmitter();
  private _isAuthenticated = interval(60000).pipe(
    startWith(moment().isBefore(this.getExpiration())),
    switchMap(() => this.emitUserChange.asObservable()),
    startWith(moment().isBefore(this.getExpiration())),
    map(() => {
      if (!this.getExpiration()) {
        return false;
      }
      return moment().isBefore(this.getExpiration());
    }),
    shareReplay(1)
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private userQuery: UserQuery,
    private userService: UserService,
    private localizeRouter: LocalizeRouterService
  ) {
    this.loadFromStorage();

    this.user$ = this.user_id.pipe(
      switchMap(id => id ?
        this.userQuery.selectEntity(id as getIDType<UserState>).pipe(
          switchMap(user => user ? of(user) :
            this.userService.get(this._user_id as getIDType<UserState>)
              .pipe(
                map(items => items.pop() ?? new User({})),
                take(1),
                shareReplay(1)
              )
          ),
          shareReplay(1)
        ) :
        of(new User({}))),
      tap(user => this.userService.currentUser = user),
      shareReplay(1)
    );

  }

  private loadFromStorage() {
    const expiration = localStorage.getItem('expires_at');
    this._expiration = moment(expiration ? JSON.parse(expiration) : '');
    const refresh_expiration = localStorage.getItem('refresh_expires_at');
    this._refresh_expiration = moment(refresh_expiration ? JSON.parse(refresh_expiration) : '');
    this.setLogoutTimeout();
    this._user_id = localStorage.getItem('user_id') ?? '';
    this._isLoggedIn = new BehaviorSubject<boolean>(moment().isBefore(this.getExpiration()));
    this.roles$ = new BehaviorSubject<string[]>(localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles') ?? '[]') : []);
    this.user_id.next(this._user_id);
    this._isLoggedIn.next(moment().isBefore(this.getExpiration()));
  }


  private setLogoutTimeout() {
    return;
    if (this._refresh_expiration.isValid() && this._refresh_expiration > moment()) {

      const timeout = (this._refresh_expiration.diff(moment(), "ms"));
      if (this.logoutTimeout) {
        clearTimeout(this.logoutTimeout);
      }
      this.logoutTimeout = setTimeout(() => {
        const _timeout = (this._refresh_expiration.diff(moment(), "ms"));

        if (_timeout <= 0) {
          console.log('logged out');
          this.logout().then();
        } else {
          this.setLogoutTimeout();
        }
      }, timeout > 0x7FFFFFFF ? 0x7FFFFFFF : timeout);
    }
  }

  login(user: User) {
    return this.http.post<ResponseTokens>(environment.apiUrl + '/login', {
      username: user.email,
      password: user.password
    }).pipe(
      map(response => {
        this.setSession(response);
        this.emitUserChange.emit();
      }),
      switchMap(() => this.user$),
      tap(user => {
        if (!user.permissions.find(permission => permission.slug === 'ROLE_ADMIN')) {
          this.router.navigate([this.localizeRouter.translateRoute('/')]).then();
        } else {
          this.router.navigate([this.localizeRouter.translateRoute('/admin')]).then();
        }
      })
    );
  }

  onetime(user: User, token: string) {
    return this.http.get<ResponseTokens>(environment.apiUrl + '/user/onetime/' + user.id + '/' + token).pipe(
      map(response => {
        this.setSession(response);
        this.emitUserChange.emit();
      }),
      switchMap(() => this.user$),
      tap(user => {
        if (!user.permissions.find(permission => permission.slug === 'ROLE_ADMIN')) {
          this.router.navigate([this.localizeRouter.translateRoute('/')]).then();
        } else {
          this.router.navigate([this.localizeRouter.translateRoute('/admin')]).then();
        }
      })
    );
  }

  private setSession(authResult: ResponseTokens) {
    const tokenData = jwtDecode<TokenData>(authResult.token);

    if (!!tokenData.id) {
      this._expiration = moment.unix(tokenData.exp);

      this._refresh_expiration = moment.unix(tokenData.exp + 2592000);
      this.setLogoutTimeout();
      this._user_id = tokenData.id;
      this.roles$.next(tokenData.roles);

      localStorage.setItem('token', authResult.token);
      localStorage.setItem('user_id', this._user_id);
      localStorage.setItem('refresh_token', authResult.refresh_token);
      localStorage.setItem('expires_at', JSON.stringify(this._expiration.valueOf()));
      localStorage.setItem('refresh_expires_at', JSON.stringify(this._refresh_expiration.valueOf()));
      localStorage.setItem('roles', JSON.stringify(tokenData.roles));

      this.user_id.next(this._user_id);
      this._isLoggedIn.next(moment().isBefore(this.getExpiration()));
    } else {
      throwError('Invalid token');
    }
  }

  getUser(): Observable<User> {
    return this.user$;
  }

  async logout(redirect: boolean = true) {

    this.logoutEvent.emit();
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_expires_at');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_id');
    localStorage.removeItem('roles');

    this.user_id.next('');

    this.loadFromStorage();
    this.emitUserChange.emit();
    this.router.navigate([this.localizeRouter.translateRoute('')]).then();
  }

  public isAuthenticated(): Observable<boolean> {
    return this._isAuthenticated;
  }

  getExpiration() {
    return this._expiration;
  }

  getRoles(): Observable<string[]> {
    return this.roles$;
  }

  hasAccess$(roles: string[]): Observable<boolean> {
    return this.getRoles().pipe(map(_roles => {
        return _roles.some(role => roles.includes(role));
      }
    ));
  }

  hasAccess(roles: string[]): boolean {
    return this.roles$.value.some(role => roles.includes(role));
  }

  getToken() {
    if (moment().isBefore(this.getExpiration())) {
      return localStorage.getItem('token');
    }

    return null;
  }
}
