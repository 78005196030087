import {AfterViewInit, Component} from '@angular/core';
import {AuthService} from "../../../authentication/service/auth.service";

@Component({
  selector: 'app-webshop-instagram',
  templateUrl: './component.html'
})
export class InstagramComponent implements AfterViewInit {

  config: any = {
    count: '12' , // any int value
    idUser: '12270523',
    tokenUser: '12270523.83e3fef.77b12f479fba4cd8a90ac4f998655fc5',
    grid: true // false is list true is grid
  };

  constructor(
    public authService: AuthService
  ) {
  }

  ngAfterViewInit() {
    /*
	  var userFeed = new Insta({
              get: 'user',
              userId: '12270523',
              clientId: '83e3fef27e7f42a28df88dbadcc6ec1c',
              accessToken: '12270523.83e3fef.77b12f479fba4cd8a90ac4f998655fc5',
              resolution: 'standard_resolution',
              template: '<div class="small-6 large-3 column"><a href="{{link}}" target="_blank" style="background-image:url({{image}})"></a></div>',
              sortBy: 'most-recent',
              limit: 12,
              links: false
            });
            userFeed.run();

     */
  }
}
