import {Component, EventEmitter, Output} from '@angular/core';
import {Observable, of} from "rxjs";
import {Cart} from "../../../webshop/cart/state/model";
import {CartService} from "../../../webshop/cart/state/service";
import {User} from "../../../authentication/user/state/model";
import {AuthService} from "../../../authentication/service/auth.service";
import {Order, OrderItem} from "../../../webshop/order/state/model";
import {ProductParameterTypeCount} from "../../../products/product-parameter-type-count/state/model";
import {OrderService} from "../../../webshop/order/state/service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {FormService} from "../../../components/service/form.service";
import {map, switchMap, take, tap} from "rxjs/operators";
import {GoogleAnalyticsService} from "../../../google-analytics/service/analytics.service";
import {TranslocoService} from "@ngneat/transloco";
import {IObjectKeys} from "../../../components/entity";
import {Router} from "@angular/router";

@Component({
  selector: 'app-checkout-sum',
  templateUrl: './sum.html'
})
export class SumComponent {
  cart$: Observable<Cart> = this.cartService.cart$.pipe(
    tap(cart => {
      if (cart.items.length <= 0) {
        this.router.navigateByUrl('/').then();
      }
    })
  );
  user$: Observable<User> = this.authService.user$;
  @Output() next = new EventEmitter();
  group: FormGroup;
  descriptionControl: FormControl;
  policyControl: FormControl;

  constructor(
    private cartService: CartService,
    private orderService: OrderService,
    private authService: AuthService,
    private fb: FormBuilder,
    private formService: FormService,
    private googleService: GoogleAnalyticsService,
    private translationService: TranslocoService,
    private router: Router
  ) {

    this.group = fb.group({});
    this.descriptionControl = fb.control('');
    this.policyControl = fb.control(null, this.formService.bindValidations(this.formService.getValidations(['required'])));

    this.group.addControl('description', this.descriptionControl);
    this.group.addControl('policy', this.policyControl);

  }

  orderByProductName(items: OrderItem[]): OrderItem[] {
    const _items = Object.assign([], items);
    return _items.sort((a, b) => {
      return a.product.name > b.product.name ? 1 : -1;
    });
  }

  orderByProductParameterName(productParameterTypeCounts: ProductParameterTypeCount[]): ProductParameterTypeCount[] {
    const _items = Object.assign([], productParameterTypeCounts);
    return _items.sort((a, b) => {
      return a.productParameterType.name > b.productParameterType.name ? 1 : -1;
    });
  }

  deleteCoupon(cart: Cart) {
    cart.coupon = null;
  }

  order() {
    if (this.group.valid) {
      this.cart$.pipe(
        take(1),
        switchMap(cart => this.user$.pipe(
          take(1),
          switchMap(user => {
            const order = new Order({
              innerDescription: this.descriptionControl.value,
              coupon: cart.coupon,
              store: cart.store,
              currency: this.translationService.getActiveLang() === 'hu' ? 'HUF' : 'EUR',
              shippingMode: cart.shippingMode,
              items: cart.items.map(item => {
                const obj = new OrderItem(item);
                obj.id = '';
                return obj;
              }),
              shippingCountry: cart.shippingCountry,
              shippingAddress: cart.shippingAddress,
              billingAddress: cart.billingAddress,
              billingCountry: cart.billingCountry,
              paymentMode: cart.paymentMode,
              user: user
            });


            if (cart.items.length > 0) {
              return this.orderService.add(order).pipe(
                tap((order: Order) => {
                  cart.items = [];
                  window['noRedir']= true;
                  this.cartService.clearCart();
                  if (order.paymentUrl) {
                    window.location.href = order.paymentUrl;
                  }
                  return order;
                })
              );
            }

            return of('');
          })
        )),
        take(1)
      ).subscribe();

    } else {
      this.formService.validateAllFormFields(this.group);
    }

  }
}
