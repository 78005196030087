import {Injectable} from '@angular/core';
import {Store as StoreStore, StoreState} from './store';
import {EntityService} from '../../../components/entity-service';
import {Store} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {StoreQuery} from './query';
import {Observable} from "rxjs";
import {GalleryService} from "../../../content/gallery/state/service";
import {FormService} from "../../../components/service/form.service";

@Injectable({providedIn: 'root'})
export class StoreService extends EntityService<StoreStore, StoreState> {

  stores$: Observable<Store[]> = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Store(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  storesWithGallery$: Observable<Store[]> = this.getWithGallery$().pipe(
    take(1),
    map(items => items.map(item => new Store(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('store.entityTitle'),
    fields: [

      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('store.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('store.address'),
        inputType: 'text',
        name: 'address',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('store.phoneNumber'),
        inputType: 'text',
        name: 'phoneNumber',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('store.openingTime'),
        inputType: 'text',
        name: 'openingTime',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('store.description'),
        inputType: 'text',
        name: 'description',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('store.latitude'),
        inputType: 'text',
        name: 'latitude',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('store.longitude'),
        inputType: 'text',
        name: 'longitude',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('store.image'),
        name: 'image1',
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.galleryService.galleries$,
        listed: false,
        label: this.translocoService.selectTranslate('store.gallery'),
        name: 'gallery',
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: StoreQuery,
    private formService: FormService,
    private galleryService: GalleryService,
    protected store: StoreStore) {
    super(store, Store);
  }

  get resourceName(): string {
    return 'webshop/store';
  }

  private getWithGallery$() {
    return this.getHttp().get<Store[]>(this.api + '/gallery')
      .pipe(
        take(1),
        shareReplay(1),
      );
  }
}
