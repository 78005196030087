import {Injectable} from '@angular/core';
import {TransporterService} from './state/service';
import {TransporterQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Transporter} from './state/model';
import {TransporterState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Transporter, TransporterState, TransporterQuery, Store> {
  constructor(protected query: TransporterQuery, protected api: TransporterService) {
    super(query, api);
  }
}
