import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {TransporterPrice} from './model';

export interface TransporterPriceState extends EntityState<TransporterPrice> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'TransporterPrice' })
export class Store extends EntityStore<TransporterPriceState> {

  constructor() {
    super();
  }

}
