import {Entity} from '../../../components/entity';

export class Language extends Entity {

  code: string;
  slug: string;

  constructor({
                id,
                code,
                slug,
                createdAt, updatedAt
              }: Partial<Language>) {
    super({id, createdAt, updatedAt});

    this.code = code ?? '';
    this.slug = slug ?? '';
  }

  getNameProperty(): string {
    return 'slug';
  }
}
