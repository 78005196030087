import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Press} from './model';

export interface PressState extends EntityState<Press> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Press' })
export class Store extends EntityStore<PressState> {

  constructor() {
    super();
  }

}
