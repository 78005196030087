import {AfterViewInit, Component, Injector} from '@angular/core';
import {CountryState, Store} from './state/store';
import {Country} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {CountryQuery} from './state/query';
import {CountryService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class CountryListComponent extends EntityListComponent<Country, CountryState, Store> implements AfterViewInit {

  translationDomain = 'country';

  constructor(
    public injector: Injector,
    protected service: CountryService,
    query: CountryQuery,
    private CountryFilterService: FilterService) {
    super(injector, service, query, CountryFilterService);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.sort.sort({
      id: 'name',
      start: 'asc',
      disableClear: false
    });
  }

  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');

  }
}
