import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable} from 'rxjs';
import {Entity} from '../../entity';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

interface Slugged {
  slug: string;
}

@Component({
  selector: 'app-chips',
  template: `
    <mat-form-field *ngIf="field.name && group?.get(field.name)" [formGroup]="group" appearance="fill">
      <mat-label>{{ field.label | async | default: '' }}</mat-label>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let element of group?.get(this.field.name)?.value" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(element)">
          {{element.slug}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input matInput type="text"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group?.get(field.name)?.hasError(validation.name)">{{validation.message$ | async}}</mat-error>
      </ng-container>
      <ng-container ngProjectAs="mat-error">
        <mat-error
          *ngIf="group?.get(field.name)?.hasError('custom')">{{group?.get(field.name)?.getError('custom').message}}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styleUrls: ['common.scss'],
})
export class ChipsComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  parentEntity$ = new Observable<Entity>();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  elements: Slugged[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor() {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value && !((this.group?.get(this.field.name)?.value ?? []) as Slugged[]).some(item => item.slug === value)) {
      this.elements = Object.assign([], this.group.get(this.field.name)?.value);
      this.elements.push({slug: value});

      this.group.get(this.field.name)?.setValue(this.elements);
    }

    // Clear the input value
    event.input.value = '';
  }

  remove(element: Slugged): void {
    const index = this.group?.get(this.field.name)?.value.indexOf(element);

    if (index >= 0) {
      let value = [];
      (this.group?.get(this.field.name)?.value ?? []).forEach((_value, key) => {
        if (key !== index) {
          value.push(_value);
        }
      });
      //value = value.splice(index, 1);
      this.group?.get(this.field.name)?.setValue(value);
    }
  }

  ngOnInit() {
  }
}
