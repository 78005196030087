export class Block {
    parent: number;
    childWidth: number;
    id: number;
    x: number;
    y: number;
    width: number;
    height: number;

    constructor(
        parent?: number,
        childWidth?: number,
        id?: number,
        x?: number,
        y?: number,
        width?: number,
        height?: number)
    {
        this.parent = parent as number;
        this.childWidth = childWidth as number;
        this.id = id as number;
        this.x = x as number;
        this.y = y as number;
        this.width = width as number;
        this.height = height as number;
    }

    maxWidth = () => Math.max(this.childWidth, this.width);
}
