import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {CouponState, Store} from './store';

@Injectable({ providedIn: 'root' })
export class CouponQuery extends QueryEntity<CouponState> {

  constructor(protected store: Store) {
    super(store);
  }

}
