import {Entity} from '../../../components/entity';
import {ProductParameterType} from '../../product-parameter-type/state/model';

export class ProductParameterTypeCount extends Entity {
  productParameterType: ProductParameterType;
  quantity: number | null;

  constructor({ id, productParameterType, quantity, createdAt, updatedAt }: Partial<ProductParameterTypeCount>) {
    super({ id, createdAt, updatedAt });
    this.productParameterType = new ProductParameterType(productParameterType ?? {});
    this.quantity = quantity ?? null;
  }

  getNameProperty(): string {
    return 'slug';
  }
}
