import {Component, Injector} from '@angular/core';
import {PressState, Store as PressStore} from './state/store';
import {Press} from './state/model';
import {PressService as PressService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {PressQuery} from './state/query';
import {tap} from "rxjs";

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class PressDetailComponent extends EntityDetailComponent<Press, PressState, PressStore> {

  constructor(
    public injector: Injector,
    service: PressService,
    protected query: PressQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);

    this.entity$ = service.config$.pipe(
      tap(config => {
        this.id = config.id as string;
      })
    );

  }

  navigateToParent() {
    this.router.navigate(['../..'], {relativeTo: this.route});
  }
}
