import {Injectable} from '@angular/core';
import {BarcodeState, Store as BarcodeStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Barcode} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, take} from 'rxjs/operators';
import {BarcodeQuery} from './query';
import {Observable} from "rxjs";
import {ProductParameterService} from "../../product-parameter/state/service";
import {ProductParameter} from "../../product-parameter/state/model";
import {Product} from "../../product/state/model";

@Injectable({ providedIn: 'root' })
export class BarcodeService extends EntityService<BarcodeStore, BarcodeState> {

  barcodes$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Barcode(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('barcode.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('product.barCode'),
        inputType: 'text',
        name: 'barcode',
        listed: true,
        visible: (formGroup: FormGroup) => true,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('product.stock'),
        inputType: 'text',
        name: 'stock',
        listed: false,
        disabled: true,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productParameterService.productParameters$,
        listed: false,
        multiple: false,
        label: this.translocoService.selectTranslate('barcode.productParameterTypes'),
        name: 'productParameterTypes',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productParameterService.productParameters$,
        listed: false,
        multiple: false,
        label: this.translocoService.selectTranslate('barcode.product'),
        name: 'product',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: BarcodeQuery,
    private productParameterService: ProductParameterService,
    protected store: BarcodeStore) {
    super(store, Barcode);
  }

  get resourceName(): string {
    return 'products/barcode';
  }

  getByProduct(product: Product): Observable<Barcode[]> {
    return this.getHttp().get<Barcode[]>(this.api + '?q[]=product:eq:' + product.id)
      .pipe(
        map(items => {
          return items.map(item => new this._constructor(item) as Barcode);
        }),
        take(1),
        shareReplay(1)
      );
  }

  getByProductParameter(productParameter: ProductParameter): Observable<Barcode[]> {
    return this.getHttp().get<Barcode[]>(this.api + '?q[]=productParameter:eq:' + productParameter.id)
      .pipe(
        map(items => {
          return items.map(item => new this._constructor(item) as Barcode);
        }),
        take(1),
        shareReplay(1)
      );
  }
}
