import {Injectable} from '@angular/core';
import {ProductParameterState, Store as ProductParameterStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {ProductParameter} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {ProductParameterQuery} from './query';
import {ProductParameterFormatService} from "../../product-parameter-format/state/service";
import {ProductParameterSizeService} from "../../product-parameter-size/state/service";
import {of} from "rxjs";
import {getEntityType} from "@datorama/akita";
import {ProductParameterTypeState} from "../../product-parameter-type/state/store";
import {Entity} from "../../../components/entity";
import {ProductParameterType} from "../../product-parameter-type/state/model";
import {ProductParameterInnerService} from "../../product-parameter-inner/state/service";

@Injectable({providedIn: 'root'})
export class ProductParameterService extends EntityService<ProductParameterStore, ProductParameterState> {

  productParameters$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new ProductParameter(item))),
    map(items => items.sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('productParameter.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameter.name'),
        inputType: 'text',
        name: 'name',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameter.displayName'),
        inputType: 'text',
        name: 'displayName',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameter.tagTitle'),
        inputType: 'text',
        name: 'tagTitle',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productParameterFormatService.productParameterFormats$,
        listed: false,
        multiple: true,
        label: this.translocoService.selectTranslate('productParameter.formats'),
        name: 'formats',
        validations: []
      }),
      FieldConfig.create({
        type: 'select',
        options: [
          {
            name$: of('1'),
            value: 1
          },
          {
            name$: of('2'),
            value: 2
          },
          {
            name$: of('3'),
            value: 3
          },
          {
            name$: of('4'),
            value: 4
          }
        ],
        listed: false,
        label: this.translocoService.selectTranslate('productParameter.perPage'),
        name: 'perPage',
        validations: []
      }),

      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('productParameter.upper'),
        name: 'upper',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('productParameter.isControl'),
        name: 'isControl',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productParameterInnerService.productParameterInners$,
        label: this.translocoService.selectTranslate('productParameter.inners'),
        name: 'inner',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productParameterSizeService.productParameterSizes$,
        listed: false,
        label: this.translocoService.selectTranslate('productParameter.size'),
        name: 'size',
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: ProductParameterQuery,
    private productParameterFormatService: ProductParameterFormatService,
    private productParameterSizeService: ProductParameterSizeService,
    private productParameterInnerService: ProductParameterInnerService,
    protected store: ProductParameterStore) {
    super(store, ProductParameter);
  }


  clearEntity(_entity: Partial<getEntityType<ProductParameterTypeState>>): Entity {
    return super.clearEntity(new ProductParameter(_entity));
  }

  get resourceName(): string {
    return 'products/product-parameter';
  }

}
