<ng-container *ngIf="item$ | async as wallpaper">
<section class="wallpaperPreview" [style.background-color]="wallpaper.color">
  <div class="row align-center">
    <div class="small-12 large-8 large-centered column">
      <h1 class="l-sectionHeading">{{ 'webshop.Ajándék letölthető naptár-háttérkép' | transloco }}:<br><span>{{ wallpaper.title }}</span></h1>
      <!--
image width:
small: (440)px
large: (980)px
-->
      <app-image
        [small]="wallpaper.image.original"
        [medium]="wallpaper.image.original"
        [large]="wallpaper.image.original"
        [xlarge]="wallpaper.image.original"
      ></app-image>
      <p [innerHTML]="wallpaper.description"></p>
      <a class="btn btn--basic" [routerLink]="['wallpaper', wallpaper.slug] | localize"> {{ 'webshop.Letöltés' | transloco }}</a>
    </div>
  </div>
</section>
</ng-container>
