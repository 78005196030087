import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {GalleryImage} from './model';

export interface GalleryImageState extends EntityState<GalleryImage> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'GalleryImage' })
export class Store extends EntityStore<GalleryImageState> {

  constructor() {
    super();
  }

}
