import {Component, Injector} from '@angular/core';
import {Store as WallpaperStore, WallpaperState} from './state/store';
import {Wallpaper} from './state/model';
import {WallpaperService as WallpaperService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {WallpaperQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class WallpaperDetailComponent extends EntityDetailComponent<Wallpaper, WallpaperState, WallpaperStore> {

  constructor(
    public injector: Injector,
    service: WallpaperService,
    protected query: WallpaperQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
