import {Entity} from '../../../components/entity';
import {Order} from "../../order/state/model";

export class FedexFile extends Entity {
  order: Order;
  fedex: string;
  fedexFile: { uploaded: any };

  constructor({
                id,
                order,
                fedex,
                fedexFile,
                createdAt, updatedAt
              }: Partial<FedexFile>) {
    super({id, createdAt, updatedAt});

    this.fedex = fedex ?? '';
    this.fedexFile = fedexFile  ?? {uploaded: ''};
    this.order = new Order(order ?? {});

  }

  getNameProperty(): string {
    return 'order';
  }

  toString(): string {
    return this.order.toString();
  }
}
