import {Component, Injector} from '@angular/core';
import {ProductParameterTypeState, Store} from './state/store';
import {ProductParameterType} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {ProductParameterTypeQuery} from './state/query';
import {ProductParameterTypeService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class ProductParameterTypeListComponent extends EntityListComponent<ProductParameterType, ProductParameterTypeState, Store> {

  translationDomain = 'productParameterType';

  constructor(
    public injector: Injector,
    protected service: ProductParameterTypeService,
    query: ProductParameterTypeQuery,
    private ProductParameterTypeFilterService: FilterService) {
    super(injector, service, query, ProductParameterTypeFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }


  ngAfterViewInit() {

    if (this.sort) {
      this.sort.active = 'name';
      this.sort.direction = 'asc';
    }
    super.ngAfterViewInit();
  }
}
