import {Component, EventEmitter, Injector, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable, of, startWith, tap} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {FormService} from "../../components/service/form.service";
import {Order} from "../../webshop/order/state/model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {FedexFile} from "../../webshop/fedex-file/state/model";
import {InvoiceFile} from "../../webshop/invoice-file/state/model";
import {FedexFileService} from "../../webshop/fedex-file/state/service";
import {InvoiceFileService} from "../../webshop/invoice-file/state/service";

@Component({
  selector: 'app-fedex-order',
  templateUrl: 'fedex-order.html',
  styleUrls: [
    'fedex-order.scss'
  ]
})
export class FedexOrderComponent implements OnInit {

  tab = 'user';
  group: FormGroup;
  @Input() order: Order;
  isMobile: any;
  dateClass: any;
  transportationAddressControl: FormControl;
  transportationDayControl: FormControl;
  transportationEmailControl: FormControl;
  transportationPhoneControl: FormControl;
  transportationContactControl: FormControl;
  transportationFileControl: FormControl;
  transportationAddressNumberControl: FormControl;
  transportationAddressPostalControl: FormControl;
  transportationAddressStreetControl: FormControl;
  transportationAddressCityControl: FormControl;
  generatedLabels$: Observable<FedexFile[]> = of([]);
  uploadedInvoices$: Observable<InvoiceFile[]> = of([]);
  host = environment.apiUrl.replace('/api/v1', '');
  loader = new EventEmitter();


  constructor(
    public injector: Injector,
    private formService: FormService,
    private fedexFileService: FedexFileService,
    private invoiceFileService: InvoiceFileService,
    private http: HttpClient,
    protected formBuilder: FormBuilder) {
    this.group = formBuilder.group({});
    this.transportationAddressControl = formBuilder.control('1033 Budapest, Szentendrei út 89-95', formService.bindValidations(formService.getValidations(['required'])));
    this.transportationAddressPostalControl = formBuilder.control('1033', formService.bindValidations(formService.getValidations(['required'])));
    this.transportationAddressStreetControl = formBuilder.control('Szentendrei út', formService.bindValidations(formService.getValidations(['required'])));
    this.transportationAddressCityControl = formBuilder.control('Budapest', formService.bindValidations(formService.getValidations(['required'])));
    this.transportationAddressNumberControl = formBuilder.control('89-95', formService.bindValidations(formService.getValidations(['required'])));
    this.transportationDayControl = formBuilder.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.transportationEmailControl = formBuilder.control('admin@bomoart.com', formService.bindValidations(formService.getValidations(['required'])));
    this.transportationPhoneControl = formBuilder.control('+3670 416 2470', formService.bindValidations(formService.getValidations(['required'])));
    this.transportationContactControl = formBuilder.control('Töttősi Annamária', formService.bindValidations(formService.getValidations(['required'])));

    this.transportationFileControl = formBuilder.control('');

    this.group.addControl('addressPostal', this.transportationAddressPostalControl);
    this.group.addControl('addressCity', this.transportationAddressCityControl);
    this.group.addControl('addressStreet', this.transportationAddressStreetControl);
    this.group.addControl('addressNumber', this.transportationAddressNumberControl);
    this.group.addControl('address', this.transportationAddressControl);
    this.group.addControl('day', this.transportationDayControl);
    this.group.addControl('email', this.transportationEmailControl);
    this.group.addControl('phone', this.transportationPhoneControl);
    this.group.addControl('contact', this.transportationContactControl);
    this.group.addControl('file', this.transportationFileControl);

  }

  ngOnInit() {
    this.generatedLabels$ = this.loader.asObservable().pipe(
      startWith(1),
      switchMap(() => this.fedexFileService.getByOrder(this.order))
    );
    this.uploadedInvoices$ = this.loader.asObservable().pipe(
      startWith(1),
      switchMap(() => this.invoiceFileService.getByOrder(this.order))
    );
  }


  submit() {
    if (this.group.valid) {
      this.http.post(environment.apiUrl + '/fedex/shipping-sheet/' + this.order.id, {
        contactEmail: this.transportationEmailControl.value,
        contactPostal: this.transportationAddressPostalControl.value,
        contactStreet: this.transportationAddressStreetControl.value,
        contactNumber: this.transportationAddressNumberControl.value,
        contactCity: this.transportationAddressCityControl.value,
        contactDay: this.transportationDayControl.value,
        contactPhone: this.transportationPhoneControl.value,
        contactName: this.transportationContactControl.value,
      }).subscribe(() => {
        this.order.labelGenerated = true;
        this.loader.emit();
      });
    } else {
      this.formService.validateAllFormFields(this.group);
    }
  }

  uploadInvoice(imageInput: HTMLInputElement) {
    const invoice = new InvoiceFile({});
    invoice.order = this.order;

    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      invoice.invoice = { uploaded: event.target.result};

      this.invoiceFileService.add(invoice).subscribe(() => this.loader.emit());
    });
    reader.readAsDataURL(file);
  }
}
