import {Injectable} from '@angular/core';
import {ResellerState, Store as ResellerStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Reseller} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {ResellerQuery} from './query';
import {Image} from "../../../components/entity";

@Injectable({providedIn: 'root'})
export class ResellerService extends EntityService<ResellerStore, ResellerState> {

  resellers$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Reseller(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('reseller.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('reseller.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('reseller.description'),
        inputType: 'text',
        name: 'description',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('reseller.url'),
        inputType: 'text',
        name: 'url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),

      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('product.weight'),
        inputType: 'number',
        name: 'weight',
        listed: false,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'image',
        label: this.translocoService.selectTranslate('reseller.logo'),
        name: 'logo',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'image',
        label: this.translocoService.selectTranslate('reseller.image'),
        name: 'image',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('reseller.isPrior'),
        name: 'isPrior',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('reseller.isLocal'),
        name: 'isLocal',
        listed: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: ResellerQuery,
    protected store: ResellerStore) {
    super(store, Reseller);
  }

  get resourceName(): string {
    return 'webshop/reseller';
  }

}
