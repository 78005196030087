<section class="signInInstant">
  <button class="signInInstant-close" type="button" (click)="panelEmitter.emit()"
          data-toggle="offCanvasTop">{{ 'webshop.Bezár' | transloco }}
  </button>
  <div class="row">
    <div class="small-12 column">
      <h2>{{ 'webshop.Belépés' | transloco }}</h2>
      <form (ngSubmit)="onSubmit()" [formGroup]="group" class="form form--offContent form--signInInstant" data-abide novalidate>
        <div class="row collapse large-unstack form--signInInstant-rows">
          <div class="columns"><input type="text" placeholder="E-mail" [formControlName]="'email'">
          </div>
          <div class="columns"><input type="password" placeholder="{{ 'webshop.Jelszó' | transloco}}" required
                                      [formControlName]="'password'"></div>
          <div class="shrink">
            <button class="btn btn--light" type="submit">
              {{ 'webshop.Belépés' | transloco }}
            </button>
          </div>
        </div>
        <span *ngIf="error" class="form-error"
              style="margin-top: 10px; display: block;">{{'Hibás bejelentkezési adatok' | transloco
          }}</span>
      </form>

    </div>
  </div>
  <nav>
    <ul>
      <li><a (click)="panelEmitter.emit()" [routerLink]="['/forgotten-password'] | localize">{{ 'webshop.Elfelejtettem a jelszavam' | transloco }}</a></li>
      <li><a (click)="panelEmitter.emit()" [routerLink]="['/registration'] | localize">{{ 'webshop.Regisztráció' | transloco }}</a></li>
    </ul>
  </nav>
</section>
