import {Component} from '@angular/core';
import {AuthService} from '../../authentication/service/auth.service';
import {interval, Observable} from "rxjs";
import {LoadingService} from "../service/loading.service";
import {debounce, distinctUntilChanged, shareReplay} from "rxjs/operators";

@Component({
  selector: 'logged-in.layout',
  templateUrl: './component.html',
  styleUrls: ['./../../../admin-styles.scss']
})
export class LoggedInLayoutComponent {

  loading$: Observable<boolean>;

  constructor(
    public authService: AuthService,
    private _loading: LoadingService
  ) {
    this.loading$ = this._loading.loadingSub
      .pipe(
        distinctUntilChanged(),
        debounce((value) => {
          if (value) {
            return interval(0);
          }
          return interval(0);
        }),
        shareReplay(1)
      );
  }

}
