<section class="l-siteContent" id="theTop" *ngIf="item$ | async as item">
  <section class="post">
    <div class="row">
      <div class="small-12 column">
        <header class="post-head">
          <time>{{ item.createdAt | date:'longDate'}}</time>
          <h1>{{ item.title }}</h1>
          <img src="/assets/images/menu_heading_decor.svg" alt="decor">
        </header>
        <article class="l-videoEmbed">
          <section>
            <p [innerHTML]="item.content">
          </section>
          <section class="post-img" *ngIf="item.image2.default_image_small">
            <div class="row">
              <div class="small-12 medium-6 column">
                <!-- width: 550px -->
                <div class="decorBorder">
                  <div>
                    <div><img src="{{ item.image1.default_image_medium}}" alt="kép címe"></div>
                  </div>
                </div>
              </div>
              <div class="small-12 medium-6 column">
                <!-- width: 550px -->
                <div class="decorBorder">
                  <div>
                    <div><img src="{{ item.image2.default_image_medium}}" alt="kép címe"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="post-img" *ngIf="!item.image2.default_image_small">
            <div class="decorBorder">
              <div>
                <div>
                  <!-- small: 450px, medium: 750px, large: 1010px, xlarge: 1185px -->
                  <app-image
                    [small]="item.image1.default_image_small"
                    [medium]="item.image1.default_image_medium"
                    [large]="item.image1.default_image_large"
                    [xlarge]="item.image1.default_image_xlarge"
                  ></app-image>
                </div>
              </div>
            </div>
          </section>
          <section>
            <p [innerHTML]="item.content2"></p>
          </section>
        </article>
        <div class="g-row">
          <div class="row">
            <div class="small-12 large-6 column">
              <section>
                <!-- AddThis Button BEGIN -->
                <div class="addthis_toolbox addthis_default_style addthis_32x32_style">
                  <a class="addthis_button_facebook"></a>
                  <a class="addthis_button_instagram"></a>
                  <a class="addthis_button_vimeo"></a>
                  <a class="addthis_button_compact"></a>
                  <a class="addthis_counter addthis_bubble_style"></a>
                </div>
                <!-- AddThis Button END -->
              </section>
            </div>
            <div class="small-12 large-6 column" *ngIf="item.product">
              <div class="g-top g-top-hide-large-up text-right">
                <a class="l-moreBig" [routerLink]="['/product', item.product.slug] | localize">{{ 'webshop.Tovább a termékhez' | transloco }}</a>
              </div>
            </div>
          </div>
        </div>
        <app-gallery *ngIf="item.gallery.id" [gallery]="item.gallery"></app-gallery>
      </div>
    </div>
  </section>
</section>
