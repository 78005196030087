import {Component, Injector} from '@angular/core';
import {ResellerState, Store} from './state/store';
import {Reseller} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {ResellerQuery} from './state/query';
import {ResellerService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class ResellerListComponent extends EntityListComponent<Reseller, ResellerState, Store> {

  translationDomain = 'reseller';

  constructor(
    public injector: Injector,
    protected service: ResellerService,
    query: ResellerQuery,
    private ResellerFilterService: FilterService) {
    super(injector, service, query, ResellerFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
