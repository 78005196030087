import {Address, Entity} from '../../../components/entity';
import {Role} from '../../role/state/model';

export class User extends Entity {
  username: string;
  email: string;
  password: string;
  permissions: Role[];
  active: boolean;
  lastLogin: Date;
  licencePlateNumber: string;
  firstName: string;
  lastName: string;
  phone: string;

  constructor({
                id,
                firstName,
                lastName,
                phone,
                username,
                email,
                password,
                permissions,
                active,
                licencePlateNumber,
                lastLogin
              }: Partial<User>) {
    super({id});
    this.email = email ?? '';
    this.username = username ?? '';
    this.password = password ?? '';
    this.firstName = firstName ?? '';
    this.lastName = lastName ?? '';
    this.phone = phone;

    this.active = active ?? false;
    this.licencePlateNumber = licencePlateNumber ?? '';
    if (typeof lastLogin === 'object') {
      this.lastLogin = lastLogin as Date;
    } else {
      this.lastLogin = new Date(Date.parse(lastLogin as unknown as string));
    }
    this.permissions = permissions ? permissions.map(entity => new Role(entity)) : [];
  }

  getNameProperty(): string {
    return 'lastName';
  }

  toString() {
    return this.lastName + ' ' + this.firstName + ' (' + this.email + ')';
  }
}
