import {Injectable} from '@angular/core';
import {NewsletterService} from './state/service';
import {NewsletterQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Newsletter} from './state/model';
import {NewsletterState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Newsletter, NewsletterState, NewsletterQuery, Store> {
  constructor(protected query: NewsletterQuery, protected api: NewsletterService) {
    super(query, api);
  }
}
