import {Component, Injector} from '@angular/core';
import {CountryState, Store as CountryStore} from './state/store';
import {Country} from './state/model';
import {CountryService as CountryService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {CountryQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class CountryDetailComponent extends EntityDetailComponent<Country, CountryState, CountryStore> {

  constructor(
    public injector: Injector,
    service: CountryService,
    protected query: CountryQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
