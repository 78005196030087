import {Entity, Image} from '../../../components/entity';

export class PressDownload extends Entity {
  url: string;
  title: string;
  image1: Image;

  constructor({
                id,
                title,
                url,
                image1,
                createdAt, updatedAt
              }: Partial<PressDownload>) {
    super({id, createdAt, updatedAt});
    this.url = url ?? '';
    this.title = title ?? '';
    this.image1 = new Image(image1 ?? {});
  }

  getNameProperty(): string {
    return 'title';
  }
}
