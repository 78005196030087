<ng-content></ng-content>
<section>
  <div class="row">
    <div class="small-12 column">
      <ul class="tagSelector">
        <li *ngIf="currentPage !== 1"><a (click)="previous()" >{{ 'Előző' | transloco }}</a></li>
        <li *ngIf="currentPage > 2"><a (click)="toPage(currentPage-2)">{{ currentPage - 2 }}</a></li>
        <li *ngIf="currentPage > 1"><a (click)="toPage(currentPage-1)">{{ currentPage - 1 }}</a></li>
        <li *ngIf="maxPage > 1" class="tagSelector-selected"><a>{{ currentPage }}</a></li>
        <li *ngIf="maxPage > currentPage"><a (click)="toPage(currentPage+1)">{{ currentPage + 1 }}</a></li>
        <li *ngIf="maxPage > currentPage+1"><a (click)="toPage(currentPage+2)">{{ currentPage + 2 }}</a></li>
        <li *ngIf="currentPage !== maxPage"><a (click)="next()">{{ 'Következő' | transloco }}</a></li>
      </ul>
    </div>
  </div>
</section>
