import {Component, Injector} from '@angular/core';
import {RoleState, Store} from './state/store';
import {Role} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {RoleQuery} from './state/query';
import {RoleService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class RoleListComponent extends EntityListComponent<Role, RoleState, Store> {

  translationDomain = 'role';

  constructor(
    public injector: Injector,
    protected service: RoleService,
    query: RoleQuery,
    private RoleFilterService: FilterService) {
    super(injector, service, query, RoleFilterService);
  }
}
