import {Component} from '@angular/core';
import {Observable, of} from "rxjs";
import {PressDownload} from "../../../content/press-download/state/model";
import {PressDownloadService} from "../../../content/press-download/state/service";
import {Router} from "@angular/router";
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";

@Component({
  templateUrl: './listDownloads.html'
})
export class WebshopPressDownloadsComponent {
  items$: Observable<PressDownload[]> = of([]);

  constructor(
    private service: PressDownloadService,
    private router: Router,
    private localizeRouter: LocalizeRouterService,
  ) {
    if (!localStorage.getItem('pressDownloads')) {
      this.router.navigate([this.localizeRouter.translateRoute('/press')]).then();
    }
    this.items$ = service.items$;
  }

  isOuterUrl(url: string) {
    return url.startsWith('http');
  }
}
