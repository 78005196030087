import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WebshopLayoutComponent} from "../layout/webshop/component";
import {IndexComponent} from "./main/index/component";
import {LocalizeRouterModule} from "@penleychan/ngx-transloco-router";
import {WebshopNewsListComponent} from "./content/news/list";
import {ShopsViewComponent} from "./webshop/shops/view";
import {WebshopWebshopComponent} from "./webshop/webshop/component";
import {RegistrationComponent} from "./usermanager/registration/component";
import {ForgottenPasswordComponent} from "./usermanager/forgotten-password/component";
import {WebshopNewsViewComponent} from "./content/news/view";
import {WebshopProductListComponent} from "./webshop/products/list";
import {WebshopProductComponent} from "./webshop/products/component";
import {CartComponent} from "./webshop/cart/component";
import {ProfileComponent} from "./usermanager/profile/component";
import {CheckoutComponent} from "./webshop/checkout/component";
import {WebshopNewsletterListComponent} from "./content/newsletter/list";
import {WebshopContactComponent} from "./content/contact/view";
import {WebshopPressViewComponent} from "./content/press/view";
import {WebshopPressNewsComponent} from "./content/press/listNews";
import {WebshopPressDownloadsComponent} from "./content/press/listDownloads";
import {WebshopPressVideosComponent} from "./content/press/listVideos";
import {WebshopPressViewNewsComponent} from "./content/press/viewNews";
import {WebshopNewsletterSubscribeComponent} from "./content/newsletter/subscribe";
import {WebshopBlogViewComponent} from "./content/blog/view";
import {WebshopBlogListComponent} from "./content/blog/list";
import {WebshopWallpaperViewComponent} from "./content/wallpaper/view";
import {WebshopContactWholesaleComponent} from "./content/contact/wholesale";
import {WebshopPageViewComponent} from "./content/page/view";
import {CheckoutLoginComponent} from "./webshop/checkout/login";
import {CheckoutGuard} from "./checkout.guard";
import {CheckoutRegistrationComponent} from "./webshop/checkout/registration-completed";
import {ShopsResellerComponent} from "./webshop/shops/reseller";
import {ActivateComponent} from "./usermanager/activate/component";
import {OneTimeComponent} from "./usermanager/onetime/component";
import {WebshopBlogMainComponent} from "./content/blog/main";
import {PaymentComponent} from "./webshop/payment/component";

const routes: Routes = [
  {
    path: '',
    component: WebshopLayoutComponent,
    children: [
      {
        path: '',
        component: IndexComponent
      },
      {
        path: 'activate/:userId/:token',
        component: ActivateComponent
      },
      {
        path: 'onetime/:userId/:token',
        component: OneTimeComponent
      },
      {
        path: 'news',
        component: WebshopNewsListComponent
      },
      {
        path: 'newsletters',
        component: WebshopNewsletterListComponent
      },
      {
        path: 'blog',
        component: WebshopBlogMainComponent
      },
      {
        path: 'blog/list',
        component: WebshopBlogListComponent
      },
      {
        path: 'blog/:slug',
        component: WebshopBlogViewComponent
      },
      {
        path: 'newsletter/sign-up',
        component: WebshopNewsletterSubscribeComponent
      },
      {
        path: 'contact',
        component: WebshopContactComponent
      },
      {
        path: 'wholesale',
        component: WebshopContactWholesaleComponent
      },
      {
        path: 'products/:term',
        component: WebshopProductListComponent
      },
      {
        path: 'products',
        component: WebshopProductListComponent
      },
      {
        path: 'press',
        component: WebshopPressViewComponent
      },
      {
        path: 'press/news',
        component: WebshopPressNewsComponent
      },
      {
        path: 'press/news/:slug',
        component: WebshopPressViewNewsComponent
      },
      {
        path: 'press/downloads',
        component: WebshopPressDownloadsComponent
      },
      {
        path: 'press/videos',
        component: WebshopPressVideosComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'cart',
        component: CartComponent
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [CheckoutGuard]
      },
      {
        path: 'checkout/login',
        component: CheckoutLoginComponent
      },
      {
        path: 'checkout/registration-completed',
        component: CheckoutRegistrationComponent
      },
      {
        path: 'shops/reseller/:slug',
        component: ShopsResellerComponent
      },
      {
        path: 'news/:slug',
        component: WebshopNewsViewComponent
      },
      {
        path: 'page/:slug',
        component: WebshopPageViewComponent
      },
      {
        path: 'blog/:slug',
        component: WebshopBlogViewComponent
      },
      {
        path: 'blog/category/:slug',
        component: WebshopBlogListComponent
      },
      {
        path: 'wallpaper/:slug',
        component: WebshopWallpaperViewComponent
      },
      {
        path: 'shops',
        component: ShopsViewComponent
      },
      {
        path: 'webshop',
        component: WebshopWebshopComponent
      },
      {
        path: 'category/:slug',
        component: WebshopProductListComponent
      },
      {
        path: 'product/:id',
        component: WebshopProductComponent
      },
      {
        path: 'registration',
        component: RegistrationComponent
      },
      {
        path: 'forgotten-password',
        component: ForgottenPasswordComponent
      },
      {
        path: 'payment/:paymentMode/:orderId',
        component: PaymentComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), LocalizeRouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}

