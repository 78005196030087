<mat-toolbar-row>
  {{ service.getEntityTitle() | async }} {{ 'form.editing' | transloco }}
</mat-toolbar-row>
<mat-toolbar-row>
  <button mat-button mat-raised-button [color]="tab === 'user' ? 'primary' : 'accent'"  (click)="tab = 'user'">Személyes adatok</button>
  <button mat-button mat-raised-button [color]="tab === 'billing' ? 'primary' : 'accent'" (click)="tab = 'billing'">Számlázási címek</button>
  <button mat-button mat-raised-button [color]="tab === 'shipping' ? 'primary' : 'accent'"  (click)="tab = 'shipping'">Szállítási címek</button>
</mat-toolbar-row>
<div style="
  display: flex;
  justify-content: center;" *ngIf="(entity$ | async) as order">

  <ng-container *ngIf="tab === 'user'">
    <div formHost [entity$]="entity$" [entityService]="service" [subForm]="false" (submit)="onSubmit($event)"
         (cancel)="onCancel()">
    </div>
  </ng-container>
  <ng-container  *ngIf="tab === 'billing'">
      <table mat-table [dataSource]="(billingAddresses$ | async)" class="mat-elevation-z1">
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>Város</th>
          <td mat-cell *matCellDef="let element">{{ element.address.city }}</td>
        </ng-container>

        <ng-container matColumnDef="postalCode">
          <th mat-header-cell *matHeaderCellDef>Ir. szám</th>
          <td mat-cell *matCellDef="let element">{{ element.address.postalCode }}</td>
        </ng-container>

        <ng-container matColumnDef="street">
          <th mat-header-cell *matHeaderCellDef>Utca</th>
          <td mat-cell *matCellDef="let element">{{ element.address.street }}</td>
        </ng-container>

        <ng-container matColumnDef="county">
          <th mat-header-cell *matHeaderCellDef>Megye</th>
          <td mat-cell *matCellDef="let element">{{ element.address.county }}</td>
        </ng-container>

        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>Ország</th>
          <td mat-cell *matCellDef="let element">{{ element.country }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['country', 'postalCode', 'county', 'city', 'street']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['country', 'postalCode', 'county', 'city', 'street'];"></tr>
      </table>

  </ng-container>
  <ng-container  *ngIf="tab === 'shipping'">
    <table mat-table [dataSource]="(shippingAddresses$ | async)" class="mat-elevation-z1">
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef>Város</th>
        <td mat-cell *matCellDef="let element">{{ element.address.city }}</td>
      </ng-container>

      <ng-container matColumnDef="postalCode">
        <th mat-header-cell *matHeaderCellDef>Ir. szám</th>
        <td mat-cell *matCellDef="let element">{{ element.address.postalCode }}</td>
      </ng-container>

      <ng-container matColumnDef="street">
        <th mat-header-cell *matHeaderCellDef>Utca</th>
        <td mat-cell *matCellDef="let element">{{ element.address.street }}</td>
      </ng-container>

      <ng-container matColumnDef="county">
        <th mat-header-cell *matHeaderCellDef>Megye</th>
        <td mat-cell *matCellDef="let element">{{ element.address.county }}</td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef>Ország</th>
        <td mat-cell *matCellDef="let element">{{ element.country }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="['country', 'postalCode', 'county', 'city', 'street']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['country', 'postalCode', 'county', 'city', 'street'];"></tr>
    </table>

  </ng-container>
</div>
