import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable} from 'rxjs';
import {Entity} from '../../entity';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-custom',
  styleUrls: ['common.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'custom.html'
})
export class CustomComponent implements OnInit {

  field: FieldConfig;
  group: FormGroup;
  setGroup: FormGroup;
  gpsGroup: FormGroup;
  parentEntity$ = new Observable<Entity>();
  customContent$ = new Observable<string>();

  constructor(
    private formBuilder: FormBuilder,
    public translationService: TranslocoService
  ) {
    this.group = new FormGroup({});
    this.setGroup = new FormGroup({});
    this.gpsGroup = new FormGroup({});
    this.field = new FieldConfig();

  }

  ngOnInit() {
    const callable = this.field.customContent as (form?: (FormGroup | undefined), entity$?: Observable<Entity>) => Observable<string>;
    const group = this.group.parent ?? this.group;
    this.customContent$ = callable(this.group, this.parentEntity$);
  }
}
