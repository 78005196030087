import {Entity, Image} from '../../../components/entity';
import {ProductParameter} from "../../product-parameter/state/model";
import {ProductParameterFormat} from "../../product-parameter-format/state/model";

export class ProductParameterType extends Entity {
  name: string;
  productParameter: ProductParameter;
  available: boolean;
  format: number;
  image: Image;
  image2: Image;
  price: number;
  weight: number | null;
  mass: number | null;
  captionLength: number;
  properties: string;
  sizes: string;
  barCode: string;
  stock: number;

  constructor({
                id,
                name,
                productParameter,
                available,
                format,
                image,
                image2,
                price,
                weight,
                barCode,
                stock,
                mass,
                properties,
                sizes,
                captionLength,
                createdAt, updatedAt
              }: Partial<ProductParameterType>) {
    super({id, createdAt, updatedAt});
    this.name = name ?? '';
    this.available = available ?? false;
    this.format = format ?? 0;
    this.image = new Image(image ?? {});
    this.image2 = new Image(image2 ?? {});
    this.price = price ?? 0;
    this.weight = weight ?? null;
    this.captionLength = captionLength ?? 0;
    this.mass = mass ?? 0;

    this.barCode = barCode ?? '';
    this.stock = stock ?? 0;
    this.properties = properties ?? '';
    this.sizes = sizes ?? '';
    this.productParameter = new ProductParameter(productParameter ?? {});
  }

  getNameProperty(): string {
    return 'name';
  }
}
