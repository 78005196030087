import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Caption} from './model';

export interface CaptionState extends EntityState<Caption> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Caption' })
export class Store extends EntityStore<CaptionState> {

  constructor() {
    super();
  }

}
