import {Entity, Image} from '../../../components/entity';
import {ProductParameterType} from "../../product-parameter-type/state/model";
import {ProductParameterFormat} from "../../product-parameter-format/state/model";
import {Product} from "../../product/state/model";

export class ProductParameterImage extends Entity {
  productParameterType: ProductParameterType;
  format: ProductParameterFormat;
  image: Image;
  product: Product;

  constructor({
                id,
                format,
                productParameterType,
                image,
                product,
                createdAt, updatedAt
              }: Partial<ProductParameterImage>) {
    super({id, createdAt, updatedAt});
    this.productParameterType = new ProductParameterType(productParameterType ?? {});
    this.format = new ProductParameterFormat(format ?? {});
    this.image = new Image(image ?? {});
    this.product = new Product(product ?? {});
  }

  getNameProperty(): string {
    return 'productParameterType';
  }
}
