import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material/material.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {Module as ComponentsModule} from '../components/module';
import {TranslocoModule} from '@ngneat/transloco';
import {GuestLayoutComponent} from './guest/component';
import {LoggedInLayoutComponent} from './logged-in/component';
import {RouterModule} from '@angular/router';
import {NavigationComponent} from './navigation/navigation.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoaderInterceptor} from './service/loader.interceptor';
import {LoadingService} from './service/loading.service';
import {SideNavComponent} from './side-nav/side-nav.component';
import {WebshopLayoutComponent} from "./webshop/component";
import {WebshopClientModule} from "../webshop-client/module";
import {AdminComponent} from "./admin/component";
import {RoutingModule} from "./routing";
import {LocalizeRouterModule} from "@penleychan/ngx-transloco-router";
import {RecaptchaModule} from "ng-recaptcha";

@NgModule({
  declarations: [
    GuestLayoutComponent,
    LoggedInLayoutComponent,
    NavigationComponent,
    SideNavComponent,
    WebshopLayoutComponent,
    AdminComponent,
  ],
  exports: [],
    imports: [
        ComponentsModule,
        CommonModule,
        MaterialModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        TranslocoModule,
        RouterModule,
        WebshopClientModule,
        RoutingModule,
        LocalizeRouterModule,
        RecaptchaModule
    ],
  providers: [
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ]
})
export class Module {
}
