import {Entity} from '../../../components/entity';

export class Role extends Entity {
  slug: string;

  constructor({ id, slug }: Partial<Role>) {
    super({ id });
    this.slug = slug ?? '';
  }

  getNameProperty(): string {
    return 'slug';
  }
}
