<section class="l-siteContent l-siteContent--divided
" id="theTop" *ngIf="user$ | async as user">
  <div class="row">
    <div class="small-12 large-4 column g-right-extrathick">
      <div class="l-siteContent--divided-pushDownContent"></div>
      <div class="l-headProfile">
        <header>
          <img src="/assets/images/menu_heading_decor_flip.svg" alt="decor">
          <h1 class="">{{ 'webshop.Profil' | transloco }}</h1>
          <img src="/assets/images/menu_heading_decor.svg" alt="decor">
          <img class="l-headProfile-avatar" src="/assets/images/popup_pic.png" alt="kép címe">
          <h2>{{ 'webshop.Adataim' | transloco }}</h2>
        </header>
      </div>
      <ul class="menuAccordion menuAccordion--userProfile g-bottom-extrathick" data-accordion
          data-allow-all-closed="true">
        <li class="menuAccordion-item is-active" data-accordion-item>
          <a class="menuAccordion-item-title">
            <span>{{ 'webshop.Fiók:' | transloco}} <span>{{ user.email }}</span></span>
          </a>
          <ul class="menuAccordion-item-content" data-tab-content>
            <li><a (click)="switchTab('personal_data')" class="menuAccordion--userProfile-personal"
                   data-smooth-scroll data-threshold="0"
                   data-animation-easing="swing">{{ 'webshop.Személyes adatok' | transloco }}</a></li>
            <li><a (click)="switchTab('billingAddress')" class="menuAccordion--userProfile-billingAddress"
                   data-smooth-scroll data-threshold="0"
                   data-animation-easing="swing">{{ 'webshop.Számlázási címek' | transloco }}</a></li>
            <li><a (click)="switchTab('delivery_address')" class="menuAccordion--userProfile-deliveryAddress"
                   data-smooth-scroll data-threshold="0"
                   data-animation-easing="swing">{{ 'webshop.Szállítási címek' | transloco }}</a></li>
            <!--li><a ng-click="tab='loyalty'" class="menuAccordion--userProfile-loyalty" href="#profileContent" data-smooth-scroll data-threshold="0" data-animation-easing="swing">{{ 'webshop.Törzsvásárlói program' | transloco }}</a></li-->
            <li><a (click)="switchTab('previous_order')" class="menuAccordion--userProfile-previousOrder"
                   data-smooth-scroll data-threshold="0"
                   data-animation-easing="swing">{{ 'webshop.Előző rendelések' | transloco }}</a></li>
            <li><a (click)="logout()" data-smooth-scroll data-threshold="0"
                   data-animation-easing="swing">{{ 'webshop.Kilépés' | transloco }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="small-12 large-8 xlarge-6 xlarge-pull-2 column" id="profileContent">
      <div class="l-siteContent--divided-right">
        <div class="l-siteContent--divided-pushDownContent"></div>
        <div class="ajaxZone" id="userProfile">
          <div class="ajaxZone-error">
            <p>
            </p>
            <div class="ajaxZone-error-codes">
            </div>
          </div>
          <div class="animStatic inView">
            <!-- ajax content goes here -->

            <div *ngIf="tab == 'personal_data'">
              <h2>{{ 'webshop.Személyes adatok' | transloco }}</h2>
              <form autocomplete="off" class="form form--userPersonalData" data-abide novalidate>
                <div data-abide-error class="alert callout h-hidden">
                  <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
                </div>
                <div class="row">
                  <div class="small-12 medium-6 column">
                    <label>{{ 'webshop.Vezetéknév' | transloco }}
                      <input [formControl]="userLastName" type="text"
                             placeholder="{{ 'webshop.Vezetéknév' | transloco }}">
                      <span class="form-error"
                            *ngIf="userLastName.hasError('required') && userLastName.touched">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
                    </label>
                  </div>
                  <div class="small-12 medium-6 column">
                    <label>{{ 'webshop.Keresztnév' | transloco }}
                      <input [formControl]="userFirstName" type="text"
                             placeholder="{{ 'webshop.Keresztnév' | transloco }}">
                      <span class="form-error"
                            *ngIf="userFirstName.hasError('required') && userLastName.touched">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="small-12 medium-6 column">
                    <label>{{ 'webshop.E-mail' | transloco }}
                      <input [formControl]="userEmail" type="text" placeholder="{{ 'webshop.E-mail' | transloco }}">
                      <span
                        class="form-error"
                        *ngIf="userEmail.hasError('email') && userEmail.touched">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco }}</span>
                      <span class="form-error"
                            *ngIf="userEmail.hasError('required') && userEmail.touched">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
                    </label>
                  </div>
                  <div class="small-12 medium-6 column">
                    <label>{{ 'webshop.Telefon' | transloco }}
                      <input [formControl]="userPhone" type="number" [placeholder]="'webshop.Telefon' | transloco" autocomplete="false">
                      <span class="form-error"
                            *ngIf="userPhone.hasError('required') && userPhone.touched">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
                    </label>
                  </div>
                </div>
                <hr>
                <h2>{{ 'webshop.Jelszó megváltoztatása' | transloco }}</h2>
                <label>{{ 'webshop.Új jelszó' | transloco }}
                  <input [formControl]="userPassword" type="password"
                         [placeholder]="'webshop.Új jelszó' | transloco">
                  <span class="form-error"
                        *ngIf="userPassword.hasError('same') && userPassword.touched">{{ 'webshop.A jelszavak nem egyeznek' | transloco }}</span>
                </label>
                <label>{{ 'webshop.Új jelszó megerősítése' | transloco }}
                  <input [formControl]="userPasswordRe" type="password"
                         [placeholder]="'webshop.Új jelszó megerősítése' | transloco">
                  <span class="form-error"
                        *ngIf="userPasswordRe.hasError('required') && userPasswordRe.touched">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
                </label>
                <div class="text-right">
                  <button class="btn btn--basic" (click)="modifyUser()"
                          type="submit">{{ 'webshop.Mentés' | transloco }}</button>
                </div>
              </form>
            </div>

            <div *ngIf="tab == 'billingAddress'">
              <h2>{{ 'webshop.Számlázási címek' | transloco }}</h2>
              <ul class="list">
                <li *ngFor="let address of (invoiceAddresses$ | async)">
                  <div class="addressItem">
                    <div class="row align-middle">
                      <div class="column">
                        {{address.address}} <span
                        class="addressItem-default"
                        *ngIf="address.isDefault">({{ 'webshop.alapértelmezett' | transloco }}
                        )</span></div>
                      <div class="shrink column addressItem-dataDivided">
                        <button (click)="editInvoiceAddress(address)"
                                class="btn btn--icon btn--edit billingAdressFormOpen"><span
                          class="show-for-sr">{{ 'webshop.Módosítás' | transloco }}</span></button>
                      </div>
                      <div class="shrink column">
                        <button (click)="deleteInvoiceAddress(address)" class="btn btn--icon btn--delete"><span
                          class="show-for-sr">{{ 'webshop.törlés' | transloco }}</span></button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="g-row">
                <a class="btn btn--basic billingAdressFormOpen"
                   (click)="editInvoiceAddress()">{{ 'webshop.Új számlázási cím' | transloco }}</a>
              </div>
            </div>

            <div *ngIf="tab == 'billingAddress_form'">
              <h2>{{ 'webshop.Számlázási cím' | transloco }}</h2>
              <form autocomplete="off" class="form form--billingAddress" data-abide
                    novalidate>
                <app-edit-address #invoiceAddressComponent [address]="invoiceAddress"></app-edit-address>
                <input [formControl]="defaultInvoiceAddress" id="defaultBillingAddress" type="checkbox"><label
                for="defaultBillingAddress">{{ 'webshop.Ez legyen az elsődleges számlázási cím' | transloco }}</label>
                <div class="g-bottom">
                  <input [formControl]="copyControl" id="copyBillingAddressToDelivery"
                         type="checkbox"><label
                  for="copyBillingAddressToDelivery">{{ 'webshop.Felveszem a szállítási címek közé is' | transloco }}</label>
                </div>
                <div class="row">
                  <div class="small-6 column">
                    <div class="text-left">
                      <button (click)="switchTab('billingAddress')"
                              class="btn btn--basic btn--cancel billingAdressFormClose"
                              type="button">{{'Mégse' | transloco }}</button>
                    </div>
                  </div>
                  <div class="small-6 column">
                    <div class="text-right">
                      <button  (click)="saveInvoiceAddress()" class="btn btn--basic" type="submit">{{ 'webshop.Mentés' | transloco }}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div *ngIf="tab == 'delivery_address'">
              <h2>{{ 'webshop.Szállítási címek' | transloco }}</h2>
              <ul class="list">
                <li *ngFor="let address of (shippingAddresses$ | async)">
                  <div class="addressItem">
                    <div class="row align-middle">
                      <div class="column">
                        {{address.address}} <span
                        class="addressItem-default"
                        *ngIf="address.isDefault">({{ 'webshop.alapértelmezett' | transloco }}
                        )</span></div>
                      <div class="shrink column addressItem-dataDivided">
                        <button (click)="editDeliveryAddress(address)"
                                class="btn btn--icon btn--edit deliveryAdressFormOpen"><span
                          class="show-for-sr">{{ 'webshop.Módosítás' | transloco }}</span></button>
                      </div>
                      <div class="shrink column">
                        <button (click)="deleteDeliveryAddress(address)" class="btn btn--icon btn--delete"><span
                          class="show-for-sr">{{ 'webshop.törlés' | transloco }}</span></button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="g-row">
                <a class="btn btn--basic deliveryAdressFormOpen"
                   (click)="editDeliveryAddress()">{{ 'webshop.Új szállítási cím' | transloco }}</a>
              </div>
            </div>

            <div *ngIf="tab == 'delivery_address_form'">
              <h2>{{ 'webshop.Szállítási cím' | transloco }}</h2>
              <form autocomplete="off" class="form form--deliveryAddress" (ngSubmit)="saveDeliveryAddress()" data-abide
                    novalidate>
                <app-edit-address #shippingAddressComponent [address]="deliveryAddress"></app-edit-address>
                <input id="defaultShippingAddress" [formControl]="defaultShippingAddress" type="checkbox"><label
                for="defaultShippingAddress">{{ 'webshop.Ez legyen az elsődleges szállítási cím' | transloco }}</label>
                <div class="g-bottom">
                  <input [formControl]="copyControl" id="copyShippingAddressToInvoice"
                         type="checkbox"><label
                  for="copyShippingAddressToInvoice">{{ 'webshop.Felveszem a számlázási címek közé is' | transloco }}</label>
                </div>
                <div class="row">
                  <div class="small-6 column">
                    <div class="text-left">
                      <button (click)="switchTab('delivery_address')"
                              class="btn btn--basic btn--cancel deliveryAdressFormClose"
                              type="button">{{ 'webshop.Mégse' | transloco }}</button>
                    </div>
                  </div>
                  <div class="small-6 column">
                    <div class="text-right">
                      <button class="btn btn--basic" type="submit">{{ 'webshop.Mentés' | transloco }}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div *ngIf="tab == 'loyalty'">
              <h2>{{ 'webshop.Törzsvásárlói program' | transloco }}</h2>
              <section class="loyalty">
                <div class="loyalty-cardNumber">
                  <p>{{ 'webshop.Kártyaszámod' | transloco }}: <strong>152316534615234651387541</strong></p>
                  <p>{{ 'webshop.Jelenlegi kedvezményed' | transloco }}: <strong>10%</strong></p>
                </div>
                <section class="loyalty-purchases">
                  <h2>{{ 'webshop.Vásárlásaid' | transloco }}</h2>
                  <ul class="list">
                    <li>
                      <div class="row">
                        <div class="small-6 column">2017.09.09.</div>
                        <div class="small-6 column">8.200 Ft</div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="small-6 column">2016.11.12.</div>
                        <div class="small-6 column">8.200 Ft</div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="small-6 column">2016.03.04.</div>
                        <div class="small-6 column">8.200 Ft</div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="small-6 column">2015.09.17.</div>
                        <div class="small-6 column">8.200 Ft</div>
                      </div>
                    </li>
                  </ul>
                </section>
                <div class="g-top">
                  <a class="btn btn--normalText"
                     href="wysiwyg_page.html">{{ 'webshop.A törzsvásárlói programról bővebben' | transloco }}</a>
                </div>
              </section>
            </div>

            <div *ngIf="tab == 'previous_order'">
              <h2>{{ 'webshop.Előző rendelések' | transloco }}</h2>
              <ul class="list">
                <li *ngFor="let order of (lastOrders$ | async) | orderBy: '-createdAt'">

                  <div class="previousOrderPreview" (click)="showOrder(order)">
                    <div class="row">
                      <div class="shrink column">
                        <div class="previousOrderPreview-align">
                          <time>{{ order.createdAt | date:'longDate' }}</time>
                        </div>
                      </div>
                      <div class="column previousOrderPreview-dataDivided">
                        <div class="previousOrderPreview-align">
                          {{ order.orderId }} - {{ orderStatus$(order) | async}} </div>
                      </div>
                      <div class="shrink column">
                        <div class="btn btn--icon btn--see" aria-hidden="true"></div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div *ngIf="tab == 'previous_order_details'">
              <section class="previousOrder">
                <header>
                  <h2>{{ 'webshop.Előző rendelés' | transloco }}</h2>
                  <div class="row">
                    <div class="small-12 medium-6 column">
                      {{ 'webshop.Rendelészám' | transloco }}:<br>
                      <mark>{{ selectedOrder.orderId }}</mark>
                    </div>
                    <div class="small-12 medium-6 column">
                      {{ 'webshop.Vásárlás dátuma' | transloco }}:<br>
                      <time>{{ selectedOrder.createdAt | date:'longDate' }}</time>
                    </div>
                    <div class="small-12 medium-6 column">
                      {{ 'webshop.Fizetés' | transloco }}:<br>
                      <mark>{{ selectedOrder.paymentMode.title }}</mark>
                    </div>
                    <div class="small-12 medium-6 column">
                      {{ 'webshop.Szállítási mód' | transloco }}:<br>
                      <mark>{{ selectedOrder.shippingMode.title }} <ng-container *ngIf="selectedOrder.shippingMode.handlerClass === 'App:Classes:PersonalShipping:Shipping'">{{ selectedOrder.store.title }}</ng-container></mark>
                    </div>
                  </div>
                </header>
                <section class="g-row-extrathick">
                  <ul class="list list--previousOrderItems">
                    <li *ngFor="let orderItem of selectedOrder.items">
                      <div class="orderItem">
                        <div class="row align-middle">
                          <div class="small-12 medium-7 column">
                            <div class="orderItem-product">
                              {{ orderItem.product.name }}</div>
                            <ul class="orderItem-detailsListing"
                                *ngIf="orderItem.productVariant.productParameterTypeCounts.length">
                              <li
                                *ngFor="let productParameterTypeCount of orderItem.productVariant.productParameterTypeCounts">
                                <ng-container *ngIf="productParameterTypeCount.quantity">
                                  <span>{{ productParameterTypeCount.quantity }}</span> –
                                  {{ productParameterTypeCount.productParameterType.name }}
                                </ng-container>
                                <ng-container *ngIf="!productParameterTypeCount.quantity">
                                  <span>{{ productParameterTypeCount.productParameterType.productParameter.name }}</span>
                                  –
                                  {{ productParameterTypeCount.productParameterType.name }}
                                </ng-container>
                              </li>
                              <li *ngIf="orderItem.caption">
                                <span>{{ 'webshop.Felirat' | transloco }} - </span> {{ orderItem.caption }}
                              </li>
                              <li *ngIf="orderItem.captionSpecial">
                                <span>{{ 'webshop.Felirat2' | transloco }} - </span> {{ orderItem.captionSpecial }}
                              </li>
                            </ul>
                          </div>
                          <div class="small-6 medium-3 column">
                            <div class="orderItem-quantity">
                              {{ orderItem.quantity }} {{ 'webshop.db' | transloco }} </div>
                          </div>
                          <div class="small-6 medium-2 column">
                            <div class="orderItem-price text-right">
                              {{ orderItem.price | webshopCurrency: selectedOrder.currency : 'symbol' : '0.0-2' :  orderItem.quantity | async}} </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul class="previousOrder-sum">
                    <li>{{ 'webshop.Részösszeg' | transloco }}:
                      <span>{{ selectedOrder.sumPrice | webshopCurrency: selectedOrder.currency : 'symbol' : '0.0-2'| async }}</span>
                    </li>
                    <li>{{ 'webshop.Szállítási költség' | transloco }}:
                      <span>{{ selectedOrder.shippingPrice | webshopCurrency: selectedOrder.currency : 'symbol' : '0.0-2' | async}}</span>
                    </li>
                    <li *ngIf="selectedOrder.productsDiscount > 0">{{ 'webshop.Akciós kedvezmény' | transloco }}:
                      <span>{{ selectedOrder.productsDiscount | webshopCurrency: selectedOrder.currency : 'symbol' : '0.0-2' | async}}</span>
                    </li>
                    <li *ngIf="false">Ajándékutalvány: <span>-1.000 Ft</span></li>
                    <li *ngIf="selectedOrder.couponDiscount > 0">{{ 'webshop.Kupon kedvezmény' | transloco }}:
                      <span>-{{ selectedOrder.couponDiscount | webshopCurrency: selectedOrder.currency : 'symbol' : '0.0-2' | async}}</span>
                    </li>
                    <li *ngIf="false">Törzsvásárlói kedvezmény (10%): <span>1.637 Ft</span></li>
                    <li>{{ 'webshop.Összesen' | transloco }}:
                      <span>{{ (selectedOrder.sumDiscountedPrice + selectedOrder.shippingPrice) | webshopCurrency: selectedOrder.currency : 'symbol' : '0.0-2' | async}}</span></li>
                  </ul>
                </section>
                <section class="previousOrder-comment">
                  <p><strong>{{ 'webshop.Megjegyzés' | transloco }}:</strong></p>
                  <p [innerHTML]="selectedOrder.innerDescription"></p>
                </section>
                <section class="previousOrder-invoiceData">
                  <div class="row g-top-extrathick">
                    <div class="small-12 medium-6 column" *ngIf="selectedOrder.billingAddress.isCompany">
                      <p><span>{{ 'webshop.Vevő' | transloco }}:</span>
                        <br> {{ selectedOrder.billingAddress.companyName}}
                        <br> {{ selectedOrder.billingAddress.postalCode}} {{ selectedOrder.billingAddress.city}}
                        , {{ selectedOrder.billingAddress.street}}</p>
                    </div>
                    <div class="small-12 medium-6 column" *ngIf="!selectedOrder.billingAddress.isCompany">
                      <p><span>{{ 'webshop.Vevő' | transloco }}:</span>
                        <br> {{ selectedOrder.billingAddress.lastName}} {{ selectedOrder.billingAddress.firstName}}
                        <br> {{ selectedOrder.billingAddress.postalCode}} {{ selectedOrder.billingAddress.city}}
                        , {{ selectedOrder.billingAddress.street}}</p>
                    </div>
                    <div class="small-12 medium-6 column" *ngIf="selectedOrder.shippingAddress.isCompany">
                      <p><span>{{ 'webshop.Szállítási cím' | transloco }}:</span>
                        <br> {{ selectedOrder.shippingAddress.companyName}}
                        <br> {{ selectedOrder.shippingAddress.postalCode}} {{ selectedOrder.shippingAddress.city}}
                        , {{ selectedOrder.shippingAddress.street}}</p>
                    </div>
                    <div class="small-12 medium-6 column" *ngIf="!selectedOrder.shippingAddress.isCompany">
                      <p><span>{{ 'webshop.Szállítási cím' | transloco }}:</span>
                        <br> {{ selectedOrder.shippingAddress.lastName}} {{ selectedOrder.shippingAddress.firstName}}
                        <br> {{ selectedOrder.shippingAddress.postalCode}} {{ selectedOrder.shippingAddress.city}}
                        , {{ selectedOrder.shippingAddress.street}}</p>
                    </div>
                    <div *ngIf="false" class="small-12 column">
                      <p class="g-top"><strong>{{ 'webshop.Rendeléstörténet' | transloco}}:</strong></p>
                      <ul class="list" *ngIf="logs$ | async as logs">
                        <li *ngFor="let log of logs | orderBy: '-created'">
                          <span>{{ log.createdAt | date:'fullTime' }}</span>
                          <p [innerHTML]="log.content"></p>
                        </li>
                      </ul>
                    </div>
                    <div class="g-top">
                      <a class="btn btn--basic"
                         (click)="switchTab('previous_order')">{{ 'webshop.Vissza' | transloco }}</a>
                    </div>
                  </div>
                </section>
              </section>
            </div>
            <!-- content end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
