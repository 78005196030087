<section class="l-siteContent" id="theTop" *ngIf="items$ | async as posts">
  <section class="cart">
    <div class="row">
      <div class="small-12 column">
        <header class="sectionHeader g-bottom">
          <h1><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Hírlevelek' | transloco }}<img
            src="/assets/images/heading_divider_right.svg" alt="decor"></h1>
        </header>
        <ul class="tagSelector">
          <li *ngFor="let _year of years" ng-class="{'tagSelector-selected': year==_year}"><a
            ng-click="selectYear(_year)">{{ _year }}</a></li>
        </ul>
        <ul class="list list--newsletter">
          <li *ngFor="let item of posts | orderBy: '-sent'">
            <div class="newsletterPreview">
              <header>
                <time>{{ item.sent | date:'longDate' }}</time>
                <h2 [innerHTML]="item.title"></h2>
              </header>
              <div class="newsletterPreview-text" [innerHTML]="item.content"></div>
              <div class="newsletterPreview-more">
                <a [href]="item.url" [attr.target]="this.isOuterUrl(item.url) ? '_blank' :null">{{ 'webshop.Tovább' | transloco }}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <app-webshop-pager [currentPage]="this.page$ | async" (paging)="paging($event)" [maxPage]="this.maxPage$ | async"></app-webshop-pager>

</section>
