import {Entity} from '../../../components/entity';

export class Newsletter extends Entity {
  slug: string;
  title: string;
  content: string;
  sent: Date | null;
  url: string;

  constructor({
                id, slug,
                title,
                content,
                sent,
                url,
                createdAt, updatedAt
              }: Partial<Newsletter>) {
    super({id, createdAt, updatedAt});
    this.slug = slug ?? '';
    this.title = title ?? '';
    this.content = content ?? '';
    this.sent = sent ?? null;
    this.url = url ?? '';
  }

  getNameProperty(): string {
    return 'title';
  }
}
