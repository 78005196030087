import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {PressVideoState, Store} from './store';

@Injectable({ providedIn: 'root' })
export class PressVideoQuery extends QueryEntity<PressVideoState> {

  constructor(protected store: Store) {
    super(store);
  }

}
