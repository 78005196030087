import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class NewsletterService {

  constructor(private httpClient: HttpClient) {
  }

  subscribe$(firstName: string, lastName: string, email: string) {
    return this.httpClient.post(environment.apiUrl + '/content/newsletter/subscribe', {
      firstName,
      lastName,
      email
    });
  }
}
