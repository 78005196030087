<mat-toolbar-row>
  {{ service.getEntityTitle() | async }} {{ 'form.editing' | transloco }}
</mat-toolbar-row>
<mat-toolbar-row *ngIf="(entity$ | async) || true">
  <button style="margin-right: 15px" mat-raised-button [color]="activeTab === 'main' ? 'primary' : 'accent'"
          (click)="activeTab = 'main'">Szállító adatlap
  </button>
  <ng-container *ngIf="(entity$ | async) as transporter">
    <button mat-raised-button [color]="activeTab === 'prices' ? 'primary' : 'accent'" (click)="activeTab = 'prices'"
            *ngIf="transporter.handlerClass && transporter.handlerClass !== 'App:Classes:PersonalShipping:Shipping' && transporter.handlerClass !== 'App:Classes:FedExShipping:Shipping'">
      Árak
    </button>
  </ng-container>
</mat-toolbar-row>
<div style="
  display: flex;
  justify-content: center;">
  <div *ngIf="activeTab === 'main'" formHost [entity$]="entity$" [entityService]="service" [subForm]="false"
       (submit)="onSubmit($event)"
       (cancel)="onCancel()">
  </div>
  <ng-container *ngIf="activeTab === 'prices'">
    <mat-card>
      <mat-card-content *ngIf="(entity$ | async) as productParameter" [formGroup]="pricesGroup">
        <mat-tab-group (selectedTabChange)="selectCountry($event)">
          <mat-tab *ngFor="let country of countries$ | async; let i=index" [label]="country.name">
            <ng-container *ngIf="selectedCountryIndex === i">
              <p *ngFor="let weightCategory of weightCategories$ | async">
                <mat-form-field appearance="fill">
                  <mat-label>< {{ weightCategory }} kg</mat-label>
                  <input matInput [formControl]="getControl(weightCategory, country)"
                         [placeholder]="''"
                         [type]="'number'">
                </mat-form-field>
              </p>
            </ng-container>
          </mat-tab>
        </mat-tab-group>

      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="savePrices()">
          Mentés
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</div>
