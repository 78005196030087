import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable, Subscription} from 'rxjs';
import {Entity} from '../../entity';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-phone',
  styleUrls: [
    'address.scss',
    'phone.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div>
      <div>
        <span>06-</span>
        <mat-form-field [formGroup]="setGroup" appearance="fill" [ngClass]="{mobile: field.isMobile, 'area-number': true}">
          <input matInput maxlength="2" [formControlName]="'area'"
                 [placeholder]="''" *ngIf="!field.isMobile">
          <mat-select *ngIf="field.isMobile" [placeholder]="''" [formControlName]="'area'">
            <mat-option [value]="20">20</mat-option>
            <mat-option [value]="30">30</mat-option>
            <mat-option [value]="31">31</mat-option>
            <mat-option [value]="50">50</mat-option>
            <mat-option [value]="70">70</mat-option>
          </mat-select>
        </mat-form-field>
        -
        <mat-form-field [formGroup]="setGroup" appearance="fill" style="margin-left: 2px">
          <mat-label>{{ field.label | async | default: '' }}</mat-label>

          <input matInput [formControlName]="'number'"
                 [placeholder]="''">
        </mat-form-field>
        #
        <mat-form-field [formGroup]="setGroup" appearance="fill" style="margin-left: 2px; width: 40px">

          <input matInput maxlength="5" [formControlName]="'extension'"
                 [placeholder]="''">
        </mat-form-field>
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
          <mat-error *ngIf="group?.get(field.name)?.hasError(validation.name)">{{validation.message$ | async}}</mat-error>
        </ng-container>
      </div>
    </div>`,
  styles: []
})
export class PhoneComponent implements OnInit, OnDestroy, AfterViewInit {
  field: FieldConfig;
  group: FormGroup;
  setGroup: FormGroup;
  parentEntity$ = new Observable<Entity>();
  changeSubscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    public translationService: TranslocoService
  ) {
    this.group = new FormGroup({});
    this.setGroup = this.fb.group({});
    this.field = new FieldConfig();
  }

  ngAfterViewInit() {
    // this.group?.get(this.field.name)?.setErrors(null);
  }

  ngOnInit() {
    this.group.addControl('_' + this.field.name, this.setGroup);

    /*const validator = Validators.required;*/
    const validator = null;
    this.setGroup.addControl('area', this.fb.control('', validator));
    this.setGroup.addControl('number', this.fb.control('', validator));
    this.setGroup.addControl('extension', this.fb.control(''));

    this.parentEntity$.subscribe(() => {
      const number = this.group.get(this.field.name)?.value;
      if (number) {
        const splitted = number.split('-');
        if (splitted.length > 1) {
          this.setGroup.get('area')?.setValue(splitted[1]);
        }
        if (splitted.length > 2) {
          this.setGroup.get('number')?.setValue(splitted[2].split('#')[0]);
          if (splitted[2].split('#').length > 1) {
            const ext = Number(number.split('-')[2].split('#')[1]);

            if (ext !== 0 && !isNaN(ext)) {
              this.setGroup.get('extension')?.setValue(Number(number.split('-')[2].split('#')[1]));
            }
          }
        }
      }
    });
    const number = this.group.get(this.field.name)?.value;
    if (number) {
      const splitted = number.split('-');
      if (splitted.length > 1) {
        this.setGroup.get('area')?.setValue(splitted[1]);
      }
      if (splitted.length > 2) {
        this.setGroup.get('number')?.setValue(splitted[2].split('#')[0]);
        if (splitted[2].split('#').length > 1) {
          const ext = Number(number.split('-')[2].split('#')[1]);

          if (ext !== 0 && !isNaN(ext)) {
            this.setGroup.get('extension')?.setValue(Number(number.split('-')[2].split('#')[1]));
          }
        }
      }
    }
    this.group.get(this.field.name)?.setErrors(null);
    // this.group.get(this.field.name)?.setValidators(null);

    this.changeSubscription = this.setGroup.valueChanges.subscribe(changes => {
      if (this.setGroup.get('number')?.value.length > 7) {
        this.setGroup.get('number')?.setValue(this.setGroup.get('number')?.value.substring(0, 7));
      }
      if (!isFinite(this.setGroup.get('number')?.value)) {
        this.setGroup.get('number')?.setValue(this.setGroup.get('number')?.value.substring(0, this.setGroup.get('number')?.value.length - 1));
      }
      if (!this.setGroup.get('area')?.errors && !this.setGroup.get('number')?.errors && changes.area && changes.number) {
        this.group.get(this.field.name)?.setValue('+36-' + (changes.area ?? '') + '-' + (changes.number ?? '') + (changes.extension ? '#' + changes.extension : ''));
      } else {
        this.group.get(this.field.name)?.setValue('');
      }
      this.group.get(this.field.name)?.markAsTouched();
    });
  }

  ngOnDestroy() {
    this.changeSubscription.unsubscribe();
  }

}
