import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class GoogleService {
  apiLoaded: Observable<boolean>;
  geocoder: google.maps.Geocoder | undefined;

  constructor(httpClient: HttpClient) {
    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=' + environment.mapsAPIKey, 'callback')
      .pipe(
        map(() => {
          this.geocoder = new google.maps.Geocoder();
          return true;
        }),
        catchError(() => of(false)),
        shareReplay(1)
      );
  }
}
