import {Injectable} from '@angular/core';
import {PressUserState, Store as PressUserStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {PressUser} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {PressUserQuery} from './query';

@Injectable({providedIn: 'root'})
export class PressUserService extends EntityService<PressUserStore, PressUserState> {

  pressUsers$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new PressUser(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('pressUser.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('pressUser.username'),
        inputType: 'text',
        name: 'username',
        listed: true,
        editable: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('pressUser.password'),
        inputType: 'text',
        name: 'password',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: PressUserQuery,
    protected store: PressUserStore) {
    super(store, PressUser);
  }

  get resourceName(): string {
    return 'content/press-user';
  }

}
