<mat-toolbar-row>
  {{ service.getEntityTitle() | async }} {{ 'form.editing' | transloco }}
</mat-toolbar-row>
<mat-toolbar-row>
  <button mat-button mat-raised-button color="primary" (click)="tab = 'gallery'">Galéria adatok</button>
  <button mat-button mat-raised-button color="primary" (click)="tab = 'images'">Képek</button>
</mat-toolbar-row>
<div style="
  display: flex;
  justify-content: center;" >
  <div *ngIf="tab === 'gallery'" formHost [entity$]="entity$" [entityService]="service" [subForm]="false"
       (submit)="onSubmit($event)"
       (cancel)="onCancel()">
  </div>
  <div *ngIf="tab === 'images' && (entity$ | async)">
    <ng-container *ngFor="let image of (entityImages$ | async); let i = index;">
      <p>
        <app-upload-image [group]="customImagesGroup" [control]="getControl(i, image)"
                          [field]="getFieldConfig(i)"></app-upload-image>
      </p>
    </ng-container>
    <p>
      <button mat-raised-button color="primary" (click)="saveImages()">
        Mentés
      </button>
    </p>
  </div>
</div>
