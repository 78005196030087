import {Component, HostListener} from '@angular/core';
import {CategoryService} from "../../webshop/category/state/service";
import {NavigationEnd, Router, RouterEvent} from "@angular/router";
import {ProductService} from "../../products/product/state/service";
import {Product} from "../../products/product/state/model";
import {Category} from "../../webshop/category/state/model";
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {TranslocoService} from "@ngneat/transloco";
import {LanguageService} from "../../transloco/language/state/service";
import {Language} from "../../transloco/language/state/model";

interface ProductCategory {
  items$: Observable<Product[]>;
  category: Category;
}

@Component({
  selector: 'app-webshop-menu',
  styleUrls: ['component.scss'],
  templateUrl: './component.html'
})
export class WebshopMenuComponent {
  categories$ = this.categoryService.categories$;
  productCategories: ProductCategory[] = [];
  opened = false;
  productMenuOpened = false;
  activeCategory: Category | null = null;

  languages$ = this.languageService.languages$.pipe(
    switchMap(languages => this.translocoService.langChanges$.pipe(map(() => languages))),
    map(languages => languages.filter(language =>  language.code !== this.translocoService.getActiveLang()))
  );


  constructor(
    private categoryService: CategoryService,
    private productService: ProductService,
    private router: Router,
    private languageService: LanguageService,
    private translocoService: TranslocoService
  ) {
    router.events.pipe(
    ).subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.opened = false;
        this.productMenuOpened = false;
      }
    });
  }

  @HostListener('document:keydown.escape')
  handleKeyboardEvent() {
    // console.log(event.key);
    //if ((event.key === "Escape" || event.key === "Esc")) {
    if (this.productMenuOpened) {
      this.toggleProductMenu();
    } else if (this.opened) {
      this.toggle();
    }
    //}
  }

  public toggle() {
    this.opened = !this.opened;
  }

  toggleProductMenu() {
    this.productMenuOpened = !this.productMenuOpened;
  }

  filterProductsByCategory$(category: any): Observable<Product[]> {
    const _item = this.productCategories.find(pc => pc.category.id === category.id);
    if (_item) {
      return _item.items$;
    }

    const _obs = this.productService.getByCategory(category);
    this.productCategories.push({
      category: category,
      items$: _obs
    });

    return _obs;
  }

  toggleCategory(category: Category) {
    if (this.activeCategory && this.activeCategory.id === category.id) {
      this.activeCategory = null;
    } else {
      this.activeCategory = category
    }
  }

  switchLanguage(language: Language) {
    this.translocoService.setActiveLang(language.code);
  }
}
