import {Injectable} from '@angular/core';
import {NewsState, Store as NewsStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {News} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {NewsQuery} from './query';
import {ProductService} from "../../../products/product/state/service";
import {GalleryService} from "../../gallery/state/service";
import {FormService} from "../../../components/service/form.service";

@Injectable({ providedIn: 'root' })
export class NewsService extends EntityService<NewsStore, NewsState> {

  news$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new News(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('news.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('news.slug'),
        inputType: 'text',
        name: 'slug',
        listed: false,
        editable: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('news.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('news.content'),
        inputType: 'text',
        name: 'content',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('news.content2'),
        inputType: 'text',
        name: 'content2',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.galleryService.galleries$,
        listed: false,
        label: this.translocoService.selectTranslate('news.gallery'),
        name: 'gallery',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productService.products$,
        listed: false,
        label: this.translocoService.selectTranslate('news.product'),
        name: 'product',
        validations: []
      }),

      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('news.image1'),
        name: 'image1',
        validations: this.formService.getValidations(['requiredImage'])
      }),

      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('news.image2'),
        name: 'image2',
        validations: []//this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('common.createdAt'),
        name: 'createdAt',
        listed: true,
        editable: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    protected productService: ProductService,
    protected galleryService: GalleryService,
    private query: NewsQuery,
    private formService: FormService,
    protected store: NewsStore) {
    super(store, News);
  }

  get resourceName(): string {
    return 'content/news';
  }

}
