import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {LoadingService} from './loading.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private _loading: LoadingService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('skip')) {
      return next.handle(request.clone({headers: request.headers.delete('skip')}));
    }
    this._loading.setLoading(true, request.url);

    return next.handle(request)
      .pipe(
        catchError((err) => {
          this._loading.setLoading(false, request.url);
          return throwError(() => err);
        }),
        finalize(() => {
          this._loading.setLoading(false, request.url);
        }),
        map((evt) => {
          if (evt instanceof HttpResponse) {
            this._loading.setLoading(false, request.url);
          }
          return evt as HttpEvent<any>;
        })
      );
  }
}
