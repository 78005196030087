import {Observable, of} from 'rxjs';
import {Entity} from '../entity';
import {EntityService, FieldListInterface} from '../entity-service';
import {FormTab} from './dynamic-form';
import {FormGroup} from '@angular/forms';

export interface Validator {
  name: string;
  validator: any;
  message$: Observable<string>;
}

export interface Choice {
  name$: Observable<string>;
  value: string | number;
}

export interface FilterConfig {
  additionalFilter?: boolean;
  blank?: boolean;
}

export interface FieldConfigInterface {
  label: Observable<string>;
  name: string;
  inputType?: string;
  options?: Choice[];
  conditionalOptions?: ((form?: FormGroup, parent?: FormGroup) => Observable<Choice[]>) | null;
  entityOptions?: Observable<Entity[]>;
  entityConditionalOptions?: ((form?: FormGroup, parent?: FormGroup) => Observable<Entity[]>) | null;
  customContent?: ((form?: FormGroup, entity$?: Observable<Entity>) => Observable<string>);
  subEntityForm?: FieldListInterface;
  subEntityTitle$?: Observable<string>;
  subEntityService?: EntityService<any, any>;
  collections?: any;
  type: string;
  editable?: boolean;
  disabled?: boolean;
  filterable?: boolean;
  creatable?: any;
  value?: any;
  validations?: Validator[];
  visible?: (formGroup?: FormGroup) => boolean;
  listed?: boolean;
  multiple?: boolean;
  tab?: FormTab | undefined;
  withMap?: boolean;
  isMobile?: boolean;
  filterConfig?: FilterConfig;
}

export class FieldConfig {
  label = of('');
  name = '';
  inputType?: string;
  options?: Choice[];
  conditionalOptions?: ((form?: FormGroup, parent?: FormGroup) => Observable<Choice[]>) | null;
  entityOptions?: Observable<Entity[]>;
  entityConditionalOptions?: ((form?: FormGroup, parent?: FormGroup) => Observable<Entity[]>) | null;
  subEntityForm?: FieldListInterface;
  subEntityTitle$?: Observable<string>;
  subEntityService?: EntityService<any, any>;
  customContent?: ((form?: FormGroup, entity$?: Observable<Entity>) => Observable<string>);
  collections?: any;
  type = 'input';
  editable ? = true;
  disabled ? = false;
  filterable ? = true;
  creatable ? = null;
  value?: any;
  validations?: Validator[];
  listed ? = false;
  multiple ? = false;
  tab?: FormTab | undefined = undefined;
  withMap = false;
  filterConfig?: FilterConfig;
  isMobile = false;

  static create({
                  label,
                  name,
                  inputType,
                  options,
                  conditionalOptions,
                  entityOptions,
                  entityConditionalOptions,
                  subEntityForm,
                  subEntityService,
                  subEntityTitle$,
                  customContent,
                  collections,
                  filterable,
                  type,
                  disabled,
                  editable,
                  creatable,
                  multiple,
                  value,
                  validations,
                  filterConfig,
                  visible,
                  listed,
                  tab,
                  isMobile,
                  withMap,
                }: FieldConfigInterface): FieldConfig {

    const fc = new FieldConfig();
    fc.conditionalOptions = conditionalOptions ?? null;
    fc.label = label === undefined ? of('') : label;
    fc.name = name === undefined ? '' : name;
    fc.inputType = inputType === undefined ? '' : inputType;
    fc.options = options === undefined ? [] : options;
    fc.entityOptions = entityOptions === undefined ? of([]) : entityOptions;
    fc.subEntityForm = subEntityForm === undefined ? {fields: []} : subEntityForm;
    fc.subEntityService = subEntityService;
    fc.subEntityTitle$ = subEntityTitle$ === undefined ? of('') : subEntityTitle$;
    fc.collections = collections === [] ? true : collections;
    fc.type = type;
    fc.customContent = customContent === undefined ? () => of('') : customContent;
    fc.editable = editable === undefined ? true : editable;
    fc.disabled = disabled === undefined ? false : disabled;
    fc.filterable = filterable === undefined ? true : filterable;
    fc.creatable = creatable === undefined ? null : creatable;
    fc.multiple = multiple === undefined ? false : multiple;
    fc.listed = listed === undefined ? false : listed;
    fc.value = value;
    fc.withMap = withMap ?? false;
    fc.isMobile = isMobile ?? false;
    fc.filterConfig = filterConfig;
    fc.validations = validations === undefined ? [] : validations;
    fc.visible = visible === undefined ? () => true : visible;
    fc.entityConditionalOptions = entityConditionalOptions === undefined ? null : entityConditionalOptions;
    fc.tab = tab;

    return fc;
  }

  visible = (form?: FormGroup) => true;

  getOptionName(value: any): Observable<string> {
    return this.options?.find(item => item.value == value)?.name$ ?? of('');
  }
}
