import {Injectable, NgModule} from '@angular/core';
import {CarouselModule, ModalModule} from "ngx-foundation";

@NgModule({
  imports: [
    CarouselModule.forRoot(),
    ModalModule.forRoot()
  ],
  exports: [
    CarouselModule,
    ModalModule
  ],
  providers: [
   ],
})
export class FoundationModule {}
