import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {ManufacturingCategory} from './model';

export interface ManufacturingCategoryState extends EntityState<ManufacturingCategory> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ManufacturingCategory' })
export class Store extends EntityStore<ManufacturingCategoryState> {

  constructor() {
    super();
  }

}
