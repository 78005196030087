import {Component, Injector} from '@angular/core';
import {Store as UserStore, UserState} from './state/store';
import {User} from './state/model';
import {UserService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {UserQuery} from './state/query';
import {Observable} from "rxjs";
import {InvoiceAddress} from "../../webshop/invoice-address/state/model";
import {ShippingAddress} from "../../webshop/shipping-address/state/model";
import {ShippingAddressService} from "../../webshop/shipping-address/state/service";
import {InvoiceAddressService} from "../../webshop/invoice-address/state/service";
import {switchMap, tap} from "rxjs/operators";

@Component({
  templateUrl: 'detail.html',
  styleUrls: [
    '../../components/entity-detail/component.scss',
    'detail.scss'
  ]
})
export class UserDetailComponent extends EntityDetailComponent<User, UserState, UserStore> {
  tab = 'user';
  billingAddresses$: Observable<InvoiceAddress[]>;
  shippingAddresses$: Observable<ShippingAddress[]>;

  constructor(
    public injector: Injector,
    private shippingAddressService: ShippingAddressService,
    private invoiceAddressService: InvoiceAddressService,
    service: UserService,
    protected query: UserQuery
  ) {
    super(injector, service, query);

    this.billingAddresses$ = this.entity$.pipe(
      switchMap(user => this.invoiceAddressService.getByUser(user).pipe(
        tap(data => console.log(data, user)
        )))
    );
    this.shippingAddresses$ = this.entity$.pipe(switchMap(user => this.shippingAddressService.getByUser(user)));
  }

  prePersist(_entity: User) {
    //_entity.active = Number(_entity.active);
  }
}
