import {Component} from '@angular/core';
import {ProductService} from "../../../products/product/state/service";
import {Observable} from "rxjs";
import {Product} from "../../../products/product/state/model";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {CategoryService} from "../../../webshop/category/state/service";

@Component({
  templateUrl: './list.html'
})
export class WebshopProductListComponent {

  items$: Observable<Product[]>;
  isCategory: boolean = false;

  constructor(
    private productService: ProductService,
    private categoryService: CategoryService,
    route: ActivatedRoute,
  ) {
    this.items$ = route.params.pipe(
      switchMap(params => {
          if (!params.term) {
            if (params.slug) {
              this.isCategory = true;
              return categoryService.getBySlug(params.slug).pipe(switchMap(category => productService.getByCategory(category)))
            }
            return productService.products$;
          } else {
            return productService.getByTerm(params.term)
          }
        }
      )
    );
  }

}
