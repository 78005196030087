import {Component, EventEmitter, Output} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {AuthService, RoleList} from '../../authentication/service/auth.service';
import {Entity} from '../../components/entity';
import {EntitySelectComponent} from '../../components/form/form-elements/entitySelect';
import {TranslocoService} from "@ngneat/transloco";
import {FormBuilder, FormControl} from "@angular/forms";
import {Observable, startWith, tap} from "rxjs";
import {map} from "rxjs/operators";
import {LanguageService} from "../../webshop/language/state/service";
import {FormService} from "../../components/service/form.service";
import {Language} from "../../webshop/language/state/model";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  Role = RoleList;
  langControl: FormControl;
  langSwitch$: Observable<boolean>;
  languages$ = this.languageService.languages$.pipe(
    tap(languages => {
      this.langControl.setValue(languages.find(language => language.code === this.localizeService.getActiveLang()))
    })
  );

  @Output() public sidenavToggle = new EventEmitter();
  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    public localizeService: TranslocoService,
    public formService: FormService,
    private languageService: LanguageService,
    private fb: FormBuilder
  ) {

    this.langControl = fb.control(new Language({}));
    this.langSwitch$ = this.langControl.valueChanges.pipe(
      tap(value => {
        localizeService.setActiveLang(value.code);
      }),
      startWith(true),
      map(() => true)
    );
  }

  hasAccess$(roles: string[]) {
    return this.authService.hasAccess$(roles);
  }

  logout() {
    this.authService.logout();
  }

  public compare(v1: Entity, v2: Entity): boolean {
    return EntitySelectComponent.compareFn(v1, v2);
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

}
