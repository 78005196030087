import {Component, Injector} from '@angular/core';
import {NewsletterState, Store} from './state/store';
import {Newsletter} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {NewsletterQuery} from './state/query';
import {NewsletterService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class NewsletterListComponent extends EntityListComponent<Newsletter, NewsletterState, Store> {

  translationDomain = 'newsletter';

  constructor(
    public injector: Injector,
    protected service: NewsletterService,
    query: NewsletterQuery,
    private NewsletterFilterService: FilterService) {
    super(injector, service, query, NewsletterFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
