<h2>Generált címkék</h2>
<table style="max-width: 50%">
<ng-container *ngFor="let label of generatedLabels$ | async">
  <tr>
    <td style="width:120px">{{ label.createdAt | date }}</td>
    <td><a target="_blank" [href]="host + label.fedex">{{ host + label.fedex }}</a></td>
  </tr>
</ng-container>
</table>
<h2>Feltöltött számlák</h2>
<table style="max-width: 50%">
  <ng-container *ngFor="let label of uploadedInvoices$ | async">
    <tr>
      <td style="width:120px">{{ label.createdAt | date }}</td>
      <td><a target="_blank" [href]="host + label.invoice">{{ host + label.invoice }}</a></td>
    </tr>
  </ng-container>
</table>
<p id="invoice-upload">
<label class="image-upload-container btn btn-bwm" [attr.for]="'upload_invoice'">
  <span>Számla feltöltése</span>
  <input #imageInput
         type="file"
         accept="application/pdf"
         (change)="uploadInvoice(imageInput)" [attr.id]="'upload_invoice'">
</label>
</p>
<ng-container>
<p>
  <mat-form-field [formGroup]="group" class="demo-full-width margin-top"><input matInput
                                                            [matDatepicker]="picker"
                                                            [formControl]="transportationDayControl"
                                                            [placeholder]="'Felvétel időpontja'">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [touchUi]="isMobile" [dateClass]="dateClass" #picker></mat-datepicker>
    <mat-hint></mat-hint>
  </mat-form-field>
</p>

<p>
  <mat-form-field [formGroup]="group" appearance="fill">
    <mat-label>Felvétel helye (irányítószám)</mat-label>
    <input matInput
           [placeholder]="''"
           [formControl]="transportationAddressPostalControl"
           [type]="'text'">
  </mat-form-field>
</p>

<p>
  <mat-form-field [formGroup]="group" appearance="fill">
    <mat-label>Felvétel helye (város)</mat-label>
    <input matInput
           [placeholder]="''"
           [formControl]="transportationAddressCityControl"
           [type]="'text'">
  </mat-form-field>
</p>

<p>
  <mat-form-field [formGroup]="group" appearance="fill">
    <mat-label>Felvétel helye (utca)</mat-label>
    <input matInput
           [placeholder]="''"
           [formControl]="transportationAddressStreetControl"
           [type]="'text'">
  </mat-form-field>
</p>

<p>
  <mat-form-field [formGroup]="group" appearance="fill">
    <mat-label>Felvétel helye (házszám)</mat-label>
    <input matInput
           [placeholder]="''"
           [formControl]="transportationAddressNumberControl"
           [type]="'text'">
  </mat-form-field>
</p>
<p>
  <mat-form-field [formGroup]="group" appearance="fill">
    <mat-label>Kontakt személy</mat-label>
    <input matInput
           [placeholder]="''"
           [formControl]="transportationContactControl"
           [type]="'text'">
  </mat-form-field>
</p>
<p>
  <mat-form-field [formGroup]="group" appearance="fill">
    <mat-label>Telefon</mat-label>
    <input matInput
           [placeholder]="''"
           [formControl]="transportationPhoneControl"
           [type]="'text'">
  </mat-form-field>
</p>
<p>
  <mat-form-field [formGroup]="group" appearance="fill">
    <mat-label>Email</mat-label>
    <input matInput
           [placeholder]="''"
           [formControl]="transportationEmailControl"
           [type]="'text'">
  </mat-form-field>
</p>
<p>
  <button mat-raised-button color="primary" type="button" (click)="submit()">PDF generálás</button>
</p>
</ng-container>
