<section class="l-siteContent" id="theTop">
  <section class="feedbackMessage">
    <div class="row align-center">
      <div class="small-12 medium-10 large-6 xlarge-4 column">
        <div class="h-relative">
          <img class="anim anim--fromLeft decorImg decorImg--checkoutAfterLeft" src="/assets/images/decor/giraffe.png"
               alt="decor">
          <img class="anim anim--fromRight decorImg decorImg--checkoutAfterRight"
               src="/assets/images/decor/elephant.png" alt="decor">
        </div>
        <ng-container *ngIf="isValid$ | async">
          <h1>{{ 'webshop.Sikeres megrendelés' | transloco }}</h1>
          <img src="/assets/images/menu_heading_decor.svg" alt="decor">
          <div class="feedbackMessage-content g-top">
            <p>{{ 'webshop.A fizetés sikeres volt' | transloco }}</p>
            <p>{{ 'webshop.Nemsokára e-mail-t küldünk megrendeléséről.' | transloco }}</p>
            <div class="g-top">
              <p>{{ 'webshop.Visszavárjuk!' | transloco }}</p>
              <span class="feedbackMessage-signature">{{ 'webshop.Bomo Art csapata' | transloco }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!(isValid$|async)">
          <h1>{{ 'webshop.Sikertelen megrendelés' | transloco }}</h1>
          <img src="/assets/images/menu_heading_decor.svg" alt="decor">
          <div class="feedbackMessage-content g-top">
            <p>{{ 'webshop.Kérjük próbáld újra' | transloco }}</p>
            <div class="g-top">
              <p>{{ 'webshop.Visszavárjuk!' | transloco }}</p>
              <span class="feedbackMessage-signature">{{ 'webshop.Bomo Art csapata' | transloco }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</section>
