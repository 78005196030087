import {Component, Injector} from '@angular/core';
import {ProductVariantState, Store as ProductVariantStore} from './state/store';
import {ProductVariant} from './state/model';
import {ProductVariantService as ProductVariantService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {ProductVariantQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class ProductVariantDetailComponent extends EntityDetailComponent<ProductVariant, ProductVariantState, ProductVariantStore> {

  constructor(
    public injector: Injector,
    service: ProductVariantService,
    protected query: ProductVariantQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
