import {Injectable} from '@angular/core';
import {Store as WallpaperStore, WallpaperState} from './store';
import {EntityService} from '../../../components/entity-service';
import {Wallpaper} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {first, map, shareReplay, switchMap} from 'rxjs/operators';
import {WallpaperQuery} from './query';
import {ProductService} from "../../../products/product/state/service";
import {GalleryService} from "../../gallery/state/service";
import {FormService} from "../../../components/service/form.service";

@Injectable({ providedIn: 'root' })
export class WallpaperService extends EntityService<WallpaperStore, WallpaperState> {

  wallpaper$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Wallpaper(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );

  current$ = this.getHttp().get(this.api + '/current').pipe(
    map(item => new Wallpaper(item as Partial<Wallpaper>)),
    first()
  );

  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('wallpaper.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('wallpaper.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('wallpaper.subTitle'),
        inputType: 'text',
        name: 'subtitle',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('wallpaper.from'),
        name: 'from',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'color',
        label: this.translocoService.selectTranslate('wallpaper.color'),
        inputType: 'text',
        name: 'color',
        listed: false,
        editable: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('wallpaper.slug'),
        inputType: 'text',
        name: 'slug',
        listed: false,
        editable: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('wallpaper.description'),
        inputType: 'text',
        name: 'description',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('wallpaper.image'),
        name: 'image',
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('wallpaper.image1'),
        name: 'image1',
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('wallpaper.image2'),
        name: 'image2',
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('wallpaper.image3'),
        name: 'image3',
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('wallpaper.image4'),
        name: 'image4',
        validations: this.formService.getValidations(['requiredImage'])
      }),

    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    protected productService: ProductService,
    protected galleryService: GalleryService,
    private query: WallpaperQuery,
    private formService: FormService,
    protected store: WallpaperStore) {
    super(store, Wallpaper);
  }

  get resourceName(): string {
    return 'content/wallpaper';
  }
}
