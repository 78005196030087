import {Entity} from '../../../components/entity';
import {Transporter} from "../../transporter/state/model";
import {Country} from "../../country/state/model";

export class TransporterPrice extends Entity {
  price: number;
  type: string;
  country: Country;
  transporter: Transporter;

  constructor({
                id,
                price,
                type,
                country,
                transporter,
                createdAt, updatedAt
              }: Partial<TransporterPrice>) {
    super({id, createdAt, updatedAt});

    this.price = price ?? 0;
    this.type = type ?? '';

    this.country = new Country(country ?? {});

    this.transporter = new Transporter(transporter ?? {});
  }

  getNameProperty(): string {
    return 'transporter';
  }
}
