<mat-toolbar color="primary">
  <div class="d-block d-md-none">
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <mat-nav-list>
  </mat-nav-list>
  <mat-nav-list class="d-none d-md-block">
    <button mat-button [matMenuTriggerFor]="users">Felhasználók</button>
    <button mat-button [matMenuTriggerFor]="press">Sajtó</button>
    <button mat-button [matMenuTriggerFor]="products">Termékek</button>
    <button mat-button [matMenuTriggerFor]="webshop">Rendelések</button>
    <button mat-button [matMenuTriggerFor]="content">Tartalmak</button>
    <button mat-button [matMenuTriggerFor]="config">Beállítások</button>
  </mat-nav-list>
  <mat-nav-list class="ms-auto language-selector" *ngIf="(langSwitch$ | async)">
    <mat-form-field appearance="fill">
      <mat-label>Nyelv</mat-label>
      <mat-select [formControl]="langControl" [compareWith]="formService.compareById">
        <mat-option *ngFor="let lang of (languages$ | async)" [value]="lang">{{ lang }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-nav-list>
  <mat-nav-list>
    <button mat-button [routerLink]="['/profile'] | localize">{{ 'user.profile' | transloco }}</button>
    <button mat-button (click)="logout()">{{ 'menu.logout' | transloco }}</button>
  </mat-nav-list>
</mat-toolbar>


<mat-menu #press="matMenu">
  <button mat-menu-item [routerLink]="['/admin/content/press-download'] | localize">Letöltések</button>
  <button mat-menu-item [routerLink]="['/admin/content/press-news'] | localize">Fontosabb megjelenések</button>
  <button mat-menu-item [routerLink]="['/admin/content/press-video'] | localize">Videók</button>
  <button mat-menu-item [routerLink]="['/admin/content/press-config'] | localize">Beállítások</button>
  <button mat-menu-item [routerLink]="['/admin/content/press-user'] | localize">Felhasználók</button>
</mat-menu>


<mat-menu #users="matMenu">
  <button mat-menu-item [routerLink]="['/admin/users/user'] | localize">Webshop felhasználók</button>
</mat-menu>

<mat-menu #products="matMenu">
  <button mat-menu-item [routerLink]="['/admin/webshop/category'] | localize">Kategóriák</button>
  <button mat-menu-item [routerLink]="['/admin/products/product'] | localize">Termékek</button>
  <button mat-menu-item [routerLink]="['/admin/products/product-parameter'] | localize">Termékparaméter típusok</button>
  <button mat-menu-item [routerLink]="['/admin/products/product-parameter-type'] | localize">Termékparaméterek</button>
  <button mat-menu-item [routerLink]="['/admin/products/product-parameter-format'] | localize">Formátumok</button>
  <button mat-menu-item [routerLink]="['/admin/content/tag'] | localize">Tagek</button>
  <button mat-menu-item [routerLink]="['/admin/webshop/reseller'] | localize">Viszonteladók</button>
  <button mat-menu-item [routerLink]="['/admin/webshop/coupon'] | localize">Kuponkódok</button>
  <button mat-menu-item [routerLink]="['/admin/webshop/payment-mode'] | localize">Fizetési mód</button>
  <button mat-menu-item [routerLink]="['/admin/webshop/transporter'] | localize">Szállítók</button>
  <button mat-menu-item [routerLink]="['/admin/webshop/transporter-discount'] | localize">Szállítói kedvezmények</button>
</mat-menu>

<mat-menu #webshop="matMenu">
  <button mat-menu-item [routerLink]="['/admin/webshop/order'] | localize">Rendelések</button>
  <button mat-menu-item [routerLink]="['/admin/webshop/order/export/manufacturable'] | localize">Gyártandók export</button>
  <button mat-menu-item [routerLink]="['/admin/webshop/order/export/excel'] | localize">Excel export</button>
</mat-menu>

<mat-menu #content="matMenu">
  <button mat-menu-item [routerLink]="['/admin/content/page'] | localize">Aloldalak</button>
  <button mat-menu-item [routerLink]="['/admin/content/news'] | localize">Hírek</button>
  <button mat-menu-item [routerLink]="['/admin/content/blog'] | localize">Blog</button>
  <button mat-menu-item [routerLink]="['/admin/content/blog-category'] | localize">Blog kategóriák</button>
  <button mat-menu-item [routerLink]="['/admin/products/gallery'] | localize">Galériák</button>
  <button mat-menu-item [routerLink]="['/admin/content/popup'] | localize">Popupok</button>
  <button mat-menu-item [routerLink]="['/admin/content/wallpaper'] | localize">Háttérképek</button>
  <button mat-menu-item [routerLink]="['/admin/expression'] | localize">Fordítások</button>
  <button mat-menu-item [routerLink]="['/admin/content/newsletter'] | localize">Hírlevelek</button>

</mat-menu>

<mat-menu #config="matMenu">
  <button mat-menu-item [routerLink]="['/admin/language'] | localize">Nyelvek</button>
  <button mat-menu-item [routerLink]="['/admin/country'] | localize">Országok</button>
  <!--button mat-menu-item [routerLink]="['/currency'] | localize">Pénznemek</button-->
  <button mat-menu-item [routerLink]="['/admin/config'] | localize">Beállítások</button>
  <button mat-menu-item [routerLink]="['/admin/webshop/store'] | localize">Üzletek</button>
</mat-menu>
