import {Component} from '@angular/core';
import {CategoryService} from "../../../webshop/category/state/service";
import {Category} from "../../../webshop/category/state/model";
import {Observable} from "rxjs";

@Component({
  templateUrl: './component.html'
})
export class WebshopCategoryListComponent {
  categories$: Observable<Category[]> = this.categoryService.categories$;

  constructor(
    private categoryService: CategoryService
  ) {
  }

}
