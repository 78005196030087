import {Entity, Image} from '../../../components/entity';
import {Product} from "../../../products/product/state/model";
import {Gallery} from "../../gallery/state/model";

export class News extends Entity {
  title: string;
  slug: string;
  content: string;
  content2: string;
  product: Product;
  gallery: Gallery;
  image1: Image;
  image2: Image;

  constructor({
                id,
                slug,
                title,
                content,
                content2,
                product,
                gallery,
                image1,
                image2,
                createdAt, updatedAt
              }: Partial<News>) {
    super({id, createdAt, updatedAt});
    this.slug = slug ?? '';
    this.title = title ?? '';
    this.content = content ?? '';
    this.content2 = content2 ?? '';
    this.image1 = new Image(image1 ?? {});
    this.image2 = new Image(image2 ?? {});
    this.product = new Product(product ?? {});
    this.gallery = new Gallery(gallery ?? {});
  }

  getNameProperty(): string {
    return 'title';
  }
}
