import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {Observable, of} from "rxjs";
import {Cart} from "../../../webshop/cart/state/model";
import {CartService} from "../../../webshop/cart/state/service";
import {User} from "../../../authentication/user/state/model";
import {AuthService} from "../../../authentication/service/auth.service";
import {ShippingAddress} from "../../../webshop/shipping-address/state/model";
import {ShippingAddressService} from "../../../webshop/shipping-address/state/service";
import {map, switchMap, tap} from "rxjs/operators";
import {FormBuilder, FormControl} from "@angular/forms";
import {FormService} from "../../../components/service/form.service";
import {EditAddressComponent} from "../../editAddress";
import {InvoiceAddress} from "../../../webshop/invoice-address/state/model";
import {InvoiceAddressService} from "../../../webshop/invoice-address/state/service";

@Component({
  selector: 'app-checkout-delivery-address',
  templateUrl: './delivery-address.html'
})
export class DeliveryAddressComponent {

  @ViewChild(EditAddressComponent) editAddress: EditAddressComponent;

  cart$: Observable<Cart>;
  user$: Observable<User> = this.authService.user$;
  addresses$: Observable<ShippingAddress[]> = this.user$.pipe(
    switchMap(user => {
      return this.shippingService.getByUser(user)
    }),
    tap(addresses => {
      const _def = addresses.find(address => address.isDefault);
      if (_def) {
        this.addressControl.setValue(_def);
      }
    }));
  newAddress: ShippingAddress = new ShippingAddress({});
  @Output() next = new EventEmitter();
  addressControl: FormControl;

  constructor(
    private cartService: CartService,
    private authService: AuthService,
    private shippingService: ShippingAddressService,
    private invoiceService: InvoiceAddressService,
    private fb: FormBuilder,
    public formService: FormService
  ) {
    this.addressControl = fb.control(new ShippingAddress({}), this.formService.bindValidations([this.formService.getValidations(['required'])]));
    this.cart$ = this.addresses$.pipe(
      switchMap(addresses => this.cartService.cart$.pipe(
        map(cart => {
          if (cart.shippingAddress) {
            const _address = addresses.find(address =>
              address.country.id === cart.shippingCountry.id &&
              address.address.city === cart.shippingAddress.city &&
              address.address.street === cart.shippingAddress.street
            );
            if (_address) {
              this.addressControl.setValue(_address);
            }
          }
          return cart;
        })
      ))
    );
  }

  save(cart: Cart) {
    if (this.addressControl.value.id) {
      cart.shippingAddress = this.addressControl.value.address;
      cart.shippingCountry = this.addressControl.value.country;
      this.cartService.update(cart.id, cart)
        .pipe(
          tap(() => this.next.emit())
        )
        .subscribe();
    } else {
      if (this.editAddress.getAddress()) {
        this.user$.pipe(
          switchMap(user => {
            const address = this.editAddress.getAddress();
            address.user = user;

            let invPipe = of('');
            if (this.editAddress.addToAnother()) {
              const invAddress = new InvoiceAddress(
                {
                  isDefault: address.isDefault,
                  user: user,
                  address: address.address,
                  country: address.country,
                }
              );

              invPipe = this.invoiceService.add(invAddress);
            }
            return this.shippingService.add(address).pipe(
              switchMap(_address => invPipe.pipe(map(() => _address))),
              switchMap((_address: ShippingAddress) => {
                cart.shippingAddress = _address.address;
                cart.shippingCountry = _address.country;
                return this.cartService.update(cart.id, cart)
                  .pipe(
                    tap(() => this.next.emit())
                  )
              })
            )
          })
        ).subscribe();
      }
    }
  }
}
