import {Injectable} from '@angular/core';
import {Store as UserStore, UserState} from './store';
import {EntityService} from '../../../components/entity-service';
import {User} from './model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {FieldConfig} from '../../../components/form/field.interface';
import {RoleQuery} from '../../role/state/query';
import {RoleService} from '../../role/state/service';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {UserQuery} from './query';
import {getEntityType} from "@datorama/akita";


export function RepeatPasswordValidator(control: FormControl) {
  const group = control.parent as FormGroup;
  if (!group) {
    return null;
  }
  const password = group.controls.password.value;
  if (!password) {
    return null;
  }
  const passwordConfirmation = group.controls.passwordAgain.value;

  return password === passwordConfirmation ? null : {passwordsNotEqual: true};
}

export function RequiredForCreateValidator(control: FormControl) {
  if (window.location.href.includes('create') && !control.value) {
    return {requiredForCreate: true};
  }

  return null;
}


@Injectable({providedIn: 'root'})
export class UserService extends EntityService<UserStore, UserState> {

  currentUser: User | undefined;
  users$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new User(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('user.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('user.lastName'),
        inputType: 'text',
        name: 'lastName',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('user.firstName'),
        inputType: 'text',
        name: 'firstName',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('user.phone'),
        inputType: 'text',
        name: 'phone',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('user.email'),
        inputType: 'email',
        name: 'email',
        listed: true,
        editable: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          },
          {
            name: 'email',
            validator: Validators.email,
            message$: this.translocoService.selectTranslate('form.email-required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'select',
        options: [
          {
            name$: this.translocoService.selectTranslate('yes'),
            value: 1
          },
          {
            name$: this.translocoService.selectTranslate('no'),
            value: 0
          }
        ],
        label: this.translocoService.selectTranslate('user.active'),
        name: 'active',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('user.lastLogin'),
        name: 'lastLogin',
        listed: true,
        editable: false,
        validations: []
      }),

      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.roleQuery.selectAll().pipe(
          switchMap(items => items.length ? of(items) : this.roleService.get()),
          take(1)
        ),
        visible: () => !!this.currentUser?.permissions.find(perm => perm.slug === 'admin'),
        multiple: true,
        label: this.translocoService.selectTranslate('user.permissions'),
        name: 'permissions',
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('user.password'),
        inputType: 'password',
        name: 'password',
        validations: [
          {
            name: 'pattern',
            validator: Validators.pattern(/^(?=\D*\d)(?=[^a-zA-Z]*[a-zA-Z]).{8,30}$/),
            message$: this.translocoService.selectTranslate('form.password-not-strong')
          },
          {
            name: 'requiredForCreate',
            validator: RequiredForCreateValidator,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('user.password-again'),
        inputType: 'password',
        name: 'passwordAgain',
        validations: [
          {
            name: 'passwordsNotEqual',
            validator: RepeatPasswordValidator,
            message$: this.translocoService.selectTranslate('form.password-match')
          }
        ]
      })
    ]
  };

  constructor(
    protected store: UserStore,
    protected roleQuery: RoleQuery,
    private query: UserQuery,
    protected roleService: RoleService,
    private translocoService: TranslocoService
  ) {
    super(store, User);
  }

  forgottenPassword(email: string) {
    return this.getHttp().post<string>(this.api + '/forgotten-password', {
        email: email
      },
      {
        responseType: "text" as "json",  // @see https://github.com/angular/angular/issues/18586
      })
      .pipe(
        take(1),
        shareReplay(1)
      );
  }

  public hasRole(role: string) {
    return !!this.currentUser?.permissions.find(perm => perm.slug === role);
  }

  get resourceName(): string {
    return 'user';
  }
}
