<section class="l-siteContent" id="theTop">
  <div class="row align-center">
    <div class="small-12 medium-10 large-6 xlarge-5 column">
      <h1 class="l-headDecor"><img src="/assets/images/bomo_hand_gray_mono.svg"
                                   alt="Hírlevél feliratkozás">{{ 'webshop.Hírlevél feliratkozás' | transloco }}</h1>
      <div class="h-relative">
        <img class="anim anim--fromRight decorImg decorImg--newsletterSignUp" src="/assets/images/decor/hand.png"
             alt="decor">
      </div>
      <form class="form form--newsletterSignUp" [formGroup]="group">
        <div data-abide-error class="alert callout h-hidden">
          <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
        </div>
        <label>{{ 'webshop.Vezetéknév' | transloco }}
          <input type="text" [placeholder]="'webshop.Vezetéknév' | transloco" [formControl]="lastNameControl">
          <span class="form-error"
                *ngIf="lastNameControl.hasError('required') && lastNameControl.touched">{{ 'webshop.Ez a mező nem lehet üres.' | transloco }}</span>
        </label>
        <label>{{ 'webshop.Keresztnév' | transloco }}
          <input type="text" [placeholder]="'webshop.Keresztnév' | transloco" [formControl]="firstNameControl">
          <span class="form-error"
                *ngIf="firstNameControl.hasError('required') && firstNameControl.touched">{{ 'webshop.Ez a mező nem lehet üres.' | transloco }}</span>
        </label>
        <label>{{ 'webshop.E-mail' | transloco }}
          <input type="text" [placeholder]="'webshop.E-mail' | transloco" [formControl]="emailControl">
          <span class="form-error"
                *ngIf="emailControl.hasError('email') && emailControl.touched">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco }}</span>
          <span class="form-error"
                *ngIf="emailControl.hasError('required') && emailControl.touched">{{ 'webshop.Ez a mező nem lehet üres.' | transloco }}</span>
        </label>
        <div class="row g-top">

          <div class="small-12 medium-12 column">
            <p [innerHTML]="'webshop.A FELIRATKOZÁS gombra kattintással elfogadod az Adatvédelmi Tájékoztatóban foglaltakat.' | transloco"></p>
          </div>
          <div class="small-12 medium-6 column">
            <div class="g-top-thin">
              <a class="l-moreBig"
                 [routerLink]="['/newsletters'] | localize">{{ 'webshop.Korábbi hírlevelek' | transloco }}</a>
            </div>
          </div>
          <div class="small-12 medium-6 column">
            <div class="g-top-thin g-top-small-only text-right">
              <button class="btn btn--basic"
                      (click)="subscribeNewsletter()">{{ 'webshop.Feliratkozás' | transloco }}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
