import {Injectable} from '@angular/core';
import {PriceListState, Store as PriceListStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {PriceList} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {PriceListQuery} from './query';

@Injectable({ providedIn: 'root' })
export class PriceListService extends EntityService<PriceListStore, PriceListState> {

  PriceLists$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new PriceList(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('priceList.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('priceList.itemNumber'),
        inputType: 'text',
        name: 'itemNumber',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('priceList.slug'),
        inputType: 'text',
        name: 'slug',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('priceList.quantitativeUnit'),
        inputType: 'text',
        name: 'quantitativeUnit',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('priceList.createdAt'),
        name: 'createdAt',
        listed: true,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('priceList.updatedAt'),
        name: 'updatedAt',
        listed: true,
        editable: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: PriceListQuery,
    protected store: PriceListStore) {
    super(store, PriceList);
  }

  get resourceName(): string {
    return 'products/price-list';
  }

}
