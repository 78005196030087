import {Component, Input, OnInit} from '@angular/core';
import {Address} from "../components/entity";
import {ShippingAddress} from "../webshop/shipping-address/state/model";
import {InvoiceAddress} from "../webshop/invoice-address/state/model";
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {CountryService} from "../webshop/country/state/service";
import {Country} from "../webshop/country/state/model";
import {Observable, of} from "rxjs";
import {FormService} from "../components/service/form.service";

const RequiredForCompany: ValidatorFn = (fg: FormGroup) => {
  const isCompany = fg.parent?.get('isCompany')?.value;
  console.log(isCompany, 'COMP');
  return (!isCompany || fg.value)
    ? null
    : { required: true };
};


const RequiredForNonCompany: ValidatorFn = (fg: FormGroup) => {
  const isCompany = fg.parent?.get('isCompany')?.value;
  console.log(isCompany, 'NON');
  return (isCompany || fg.value)
    ? null
    : { required: true };
};


const ValidEntity: ValidatorFn = (fg: FormGroup) => {
  return (fg.value && fg.value.id)
    ? null
    : { required: true };
};

@Component({
  selector: 'app-edit-address',
  templateUrl: './editAddress.html'
})
export class EditAddressComponent implements OnInit {

  @Input() address: ShippingAddress | InvoiceAddress;
  @Input() addToAnotherType = false;

  group: FormGroup;
  countries$: Observable<Country[]> = this.countryService.countries$;
  phoneControl: FormControl;
  isCompanyControl: FormControl;
  lastNameControl: FormControl;
  firstNameControl: FormControl;
  companyNameControl: FormControl;
  vatNumberControl: FormControl;
  postalCodeControl: FormControl;
  countyControl: FormControl;
  cityControl: FormControl;
  streetControl: FormControl;
  countryControl: FormControl;
  addAnotherTypeControl: FormControl;

  constructor(
    fb: FormBuilder,
    private countryService: CountryService,
    public formService: FormService
  ) {
    this.group = fb.group({});
    this.addAnotherTypeControl = fb.control(false);
    this.phoneControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.isCompanyControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.lastNameControl = fb.control('', formService.bindValidations([
      {
        name: 'required',
        validator: RequiredForNonCompany,
        message$: of('')
      }
    ]));
    this.firstNameControl = fb.control('', formService.bindValidations([
      {
        name: 'required',
        validator: RequiredForNonCompany,
        message$: of('')
      }
    ]));
    this.companyNameControl = fb.control('', formService.bindValidations([
      {
        name: 'required',
        validator: RequiredForCompany,
        message$: of('')
      }
    ]));
    this.vatNumberControl = fb.control('', formService.bindValidations([
      {
        name: 'required',
        validator: RequiredForCompany,
        message$: of('')
      }
    ]));
    this.postalCodeControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.countyControl = fb.control('');
    this.cityControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.streetControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.countryControl = fb.control('', formService.bindValidations([
      {
        name: 'required',
        validator: ValidEntity,
        message$: of('')
      }
    ]));

    this.group.addControl('isCompany', this.isCompanyControl);
    this.group.addControl('phone', this.phoneControl);
    this.group.addControl('street', this.streetControl);
    this.group.addControl('city', this.cityControl);
    this.group.addControl('country', this.countryControl);
    this.group.addControl('county', this.countyControl);
    this.group.addControl('vat', this.vatNumberControl);
    this.group.addControl('postalCode', this.postalCodeControl);
    this.group.addControl('lastName', this.lastNameControl);
    this.group.addControl('firstName', this.firstNameControl);
    this.group.addControl('companyName', this.companyNameControl);
    this.group.addControl('addAnotherType', this.addAnotherTypeControl);
  }

  ngOnInit() {
    if (this.address) {
      this.phoneControl.setValue(this.address.address.phone);
      this.isCompanyControl.setValue(this.address.address.isCompany);
      this.lastNameControl.setValue(this.address.address.lastName);
      this.firstNameControl.setValue(this.address.address.firstName);
      this.companyNameControl.setValue(this.address.address.companyName);
      this.vatNumberControl.setValue(this.address.address.vatNumber);
      this.countyControl.setValue(this.address.address.county);
      this.vatNumberControl.setValue(this.address.address.vatNumber);
      this.streetControl.setValue(this.address.address.street);
      this.cityControl.setValue(this.address.address.city);
      this.countryControl.setValue(this.address.country);
      this.postalCodeControl.setValue(this.address.address.postalCode);
    }
  }

  getAddress(): InvoiceAddress | ShippingAddress | null {
    if(this.isCompanyControl.value) {
      this.firstNameControl.setValue(' ');
      this.lastNameControl.setValue(' ');
    } else {
      this.companyNameControl.setValue(' ');
      this.vatNumberControl.setValue(' ');
    }
    if (this.group.valid) {
      this.address.address.phone = this.phoneControl.value;
      this.address.address.lastName = this.lastNameControl.value;
      this.address.address.firstName = this.firstNameControl.value;
      this.address.address.city = this.cityControl.value;
      this.address.address.county = this.countyControl.value;
      this.address.country = this.countryControl.value;
      this.address.address.vatNumber = this.vatNumberControl.value;
      this.address.address.companyName = this.companyNameControl.value;
      this.address.address.postalCode = this.postalCodeControl.value;
      this.address.address.street = this.streetControl.value;
      this.address.address.isCompany = this.isCompanyControl.value;

      return this.address;
    } else {
      this.formService.validateAllFormFields(this.group);
      return null;
    }
  }

  addToAnother(): boolean {
    return this.addAnotherTypeControl.value;
  }

}
