import {Component, Injector} from '@angular/core';
import {Store as UserStore, UserState} from './state/store';
import {User} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {UserQuery} from './state/query';
import {UserService} from './state/service';

@Component({
  templateUrl: 'list.html',
  styleUrls: ['../../components/entity-list/component.scss', 'list.scss']
})
export class UserListComponent extends EntityListComponent<User, UserState, UserStore> {

  translationDomain = 'user';

  constructor(
    public injector: Injector,
    protected service: UserService,
    query: UserQuery,
    private UserFilterService: FilterService) {
    super(injector, service, query, UserFilterService);
  }
}
