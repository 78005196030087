import {Component, Injector} from '@angular/core';
import {CurrencyState, Store as CurrencyStore} from './state/store';
import {Currency} from './state/model';
import {CurrencyService as CurrencyService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {CurrencyQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class CurrencyDetailComponent extends EntityDetailComponent<Currency, CurrencyState, CurrencyStore> {

  constructor(
    public injector: Injector,
    service: CurrencyService,
    protected query: CurrencyQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
