import {Component, Injector} from '@angular/core';
import {PageState, Store as PageStore} from './state/store';
import {Page} from './state/model';
import {PageService as PageService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {PageQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class PageDetailComponent extends EntityDetailComponent<Page, PageState, PageStore> {

  constructor(
    public injector: Injector,
    service: PageService,
    protected query: PageQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
