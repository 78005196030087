import {Component, Injector} from '@angular/core';
import {CouponState, Store} from './state/store';
import {Coupon} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {CouponQuery} from './state/query';
import {CouponService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class CouponListComponent extends EntityListComponent<Coupon, CouponState, Store> {

  translationDomain = 'coupon';

  constructor(
    public injector: Injector,
    protected service: CouponService,
    query: CouponQuery,
    private CouponFilterService: FilterService) {
    super(injector, service, query, CouponFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.sort.sort({
      id: 'code',
      start: 'asc',
      disableClear: false
    });
  }

}
