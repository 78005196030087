import {Injectable} from '@angular/core';
import {GalleryState, Store as GalleryStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Gallery} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {GalleryQuery} from './query';

@Injectable({ providedIn: 'root' })
export class GalleryService extends EntityService<GalleryStore, GalleryState> {

  galleries$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Gallery(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('gallery.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('gallery.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),

      FieldConfig.create({
        type: 'select',
        options: [
          {
            name$: this.translocoService.selectTranslate('gallery.types.0'),
            value: 0
          },
          {
            name$: this.translocoService.selectTranslate('gallery.types.1'),
            value: 1
          },
          {
            name$: this.translocoService.selectTranslate('gallery.types.2'),
            value: 2
          },
          {
            name$: this.translocoService.selectTranslate('gallery.types.3'),
            value: 3
          },
          {
            name$: this.translocoService.selectTranslate('gallery.types.4'),
            value: 4
          }
        ],
        listed: true,
        label: this.translocoService.selectTranslate('gallery.type'),
        name: 'type',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        label: this.translocoService.selectTranslate('gallery.images'),
        multiple: true,
        name: 'images',
        listed: false,
        validations: [
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: GalleryQuery,
    protected store: GalleryStore) {
    super(store, Gallery);
  }

  get resourceName(): string {
    return 'products/gallery';
  }

}
