import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {PressNews} from "../../../content/press-news/state/model";
import {ActivatedRoute} from "@angular/router";
import {PressNewsService} from "../../../content/press-news/state/service";
import {switchMap} from "rxjs/operators";

@Component({
  templateUrl: './viewNews.html'
})
export class WebshopPressViewNewsComponent {

  public item$: Observable<PressNews>;

  constructor(
    route: ActivatedRoute,
    service: PressNewsService
  ) {
    this.item$ = route.params.pipe(
      switchMap(params => service.getBySlug(params.slug))
    );
  }
}
