import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable, startWith, tap} from 'rxjs';
import {Entity} from '../../entity';
import {Editor, toHTML} from 'ngx-editor';
import {map, shareReplay} from "rxjs/operators";

@Component({
  selector: 'app-editor',
  styleUrls: ['wysiwyg.scss'],
  template: `
    <div class="NgxEditor__Wrapper" *ngIf="onChange$ | async">
      <h3>{{ field.label | async }}</h3>
      <ngx-editor-menu [editor]="editor"></ngx-editor-menu>
      <ngx-editor
        [editor]="editor"
        [ngModel]="this.group.get(this.field.name).value"
        [disabled]="false"
        [placeholder]="'Type here...'"
      ></ngx-editor>
    </div>
    <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
      <mat-error
        *ngIf="group?.get(field.name)?.hasError(validation.name) && group?.get(field.name)?.touched">{{validation.message$ | async}}</mat-error>
    </ng-container>
  `,
  styles: []
})
export class WysiwygComponent implements OnInit, OnDestroy {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  parentEntity$ = new Observable<Entity>();
  editor: Editor;
  onChange$: Observable<boolean>;

  ngOnInit(): void {
    this.editor = new Editor();

    this.onChange$ = this.editor.valueChanges.pipe(
      tap(content => this.group.get(this.field.name).setValue(toHTML(content))),
      map(() => true),
      startWith(true),
      shareReplay(1)
    )
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  constructor() {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }

}
