import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Popup} from './model';

export interface PopupState extends EntityState<Popup> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Popup' })
export class Store extends EntityStore<PopupState> {

  constructor() {
    super();
  }

}
