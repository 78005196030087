<section class="g-top-extrathick" *ngIf="(images$ | async) as images">
  <div class="row">
    <div class="small-12 column">
      <header class="sectionHeader g-row-extrathick">
        <h2><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Galéria' | transloco }}<img
          src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
      </header>
      <div [class]="'gallery gallery--v' + (gallery.type+1) ">
        <!-- images: small:  480x269, medium: 500x281, large: 670x376, xlarge: 790x632 full: 1600x898-->
        <!-- images: small:  480x541, medium: 256x288, large: 340x383, xlarge: 400x541 full: 797x898-->
        <ng-container *ngIf="gallery.type+1 === 1">
          <figure *ngFor="let image of images | orderBy: 'createdAt'">
            <a  [href]="image.url.original" (click)="onClickImage(image, 'landscape', gallery.type)" data-size="1600x898">
              <app-image
                [small]="image.url.gallery_landscape_small"
                [medium]="image.url.gallery_landscape_medium"
                [large]="image.url.gallery_landscape_large"
                [xlarge]="image.url.gallery_landscape_xlarge"
              ></app-image>
            </a>
          </figure>
        </ng-container>

        <ng-container *ngIf="gallery.type+1 == 2">
          <figure *ngFor="let image of images">
            <a  [href]="image.url.original" (click)="onClickImage(image, 'landscape', gallery.type)" data-size="1600x898">
              <app-image
                [small]="image.url.gallery_landscape_small"
                [medium]="image.url.gallery_landscape_medium"
                [large]="image.url.gallery_landscape_large"
                [xlarge]="image.url.gallery_landscape_xlarge"
              ></app-image>
            </a>
          </figure>
        </ng-container>

        <ng-container *ngIf="gallery.type+1 == 3">
          <figure *ngFor="let image of images">
            <a  [href]="image.url.original" (click)="onClickImage(image, 'portrait', gallery.type)" data-size="797x898">
              <app-image
                [small]="image.url.gallery_portrait_small"
                [medium]="image.url.gallery_portrait_medium"
                [large]="image.url.gallery_portrait_large"
                [xlarge]="image.url.gallery_portrait_xlarge"
              ></app-image>
            </a>
          </figure>
        </ng-container>

        <ng-container *ngIf="gallery.type+1 == 4">

          <figure *ngFor="let image of images; let $index = index;">
            <a *ngIf="($index % 7 == 0) || ($index % 7 == 1)"  [href]="image.url.gallery_portrait_xxlarge" (click)="onClickImage(image, 'portrait', gallery.type)"
               data-size="797x898">
              <app-image
                [small]="image.url.gallery_portrait_small"
                [medium]="image.url.gallery_portrait_medium"
                [large]="image.url.gallery_portrait_large"
                [xlarge]="image.url.gallery_portrait_xlarge"
              ></app-image>
            </a>
            <a *ngIf="!(($index % 7 == 0) || ($index % 7 == 1))"  [href]="image.url.gallery_landscape_xxlarge" (click)="onClickImage(image, 'landscape', gallery.type)"
               data-size="1600x898">
              <app-image
                [small]="image.url.gallery_landscape_small"
                [medium]="image.url.gallery_landscape_medium"
                [large]="image.url.gallery_landscape_large"
                [xlarge]="image.url.gallery_landscape_xlarge"
              ></app-image>
            </a>
          </figure>
        </ng-container>

        <ng-container *ngIf="gallery.type+1 == 5">

          <figure *ngFor="let image of images; let $index = index;">
            <a *ngIf="($index % 5 == 1)"  [href]="image.url.gallery_portrait_xxlarge" (click)="onClickImage(image, 'portrait', gallery.type)" data-size="797x898">
              <app-image
                [small]="image.url.gallery_portrait_small"
                [medium]="image.url.gallery_portrait_medium"
                [large]="image.url.gallery_portrait_large"
                [xlarge]="image.url.gallery_portrait_xlarge"
              ></app-image>
            </a>
            <a *ngIf="!(($index % 5 == 1))"  [href]="image.url.gallery_landscape_xxlarge" (click)="onClickImage(image, 'landscape', gallery.type)" data-size="1600x898">
              <app-image
                [small]="image.url.gallery_landscape_small"
                [medium]="image.url.gallery_landscape_medium"
                [large]="image.url.gallery_landscape_large"
                [xlarge]="image.url.gallery_landscape_xlarge"
              ></app-image>
            </a>
          </figure>
        </ng-container>


        <ng-container *ngIf="gallery.type+1 == 6">

          <figure *ngFor="let image of images; let $index = index;">
            <a [href]="image.url.gallery_portrait_xxlarge" (click)="onClickImage(image, 'landscape', gallery.type)" data-size="1600x898">
              <app-image
                [small]="image.url.gallery_landscape_small"
                [medium]="image.url.gallery_landscape_medium"
                [large]="image.url.gallery_landscape_large"
                [xlarge]="image.url.gallery_landscape_xlarge"
              ></app-image>
            </a>
          </figure>
        </ng-container>

        <!-- Root element of PhotoSwipe. Must have class pswp. -->

      </div>
    </div>
    <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div class="pswp" #ngxLightbox tabindex="-1" role="dialog" aria-hidden="true">

      <!-- Background of PhotoSwipe.
It's a separate element, as animating opacity is faster than rgba(). -->
      <div class="pswp__bg"></div>

      <!-- Slides wrapper with overflow:hidden. -->
      <div class="pswp__scroll-wrap">

        <!-- Container that holds slides. PhotoSwipe keeps only 3 slides in DOM to save memory. -->
        <!-- don't modify these 3 pswp__item elements, data is added later on. -->
        <div class="pswp__container">
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
        </div>

        <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
        <div class="pswp__ui pswp__ui--hidden">
          <div class="pswp__top-bar">
            <!--  Controls are self-explanatory. Order can be changed. -->
            <div class="pswp__counter"></div>
            <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
            <button class="pswp__button pswp__button--share" title="Share"></button>
            <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

            <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
            <!-- element will get class pswp__preloader--active when preloader is running -->
            <div class="pswp__preloader">
              <div class="pswp__preloader__icn">
                <div class="pswp__preloader__cut">
                  <div class="pswp__preloader__donut"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div class="pswp__share-tooltip"></div>
          </div>

          <button class="pswp__button pswp__button--arrow--left" title="Előző"></button>
          <button class="pswp__button pswp__button--arrow--right" title="Következő"></button>

          <div class="pswp__caption">
            <div class="pswp__caption__center"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
