import {Injectable} from '@angular/core';
import {ProductParameterService} from './state/service';
import {ProductParameterQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {ProductParameter} from './state/model';
import {ProductParameterState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<ProductParameter, ProductParameterState, ProductParameterQuery, Store> {
  constructor(protected query: ProductParameterQuery, protected api: ProductParameterService) {
    super(query, api);
  }
}
