import {Component, EventEmitter} from '@angular/core';
import {Blog} from "../../../content/blog/state/model";
import {BlogService} from "../../../content/blog/state/service";
import {ActivatedRoute} from "@angular/router";
import {Observable, startWith} from "rxjs";
import {map, shareReplay, switchMap, take, tap} from "rxjs/operators";
import {BlogCategory} from "../../../content/blog-category/state/model";
import {BlogCategoryService} from "../../../content/blog-category/state/service";
import {getEntityType} from "@datorama/akita";

@Component({
  templateUrl: './main.html'
})
export class WebshopBlogMainComponent {
  public items$: Observable<Blog[]>;
  public categories$: Observable<BlogCategory[]>;
  categoryChange$ = new EventEmitter();
  activeCategory: BlogCategory | null = null;

  constructor(
    service: BlogService,
    categoryService: BlogCategoryService,
    route: ActivatedRoute
  ) {
    this.items$ = this.categoryChange$.pipe(
      startWith(true),
      switchMap(() => service.getHttp().get<Blog[]>(service.api + '/?c=10&s=-createdAt' + (this.activeCategory ? ('&q[]=category:eq:' + this.activeCategory.id) : ''))
        .pipe(
          map(items => items.map(item => new Blog(item as Blog))))),
      map(blogs => blogs.filter(blog => {
        return !this.activeCategory || blog.category.id === this.activeCategory.id
      }))
    );
    this.categories$ = categoryService.blogCategories$;
  }

  setActive(category: BlogCategory | null) {
    this.activeCategory = category;
    this.categoryChange$.emit();
  }
}
