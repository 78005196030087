import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UserService} from "../../../authentication/user/state/service";
import {FormService} from "../../../components/service/form.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslocoService} from "@ngneat/transloco";
import {tap} from "rxjs";
import {NotificationComponent} from "../../../notifications/notification/component";
import {catchError} from "rxjs/operators";

@Component({
  templateUrl: './component.html'
})
export class ForgottenPasswordComponent {

  emailControl: FormControl;
  group: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private translationService: TranslocoService,
    public formService: FormService
  ) {
    this.emailControl = fb.control('', formService.bindValidations(
      formService.getValidations(['required', 'email']),
    ));

    this.group = fb.group({});
    this.group.addControl('email', this.emailControl);
  }


  forgottenPassword() {
    if (this.emailControl.valid) {
      this.userService.forgottenPassword(this.emailControl.value).pipe(
        catchError(err => {
          const snackBarRef = this.snackBar.openFromComponent(NotificationComponent);
          snackBarRef.instance.class = 'warning';
          snackBarRef.instance.message$ = this.translationService.selectTranslate('notification.forgotten-email-error');

          return err;
        }),
        tap(() => {
          //const snackBarRef = this.snackBar.openFromComponent(NotificationComponent);
          //snackBarRef.instance.class = 'success';
          //snackBarRef.instance.message$ = this.translationService.selectTranslate('notification.forgotten-email-sent');

          this.submitted = true;
        })
      ).subscribe();
    } else {
      this.formService.validateAllFormFields(this.group);
    }
  }
}
