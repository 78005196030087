import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {PressDownload} from './model';

export interface PressDownloadState extends EntityState<PressDownload> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'PressDownload' })
export class Store extends EntityStore<PressDownloadState> {

  constructor() {
    super();
  }

}
