<form *ngIf="!subForm && form" class="dynamic-form" [formGroup]="form" (submit)="onSubmit($event)">
  <mat-card>
    <mat-card-title>
      {{ formTitle$ | async }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ (entity$ | async)?.id }}
    </mat-card-subtitle>
    <mat-card-content>
      <mat-tab-group *ngIf="getFormTabs().length" mat-align-tabs="center">
        <mat-tab *ngFor="let tab of getFormTabs()" [label]="tab?.name$ | async | default: ''"
                 style="align-content: center" [ngClass]="{'col-12': true}">
          <ng-template mat-tab-label>
            <div [ngClass]="{'error': hasTabError(tab)}">
              {{ tab?.name$ | async |default: '' }}
            </div>
          </ng-template>
          <ng-container *ngFor="let field of getFieldsByTab(tab);">
            <div class="col-12" *ngIf="field.visible(form)" style="display:inline-block">
              <p dynamicField [field]="field" [group]="form"
                 [parentEntity$]="entity$"></p>
            </div>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
      <ng-container *ngFor="let field of getFieldsByTab();">
        <p *ngIf="field.visible(form)" dynamicField [field]="field" [group]="form"
           [parentEntity$]="entity$"></p>
      </ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="cancelTitle$ | async" mat-stroked-button type="button"
              (click)="onCancel($event)">{{ cancelTitle$ | async | transloco }}</button>
      <button mat-raised-button color="primary" type="submit">{{ submitTitle$ | async | transloco }}</button>
    </mat-card-actions>
  </mat-card>
</form>
<div *ngIf="subForm && form">
  <div>
    <ng-container *ngFor="let field of fields;">
      <p *ngIf="field.visible(form)" class="col-12" dynamicField [field]="field" [group]="form"
         [parentEntity$]="entity$"></p>
    </ng-container>
  </div>
</div>
