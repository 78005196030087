import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {User} from "../../../authentication/user/state/model";
import {catchError, merge, take, tap} from "rxjs/operators";
import {AuthService} from "../../../authentication/service/auth.service";
import {Router} from "@angular/router";
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";
import {FormService} from "../../../components/service/form.service";

@Component({
  selector: 'app-login-panel',
  templateUrl: './component.html'
})
export class LoginPanelComponent {

  group: FormGroup;
  error: boolean = false;
  @Output() panelEmitter = new EventEmitter<boolean>();

  emailValidators: any[] = [];
  passwordValidators: any[] = [];

  constructor(
    fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private formService: FormService,
    private localizeRouter: LocalizeRouterService
  ) {

    this.group = fb.group({});
    this.group.addControl('email', fb.control('', formService.bindValidations(this.emailValidators)));
    this.group.addControl('password', fb.control('', formService.bindValidations(this.passwordValidators)));


    this.group.valueChanges.pipe(
      tap(() => this.error = false)
    ).subscribe();
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList: ValidatorFn[] = [];
      validations.forEach((valid: { validator: ValidatorFn; }) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control?.markAsTouched({onlySelf: true});
    });
  }


  onSubmit(): void {
    if (this.group.valid) {

      const entity = new User({
        username: this.group.get('email').value,
        email: this.group.get('email').value,
        password: this.group.get('password').value
      });
      this.authService.login(entity as User).pipe(
        take(1),
        catchError(err => {
          this.error = true;
          return err;
        }),
        tap(() => this.panelEmitter.emit())
      )
        .subscribe(
          (user: User) => {
          }
        );
    } else {
      this.validateAllFormFields(this.group);
    }
  }

}
