import {Injectable} from '@angular/core';
import {ResellerService} from './state/service';
import {ResellerQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Reseller} from './state/model';
import {ResellerState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Reseller, ResellerState, ResellerQuery, Store> {
  constructor(protected query: ResellerQuery, protected api: ResellerService) {
    super(query, api);
  }
}
