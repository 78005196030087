import {Component, Injector} from '@angular/core';
import {OrderState, Store as OrderStore} from './state/store';
import {Order, OrderItem, OrderLog} from './state/model';
import {OrderService as OrderService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {OrderQuery} from './state/query';
import {Observable, of, tap} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {FormService} from "../../components/service/form.service";

@Component({
  templateUrl: 'detail.html',
  styleUrls: [
    '../../components/entity-detail/component.scss',
    '../../components/form/form-elements/common.scss',
    'detail.scss'
  ]
})
export class OrderDetailComponent extends EntityDetailComponent<Order, OrderState, OrderStore> {

  tab = 'user';
  dataSource$: Observable<OrderItem[]>;
  logs$: Observable<OrderLog[]>;
  statusGroup: FormGroup;
  statusControl: FormControl;
  trackingControl: FormControl;
  descriptionControl: FormControl;
  order: Order;
  shippingGroup: FormGroup;

  constructor(
    public injector: Injector,
    private _service: OrderService,
    protected query: OrderQuery,
    private formService: FormService,
    protected formBuilder: FormBuilder) {
    super(injector, _service, query);

    this.statusGroup = formBuilder.group({});
    this.shippingGroup = formBuilder.group({
      company: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      phone: new FormControl(''),
      street: new FormControl(''),
      city: new FormControl(''),
      postalCode: new FormControl(''),
    });
    this.statusControl = formBuilder.control('');
    this.descriptionControl = formBuilder.control('');
    this.trackingControl = formBuilder.control('');

    this.statusGroup.addControl('status', this.statusControl);
    this.statusGroup.addControl('tracking', this.trackingControl);
    this.statusGroup.addControl('description', this.descriptionControl);

    this.entity$ = this.entity$.pipe(
      tap(order => {
        this.order = order;
        this.shippingGroup.get('firstName').setValue(order.shippingAddress.firstName);
        this.shippingGroup.get('lastName').setValue(order.shippingAddress.lastName);
        this.shippingGroup.get('company').setValue(order.shippingAddress.companyName);
        this.shippingGroup.get('phone').setValue(order.shippingAddress.phone);
        this.shippingGroup.get('street').setValue(order.shippingAddress.street);
        this.shippingGroup.get('city').setValue(order.shippingAddress.city);
        this.shippingGroup.get('postalCode').setValue(order.shippingAddress.postalCode);
        this.statusControl.setValue(order.status);
        this.trackingControl.setValue(order.trackingCode);
      })
    );

    this.dataSource$ = this.entity$.pipe(
      map(item => {

          return item.items;
        }
      ));

    this.logs$ = this.entity$.pipe(
      switchMap(order => this._service.getLogsByOrder(order))
    );
  }

  getPossibleOptions(order: Order) {

    let _return = [];

    const hasManufacturable = order.items.some(orderItem => orderItem.product.manufacturingCategory.id);

    switch (order.status) {
      case 0:
        _return = [{
          id: 2,
          title: 'FIZETVE'
        }, {
          id: 6,
          title: 'TÖRÖLVE (általános okból)'
        },
          {
            id: 10,
            title: 'TÖRÖLVE (ügyfél kérésére)'
          },
          {
            id: 99,
            title: 'LEZÁRT'
          }
          ];
        break;
      case 2:
        _return = [{
          id: 8,
          title: 'GYÁRTÁS ALATT'
        }, {
          id: 5,
          title: 'SZÁLLÍTÁS ALATT'
        }, {
          id: 6,
          title: 'TÖRÖLVE (általános okból)'
        },
          {
            id: 10,
            title: 'TÖRÖLVE (ügyfél kérésére)'
          },
          {
            id: 99,
            title: 'LEZÁRT'
          }];
        break;
      case 8:
        _return = [{
          id: 5,
          title: 'SZÁLLÍTÁS ALATT'
        },{
          id: 6,
          title: 'TÖRÖLVE (általános okból)'
        },
          {
            id: 10,
            title: 'TÖRÖLVE (ügyfél kérésére)'
          },
          {
            id: 99,
            title: 'LEZÁRT'
          }];
        break;
      case 5:
        _return = [ {
          id: 6,
          title: 'TÖRÖLVE (általános okból)'
        },
          {
            id: 10,
            title: 'TÖRÖLVE (ügyfél kérésére)'
          },
          {
            id: 11,
            title: 'TÖRÖLVE (10 napon belül át nem vett)'
          },
          {
            id: 99,
            title: 'LEZÁRT'
          }];
        break;
      case 4:
        _return = [{
          id: 6,
          title: 'TÖRÖLVE (általános okból)'
        },
          {
            id: 10,
            title: 'TÖRÖLVE (ügyfél kérésére)'
          },
          {
            id: 99,
            title: 'LEZÁRT'
          }];
        break;
      case 7:
        _return = [{
          id: 6,
          title: 'TÖRÖLVE (általános okból)'
        },
          {
            id: 10,
            title: 'TÖRÖLVE (ügyfél kérésére)'
          },
          {
            id: 99,
            title: 'LEZÁRT'
          }];
        break;
      case 9:
        _return = [{
          id: 5,
          title: 'SZÁLLÍTÁS ALATT'
        }, {
          id: 6,
          title: 'TÖRÖLVE (általános okból)'
        },
          {
            id: 10,
            title: 'TÖRÖLVE (ügyfél kérésére)'
          },
          {
            id: 99,
            title: 'LEZÁRT'
          }
        ];
        break;
    }

    _return = _return.map(item => item.id);

    _return.push(order.status);

    return _return;

  }

  saveOrderStatus($event: MouseEvent) {
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    if (this.statusGroup.valid) {
      const order = new Order(this.order);

      order.status = this.statusControl.value;
      order.trackingCode = this.trackingControl.value;

      this.service.update(order.id, order).pipe(
        switchMap(() => this._service.addLog$(order, this.descriptionControl.value)),
        tap(() => {
          this.descriptionControl.reset();
        }),
        tap(() => this.navigateToParent())
      ).subscribe();
    } else {
      this.formService.validateAllFormFields(this.statusGroup);
    }
  }

  saveOrder($event: MouseEvent) {
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    const order = new Order(this.order);
    order.shippingAddress.city = this.shippingGroup.get('city')?.value;
    order.shippingAddress.street = this.shippingGroup.get('street')?.value;
    order.shippingAddress.phone = this.shippingGroup.get('phone')?.value;
    order.shippingAddress.firstName = this.shippingGroup.get('firstName')?.value;
    order.shippingAddress.lastName = this.shippingGroup.get('lastName')?.value;
    order.shippingAddress.companyName = this.shippingGroup.get('company')?.value;
    order.shippingAddress.postalCode = this.shippingGroup.get('postalCode')?.value;

    this.service.update(order.id, order).pipe(
      tap(() => this.navigateToParent())
    ).subscribe();
  }
}
