import {Component, EventEmitter, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Cart} from "../../../webshop/cart/state/model";
import {CartService} from "../../../webshop/cart/state/service";
import {User} from "../../../authentication/user/state/model";
import {AuthService} from "../../../authentication/service/auth.service";
import {OrderItem} from "../../../webshop/order/state/model";
import {ProductParameterTypeCount} from "../../../products/product-parameter-type-count/state/model";

@Component({
  templateUrl: './registration-completed.html'
})
export class CheckoutRegistrationComponent {

  constructor(
  ) {
  }
}
