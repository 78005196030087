import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, tap, throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {catchError} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {

  public constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.authService.getToken();
    if (token) {

      let cloned = null;
      if (!window.location.pathname.includes('admin')) {
        cloned = req.clone({
          headers: req.headers.set('Authorization',
            'Bearer ' + token).set('X-Webshop', '1')
        });
      } else {
        cloned = req.clone({
          headers: req.headers.set('Authorization',
            'Bearer ' + token)
        });
      }

      return next.handle(cloned).pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {

            if (err.status === 401 || err.status === 403) {
              this.authService.logout();
            }

            // return the error back to the caller
            return throwError(() => err);
          }

          return throwError(err);
        }));
    } else {
      let cloned = req.clone({
        headers: req.headers.set('X-Webshop', '1')
      });
      return next.handle(cloned);
    }
  }
}
