import {Injectable} from '@angular/core';
import {LanguageState, Store as LanguageStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Language} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {LanguageQuery} from './query';

@Injectable({providedIn: 'root'})
export class LanguageService extends EntityService<LanguageStore, LanguageState> {

  languages$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Language(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('language.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('language.code'),
        inputType: 'text',
        name: 'code',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('language.slug'),
        inputType: 'text',
        name: 'slug',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      })
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: LanguageQuery,
    protected store: LanguageStore) {
    super(store, Language);
  }

  get resourceName(): string {
    return 'language';
  }

}
