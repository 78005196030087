import {Injectable} from '@angular/core';
import {ProductVariantService} from './state/service';
import {ProductVariantQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {ProductVariant} from './state/model';
import {ProductVariantState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<ProductVariant, ProductVariantState, ProductVariantQuery, Store> {
  constructor(protected query: ProductVariantQuery, protected api: ProductVariantService) {
    super(query, api);
  }
}
