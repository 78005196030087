import {Component} from '@angular/core';
import {AuthService} from "../../../authentication/service/auth.service";
import {StoreService} from "../../../webshop/store/state/service";

@Component({
  selector: 'app-webshop-shops',
  templateUrl: './component.html'
})
export class ShopsComponent {
  stores$ = this.storeService.stores$;

  constructor(
    public authService: AuthService,
    private storeService: StoreService
  ) {
  }
}
