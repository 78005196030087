import {Injectable} from '@angular/core';
import {LanguageState, Store as LanguageStore} from './store';
import {Language} from './model';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {shareReplay} from "rxjs/operators";
import {EntityService} from "../../../components/entity-service";
import {FieldConfig} from "../../../components/form/field.interface";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class LanguageService extends EntityService<LanguageStore, LanguageState> {

  languages$: Observable<Language[]> = this.get().pipe(shareReplay(1));
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    formTitle: this.translocoService.selectTranslate('profile'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('language.slug'),
        inputType: 'text',
        name: 'slug',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  static translations() {
  }

  constructor(
    protected store: LanguageStore,
    private translocoService: TranslocoService
  ) {
    super(store, Language);
  }

  get resourceName(): string {
    return 'language';
  }

}
