<div class="menuMain menuMain--left" [ngClass]="{'menuMain--opened' : opened}">
  <button class="menuMain-close" (click)="toggle()" aria-label="Bezár" type="button">{{ 'webshop.Bezár' | transloco }}</button>
  <div class="row">
    <div class="column">
      <h2>{{ 'webshop.Webshop' | transloco }}</h2>
      <nav>
        <ul>
          <li><a [routerLink]="['/webshop'] | localize">{{ 'webshop.Webshop' | transloco }}</a></li>
          <li><a (click)="toggleProductMenu()" class="menuShopOpen">{{ 'webshop.Termékek' | transloco }}</a></li>
          <li><a [routerLink]="['/shops'] | localize">{{ 'webshop.Üzletek' | transloco }}</a></li>
          <li><a [routerLink]="['/wholesale'] | localize">{{ 'webshop.Viszonteladás' | transloco }}</a></li>
          <li><a [routerLink]="['/newsletter/sign-up'] | localize">{{ 'webshop.Hírlevél' | transloco }}</a></li>
          <li><a class="searchMainOpen hide-for-large" href="#">{{ 'webshop.Keresés' | transloco }}</a></li>
        </ul>
        <a class="menuMain-lang hide-for-large" *ngFor="let language of (languages$ | async)" (click)="switchLanguage(language)">{{ language.slug }}</a>
      </nav>
    </div>
    <div class="shrink column">
      <img class="menuMain-decor" src="/assets/images/menu_decor_left.svg" alt="decor">
    </div>
  </div>
</div>
<div class="menuMain menuMain--right" [ngClass]="{'menuMain--opened' : opened}">
  <div class="row">
    <div class="shrink column">
      <img class="menuMain-decor" src="/assets/images/menu_decor_right.svg" alt="decor">
    </div>
    <div class="column">
      <h2>{{ 'webshop.Felfedezés' | transloco }}</h2>
      <nav>
        <ul>
          <li><a [routerLink]="['/page', 'bomo-sztori'] | localize">{{ 'webshop.A Bomo sztori' | transloco }}</a></li>
          <li><a [routerLink]="['/page', 'bomo-muhely'] | localize">{{ 'webshop.Bomo Műhely' | transloco }}</a></li>
          <li><a [routerLink]="['/news'] | localize">{{ 'webshop.Bomo hírek' | transloco }}</a></li>
          <li><a [routerLink]="['/blog'] | localize">{{ 'webshop.Blog' | transloco }}</a></li>
          <li><a [routerLink]="['/press'] | localize">{{ 'webshop.Sajtó' | transloco }}</a></li>
          <li><a [routerLink]="['/contact'] | localize">{{ 'webshop.Kapcsolat' | transloco }}</a></li>
        </ul>
      </nav>
    </div>
  </div>
</div>
<div class="l-menuShop" [ngClass]="{'l-menuShop--opened': productMenuOpened}">
  <button class="menuShop-close" (click)="toggleProductMenu()" type="button"><span>{{ 'webshop.Bezár' | transloco}}</span></button>
  <div class="row align-center">
    <div class="small-12 medium-10 large-8 xlarge-6 column">
      <header>
        <h2>{{ 'webshop.Termékek' | transloco }}</h2>
        <img class="g-top-thin" src="/assets/images/menu_heading_decor.svg" alt="decor">
        <div class="g-top text-center show-for-medium">
          <img src="/assets/images/decor/fox.png" alt="decor">
        </div>
      </header>
      <nav>
        <ul class="menuShop" data-accordion data-allow-all-closed="true" *ngIf="(categories$ | async) as categories">
          <li class="menuShop-itemToShop"><a
            [routerLink]="['webshop'] | localize">{{ 'webshop.Gyerünk a shopba' | transloco }}</a></li>
          <li class="menuShop-item" data-accordion-item *ngFor="let category of categories | orderBy: 'weight'"
              [ngClass]="{'is-active': activeCategory && activeCategory.id === category.id}" (click)="toggleCategory(category)">
            <a class="menuShop-item-title">{{ category.name }}</a>
            <ul class="menuShop-item-content" *ngIf="activeCategory && activeCategory.id === category.id">
              <li *ngFor="let product of filterProductsByCategory$(category) | async | orderBy: 'name'"><a
               (click)="toggleProductMenu()" [routerLink]="['/product', product.slug] | localize">{{ product.name }}</a></li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
