import {Injectable} from '@angular/core';
import {ProductParameterFormatService} from './state/service';
import {ProductParameterFormatQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {ProductParameterFormat} from './state/model';
import {ProductParameterFormatState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<ProductParameterFormat, ProductParameterFormatState, ProductParameterFormatQuery, Store> {
  constructor(protected query: ProductParameterFormatQuery, protected api: ProductParameterFormatService) {
    super(query, api);
  }
}
