import {Component} from '@angular/core';
import {Cart} from "../../../webshop/cart/state/model";
import {Observable, of, tap} from "rxjs";
import {CartService} from "../../../webshop/cart/state/service";
import {OrderItem} from "../../../webshop/order/state/model";
import {ProductParameterTypeCount} from "../../../products/product-parameter-type-count/state/model";
import {FormBuilder, FormControl} from "@angular/forms";
import {CouponService} from "../../../webshop/coupon/state/service";
import {Router} from "@angular/router";
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";
import {Coupon} from "../../../webshop/coupon/state/model";
import {switchMap} from "rxjs/operators";

@Component({
  templateUrl: './component.html'
})
export class CartComponent {
  cart$: Observable<Cart> = this.cartService.cart$.pipe(
  );
  couponError = false;
  couponControl: FormControl;

  constructor(
    private cartService: CartService,
    private couponService: CouponService,
    private fb: FormBuilder,
    private localizeRouter: LocalizeRouterService,
    private router: Router
  ) {
    this.couponControl = fb.control('');
  }

  checkCoupon() {

    //TODO
    return true;
  }

  orderByProductName(items: OrderItem[]): OrderItem[] {
    const _items = Object.assign([], items);
    return _items.sort((a: OrderItem, b: OrderItem) => {
      return a.product.name > b.product.name ? 1 : -1;
    });
  }


  orderByCreatedAt(items: OrderItem[]): OrderItem[] {
    const _items = Object.assign([], items);
    return _items.sort((a: OrderItem, b: OrderItem) => {
      if (a.createdAt.getTime() === b.createdAt.getTime()) {
        return a.id < b.id ? 1 : -1;
      }
      return a.createdAt > b.createdAt ? 1 : -1;
    });
  }

  orderByProductParameterName(productParameterTypeCounts: ProductParameterTypeCount[]): ProductParameterTypeCount[] {
    const _items = Object.assign([], productParameterTypeCounts);
    return _items.sort((a, b) => {
      return a.productParameterType.name > b.productParameterType.name ? 1 : -1;
    });
  }

  addCoupon(cart: Cart) {
    if (this.couponControl.value.trim()) {
      this.couponService.getByAttribute({name: 'code', value: this.couponControl.value}).pipe(
        tap(coupon => {
          if (coupon.id) {
            cart.coupon = coupon;
            this.couponError = false;
          } else {
            this.couponError = true;
            throw 'Invalid';
          }
        }),
        switchMap(() => this.cartService.update(cart.id, cart))
      ).subscribe();
    }
  }

  incOrderItem(cartItem: OrderItem, cart: Cart) {
    this.cartService.incrementOrderItem(cartItem, 1, cart);
    this.cartService.update(cart.id, cart).subscribe();
  }

  decOrderItem(cartItem: OrderItem, cart: Cart) {
    this.cartService.decrementOrderItem(cartItem, 1, cart);
    this.cartService.update(cart.id, cart).subscribe();
  }

  removeProduct(cartItem: OrderItem, cart: Cart) {
    this.cartService.deleteProductFromCart(cartItem, cart);
    this.cartService.update(cart.id, cart).subscribe();
  }

  deleteCoupon(cart: Cart) {
    cart.coupon = new Coupon({});

    this.cartService.update(cart.id, cart).subscribe();
  }

  submit() {
    if (this.checkCoupon()) {
      this.router.navigate([this.localizeRouter.translateRoute('/checkout')]).then();
    }
  }
}
