import {Injectable} from '@angular/core';
import {UserService} from './state/service';
import {UserQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {User} from './state/model';
import {Store, UserState} from './state/store';
import {UserListQuery} from "./state/listQuery";

@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<User, UserState, UserQuery, Store> {
  constructor(protected query: UserListQuery, protected api: UserService) {
    super(query, api);
  }
}
