import {Component} from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../service/auth.service';
import {getEntityType} from '@datorama/akita';
import {User} from '../user/state/model';
import {TranslocoService} from '@ngneat/transloco';
import {FieldConfig} from '../../components/form/field.interface';
import {Observable, of} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationComponent} from '../../notifications/notification/component';
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";

@Component({
  selector: 'login',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class LoginComponent {
  title$ = of('');
  cancelTitle = of('');
  fields: FieldConfig[] = [];
  entity$: Observable<User>;
  group: FormGroup;
  emailValidators: any[] = [];
  passwordValidators: any[] = [];

  constructor(
    private fb: FormBuilder,
    public translationService: TranslocoService,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private localizeRouter: LocalizeRouterService
    ) {

    this.group = fb.group({});
    this.group.addControl('email', fb.control('', this.bindValidations(this.emailValidators)));
    this.group.addControl('password', fb.control('', this.bindValidations(this.passwordValidators)));

    this.entity$ = new Observable<User>();
    this.title$ = this.translationService.selectTranslate('authentication.login.title');
    this.fields = [
      FieldConfig.create({
        type: 'input',
        label: this.translationService.selectTranslate('authentication.login.username'),
        inputType: 'text',
        name: 'email',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translationService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translationService.selectTranslate('authentication.login.password'),
        inputType: 'password',
        name: 'password',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translationService.selectTranslate('form.required')
          }
        ]
      })
    ];
  }


  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList: ValidatorFn[] = [];
      validations.forEach((valid: { validator: ValidatorFn; }) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control?.markAsTouched({onlySelf: true});
    });
  }

  onSubmit(entity: getEntityType<User>) {
    if (this.group.valid) {
      this.authService.login(entity as User).pipe(
        take(1),
        catchError(err => {
          const snackBarRef = this.snackBar.openFromComponent(NotificationComponent);
          snackBarRef.instance.class = 'warning';
          snackBarRef.instance.message$ = this.translationService.selectTranslate('login.invalidCredentials');
          return err;
        })
      )
        .subscribe(
          (user: User) => {
            if (!user.permissions.find(permission => permission.slug === 'ROLE_ADMIN')) {
              this.router.navigateByUrl('/').then();
            } else {
              this.router.navigate([this.localizeRouter.translateRoute('/admin')]).then();
            }
          }
        );
    } else {
      this.validateAllFormFields(this.group);
    }
  }

  onCancel() {
    this.router.navigate(['/']);
  }
}
