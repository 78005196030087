import {Injectable} from '@angular/core';
import {GalleryImageState, Store as GalleryImageStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {GalleryImage} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {first, map, multicast, publishReplay, refCount, share, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {GalleryImageQuery} from './query';
import {Observable, of, Subject} from "rxjs";
import {getEntityType} from "@datorama/akita";

@Injectable({providedIn: 'root'})
export class GalleryImageService extends EntityService<GalleryImageStore, GalleryImageState> {

  galleries$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new GalleryImage(item))),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('galleryImage.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('galleryImage.itemNumber'),
        inputType: 'text',
        name: 'url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('galleryImage.slug'),
        inputType: 'text',
        name: 'gallery',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('galleryImage.quantitativeUnit'),
        inputType: 'text',
        name: 'quantitativeUnit',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('galleryImage.createdAt'),
        name: 'createdAt',
        listed: true,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('galleryImage.updatedAt'),
        name: 'updatedAt',
        listed: true,
        editable: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: GalleryImageQuery,
    protected store: GalleryImageStore) {
    super(store, GalleryImage);
  }

  get resourceName(): string {
    return 'products/gallery-image';
  }

  getByGalleryId(id: string | number): Observable<GalleryImage[]> {
    if (id) {
      return this.getHttp().get<GalleryImage[]>(this.api + '?q[]=gallery.id:eq:' + id)
        .pipe(
          map(items => {
            return items.map(item => {
              return new this._constructor(item) as GalleryImage;
            });
          }),
          first(),
          shareReplay(1)
        );
    } else {
      return of([]);
    }
  }
}
