import {Injectable} from '@angular/core';
import {ShippingAddressState, Store as ShippingAddressStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {ShippingAddress} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {ShippingAddressQuery} from './query';
import {Observable, of} from "rxjs";
import {AuthService} from "../../../authentication/service/auth.service";
import {User} from "../../../authentication/user/state/model";

@Injectable({providedIn: 'root'})
export class ShippingAddressService extends EntityService<ShippingAddressStore, ShippingAddressState> {

  addresses$ = this.authService.user$.pipe(
    switchMap(() => this.get()),
    map(items => items.map(item => new ShippingAddress(item))),
    shareReplay(1)
  );

  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('country.entityTitle'),
    fields: [

      FieldConfig.create({
        type: 'input',
        label: of(''),
        inputType: 'text',
        name: 'address',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: of(''),
        inputType: 'text',
        name: 'user',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: of(''),
        inputType: 'text',
        name: 'country',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: of(''),
        inputType: 'text',
        name: 'isDefault',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: ShippingAddressQuery,
    private authService: AuthService,
    protected store: ShippingAddressStore) {
    super(store, ShippingAddress);
  }

  get resourceName(): string {
    return 'webshop/shipping-address';
  }

  getByUser(user: User): Observable<ShippingAddress[]> {
    return this.getHttp().get<ShippingAddress[]>(this.api + '/detail?q[]=user:eq:' + user.id)
      .pipe(
        map(items => {
          return (items ?? []).map(_item => new this._constructor(_item) as ShippingAddress);
        }),
        take(1),
      );
  }
}
