import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable} from 'rxjs';
import {Entity} from '../../entity';
import {MatCalendarCellClassFunction} from '@angular/material/datepicker';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
  selector: 'app-date-range',
  template: `
    <mat-form-field class="demo-full-width margin-top" *ngIf="field.name" [formGroup]="group">
      <mat-label>{{ field.label | async | default: '' }}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [formControlName]="field.name + 'From'">
        <input matEndDate [formControlName]="field.name + 'To'">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker [touchUi]="isMobile"  [dateClass]="dateClass" #picker></mat-date-range-picker>
      <mat-hint></mat-hint>
      <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group?.get(field.name)?.hasError(validation.name)">{{validation.message$ | async}}</mat-error>
      </ng-container>
      <ng-container ngProjectAs="mat-error">
        <mat-error
          *ngIf="group?.get(field.name)?.hasError('custom')">{{group?.get(field.name)?.getError('custom').message}}</mat-error>
      </ng-container>
    </mat-form-field>`,
  styleUrls: ['common.scss'],
})
export class DateRangeComponent implements OnInit {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  parentEntity$ = new Observable<Entity>();
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      const date = cellDate.getUTCDay();

      if (date > 4) {
        return 'calendar-highlighted';
      }
    }

    return '';
  }
  constructor(private breakpointObserver: BreakpointObserver) {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }

  ngOnInit() {
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
}
