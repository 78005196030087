import {AfterViewInit, Component, OnInit, TemplateRef} from '@angular/core';
import {ProductService} from "../../../products/product/state/service";
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin, merge, Observable, of, startWith, switchMap} from "rxjs";
import {Product} from "../../../products/product/state/model";
import {ProductParameter} from "../../../products/product-parameter/state/model";
import {ProductParameterTypeService} from "../../../products/product-parameter-type/state/service";
import {ProductParameterSize} from "../../../products/product-parameter-size/state/model";
import {catchError, first, map, shareReplay, take, tap} from "rxjs/operators";
import {CartService} from "../../../webshop/cart/state/service";
import {Cart} from "../../../webshop/cart/state/model";
import {AbstractControl, FormBuilder, FormControl, Validators} from "@angular/forms";
import {ProductVariant} from "../../../products/product-variant/state/model";
import {ProductParameterType} from "../../../products/product-parameter-type/state/model";
import {ProductParameterTypeCount} from "../../../products/product-parameter-type-count/state/model";
import {BsModalRef, BsModalService} from "ngx-foundation";
import {ProductParameterFormat} from "../../../products/product-parameter-format/state/model";
import {FormService} from "../../../components/service/form.service";
import {TranslocoService} from "@ngneat/transloco";
import {Config} from "../../../webshop/config/state/model";
import {ConfigService} from "../../../webshop/config/state/service";
import {Caption} from "../../../products/caption/state/model";
import {ProductParameterFormatImage} from "../../../products/product-parameter-format-image/state/model";
import {Barcode} from "../../../products/barcode/state/model";
import {BarcodeService} from "../../../products/barcode/state/service";
import {ProductParameterInner} from "../../../products/product-parameter-inner/state/model";
import {orderByWeight} from "../../module";
import {getTableUnknownDataSourceError} from "@angular/cdk/table/table-errors";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {error} from "protractor";
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";


export function textValid(control: AbstractControl) {
  var regexp = /(?!(.*a){7,})(?!(.*á){4,})(?!(.*b){5,})(?!(.*c){5,})(?!(.*d){5,})(?!(.*e){7,})(?!(.*é){4,})(?!(.*f){5,})(?!(.*g){5,})(?!(.*h){4,})(?!(.*i){5,})(?!(.*í){3,})(?!(.*j){4,})(?!(.*k){4,})(?!(.*l){5,})(?!(.*m){4,})(?!(.*n){5,})(?!(.*o){5,})(?!(.*ó){3,})(?!(.*ö){3,})(?!(.*ő){3,})(?!(.*p){5,})(?!(.*q){3,})(?!(.*s){6,})(?!(.*t){5,})(?!(.*u){5,})(?!(.*ú){3,})(?!(.*ü){3,})(?!(.*ű){3,})(?!(.*v){4,})(?!(.*w){3,})(?!(.*x){3,})(?!(.*y){3,})(?!(.*z){5,})(?!(.*A){4,})(?!(.*Á){3,})(?!(.*B){4,})(?!(.*C){4,})(?!(.*D){3,})(?!(.*E){4,})(?!(.*É){3,})(?!(.*F){4,})(?!(.*G){4,})(?!(.*H){4,})(?!(.*I){4,})(?!(.*Í){3,})(?!(.*J){4,})(?!(.*K){4,})(?!(.*L){4,})(?!(.*M){4,})(?!(.*N){4,})(?!(.*O){4,})(?!(.*Ó){3,})(?!(.*Ö){3,})(?!(.*Ő){3,})(?!(.*P){4,})(?!(.*Q){3,})(?!(.*S){4,})(?!(.*T){4,})(?!(.*U){4,})(?!(.*Ú){3,})(?!(.*Ü){3,})(?!(.*Ű){3,})(?!(.*V){4,})(?!(.*W){3,})(?!(.*X){3,})(?!(.*Y){3,})(?!(.*Z){4,})(?!(.*\@){2,})(?!(.*\&){2,})(?!(.*\#){2,})(?!(.*\.){4,})(?!(.*\,){3,})(?!(.*\:){2,})(?!(.*\;){2,})(?!(.*\(){2,})(?!(.*\)){2,})(?!(.*\/){4,})(?!(.*\'){3,})(?!(.*\-){3,})(?!(.*\♡){3,})(?!(.*1){7,})(?!(.*2){7,})(?!(.*3){7,})(?!(.*4){7,})(?!(.*5){7,})(?!(.*6){7,})(?!(.*7){7,})(?!(.*8){7,})(?!(.*9){7,})(?!(.*0){7,})(?=^[\sa-zA-Z0-9\@\♡\&\#\.\,\:\;\(\)\/\'\-áéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$).+$/gm;
  /*
  var regexp = /
  (?!(.*a){6,})
  (?!(.*á){3,})
  (?!(.*b){4,})
  (?!(.*c){4,})
  (?!(.*d){4,})
  (?!(.*e){6,})
  (?!(.*é){3,})
  (?!(.*f){4,})
  (?!(.*g){4,})
  (?!(.*h){3,})
  (?!(.*i){4,})
  (?!(.*í){2,})
  (?!(.*j){3,})
  (?!(.*k){3,})
  (?!(.*l){4,})
  (?!(.*m){3,})
  (?!(.*n){4,})
  (?!(.*o){4,})
  (?!(.*ó){2,})
  (?!(.*ö){2,})
  (?!(.*ő){2,})
  (?!(.*p){4,})
  (?!(.*q){2,})
  (?!(.*s){5,})
  (?!(.*t){4,})
  (?!(.*u){4,})
  (?!(.*ú){2,})
  (?!(.*ü){2,})
  (?!(.*ű){2,})
  (?!(.*v){3,})
  (?!(.*w){2,})
  (?!(.*x){2,})
  (?!(.*y){2,})
  (?!(.*z){4,})
  (?!(.*A){3,})
  (?!(.*Á){2,})
  (?!(.*B){3,})
  (?!(.*C){3,})
  (?!(.*D){2,})
  (?!(.*E){3,})
  (?!(.*É){2,})
  (?!(.*F){3,})
  (?!(.*G){3,})
  (?!(.*H){3,})
  (?!(.*I){3,})
  (?!(.*Í){2,})
  (?!(.*J){3,})
  (?!(.*K){3,})
  (?!(.*L){3,})
  (?!(.*M){3,})
  (?!(.*N){3,})
  (?!(.*O){3,})
  (?!(.*Ó){2,})
  (?!(.*Ö){2,})
  (?!(.*Ő){2,})
  (?!(.*P){3,})
  (?!(.*Q){2,})
  (?!(.*S){3,})
  (?!(.*T){3,})
  (?!(.*U){3,})
  (?!(.*Ú){2,})
  (?!(.*Ü){2,})
  (?!(.*Ű){2,})
  (?!(.*V){3,})
  (?!(.*W){2,})
  (?!(.*X){2,})
  (?!(.*Y){2,})
  (?!(.*Z){3,})

  (?!(.*\@){1,})
  (?!(.*\&){1,})
  (?!(.*\#){1,})
  (?!(.*\.){3,})
  (?!(.*\,){2,})
  (?!(.*\:){1,})
  (?!(.*\;){1,})
  (?!(.*\(){1,})
  (?!(.*\)){1,})
  (?!(.*\/){3,})
  (?!(.*\'){2,})
  (?!(.*\-){2,})
  (?!(.*\u2661){2,})
  (?!(.*\1){6,})
  (?!(.*\2){6,})
  (?!(.*\3){6,})
  (?!(.*\4){6,})
  (?!(.*\5){6,})
  (?!(.*\6){6,})
  (?!(.*\7){6,})
  (?!(.*\8){6,})
  (?!(.*\9){6,})
  (?!(.*\0){6,})
  (?=^[\sa-zA-Z0-9\@\u2661\&\#\.\,\:\;\(\)\/\'\-áéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$).+$/gm
    */
  if (!control.value) {
    return null;
  }

  var resp = control.value.match(regexp);

  if (!resp || !resp[0]) {
    return {textValid: true};
  }

  return null;
}

export interface productParameterTypeQuery {
  productParameter: ProductParameter,
  types: ProductParameterType[];
  query$: Observable<ProductParameterType[]>;
}

export interface productParameterTypeImageQuery {
  productParameterType: ProductParameterType,
  query$: Observable<ProductParameterFormatImage[]>;
  images: ProductParameterFormatImage[];
}

export interface productParameterControl {
  productParameter: ProductParameter,
  control: FormControl
}

@Component({
  templateUrl: './component.html',
  styleUrls: ['component.scss']
})
export class WebshopProductComponent implements OnInit, AfterViewInit {

  validations = [
    {
      name: 'maxLength',
      validator: (control: AbstractControl) => {

        if (!control.value) {
          return null;
        }
        if (control.value.length > this.captionLength) {
          return {maxLength: true};
        }

        return null;
      },
      message$: this.translocoService.selectTranslate('form.maxLength')
    },
    {
      name: 'required',
      validator: Validators.required,
      message$: this.translocoService.selectTranslate('form.required')
    },
    {
      name: 'textValid',
      validator: textValid,
      message$: this.translocoService.selectTranslate('form.textValid')
    },
  ];
  cart$: Observable<Cart>;
  config: Config;
  cart: Cart;
  item$: Observable<Product>;
  product: Product;
  barcodes: Barcode[] = [];
  formatImages$: Observable<ProductParameterFormatImage[]>;
  selectedFormat: ProductParameterFormat;
  caption: Caption;
  captionLength = 8;
  controls: productParameterControl[] = [];
  specialCaptionControl: FormControl;
  specialCaption2Control: FormControl;
  productVariant = new ProductVariant({});
  productParameterTypes: productParameterTypeQuery[] = [];
  productParameterTypeImages: productParameterTypeImageQuery[] = [];
  modalRef: BsModalRef;
  activeProductParameter: ProductParameter;
  activeProductParameterSize: ProductParameterSize;
  activeProductParameterInner: ProductParameterInner;
  price: number | null = null;

  constructor(
    private route: ActivatedRoute,
    private cartService: CartService,
    private productService: ProductService,
    private configService: ConfigService,
    private productParameterTypeService: ProductParameterTypeService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private barcodeService: BarcodeService,
    public formService: FormService,
    private translocoService: TranslocoService,
    private breakpointObserver: BreakpointObserver,
    private localizeRouter: LocalizeRouterService,
    private router: Router
  ) {
    this.cart$ = cartService.cart$.pipe(
      tap(cart => this.cart = new Cart(cart))
    );
  }

  ngAfterViewInit() {


  }

  ngOnInit() {

    this.specialCaption2Control = this.fb.control('', this.formService.bindValidations([
      this.validations.find(validator => validator.name === 'maxLength'),
      this.validations.find(validator => validator.name === 'textValid'),
    ]));
    this.specialCaptionControl = this.fb.control('', this.formService.bindValidations([
      this.validations.find(validator => validator.name === 'maxLength'),
      this.validations.find(validator => validator.name === 'textValid'),
    ]));


    this.item$ = this.route.params.pipe(
      switchMap(params => this.configService.config$.pipe(
        map(config => {

          this.productVariant = new ProductVariant({});
          this.controls = [];
          this.productParameterTypes = [];
          this.productParameterTypeImages = [];
          this.config = config;
          this.specialCaptionControl.setValue('');
          this.specialCaption2Control.setValue('');
          this.selectedFormat = null;
          return params;
        })
      )),
      switchMap(params => this.productService.getBySlug(params.id)),
      switchMap(product => {
        if (!product.id) {
          this.router.navigate([this.localizeRouter.translateRoute('/404')]).then();
        }
        return of(product);
      }),
      switchMap(product => this.barcodeService.getByProduct(product).pipe(
        tap(barcodes => {
          this.barcodes = barcodes;
        }),
        map(() => product)
      )),
      switchMap((product: Product) => {
          const controlType = product.productParameters.find(pp => pp.isControl);
          this.product = product;

          this.productVariant = new ProductVariant({});
          this.controls = [];
          this.productParameterTypes = [];
          this.productParameterTypeImages = [];
          this.specialCaptionControl.setValue('');
          this.specialCaption2Control.setValue('');
          this.selectedFormat = null;
          let obs: Observable<any> = of('');

          if (product.selectorProductParameter.id) {
            const query = this.productParameterTypeService.getByProductParameter(product.selectorProductParameter).pipe(
              switchMap(productParameterTypes => {
                this.productParameterTypes.find(pts => pts.productParameter.id === product.selectorProductParameter.id).types = productParameterTypes;

                let _querySet: Observable<any>[] = [];

                productParameterTypes.forEach(parameter => {
                  const _query = this.productParameterTypeService.getImagesByProductParameterType(parameter).pipe(
                    tap(images => {
                      this.productParameterTypeImages.find(item => item.productParameterType.id === parameter.id).images = images;
                    })
                  );

                  _querySet.push(_query);

                  this.productParameterTypeImages.push({
                    productParameterType: parameter,
                    images: [],
                    query$: _query
                  });
                });


                return forkJoin(_querySet).pipe(map(() => productParameterTypes));
              }),
              take(1),
              shareReplay()
            );
            this.productParameterTypes.push({
              productParameter: product.selectorProductParameter,
              types: [],
              query$: query
            });


            obs = obs.pipe(switchMap(() => query));
          }

          product.productParameters.forEach(parameter => {
            if (parameter.id !== this.product.selectorProductParameter.id) {

              const query = this.productParameterTypeService.getByProductParameter(parameter).pipe(
                switchMap(productParameterTypes => {
                  this.productParameterTypes.find(pts => pts.productParameter.id === parameter.id).types = productParameterTypes;

                  if (this.product.selectorProductParameter.id !== parameter.id) {
                    if (!this.getControl(parameter).value && productParameterTypes.length && parameter.id !== this.product.selectorProductParameter.id) {
                      this.getControl(parameter).setValue(productParameterTypes[0]);
                      this.chooseParameter(parameter, productParameterTypes[0]);
                    }
                  }

                  let _querySet: Observable<any>[] = [];

                  productParameterTypes.forEach(parameter => {
                    const _query = this.productParameterTypeService.getImagesByProductParameterType(parameter).pipe(
                      tap(images => {
                        this.productParameterTypeImages.find(item => item.productParameterType.id === parameter.id).images = images;
                      })
                    );

                    _querySet.push(_query);

                    this.productParameterTypeImages.push({
                      productParameterType: parameter,
                      images: [],
                      query$: _query
                    });
                  });


                  return forkJoin(_querySet).pipe(map(() => productParameterTypes));
                }),
                take(2),
                shareReplay()
              );

              this.productParameterTypes.push({
                productParameter: parameter,
                types: [],
                query$: query
              });


              obs = obs.pipe(switchMap(() => query));
            }
          });

          return obs.pipe(map(() => {
            if (controlType) {
              const types = this.parameterTypes(product.productParameters.find(pp => pp.isControl));

              this.captionLength = types.find(() => true)?.captionLength;
              if(!this.captionLength) {
                this.captionLength = 24;
              }
              console.log('DEFAULT ' + this.captionLength);
              this.resetProduct(product);
            }
            return product
          }));
        }
      ),
      switchMap((product: Product) => {
        return this.specialCaptionControl.valueChanges.pipe(
          startWith(''),
          map(() => {
            this.calculatePrice();
            return product;
          })
        )
      }),
      switchMap((product: Product) => {
        return this.specialCaption2Control.valueChanges.pipe(
          startWith(''),
          map(() => {
            this.calculatePrice();
            return product;
          })
        )
      }),
      tap(((product: Product) => {
      })),
      shareReplay(1)
    );
    this.formatImages$ = this.item$.pipe(
      take(1),
      switchMap(product => this.productService.getImagesByProduct(product)),
      shareReplay(1)
    );

  }

  resetProduct(product: Product) {
    if (product.defaultVariant) {
      const oldVariant = this.productVariant;
      this.productVariant = new ProductVariant(product.defaultVariant);

      oldVariant.productParameterTypeCounts.forEach(pptc => {
        if (!this.productVariant.productParameterTypeCounts.find(_pptc => _pptc.productParameterType.productParameter.id === pptc.productParameterType.productParameter.id)) {
          this.productVariant.productParameterTypeCounts.push(new ProductParameterTypeCount({
            productParameterType: pptc.productParameterType,
            quantity: pptc.quantity
          }));
        }
      });

      this.productVariant.productParameterTypeCounts.forEach(pptc => {
        if (pptc.productParameterType.productParameter.id !== this.product.selectorProductParameter.id) {
          pptc.quantity = 0;
        }
      });
      const ptc = this.productVariant.productParameterTypeCounts.find(_ptc => _ptc.productParameterType.productParameter.isControl);
      if (ptc) {
        this.selectedFormat = ptc.productParameterType.productParameter.formats[0];
        this.productVariant.productParameterTypeCounts.forEach(pptc => {
          this.chooseParameter(pptc.productParameterType.productParameter, pptc.productParameterType);
        });
      }
    } else {
    }
    if (this.selectedFormat && this.selectedFormat.id) {
      this.caption = product.productParameters.find(pp => pp.isControl).captions?.find(caption => caption.format.id === this.selectedFormat.id);
    }
    this.calculatePrice();
  }

  activeTab: string = 'selectable';

  // Reveal (Modal)
  openReveal(template: TemplateRef<any>
  ) {
    document.body.style.position = "fixed";
    this.modalRef = this.modalService.show(template, {class: 'full'});
  }

  closeReveal() {
    document.body.style.position = "relative";

    document.body.className = "";
    this.modalRef.hide();
    let divs = document.getElementsByTagName('modal-container');
    if (divs.length) {
      for (let i = 0; i < divs.length; i++) {
        let div = divs.item(i);
        div.parentNode?.removeChild(div);
      }
    }

    var element = document.getElementById('selector-position');
    window.scrollTo(0, element.offsetTop - 100);
  }

  openSize(modal: TemplateRef<any>, size: ProductParameterSize
  ) {
    this.activeProductParameterSize = size;
    this.openReveal(modal);
  }

  openInner(modal: TemplateRef<any>, parameter: ProductParameter
  ) {
    this.activeProductParameterInner = parameter.inner;
    this.openReveal(modal);
  }

  openProductIngredients(modal: TemplateRef<any>, parameter: ProductParameter
  ) {
    this.activeProductParameter = parameter;
    this.openReveal(modal);
  }

  secondCaption(item: Product
  ) {
    return this.productVariant.productParameterTypeCounts.find(pc => pc.productParameterType.id === '8fa71309-26cf-4746-8608-afed766ab75d');
  }

  hasFormat(type: ProductParameterType, item: Product, productParameterFormatImages: ProductParameterFormatImage[] | null) {

    if (!productParameterFormatImages) {
      //console.log('No format images for ' + type.name);
      return false;
    }
    const control = type.productParameter.isControl;

    if (!productParameterFormatImages.find(image => image.format.id === this.selectedFormat.id)) {
      //console.log('No format image for ' + type.name + ' and format ' + this.selectedFormat.name);
      //console.log(productParameterFormatImages);
    }
    return !productParameterFormatImages || control || productParameterFormatImages.find(image => image.format.id === this.selectedFormat.id);
  }

  parameterTypes(parameter: ProductParameter): ProductParameterType[] {
    return this.productParameterTypes.find(index => index.productParameter.id === parameter.id)?.types.filter(a => a.available).sort((a, b) => a.weight > b.weight ? 1 : -1) ?? [];
  }

  parameterTypeFormatImages(parameter: ProductParameterType) {
    return this.productParameterTypeImages.find(index => index.productParameterType.id === parameter.id)?.images ?? [];
  }

  size$(): Observable<ProductParameterSize> {
    return this.item$.pipe(
      map(item => {
        const controls = item.productParameters.filter(pp => pp.isControl);
        if (controls.length) {
          return controls[0].size;
        } else {
          return null;
        }
      }),
      shareReplay(1)
    );
  }

  addToCart(item: Product) {
    if (item.setCount && item.setCount !== this.getSelectedCount()) {
      return;
    }

    if (!this.specialCaption2Control.valid || !this.specialCaptionControl.valid) {
      return;
    }

    let caption2 = this.specialCaption2Control.value;
    if (!this.productVariant.productParameterTypeCounts.some(pptc => pptc.productParameterType.id === '8fa71309-26cf-4746-8608-afed766ab75d')) {
      caption2 = '';
    }
    this.cartService.addProductToCart(item, this.productVariant, this.specialCaptionControl.value, caption2, 1, this.cart);
    this.cartService.update(this.cart.id, this.cart).pipe(
      tap(() => {
        //this.resetProduct(this.product);
        //this.specialCaptionControl.reset();
        //this.specialCaption2Control.reset();
        //if (this.product.selectorProductParameter.id) {
        //  this.productVariant.productParameterTypeCounts = this.productVariant.productParameterTypeCounts.filter(pptc => pptc.productParameterType.productParameter.id !== this.product.selectorProductParameter.id);
        //}
      })
    ).subscribe();
  }

  parseNumber(value: string) {
    return parseFloat(value);
  }

  chooseParameter(parameter: ProductParameter, type: ProductParameterType) {

    this.getControl(parameter).setValue(type);

    this.changeProductParameter(this.getControl(parameter), parameter);
    if (parameter.isControl) {
      this.captionLength = type.captionLength;
      console.log('switch ' + this.captionLength);
    }
    this.calculatePrice();
  }

  hasNonSelectorProductParameter(item: Product) {
    return item.productParameters.filter(pp => pp.id !== item.selectorProductParameter.id).length > 0;
  }

  incrementSelected(productParameterType: ProductParameterType, setCount: number) {
    if (this.getStock(productParameterType) <= 0) {
      return;
    }
    if (this.getSelectedCount() >= setCount && setCount) {
      return;
    }
    const typeCount = this.productVariant.productParameterTypeCounts.find(tc => tc.productParameterType.id === productParameterType.id);

    if (!typeCount) {
      this.productVariant.productParameterTypeCounts.unshift(new ProductParameterTypeCount({
        productParameterType: productParameterType,
        quantity: 1
      }));
    } else {
      typeCount.quantity += 1;
    }

    this.calculatePrice();
  }

  decrementSelected(productParameterType: ProductParameterType) {
    const typeCount = this.productVariant.productParameterTypeCounts.find(tc => tc.productParameterType.id === productParameterType.id);

    if (!typeCount) {

    } else {
      typeCount.quantity -= 1;

      if (typeCount.quantity <= 0) {
        this.productVariant.productParameterTypeCounts = this.productVariant.productParameterTypeCounts.filter(tc => tc.productParameterType.id !== productParameterType.id);
      }
    }
    this.calculatePrice();
  }

  getPage(productParameterTypes: ProductParameterType[] | null, n: number, perPage: number) {
    return productParameterTypes.slice((n) * perPage, (n + 1) * perPage);
  }

  ceil(number: number) {
    return Math.ceil(number);
  }

  calculatePrice(): number {

    this.price = (this.product.selectorProductParameter.id && !this.product.setCount ? this.productVariant.productParameterTypeCounts.filter(ppc => ppc.productParameterType.productParameter.id == this.product.selectorProductParameter.id).reduce((agg, curr) => curr.quantity + agg, 0) * this.product.price : this.product.price) +
      this.productVariant.productParameterTypeCounts.reduce((agg, curr) => agg + curr.productParameterType.price * Math.max(curr.quantity, 1) ?? 1, 0) +
      (this.specialCaptionControl.value ? this.config.captionPrice : 0) +
      ((this.specialCaption2Control.value && this.secondCaption(this.product)) ? this.config.captionPrice : 0)

    return this.price;
  }

  getFullPrice(): number {
    if (this.price) {
      return this.price;
    }

    return this.calculatePrice();
  }

  getSelectedCount() {
    return this.productVariant.productParameterTypeCounts.filter(ppc => ppc.productParameterType.productParameter.id === this.product.selectorProductParameter.id).reduce((agg, curr) => agg + curr.quantity, 0);
  }

  getParameterTypeImage(productParameterType: ProductParameterType, productParameterImages: ProductParameterFormatImage[] | null, productParameterTypeImages: ProductParameterFormatImage[] | null) {
    let ppImg;
    if (!this.selectedFormat) {
      return '';
    }
    if (productParameterImages) {
      ppImg = productParameterImages.find(img => img.productParameterType.id === productParameterType.id && img.format.id === this.selectedFormat.id);
      if (ppImg) {
        return ppImg.image.default_image_xlarge;
      }
    }
    if (productParameterTypeImages) {
      ppImg = productParameterTypeImages.find(img => img.productParameterType.id === productParameterType.id && img.format.id === this.selectedFormat.id);
      if (ppImg) {
        return ppImg.image.default_image_xlarge;
      }
    }
    return '';
    return productParameterType.image.default_image_xlarge;
  }

  changeProductParameter(control: FormControl, parameter: ProductParameter) {
    const pptc = this.productVariant.productParameterTypeCounts.find(pptc => pptc.productParameterType.productParameter.id === parameter.id);

    if (parameter.isControl) {
      const images = this.parameterTypeFormatImages(control.value);
      if (images.length) {
        const foundFormat = images.find(_format => parameter.formats.find(format => format.id === _format.format.id));
        //this.selectedFormat = images[0].format;
        if (foundFormat?.format) {
          this.selectedFormat = foundFormat?.format;
          this.caption = this.product.productParameters.find(pp => pp.isControl).captions?.find(caption => caption.format.id === this.selectedFormat.id);

          console.log('format changed to ' + this.selectedFormat.name);
          console.log(this.caption);

          console.log(control.value);
          this.captionLength = control.value.captionLength;
          console.log('switch ' + this.captionLength);
        }
      }
      this.productVariant.productParameterTypeCounts.forEach(pptc => {
        if (!pptc.productParameterType.productParameter.isControl) {
          const images = this.productParameterTypeImages.find(ppti => ppti.productParameterType.id === pptc.productParameterType.id)?.images;
          if (!this.hasFormat(pptc.productParameterType, this.product, images ?? [])) {
            const _new = orderByWeight(this.productParameterTypes.find(_item => _item.productParameter.id === pptc.productParameterType.productParameter.id).types, 1, 'createdAt').find(type => this.hasFormat(type, this.product, this.productParameterTypeImages.find(ppti => ppti.productParameterType.id === type.id).images));
            if (_new) {
              //console.log('Switch to ' + pptc.productParameterType.productParameter.displayName + ' - ' + _new.name);
              this.chooseParameter(pptc.productParameterType.productParameter, _new);
            }
          }
        }
      })
    }

    if (pptc) {
      pptc.productParameterType = control.value;
    } else {
      this
        .productVariant
        .productParameterTypeCounts
        .push(
          new

          ProductParameterTypeCount({
            productParameterType: control.value,
            quantity: 0
          })
        );
    }

    this.calculatePrice();
  }

  getControl(parameter: ProductParameter
  ):
    FormControl {
    let ctrl = this.controls.find(_ctrl => _ctrl.productParameter.id === parameter.id);
    if (!ctrl) {
      ctrl = {
        productParameter: parameter,
        control: this.fb.control('')
      }
      if (parameter.isControl && parameter.formats.length) {
        this.selectedFormat = parameter.formats[0];
        this.caption = this.product.productParameters.find(pp => pp.isControl).captions?.find(caption => caption.format.id === this.selectedFormat.id);

      }

      this.controls.push(ctrl);
    }
    return ctrl.control;
  }

  max(number: number, number2: number
  ) {
    return Math.max(number, number2);
  }

  getStock(type?: ProductParameterType) {

    if (this.product.manufacturingCategory.id) {
      return 9999;
    }
    if (!type) {
      type = new ProductParameterType({});
    }
    let bcPpt: ProductParameter[] = [];
    this.barcodes.forEach(bc => {
      bc.productParameterTypes.forEach(ppt => {
        if (!bcPpt.find(_ppt => _ppt.id === ppt.productParameter.id)) {
          bcPpt.push(ppt.productParameter);
        }
      });
    });
    let code = this.barcodes.find(bc => {

      let productParameterTypes = this.productVariant.productParameterTypeCounts.map(tc => tc.productParameterType).filter(ppt => bcPpt.find(_pp => _pp.id === ppt.productParameter.id));
      if (productParameterTypes.length) {
        return bc.productParameterTypes.some(ppt => ppt.id === type.id && bc.stock > 0);
      }

      if (this.product.selectorProductParameter.id && type && type.productParameter.id === this.product.selectorProductParameter.id) {
        if (bc.productParameterTypes.find(ppt => ppt.productParameter.id === this.product.selectorProductParameter.id)) {
          return bc.productParameterTypes.find(ppt => ppt.id === type.id);
        }
      }
      return bc.productParameterTypes.length === 0;
    });

    if (!code) {
      return 0;
    }

    return code.stock;
  }

  hasCurrentStock() {
    return this.productVariant.productParameterTypeCounts.every(ptc => {
      return this.getStock(ptc.productParameterType) > 0;
    }) || this.product.manufacturingCategory.name;
  }

  getItemsPerPage(item: Product, count: number) {
    return count < 2 ? 1 : item.selectorProductParameter.perPage;
  }

  getPositionX(x: number) {
    return x;
  }

  getPositionY(y: number) {
    return y;
  }

  isMobile() {
    return this.breakpointObserver.isMatched(Breakpoints.TabletLandscape) || this.breakpointObserver.isMatched(Breakpoints.Tablet) || this.breakpointObserver.isMatched(Breakpoints.Medium) || this.breakpointObserver.isMatched(Breakpoints.Handset)
  }
}
