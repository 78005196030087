import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoggedInLayoutComponent} from "./logged-in/component";
import {AdminComponent} from "./admin/component";
import {LocalizeRouterModule} from "@penleychan/ngx-transloco-router";
import {AuthGuard} from "../authentication/auth.guard";

const routes: Routes = [
  {
    path:  'admin',
    component:  LoggedInLayoutComponent,
    children: [
      {
        path:  '',
        component: AdminComponent,
        canActivate: [ AuthGuard ]
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes), LocalizeRouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class RoutingModule {}

