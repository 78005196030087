import {Entity} from '../../../components/entity';
import {ProductParameterTypeCount} from '../../product-parameter-type-count/state/model';

export class ProductVariant extends Entity {
  productParameterTypeCounts: ProductParameterTypeCount[];

  constructor({ id, productParameterTypeCounts, createdAt, updatedAt }: Partial<ProductVariant>) {
    super({ id, createdAt, updatedAt });
    this.productParameterTypeCounts = (productParameterTypeCounts ?? []).map(pptc => new ProductParameterTypeCount(pptc ?? {}));
  }

  getNameProperty(): string {
    return '';
  }
}
