import {Injectable} from '@angular/core';
import {CaptionState, Store as CaptionStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Caption} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {CaptionQuery} from './query';
import {Category} from "../../../webshop/category/state/model";
import {Observable} from "rxjs";
import {ProductParameterService} from "../../product-parameter/state/service";
import {ProductParameter} from "../../product-parameter/state/model";

@Injectable({ providedIn: 'root' })
export class CaptionService extends EntityService<CaptionStore, CaptionState> {

  captions$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Caption(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('caption.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productParameterService.productParameters$,
        listed: false,
        multiple: false,
        label: this.translocoService.selectTranslate('caption.productParameter'),
        name: 'productParameter',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productParameterService.productParameters$,
        listed: false,
        multiple: false,
        label: this.translocoService.selectTranslate('caption.productParameter'),
        name: 'format',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('caption.positionX'),
        inputType: 'number',
        name: 'positionX',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('caption.positionY'),
        inputType: 'number',
        name: 'positionY',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('caption.fontSize'),
        inputType: 'number',
        name: 'fontSize',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        label: this.translocoService.selectTranslate('caption.orientation'),
        type: 'select',
        options: [
          {
            name$: this.translocoService.selectTranslate('Vízszintes'),
            value: 1
          },
          {
            name$: this.translocoService.selectTranslate('Függőleges'),
            value: 2
          },
        ],
        name: 'orientation',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: CaptionQuery,
    private productParameterService: ProductParameterService,
    protected store: CaptionStore) {
    super(store, Caption);
  }

  get resourceName(): string {
    return 'products/caption';
  }


  getByProductParameter(productParameter: ProductParameter): Observable<Caption[]> {
    return this.getHttp().get<Caption[]>(this.api + '?q[]=productParameter:eq:' + productParameter.id)
      .pipe(
        map(items => {
          return items.map(item => new this._constructor(item) as Caption);
        }),
        take(1),
        shareReplay(1)
      );
  }
}
