import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {BlogCategory} from './model';

export interface BlogCategoryState extends EntityState<BlogCategory> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'BlogCategory' })
export class Store extends EntityStore<BlogCategoryState> {

  constructor() {
    super();
  }

}
