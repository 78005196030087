import {Entity, Image} from '../../../components/entity';

export class Reseller extends Entity {
  isPrior: boolean;
  isLocal: boolean;
  url: string;
  image: Image;
  logo: Image;
  title: string;
  description: string;
  slug: string;
  weight: number;

  constructor({
                id,
                title,
                isLocal,
                isPrior,
                slug,
                url,
                description,
                image,
                logo,
                weight,
                createdAt, updatedAt
              }: Partial<Reseller>) {
    super({id, createdAt, updatedAt});
    this.isPrior = isPrior ?? false;
    this.isLocal = isLocal ?? false;
    this.description = description ?? '';
    this.url = url ?? '';
    this.slug = slug ?? '';
    this.title = title ?? '';
    this.weight = weight ?? null;

    this.image = new Image(image ?? {});
    this.logo = new Image(logo ?? {});
  }

  getNameProperty(): string {
    return 'title';
  }
}
