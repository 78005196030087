import {Component, Injector} from '@angular/core';
import {PaymentModeState, Store as PaymentModeStore} from './state/store';
import {PaymentMode} from './state/model';
import {PaymentModeService as PaymentModeService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {PaymentModeQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class PaymentModeDetailComponent extends EntityDetailComponent<PaymentMode, PaymentModeState, PaymentModeStore> {

  constructor(
    public injector: Injector,
    service: PaymentModeService,
    protected query: PaymentModeQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
