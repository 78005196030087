<div class="off-canvas position-top l-offContentBackground"
     [ngClass]="{'is-transition-push': loginPanelOpen, 'is-open': loginPanelOpen}"
     id="offCanvasTop" data-off-canvas
     data-auto-focus="false">
  <app-login-panel (panelEmitter)="toggleLoginPanel()"></app-login-panel>
</div>

<div class="off-canvas-content" data-off-canvas-content
     [ngClass]="{'is-open-top': loginPanelOpen, 'has-transition-push': loginPanelOpen, 'has-position-top': loginPanelOpen}">
  <app-webshop-header (menuToggle)="menu.toggle()" (searchToggle)="search.toggle()" (loginToggle)="toggleLoginPanel()"></app-webshop-header>
  <router-outlet></router-outlet>
  <app-webshop-footer></app-webshop-footer>
</div>
<re-captcha size="invisible"></re-captcha>
<app-webshop-menu></app-webshop-menu>
<app-webshop-search></app-webshop-search>
<app-webshop-popup></app-webshop-popup>

<div class="loaderAnim" *ngIf="loading$ | async">
  <div>
    <img src="/assets/images/loader_anim.gif" alt="töltés...">
  </div>
</div>
