import {Component, Injector} from '@angular/core';
import {PressNewsState, Store} from './state/store';
import {PressNews} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {PressNewsQuery} from './state/query';
import {PressNewsService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class PressNewsListComponent extends EntityListComponent<PressNews, PressNewsState, Store> {

  translationDomain = 'pressNews';

  constructor(
    public injector: Injector,
    protected service: PressNewsService,
    query: PressNewsQuery,
    private PressNewsFilterService: FilterService) {
    super(injector, service, query, PressNewsFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
