import {Component, OnDestroy, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Entity} from '../../entity';
import {merge, Observable, of, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-entity-select',
  template: `
    <mat-form-field *ngIf="field.name" [formGroup]="group">
      <mat-select [multiple]="field.multiple" [placeholder]="field.label | async | default: ''"
                  [formControlName]="field.name"
                  [compareWith]="compare">
        <mat-option *ngIf="!field.multiple" [value]="null">{{ 'form.selectNone' | transloco }}</mat-option>
        <mat-option *ngFor="let item of (getOptions$() | async)" [value]="item">{{ item }}</mat-option>
      </mat-select>
      <ng-container ngProjectAs="mat-error">
        <mat-error
          *ngIf="group?.get(field.name)?.hasError('custom')">{{group?.get(field.name)?.getError('custom').message}}</mat-error>
      </ng-container>
    </mat-form-field>`,
  styleUrls: ['common.scss'],
})
export class EntitySelectComponent implements OnDestroy {

  constructor(
    public dialog: MatDialog
  ) {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }
  field: FieldConfig;
  group: FormGroup;
  parentEntity$ = new Observable<Entity>();
  subscriptions: Subscription[] = [];
  options$: Observable<Entity[]> | null = null;
  @ViewChild(MatSelect) element: MatSelect | unknown;

  public static compareFn(v1: any | null, v2: any | null): boolean {
    if (v1 === null || v2 === null) {
      return false;
    }

    if (v1.hasOwnProperty('id') && v2.hasOwnProperty('id')) {
      return v1.id === v2.id;
    }

    if (v1.hasOwnProperty('id') && v2.toString()) {
      return v1.id === v2.toString();
    }

    if (v2.hasOwnProperty('id') && v1.toString()) {
      return v2.id === v1.toString();
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  getOptions$() {
    if (!this.options$) {
      if (this.field.entityConditionalOptions) {
        const callable = this.field.entityConditionalOptions as (form?: (FormGroup | undefined)) => Observable<Entity[]>;
        const group = this.group.parent ?? this.group;
        this.options$ = merge(group.valueChanges, of([])).pipe(switchMap(() => {
          return callable(this.group).pipe(
            tap(items => {
              if (this.group.get(this.field.name)?.value && this.group.get(this.field.name)?.value.hasOwnProperty('id') && this.group.get(this.field.name)?.value.id) {
                if (!items.find(_item => _item.id === this.group.get(this.field.name)?.value.id)) {
                  this.group.get(this.field.name)?.setValue({id: ''});
                }
              }
            }));
        }));
      } else {
        this.options$ = this.field.entityOptions as Observable<Entity[]>;
      }
    }

    return this.options$;
  }

  public compare(v1: any | null, v2: any | null): boolean {
    return EntitySelectComponent.compareFn(v1, v2);
  }
}
