import {Component, Injector} from '@angular/core';
import {BlogCategoryState, Store} from './state/store';
import {BlogCategory} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {BlogCategoryQuery} from './state/query';
import {BlogCategoryService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class BlogCategoryListComponent extends EntityListComponent<BlogCategory, BlogCategoryState, Store> {

  translationDomain = 'blogCategory';

  constructor(
    public injector: Injector,
    protected service: BlogCategoryService,
    query: BlogCategoryQuery,
    private BlogCategoryFilterService: FilterService) {
    super(injector, service, query, BlogCategoryFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
