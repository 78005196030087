import {Injectable} from '@angular/core';
import {PressDownloadState, Store as PressDownloadStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {PressDownload} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {PressDownloadQuery} from './query';
import {FormService} from "../../../components/service/form.service";

@Injectable({ providedIn: 'root' })
export class PressDownloadService extends EntityService<PressDownloadStore, PressDownloadState> {

  items$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new PressDownload(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('pressDownload.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('pressDownload.url'),
        inputType: 'text',
        name: 'url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('pressDownload.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        label: this.translocoService.selectTranslate('pressDownload.image'),
        name: 'image1',
        listed: false,
        validations: this.formService.getValidations(['requiredImage'])
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: PressDownloadQuery,
    private formService: FormService,
    protected store: PressDownloadStore) {
    super(store, PressDownload);
  }

  get resourceName(): string {
    return 'content/press-download';
  }

}
