import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoutingModule} from './routing';
import {MaterialModule} from '../material/material.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {Module as ComponentsModule} from '../components/module';
import {BlogListComponent} from './blog/list';
import {BlogViewComponent} from './blog/view';
import {NewsListComponent} from './news/list';
import {NewsDetailComponent} from './news/detail';
import {NewsletterListComponent} from './newsletter/list';
import {NewsletterDetailComponent} from './newsletter/detail';
import {PageListComponent} from './page/list';
import {PageDetailComponent} from './page/detail';
import {PressDetailComponent} from './press/detail';
import {BlogDetailComponent} from "./blog/detail";
import {PageViewComponent} from "./page/view";
import {NewsletterViewComponent} from "./newsletter/view";
import {PressViewComponent} from "./press/view";
import {BlogCategoryListComponent} from "./blog-category/list";
import {BlogCategoryDetailComponent} from "./blog-category/detail";
import {BlogCategoryViewComponent} from "./blog-category/view";
import {WallpaperDetailComponent} from "./wallpaper/detail";
import {WallpaperListComponent} from "./wallpaper/list";
import {PopupListComponent} from "./popup/list";
import {PopupDetailComponent} from "./popup/detail";
import {TranslocoModule} from "@ngneat/transloco";
import {PressDownloadListComponent} from "./press-download/list";
import {PressDownloadDetailComponent} from "./press-download/detail";
import {PressVideoListComponent} from "./press-video/list";
import {PressVideoDetailComponent} from "./press-video/detail";
import {PressNewsListComponent} from "./press-news/list";
import {PressNewsDetailComponent} from "./press-news/detail";
import {GalleryListComponent} from "./gallery/list";
import {GalleryDetailComponent} from "./gallery/detail";
import {TagListComponent} from "../webshop/tag/list";
import {TagDetailComponent} from "../webshop/tag/detail";
import {PressUserListComponent} from "./press-user/list";
import {PressUserDetailComponent} from "./press-user/detail";

@NgModule({
  declarations: [
    BlogListComponent,
    BlogDetailComponent,
    BlogViewComponent,
    BlogCategoryListComponent,
    BlogCategoryDetailComponent,
    BlogCategoryViewComponent,
    NewsListComponent,
    NewsDetailComponent,
    NewsletterListComponent,
    NewsletterDetailComponent,
    NewsletterViewComponent,
    PageListComponent,
    PageDetailComponent,
    PageViewComponent,
    PressDetailComponent,
    PressViewComponent,
    WallpaperDetailComponent,
    WallpaperListComponent,
    PopupListComponent,
    PopupDetailComponent,
    PressDownloadListComponent,
    PressDownloadDetailComponent,
    PressVideoListComponent,
    PressVideoDetailComponent,
    PressNewsListComponent,
    PressNewsDetailComponent,
    GalleryListComponent,
    GalleryDetailComponent,
    PressUserListComponent,
    PressUserDetailComponent
  ],
  exports: [],
  imports: [
    ComponentsModule,
    CommonModule,
    MaterialModule,
    RoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslocoModule,
  ],
})
export class ContentModule {
}
