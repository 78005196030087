<section class="l-siteContent" id="theTop" *ngIf="item$ | async as item">
  <section>
    <div class="row">
      <div class="small-12 column">
        <header class="l-headWithSubhead">
          <h1>{{ item.title }}</h1>
          <h2>{{ item.subtitle }}</h2>
          <img src="/assets/images/menu_heading_decor.svg" alt="decor">
        </header>
        <article [innerHTML]="item.content | safeHtml"></article>
        <hr class="l-divider l-divider--flexCard">
      </div>
    </div>
  </section>
</section>
