<section class="l-siteContent" id="theTop" *ngIf="(item$ | async) as item">
  <section class="product">
    <div class="row">
      <div class="small-12 large-6 column large-order-2">
        <figure class="text-center">
          <img class="anim anim--fromRight anim--relative" [src]="item.image1.product_image_thumb" [alt]="item.name"
               data-animation-in="slide-in-right ease">
        </figure>
      </div>
      <div class="small-12 large-6 column large-order-1">
        <div class="g-top-thick g-top-medium-down">
          <header class="l-headWithSubhead">
            <h1>{{ item.name }}</h1>
            <!--h2>{{ item.subtitle }}</h2-->
            <img src="/assets/images/menu_heading_decor.svg" alt="decor">
          </header>
          <article [innerHTML]="item.description">
          </article>
          <footer *ngIf="(cart$ | async) as cart">
            <div class="product-price">
              <ng-container
                *ngIf="item.multipart && !item.selectorProductParameter.id">{{ 'webshop.Indulóár' | transloco }}:
              </ng-container>
              <ng-container *ngIf="item.selectorProductParameter.id">{{ 'webshop.Darabár' | transloco }}:</ng-container>
              <ng-container
                *ngIf="!item.multipart && !item.selectorProductParameter.id">{{ 'webshop.Darabár' | transloco }}:
              </ng-container>
              <span>{{ item.price | webshopCurrency: '' : 'symbol' : '0.0-2' | async }}</span>
              <hr class="l-divider l-divider--flexCard" *ngIf="!item.multipart">
            </div>
            <a class="btn btn--basic" (click)="addToCart(item)"
               *ngIf="!item.multipart && getStock() > 0">{{ 'webshop.Kosárba' | transloco }}</a>
            <a class="btn btn--basic"
               *ngIf="!item.multipart && getStock() <= 0">{{ 'webshop.Elfogyott' | transloco }}</a>

          </footer>
        </div>
      </div>
    </div>
  </section>
  <div class="row l-dividerDecorative" *ngIf="item.multipart">
    <div class="column"></div>
    <div class="shrink column"></div>
    <div class="column"></div>
  </div>
  <section *ngIf="item.multipart">
    <div class="row align-center productAssembler">
      <div class="small-12 large-6 column large-order-2">
        <h2 id="selector-position" class="l-headDecor g-bottom"><img src="/assets/images/bomo_hand_gray_mono.svg"
                                              alt="decor">
          <ng-container *ngIf="item.selectorProductParameter.id && hasNonSelectorProductParameter(item)">
            {{ 'webshop.Tervezd és töltsd meg a dobozodat!' | transloco }}

          </ng-container>
          <ng-container *ngIf="item.selectorProductParameter.id && !hasNonSelectorProductParameter(item)">
            {{ 'webshop.Válogass a designok között!' | transloco }}

          </ng-container>
          <ng-container *ngIf="!item.selectorProductParameter.id && hasNonSelectorProductParameter(item)">
            {{ 'webshop.Válassz a lehetőségek közül!' | transloco }}

          </ng-container>
        </h2>
        <div class="form form--greyInput form--productIngredients">
          <div data-abide-error class="alert callout h-hidden">
            <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
          </div>
          <ng-container
            *ngFor="let parameter of item.productParameters | orderByWeight: 'createdAt' | orderBy: '-isControl'">
            <ng-container *ngIf="parameterTypes(parameter) as parameterTypes">
              <div
                *ngIf="parameterTypes.length > 0 && parameter.id !== item.selectorProductParameter.id">
                <div class="form-row" *ngIf="getControl(parameter) as control">
                  <label>{{ parameter.displayName }}
                    <select [attr.id]="'selector' + parameter.id" [formControl]="control"
                            (change)="changeProductParameter(control, parameter)"
                            [compareWith]="formService.compareById">
                      <ng-container
                        *ngFor="let type of parameterTypes | filter: { available: true } | orderByWeight: 'createdAt'">
                        <option
                          *ngIf="hasFormat(type,item,parameterTypeFormatImages(type)) || parameter.isControl"
                          [ngValue]="type" [disabled]="getStock(type) <= 0">{{ type.name }}
                        </option>
                      </ng-container>
                    </select>
                  </label>
                  <button *ngIf="parameter.tagTitle"
                          class="productIngredients-cover"
                          type="button" (click)="openProductIngredients(productIngredientsModal, parameter)">
                    <span>{{ parameter.tagTitle }}</span></button>
                </div>
                <div class="form-row form-row--helpBtn"
                     *ngIf="(parameter.size.name != 'Nincs' && parameter.size.name)  || (parameter.inner.name != 'Nincs' && parameter.inner.name)">
                  <div class="row">
                    <div class="small-6 column" *ngIf="parameter.size.name != 'Nincs' && parameter.size.name">
                      <button class="productIngredients-helpSize" type="button"
                              (click)="openSize(productIngredientsSizes, parameter.size)">
                        <span>{{ 'webshop.Méretek' | transloco }}</span></button>
                    </div>
                    <div class="small-6 column" *ngIf="parameter.inner.name != 'Nincs' && parameter.inner.name">
                      <button class="productIngredients-helpInner" type="button"
                              (click)="openInner(productIngredientsInnerModal, parameter)">
                        <span>{{ 'webshop.Belsők' | transloco }}</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <!-- popup ingredients -->
          <ng-template #productIngredientsModal>
            <div data-full-screen class="product-modal"
                 data-reveal data-close-on-click="true" data-animation-in="slide-in-down"
                 data-animation-out="slide-out-up">
              <button class="product-modal-close" data-close aria-label="Bezár" (click)="closeReveal()" type="button">
                {{ 'webshop.Bezár' | transloco }}
              </button>
              <div class="ajaxZone">
                <div class="ajaxZone-content">
                  <header class="sectionHeader">
                    <h2><img src="/assets/images/heading_divider_left.svg"
                             alt="decor">{{ 'webshop.Válaszd ki a megfelelőt' | transloco }}<img
                      src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
                  </header>
                  <div class="g-top-extrathick">
                    <div class="row collapse small-up-1 medium-up-2 xlarge-up-3">
                      <ng-container
                        *ngFor="let type of (parameterTypes(activeProductParameter)) | filter: { available: true }">
                        <div class="column"
                             *ngIf="hasFormat(type,item,parameterTypeFormatImages(type)) || activeProductParameter.isControl">
                          <div class="ingredientPreview" data-close
                               (click)="chooseParameter(activeProductParameter, type); closeReveal()">
                            <div class="decorBorder decorBorder--hover">
                              <div>
                                <div>
                                  <!-- image width: 480px -->
                                  <img [src]="type.image.original" alt="összetevő neve">
                                </div>
                              </div>
                            </div>
                            <div class="ingredientPreview-title">
                              {{ type.name }} </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- popup belsők -->
          <ng-template #productIngredientsInnerModal>
            <div data-full-screen class="product-modal" id="productIngredientsInnerModal" data-reveal
                 data-close-on-click="true" data-animation-in="slide-in-down" data-animation-out="slide-out-up">
              <button class="product-modal-close" data-close aria-label="Bezár" type="button" (click)="closeReveal()">
                {{ 'webshop.Bezár' | transloco }}
              </button>
              <div class="ajaxZone" [id]="'productIngredientsInnerContent'">
                <div class="ajaxZone-content">
                  <header class="sectionHeader">
                    <h2><img src="/assets/images/heading_divider_left.svg"
                             alt="decor">{{ 'webshop.Válaszható napló belsők' | transloco }}<img
                      src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
                  </header>
                  <div class="g-top-extrathick">
                    <div class="l-helpSectionHead"
                         *ngIf="activeProductParameterInner.name == 'Naplók - fél és teljes bőrkötéssel'">
                      <h1>{{ 'webshop.Naplók' | transloco }}</h1>
                      <div>{{ 'webshop.(Fél- és teljes bőrkötéssel)' | transloco }}</div>
                    </div>
                    <ul class="list" *ngIf="activeProductParameterInner.name == 'Naplók - fél és teljes bőrkötéssel'">
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Sima lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.vízjeles papírral' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust rendelheted B8, négyzetes 15 cm-es és' | transloco }}<br>{{ 'webshop.22 cm-es, B7, A5, B5' | transloco }}<br>{{ 'webshop.és A4 méretben.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_hlb_and_flb_inside_pages_plain1.jpg" alt="Sima lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_hlb_and_flb_inside_pages_plain2.jpg" alt="Sima lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Vonalas lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.finom szürke vonalazással' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust rendelheted' | transloco }}<br>{{ 'webshop.B7 és frncia (13x22 cm) méretben.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_hlb_and_flb_inside_pages_lined1.jpg" alt="Vonalas lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_hlb_and_flb_inside_pages_lined2.jpg" alt="Vonalas lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Ponthálós lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.finom, szürke,' | transloco }}<br>{{ 'webshop.5mm-es pontráccsal' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust kizárólag' | transloco }}<br>{{ 'webshop.A5-ös méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_hlb_and_flb_inside_pages_dot-grid1.jpg" alt="Ponthálós lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_hlb_and_flb_inside_pages_dot-grid2.jpg" alt="Ponthálós lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="l-helpSectionHead"
                         *ngIf="activeProductParameterInner.name == 'Naplók - fél és teljes bőrkötéssel (átkötős)'">
                      <h1>{{ 'webshop.Naplók' | transloco }}</h1>
                      <div>{{ 'webshop.(Teljes bőrkötéssel, átkötős változatban)' | transloco }}</div>
                    </div>
                    <ul class="list"
                        *ngIf="activeProductParameterInner.name == 'Naplók - fél és teljes bőrkötéssel (átkötős)'">
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Sima lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.vízjeles papírral' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust rendelheted B8, négyzetes 15 cm-es' | transloco }}<br>{{ 'webshop.B7, A5, B5' | transloco }}<br>{{ 'webshop.és A4 méretben.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_flb_with_tie_inside_pages_plain1.jpg" alt="Sima lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_flb_with_tie_inside_pages_plain2.jpg" alt="Sima lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Vonalas lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.finom szürke vonalazással' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust rendelheted' | transloco }}<br>{{ 'webshop.B7 és francia (13x22 cm) méretben.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_flb_with_tie_inside_pages_lined1.jpg" alt="Vonalas lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_flb_with_tie_inside_pages_lined2.jpg" alt="Vonalas lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Ponthálós lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.finom, szürke,' | transloco }}<br>{{ 'webshop.5mm-es pontráccsal' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust kizárólag' | transloco }}<br>{{ 'webshop.A5-ös méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_flb_with_tie_inside_pages_dot-grid1.jpg" alt="Ponthálós lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/journal_flb_with_tie_inside_pages_dot-grid2.jpg" alt="Ponthálós lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="l-helpSectionHead"
                         *ngIf="activeProductParameterInner.name == 'Naplók - vászon kötéssel'">
                      <h1>{{ 'webshop.Naplók' | transloco }}</h1>
                      <div>{{ 'webshop.(Vászon kötéssel)' | transloco }}</div>
                    </div>
                    <ul class="list" *ngIf="activeProductParameterInner.name == 'Naplók - vászon kötéssel'">
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Sima lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.vízjeles papírral' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust kizárólag B7-es méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/img_help_inner_0101.jpg" alt="Sima lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/img_help_inner_0102.jpg" alt="Sima lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Vonalas lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.finom szürke vonalazással' | transloco }}<br>{{ 'webshop.textilkönyvjelővel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust kizárólag' | transloco }}<br>{{ 'webshop.francia (13x22 cm) méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/img_help_inner_0201.jpg" alt="Vonalas lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/img_help_inner_0202.jpg" alt="Vonalas lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="l-helpSectionHead" *ngIf="activeProductParameterInner.name == 'Határidőnaplók'">
                      <h1>{{ 'webshop.Határidőnaplók' | transloco }}</h1>
                      <div>{{ 'webshop.(Fél- és teljes bőrkötéssel)' | transloco }}</div>
                    </div>
                    <ul class="list" *ngIf="activeProductParameterInner.name == 'Határidőnaplók'">
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Heti beosztás' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.sárgás színű lapokkal' | transloco }}<br>{{ 'webshop.szürke és vörös nyomással' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}<br>{{ 'webshop.7 világnyelven' | transloco }}<br><span class="text-highlight">{{ 'webshop.Fontos! Nem tartalmaz magyar nyelven információt' | transloco }}</span>
                                </p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust rendelheted A8, B7, A5, B5 és A4 méretben.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/diary_hlb_and_flb_inside_pages_weekly1.jpg" alt="Heti beosztás">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/diary_hlb_and_flb_inside_pages_weekly2.jpg" alt="Heti beosztás">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Napi beosztás' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.sárgás színű lapokkal' | transloco }}<br>{{ 'webshop.szürke és vörös nyomással' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}<br>{{ 'webshop.7 világnyelven' | transloco }}<br><span class="text-highlight">{{ 'webshop.Fontos! Nem tartalmaz magyar nyelven információt' | transloco }}</span>
                                </p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust rendelheted B8, B7, A5, B5 méretben.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/diary_hlb_and_flb_inside_pages_daily1.jpg" alt="Napi beosztás">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/diary_hlb_and_flb_inside_pages_daily2.jpg" alt="Napi beosztás">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!--li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  Heti beosztás magyar névnapokkal
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>90 gr/m2<br>törtfehér színű lapokkal<br>szürke és vörös nyomással<br>textil
                                  könyvjelővel<br>4 nyelven<br>tartalmazza a magyar névnapokat és nemzeti
                                  ünnepeket<br><span
                                    class="text-highlight">Fontos! Ez a típus 2010-re nem rendelhető</span></p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>Ezt a típust rendelheted B8, B7, A5, B5 méretben.</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/img_help_inner_0101.jpg"
                                       alt="Heti beosztás magyar névnapokkal">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/img_help_inner_0102.jpg"
                                       alt="Heti beosztás magyar névnapokkal">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li-->
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Heti beosztás jegyzetelővel és magyar névnapokkal' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.szürke és vörös nyomással' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}<br>{{ 'webshop.4 nyelven' | transloco }}<br>{{ 'webshop.tartalmazza a magyar névnapokat és nemzeti ünnepeket' | transloco}}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust kizárólag "Bomo" (12,5x20,5 cm) méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/diary_hlb_and_flb_inside_pages_weekly_notes_with_hungarian_name-days1.jpg"
                                       alt="Heti beosztás jegyzetelővel és magyar névnapokkal">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/diary_hlb_and_flb_inside_pages_weekly_notes_with_hungarian_name-days2.jpg"
                                       alt="Heti beosztás jegyzetelővel és magyar névnapokkal">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>


                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Öröknaptár' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.szürke és vörös nyomással' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}<br>{{ 'webshop.4 nyelven' | transloco }}<br>{{ 'webshop.oldalpáronként 4 napos beosztással' | transloco}}<br>{{ 'webshop.különleges, extra tartalommal' | transloco}}<br>{{ 'webshop.tartalmazza a magyar névnapokat és nemzeti ünnepeket' | transloco}}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a típust kizárólag B7-es (12 x 17,5 cm) méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/diary_hlb_and_flb_inside_pages_perpetual1.jpg"
                                       alt="Heti beosztás jegyzetelővel és magyar névnapokkal">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/diary_hlb_and_flb_inside_pages_perpetual2.jpg"
                                       alt="Heti beosztás jegyzetelővel és magyar névnapokkal">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="l-helpSectionHead" *ngIf="activeProductParameterInner.name == 'Fotóalbumok'">
                      <h1>{{ 'webshop.Fotóalbumok' | transloco }}</h1>
                      <div>{{ 'webshop.(Fél- és teljes bőrkötéssel)' | transloco }}</div>
                    </div>
                    <ul class="list" *ngIf="activeProductParameterInner.name == 'Fotóalbumok'">
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Fekete lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.230 gr/m2' | transloco }}<br>{{ 'webshop.savmentes fekete karton lapokkal' | transloco }}<br>{{ 'webshop.pergamen elválasztó lapokkal' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a belsőt rendelheted A5 60 oldalas, A4 álló 60 és 100 oldalas, A4 fekvő 60 és 100 oldalas és négyzetes 30 cm-es 60 és 100 oldalas méretben.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/photo_album_with_black_pages1.jpg" alt="Fekete lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/photo_album_with_black_pages2.jpg" alt="Fekete lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Pezsgő színű lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.230 gr/m2' | transloco }}<br>{{ 'webshop.savmentes pezsgő színű karton lapokkal' | transloco }}<br>{{ 'webshop.pergamen elválasztó lapokkal' | transloco }}
                                </p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a belsőt rendelheted A5 60 és 100 oldalas, A4 álló 60 és 100 oldalas, A4 fekvő 60 és 100 oldalas és négyzetes 30 cm-es 60 és 100 oldalas méretben.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/photo_album_with_ivory_pages1.jpg" alt="Pezsgő színű lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/photo_album_with_ivory_pages2.jpg" alt="Pezsgő színű lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="l-helpSectionHead" *ngIf="activeProductParameterInner.name == 'Receptkönyv'">
                      <h1>{{ 'webshop.Receptkönyv' | transloco }}</h1>
                    </div>
                    <ul class="list" *ngIf="activeProductParameterInner.name == 'Receptkönyv'">
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Fűzött' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.barna színű nyomással' | transloco }}<br>{{ 'webshop.laminált regiszter fülekkel' | transloco }}<br>{{ 'webshop.textil könyvjelővel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a belsőt kizárólag B5-ös (17x25 cm) méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/recipe_book_hlb_inside_pages_stiched1.jpg" alt="Fűzött">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/recipe_book_hlb_inside_pages_stiched2.jpg" alt="Fűzött">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Gyűrűs' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.90 gr/m2' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.szürke színű nyomással' | transloco }}<br>{{ 'webshop.laminált regiszter fülekkel' | transloco }}<br>{{ 'webshop.4 gyűrűvel' | transloco }}<br>{{ 'webshop.további betétlapokkal bővíthető' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a belsőt kizárólag 19x23 cm-es méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/recipe_book_hlb_inside_pages_with_ring1.jpg" alt="Gyűrűs">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/recipe_book_hlb_inside_pages_with_ring2.jpg" alt="Gyűrűs">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="l-helpSectionHead" *ngIf="activeProductParameterInner.name == 'BOMO memo'">
                      <h1>{{ 'webshop.Bomo memo' | transloco }}</h1>
                    </div>
                    <ul class="list" *ngIf="activeProductParameterInner.name == 'BOMO memo'">
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Sima - vonalas - ponthálós lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.3db-os csomagolás' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.irkafűzött' | transloco }}<br>{{ 'webshop.szürke és bordó nyomással' | transloco }}<br>{{ 'webshop.színes előzékkel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a belsőt kizárólag A5-ös méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/bomo_memo_a5_inside_balloons_art_deco_cas1.jpg"
                                       alt="Sima - vonalas - ponthálós lapok">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/bomo_memo_a5_inside_balloons_art_eco_cas2.jpg"
                                       alt="Sima - vonalas - ponthálós lapok">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Sima - vonalas - kockás lapok' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.3db-os csomagolás' | transloco }}<br>{{ 'webshop.törtfehér színű lapokkal' | transloco }}<br>{{ 'webshop.irkafűzött' | transloco }}<br>{{ 'webshop.szürke és bordó nyomással' | transloco }}<br>{{ 'webshop.színes előzékkel' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a belsőt kizárólag A8-as méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/bomo_memo_a8_inside1.jpg"
                                       alt="Sima - vonalas - kockás">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/bomo_memo_a8_inside2.jpg"
                                       alt="Sima - vonalas - kockás">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="l-helpSectionHead" *ngIf="activeProductParameterInner.name == 'Iratrendező'">
                      <h1>{{ 'webshop.Iratrendező' | transloco }}</h1>
                    </div>
                    <ul class="list" *ngIf="activeProductParameterInner.name == 'Iratrendező'">
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Nyitott' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.9 fakkos' | transloco }}<br>{{ 'webshop.feliratozható elválasztókkal' | transloco }}<br>{{ 'webshop.teljes méretre nyitható' | transloco }}<br>{{ 'webshop.zsinórral és gombbal zárható' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a belsőt kizárólag 16,5x29 cm-es méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/binder_open1.jpg" alt="Nyitott">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/binder_open2.jpg" alt="Nyitott">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>

                        <div class="helpInnerPreview">
                          <div class="row">
                            <div class="small-12 medium-6 xlarge-2 column">
                              <div class="helpInnerPreview-text">
                                <div class="helpInnerPreview-title">
                                  {{ 'webshop.Zárt' | transloco }}
                                </div>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.9 fakkos' | transloco }}<br>{{ 'webshop.feliratozható elválasztókkal' | transloco }}<br>{{ 'webshop.legyező formájú szerkezet' | transloco }}<br>{{ 'webshop.zsinórral és gombbal zárható' | transloco }}</p>
                                <div class="helpInnerPreview-divider"></div>
                                <p>{{ 'webshop.Ezt a belsőt kizárólag 16,5x29 cm-es méretben rendelheted.' | transloco }}</p>
                              </div>
                            </div>
                            <div class="small-12 medium-6 xlarge-10 column">
                              <div class="row">
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/binder_close1.jpg" alt="Zárt">
                                </div>
                                <div class="small-12 xlarge-6 column">
                                  <img src="/assets/images/inner_help/binder_close2.jpg" alt="Zárt">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template #productSelectModal>
            <div data-full-screen class="product-modal--noGutter" id="productSelectModal" data-reveal
                 data-close-on-click="true" data-animation-in="slide-in-down" data-animation-out="slide-out-up">
              <button class="product-modal-close" data-close="" aria-label="Bezár" (click)="closeReveal()"
                      type="button">{{ 'webshop.Bezár' | transloco }}
              </button>
              <div class="ajaxZone" id="productSelect">
                <div class="ajaxZone-content">
                  <div class="productSelect">
                    <div class="grid-y grid-frame">
                      <div class="cell shrink productSelect-header">
                        <div class="column">
                          <button class="productSelect-head"
                                  [ngClass]="{'productSelect-head--selectable': activeTab === 'selectable', 'productSelect-head--active': activeTab === 'selectable'}"
                                  type="button" (click)="activeTab = 'selectable'">
                            <div class="productSelect-head-title"><img
                              src="/assets/images/heading_divider_left.svg"
                              alt="decor"><span>{{ 'webshop.Választható minták' | transloco }}</span><img
                              src="/assets/images/heading_divider_right.svg" alt="decor"></div>
                            <span>{{(parameterTypes(item.selectorProductParameter)).length}} {{ 'webshop.darab' | transloco }}</span>
                          </button>
                        </div>
                        <div class="column">
                          <button class="productSelect-head productSelect-head--selected"
                                  [ngClass]="{'productSelect-head--selectable': activeTab === 'selected', 'productSelect-head--active': activeTab === 'selected'}"
                                  type="button" (click)="activeTab = 'selected'">
                            <div class="productSelect-head-title"><img
                              src="/assets/images/heading_divider_left.svg"
                              alt="decor"><span>{{ 'webshop.Kiválasztott minták' | transloco }}</span><img
                              src="/assets/images/heading_divider_right.svg" alt="decor"></div>
                          </button>
                        </div>
                      </div>
                      <div class="cell auto">
                        <div class="productSelect-products">
                          <div class="productSelect-content productSelect-content--selectable"
                               [ngClass]="{'show-for-medium': activeTab !== 'selectable'}">
                            <div
                              *ngFor="let n of [].constructor(ceil((parameterTypes(item.selectorProductParameter)).length / (getItemsPerPage(item, parameterTypes(item.selectorProductParameter).length)))); index as i"
                              class="blockList grid-padding-x grid-padding-y grid-x xxlarge-up-{{ getItemsPerPage(item, parameterTypes(item.selectorProductParameter).length) }}">
                              <div class="cell" [ngClass]="{disabled: getStock(parameterType) <= 0}"
                                   *ngFor="let parameterType of getPage(parameterTypes(item.selectorProductParameter), i, (getItemsPerPage(item, parameterTypes(item.selectorProductParameter).length)))">
                                <a class="productSelectItem"
                                   (click)="incrementSelected(parameterType, item.setCount)">
                                  <div class="decorBorder decorBorder--hover">
                                    <div>
                                      <div>
                                        <!-- image width: small: 150px, large: 250px -->
                                        <img [src]="parameterType.image.original"
                                             alt="összetevő neve">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="productSelectItem-title">
                                    {{ parameterType.name }}
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="productSelect-content productSelect-content--selected"
                               [ngClass]="{'show-for-medium': activeTab !== 'selected'}">
                            <div
                              class="blockList grid-padding-x grid-padding-y grid-x small-up-1 medium-up-2 xxlarge-up-3">
                              <ng-container
                                *ngFor="let productParameterCount of productVariant.productParameterTypeCounts">
                                <div class="cell"
                                     *ngIf="productParameterCount.productParameterType.productParameter.id === product.selectorProductParameter.id">
                                  <div class="productSelectedItem row">
                                    <div class="column">
                                      <!-- use images from productSelectItem source -->
                                      <img [src]="productParameterCount.productParameterType.image.original"
                                           alt="összetevő neve">

                                    </div>
                                    <div class="column align-self-bottom">
                                      <div class="productSelectedItem-piece"><input
                                        type="text"
                                        name=""
                                        [value]="productParameterCount.quantity">
                                      </div>
                                      <div class="productSelectedItem-title">{{
                                        productParameterCount.productParameterType.name }}
                                      </div>
                                      <div class="g-top-extrathin">
                                        <button class="productSelectedItem-quantity productSelectedItem-quantity--dec"
                                                type="button" aria-label="Minus"
                                                (click)="decrementSelected(productParameterCount.productParameterType)"></button>
                                        <button *ngIf="getStock(productParameterCount.productParameterType) > 0"
                                                class="productSelectedItem-quantity productSelectedItem-quantity--inc"
                                                type="button" aria-label="Plus"
                                                (click)="incrementSelected(productParameterCount.productParameterType, item.setCount)"></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cell shrink productSelect-footer" *ngIf="item.setCount">
                        <div>{{'Az összeállított termék ára' | transloco}}:
                          <span>{{ price | webshopCurrency: '' : 'symbol' : '0.0-2' | async }}</span>
                        </div>
                        <div><span>{{ getSelectedCount() }}./{{ item.setCount }}.</span>
                          – {{ 'webshop.még' | transloco }} {{
                          item.setCount - getSelectedCount() }} {{ 'webshop.termék hiányzik' | transloco }}
                        </div>
                        <div>
                          <button class="btn btn--basic" type="button"
                                  (click)="closeReveal()">{{ 'webshop.Ok' | transloco }}</button>
                        </div>
                      </div>
                      <div class="cell shrink productSelect-footer" *ngIf="!item.setCount">
                        <div>{{'Az összeállított termék ára' | transloco}}:
                          <span>{{ price | webshopCurrency: '' : 'symbol' : '0.0-2' | async }}</span>
                        </div>
                        <div><span>{{ getSelectedCount() }}</span> {{ 'webshop.kiválasztva' | transloco }}</div>
                        <div>
                          <button class="btn btn--basic" type="button"
                                  (click)="closeReveal()">{{ 'webshop.Ok' | transloco }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- popup méretek -->
          <ng-template #productIngredientsSizes>
            <div data-full-screen class="product-modal"
                 data-reveal data-close-on-click="true" data-animation-in="slide-in-down"
                 data-animation-out="slide-out-up">
              <button class="product-modal-close" data-close aria-label="Bezár" type="button" (click)="closeReveal()">
                {{ 'webshop.Bezár' | transloco }}
              </button>
              <div class="ajaxZone" [id]="'productIngredientsSizesContent'">
                <div class="ajaxZone-content">
                  <header class="sectionHeader">
                    <h2><img src="/assets/images/heading_divider_left.svg"
                             alt="decor">{{ 'webshop.Segítség a méretekhez' | transloco }}<img
                      src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
                  </header>
                  <div class="g-top-extrathick">
                    <div class="l-helpSectionHead" *ngIf="activeProductParameterSize.name == 'Naplók'">
                      <h1>{{ 'webshop.Naplók' | transloco }}</h1>
                      <div>{{ 'webshop.A4 mérethez viszonyítva' | transloco }}
                        <span>{{ 'webshop.Fontos!' | transloco }}</span> {{ 'webshop.A design mérete nem változhat.' | transloco }}
                      </div>
                    </div>
                    <div *ngIf="activeProductParameterSize.name == 'Naplók'"
                         class="row small-up-1 medium-up-2 xlarge-up-3">

                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/naplo_b8.png" alt="B8">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.A8' | transloco }}</strong> {{ 'webshop.(8 x 12,5 cm)' | transloco}}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Sima' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/naplo_a4_square15.png" alt="Négyzetes">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.Négyzetes' | transloco }}</strong> {{ 'webshop.(15 x 15 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Sima' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/naplo_b7.png" alt="B7">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.B7' | transloco }}</strong> {{ 'webshop.(12,5 x 17,6 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Sima' | transloco }}</li>
                            <li>{{ 'webshop.Vonalas' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/naplo_a5_french.png" alt="FRANCIA A5">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.FRANCIA A5' | transloco }}</strong> {{ 'webshop.(13 x 22 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Vonalas' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/naplo_a5.png" alt="A5">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.A5' | transloco }}</strong> {{ 'webshop.(15 x 22 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Sima' | transloco }}</li>
                            <li>{{ 'webshop.Ponthálós' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/naplo_b5.png" alt="B5">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.B5' | transloco }}</strong> {{ 'webshop.(17 x 25 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi alábbi változatban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Sima' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/naplo_a4_square22.png" alt="Négyzetes">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.Négyzetes' | transloco }}</strong> {{ 'webshop.(22 x 22 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Sima' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/naplo_a4.png" alt="A4">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.A4' | transloco }}</strong> {{ 'webshop.(21,5 x 30,7 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Sima' | transloco }}</li>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div *ngIf="activeProductParameterSize.name == 'Határidőnaplók'" class="l-helpSectionHead">
                      <h1>{{ 'webshop.Határidőaplók' | transloco }}</h1>
                      <div>{{ 'webshop.A4 mérethez viszonyítva' | transloco }}
                        <span>{{ 'webshop.Fontos!' | transloco }}</span> {{ 'webshop.A design mérete nem változhat.' | transloco }}
                      </div>
                    </div>
                    <div *ngIf="activeProductParameterSize.name == 'Határidőnaplók'"
                         class="row small-up-1 medium-up-2 xlarge-up-3">
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/hataridonaplo_a8.png" alt="A8">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.A8' | transloco }}</strong> {{ 'webshop.(8,1 x 13,3 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Heti' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/hataridonaplo_b8.png" alt="B8">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.B8' | transloco }}</strong> {{ 'webshop.(9 x 14 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Napi' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/hataridonaplo_b7.png" alt="B7">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.B7' | transloco }}</strong> {{ 'webshop.(12 x 17,5 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Heti' | transloco }}</li>
                            <li>{{ 'webshop.Napi' | transloco }}</li>
                            <li>{{ 'webshop.Öröknaptár' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/hataridonaplo_bomo.png" alt="Bomo méret">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.Bomo méret' | transloco }}</strong> {{ 'webshop.(12 x 20,5 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li *ngIf="false">{{ 'webshop.Heti magyar névnapokkal' | transloco }}</li>
                            <li>{{ 'webshop.Heti jegyzetelő magyar névnapokkal' | transloco }}</li>
                            <li *ngIf="false">{{ 'webshop.Öröknaptár' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/hataridonaplo_a5.png" alt="A5">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.A5' | transloco }}</strong> {{ 'webshop.(15 x 22 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Heti' | transloco }}</li>
                            <li>{{ 'webshop.Napi' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/hataridonaplo_b5.png" alt="B5">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.B5' | transloco }}</strong> {{ 'webshop.(17 x 25 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Heti' | transloco }}</li>
                            <li>{{ 'webshop.Napi' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/hataridonaplo_a4.png" alt="A4">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.A4' | transloco }}</strong> {{ 'webshop.(21,5 x 30,7 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.Heti' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="activeProductParameterSize.name == 'Fotóalbumok'" class="l-helpSectionHead">
                      <h1>{{ 'webshop.Fotóalbumok' | transloco }}</h1>
                      <div>{{ 'webshop.30x30cm-es mérethez viszonyítva' | transloco }}
                        <span>{{ 'webshop.Fontos!' | transloco }}</span> {{ 'webshop.A design mérete nem változhat.' | transloco }}
                      </div>
                    </div>
                    <div *ngIf="activeProductParameterSize.name == 'Fotóalbumok'"
                         class="row small-up-1 medium-up-2 xlarge-up-3">
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/fotoalbum_square_a4.png" alt="A4 álló">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.A4 álló' | transloco }}</strong> {{ 'webshop.(23 x 31 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.30 lapos bézs és fekete belsővel' | transloco }}</li>
                            <li>{{ 'webshop.50 lapos bézs és fekete belsővel' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/fotoalbum_square_a4_fekvo.png" alt="A4 fekvő">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.A4 fekvő' | transloco }}</strong> {{ 'webshop.(35 x 25,5 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.30 lapos bézs és fekete belsővel' | transloco }}</li>
                            <li>{{ 'webshop.50 lapos bézs és fekete belsővel' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/fotoalbum_square_a5_fekvo.png" alt="A5">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.A5' | transloco }}</strong> {{ 'webshop.(20,5 x 16,1 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.30 lapos bézs és fekete belsővel' | transloco }}</li>
                            <li>{{ 'webshop.50 lapos bézs belsővel' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="column">

                        <div class="helpSizePreview" data-close="">
                          <figure>
                            <img src="/assets/images/size_help/fotoalbum_square30.png" alt="Négyzetes">
                          </figure>
                          <div class="helpSizePreview-title">
                            <strong>{{ 'webshop.Négyzetes' | transloco }}</strong> {{ 'webshop.(30 x 30 cm)' | transloco }}
                          </div>
                          <div class="helpSizePreview-comment">
                            {{ 'webshop.Ezt a méretet kizárólag az alábbi változatokban rendelheted:' | transloco }}
                          </div>
                          <ul>
                            <li>{{ 'webshop.30 lapos bézs és fekete belsővel' | transloco }}</li>
                            <li>{{ 'webshop.50 lapos bézs és fekete belsővel' | transloco }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <div class="" *ngIf="item.specialCaption">
            <p>{{ 'webshop.Szeretné még személyesebbé tenni rendelését? Készítsen feliratot a gerincre. Ötletek: név, monogram, cím, évszám' | transloco }}
              <br>
              <i>{{ 'webshop.Hagyja üresen a mezőt, ha nem szeretne felirat!' | transloco }}</i></p>
            <input type="text" [formControl]="specialCaption2Control" *ngIf="secondCaption(item)">
            <span *ngIf="secondCaption(item)"
                  class="form-row--text-charCounter">{{ max(0, captionLength - parseNumber(specialCaption2Control.value ? specialCaption2Control.value.length : 0)) }}
              {{ 'webshop.karakter maradt' | transloco }}</span>
            <span *ngIf="specialCaption2Control.hasError('textValid')"
                  class="form-error is-visible">{{ 'webshop.Ez a mező nem felel meg a formátumnak. Csak számokat és betűket adhatsz meg\, valamint a következő karakterekből maximum a megadott darabszámot: @(1)\, &(1)\,\#(1)\,.(3)\,\,(1)\,\:(1)\,\;(1)\,\((1)\,\)(1)\, \/(3)\, \'(2) -(2)' | transloco }}</span>
            <span *ngIf="specialCaption2Control.hasError('maxLength') && secondCaption(item)"
                  class="form-error is-visible">{{ 'webshop.Legfeljebb' | transloco }} {{ captionLength }} {{ 'webshop.karaktert adhatsz meg' | transloco }}</span>


            <br *ngIf="secondCaption(item)"/>
            <input type="text" [formControl]="specialCaptionControl">
            <span
              class="form-row--text-charCounter">{{ max(0, captionLength - parseNumber(specialCaptionControl.value ? specialCaptionControl.value.length : 0)) }}
              {{ 'webshop.karakter maradt' | transloco }}</span>
            <span *ngIf="specialCaptionControl.hasError('textValid')"
                  class="form-error is-visible">{{ 'webshop.Ez a mező nem felel meg a formátumnak. Csak számokat és betűket adhatsz meg\, valamint a következő karakterekből maximum a megadott darabszámot: @(1)\, &(1)\,\#(1)\,.(3)\,\,(1)\,\:(1)\,\;(1)\,\((1)\,\)(1)\, \/(3)\, \'(2) -(2)' | transloco }}</span>
            <span *ngIf="specialCaptionControl.hasError('maxLength')"
                  class="form-error is-visible">{{ 'webshop.Legfeljebb' | transloco }} {{ captionLength }} {{ 'webshop.karaktert adhatsz meg' | transloco }}</span>
          </div>
          <div class="form-row form-row--price"
               *ngIf="item.selectorProductParameter.id && item.multipart && hasNonSelectorProductParameter(item)">
            {{ 'webshop.Kérjük, válassz tartalmat!' | transloco }}
          </div>
          <div class="form--productIngredients-selection" *ngIf="item.selectorProductParameter.id">
            <button class="productSelect-open btn btn--basic" type="button" (click)="openReveal(productSelectModal)">
              <span>{{ 'webshop.Összeállítás' | transloco }}</span></button>
            <ul class="">
              <ng-container *ngFor="let typeCount of productVariant.productParameterTypeCounts">
                <li *ngIf="typeCount.productParameterType.productParameter.id === item.selectorProductParameter.id">
                  <span>{{typeCount.quantity}}</span>
                  {{typeCount.productParameterType.name}}
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="form-row form-row--price">
            {{ 'webshop.Az összeállított termék ára' | transloco }}:
            <span>{{ price | webshopCurrency: '' : 'symbol' : '0.0-2' | async}}</span>
          </div>
          <div class="form-row form-row--submit" *ngIf="(cart$ | async) as cart">
            <label>
              <span *ngIf="item.setCount && item.setCount !== getSelectedCount()"
                    class="form-error is-visible">{{ 'webshop.A dobozod tartalma nem teljes, kérjük, válassz még elemeket!' | transloco }}</span>
            </label>

            <a class="btn btn--basic" (click)="addToCart(item)"
               *ngIf="hasCurrentStock()">{{ 'webshop.Kosárba' | transloco }}</a>
            <a class="btn btn--basic"
               *ngIf="!hasCurrentStock()">{{ 'webshop.Elfogyott' | transloco }}</a>
          </div>
        </div>
      </div>
      <div class="small-12 large-6 column large-order-1" *ngIf="item.multipart && hasNonSelectorProductParameter(item)">
        <div class="anim productPieces">
          <ng-container *ngFor="let productParameterTypeCount of productVariant.productParameterTypeCounts">
            <div class=""
                 *ngIf="productParameterTypeCount.productParameterType.productParameter.id !== product.selectorProductParameter.id"
                 [ngClass]="{
        'productPieces-ingredient'   : true,
        'upper-image' : productParameterTypeCount.productParameterType.productParameter.upper }">
              <ng-container *ngIf="(formatImages$ | async) as formatImages">
                <ng-container
                  *ngIf="(parameterTypeFormatImages(productParameterTypeCount.productParameterType)) as formatTypeImages">
                  <img
                    *ngIf="getParameterTypeImage(productParameterTypeCount.productParameterType, formatImages, formatTypeImages)"
                    [src]="getParameterTypeImage(productParameterTypeCount.productParameterType, formatImages, formatTypeImages)">
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <div class="productPieces-ingredient upper-image productCaption" *ngIf="caption && specialCaptionControl.valid">
            <div [ngClass]="{'productPieces-text': true, 'text-center': caption.rotate == 0}"
                 [ngStyle]="{'left': getPositionX(caption.positionX)+'%', 'top': getPositionY(caption.positionY)+'%', 'font-size': caption.fontSize+'px', 'transform': 'rotate('+caption.rotate+'deg)', '-webkit-transform': 'rotate('+caption.rotate+'deg)', '-ms-transform': 'rotate('+caption.rotate+'deg)'}">
              <span [innerHTML]="specialCaptionControl.value"></span>
            </div>
          </div>

          <div class="productPieces-ingredient upper-image productCaption"
               *ngIf="secondCaption(item) && caption && specialCaption2Control.valid">
            <div class="productPieces-text text-center"
                 [ngStyle]="{'left': '0%', 'top': '20%', 'font-size': caption.fontSize+'px', 'transform': 'rotate(0deg)', '-webkit-transform': 'rotate(0deg)', '-ms-transform': 'rotate(0deg)'}">
              <span [innerHTML]="specialCaption2Control.value"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-gallery *ngIf="item.gallery.id" [gallery]="item.gallery"></app-gallery>
  <section class="g-top-extrathick" *ngIf="!item.gallery.id"></section>
</section>
