<section class="l-siteContent l-siteContent--fullHeight
" id="theTop">
  <section>
    <div class="row">
      <div class="small-12 column">
        <ul class="list">
          <li *ngFor="let category of categories$ | async | orderByWeight: 'name'">
            <div class="categoryPreview">
              <div class="categoryPreview-content">
                <h1>{{ category.name}}</h1>
                {{ category.description }}
                <div
                  class="g-row">
                  <a class="btn btn--basic" [routerLink]="[''] | localize">{{ 'webshop.Megnézem' | transloco }}</a>
                </div>
              </div>
              <div class="categoryPreview-img">
                <img [src]="category.image.image_thumb" alt="{{ category.name}}">
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</section>
