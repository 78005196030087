import {Component, Injector} from '@angular/core';
import {PaymentModeState, Store} from './state/store';
import {PaymentMode} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {PaymentModeQuery} from './state/query';
import {PaymentModeService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class PaymentModeListComponent extends EntityListComponent<PaymentMode, PaymentModeState, Store> {

  translationDomain = 'paymentMode';

  constructor(
    public injector: Injector,
    protected service: PaymentModeService,
    query: PaymentModeQuery,
    private PaymentModeFilterService: FilterService) {
    super(injector, service, query, PaymentModeFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
