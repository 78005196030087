import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Expression} from './model';

export interface ExpressionState extends EntityState<Expression> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Expression' })
export class Store extends EntityStore<ExpressionState> {

  constructor() {
    super();
  }

}
