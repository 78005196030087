import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {Store, UserState} from './store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends QueryEntity<UserState> {

  constructor(
    protected store: Store,
  ) {
    super(store);
  }

}
