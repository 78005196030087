import {Injectable} from '@angular/core';
import {CouponService} from './state/service';
import {CouponQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Coupon} from './state/model';
import {CouponState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Coupon, CouponState, CouponQuery, Store> {
  constructor(protected query: CouponQuery, protected api: CouponService) {
    super(query, api);
  }
}
