import {Component} from '@angular/core';
import {Observable, of} from "rxjs";
import {PressNews} from "../../../content/press-news/state/model";
import {PressNewsService} from "../../../content/press-news/state/service";

@Component({
  templateUrl: './listNews.html'
})
export class WebshopPressNewsComponent {

  items$: Observable<PressNews[]>;
  constructor(
    private service: PressNewsService
  ) {
    this.items$ = service.items$;
  }
}
