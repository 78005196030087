import {Injectable} from '@angular/core';
import {PaymentModeState, Store as PaymentModeStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {PaymentMode} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {PaymentModeQuery} from './query';
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class PaymentModeService extends EntityService<PaymentModeStore, PaymentModeState> {

  paymentModes$: Observable<PaymentMode[]> = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new PaymentMode(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('paymentMode.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('Név'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: PaymentModeQuery,
    protected store: PaymentModeStore) {
    super(store, PaymentMode);
  }

  get resourceName(): string {
    return 'webshop/payment-mode';
  }

}
