import {Injectable} from '@angular/core';
import {RoleService} from './state/service';
import {RoleQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Role} from './state/model';
import {RoleState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Role, RoleState, RoleQuery, Store> {
  constructor(protected query: RoleQuery, protected api: RoleService) {
    super(query, api);
  }
}
