import {Component, TemplateRef, ViewChild} from '@angular/core';
import {AuthService} from "../../../authentication/service/auth.service";
import {Observable, startWith, tap} from "rxjs";
import {Popup} from "../../../content/popup/state/model";
import {PopupService} from "../../../content/popup/state/service";
import {BsModalRef, BsModalService} from "ngx-foundation";
import {debounceTime, first, map, shareReplay, switchMap} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-webshop-popup',
  styleUrls: ['component.scss'],
  templateUrl: './component.html'
})
export class PopupComponent {
  modalRef: BsModalRef;
  @ViewChild('popupModal') popupModal: TemplateRef<any>;
  openedList: string[] = [];

  item$: Observable<Popup> = this.popupService.popup$.pipe(
    first(),
    switchMap((popups) => this.router.events.pipe(
      startWith(''),
      debounceTime(100),
      map(url => {
        // @ts-ignore
        return popups.find(popup => popup.urls.find(url => this.valid(url.slug, popup))) ?? new Popup({});
      })
    )),
    tap((item) => {
        if (item && item.id) {
          // @ts-ignore
          this.openedList.push(item.id);
          console.log(item);
          localStorage.setItem('popup' + item.id, '1');
          this.openReveal(this.popupModal);
        }
    }),
    shareReplay(1)
  );

  constructor(
    public authService: AuthService,
    private popupService: PopupService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router : Router
  ) {
  }

  private valid(slug: string, popup: Popup) {
    // @ts-ignore
    const valid = (slug === this.router.url && !localStorage.getItem('popup' + popup.id) && !this.openedList.includes(popup.id));

    if (!valid) {
      return false;
    }

    if (popup.from) {
      if(popup.from > new Date()) {
        return false;
      }
    }

    if (popup.to) {
      if(popup.to < new Date()) {
        return false;
      }
    }

    return true;
  }

  // Reveal (Modal)
  openReveal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'slide-in-down ease messageModal'});
  }

  closeReveal() {
    this.modalRef.hide();
  }
}
