import {Injectable} from '@angular/core';
import {TransporterDiscountService} from './state/service';
import {TransporterDiscountQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {TransporterDiscount} from './state/model';
import {TransporterDiscountState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<TransporterDiscount, TransporterDiscountState, TransporterDiscountQuery, Store> {
  constructor(protected query: TransporterDiscountQuery, protected api: TransporterDiscountService) {
    super(query, api);
  }
}
