import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Wallpaper} from './model';

export interface WallpaperState extends EntityState<Wallpaper> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Wallpaper' })
export class Store extends EntityStore<WallpaperState> {

  constructor() {
    super();
  }

}
