import {Injectable} from '@angular/core';
import {ProductParameterTypeState, Store as ProductParameterTypeStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {ProductParameterType} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {catchError, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {ProductParameterTypeQuery} from './query';
import {ProductParameter} from "../../product-parameter/state/model";
import {Observable, startWith} from "rxjs";
import {getEntityType} from "@datorama/akita";
import {Entity, Image} from "../../../components/entity";
import {ProductParameterService} from "../../product-parameter/state/service";
import {ProductParameterFormat} from "../../product-parameter-format/state/model";
import {FormService} from "../../../components/service/form.service";
import {ProductParameterFormatImage} from "../../product-parameter-format-image/state/model";

@Injectable({providedIn: 'root'})
export class ProductParameterTypeService extends EntityService<ProductParameterTypeStore, ProductParameterTypeState> {

  productParameterTypes$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new ProductParameterType(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('productParameterType.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameterType.name'),
        inputType: 'text',
        name: 'name',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productParameterService.productParameters$,
        listed: true,
        label: this.translocoService.selectTranslate('productParameterType.productParameter'),
        name: 'productParameter',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('productParameterType.available'),
        inputType: 'text',
        name: 'available',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameterType.barCode'),
        inputType: 'text',
        name: 'barCode',
        listed: true,
        visible: (formGroup: FormGroup) => formGroup.get('productParameter')?.value?.isControl,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameterType.stock'),
        inputType: 'text',
        name: 'stock',
        listed: false,
        disabled: true,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameterType.weight'),
        inputType: 'number',
        name: 'weight',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameterType.mass'),
        inputType: 'number',
        name: 'mass',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameterType.captionLength'),
        inputType: 'number',
        name: 'captionLength',
        listed: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productParameterType.price'),
        inputType: 'number',
        name: 'price',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('productParameterType.image1'),
        name: 'image',
        validations: [] //this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('productParameterType.image2'),
        name: 'image2',
        validations: [] //this.formService.getValidations(['requiredImage'])
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private productParameterService: ProductParameterService,
    private query: ProductParameterTypeQuery,
    private formService: FormService,
    protected store: ProductParameterTypeStore) {
    super(store, ProductParameterType);
  }

  get resourceName(): string {
    return 'products/product-parameter-type';
  }

  clearEntity(_entity: Partial<getEntityType<ProductParameterTypeState>>): Entity {
    return super.clearEntity(new ProductParameterType(_entity));
  }

  getByProductParameter(parameter: ProductParameter): Observable<ProductParameterType[]> {
    return this.getHttp().get<ProductParameterType[]>(this.api + '/detail?q[]=productParameter.id:eq:' + parameter.id)
      .pipe(
        map(items =>
          items.map(item => new ProductParameterType(item ?? {}) as ProductParameterType)
        ),
        take(1),
        shareReplay(1)
      );
  }

  getImagesByProductParameterType(productParameterType: ProductParameterType): Observable<ProductParameterFormatImage[]> {
    return this.getHttp().get<ProductParameterFormatImage[]>(this.api + '/' + productParameterType.id + '/images')
      .pipe(
        catchError(err => []),
        map(items => {
            return items.map(item => {
              return new ProductParameterFormatImage({
                id: item.id,
                productParameterType: productParameterType,
                format: new ProductParameterFormat(item.format),
                image: new Image(item.image ?? {})
              });
            })
          }
        ),
        take(1),
        shareReplay(1)
      );
  }
}
