<section class="l-siteContent" id="theTop">
  <section class="checkout">
    <div class="row">
      <div class="small-12 column">
        <header class="sectionHeader g-bottom">
          <h1><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Fizetés' | transloco }}<img
            src="/assets/images/heading_divider_right.svg" alt="decor"></h1>
        </header>
        <ul class="checkout-breadcrumb">
          <li>
            <span>{{ 'webshop.Adatok megadása' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Szállítási mód' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Fizetési mód' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Összegzés' | transloco }}</span></li>
        </ul>

        <section class="g-top-thick">
          <section class="feedbackMessage">
            <div class="row align-center">
              <div class="small-12 medium-10 large-6 xlarge-4 column">
                <div class="feedbackMessage-content g-top">
                  <p>{{ 'webshop.Levelet küldtünk a megadott e-mail címre, amelyben megtalálod a visszaigazoló linket.' | transloco }}<br> {{ 'webshop.Visszaigazolás után folytatjuk a fizetést.' | transloco }}</p>
                  <div class="g-top">
                    <p>{{ 'webshop.Köszönjük:' | transloco }}</p>
                    <span class="feedbackMessage-signature">{{ 'webshop.Bomo Art csapata' | transloco }}</span>
                  </div>
                  <img class="anim anim--fromLeft decorImg decorImg--signUpAfterCheckoutLeft" src="/assets/images/decor/butterfly_01.png" alt="decor">
                  <img class="anim anim--fromRight decorImg decorImg--signUpAfterCheckoutRight" src="/assets/images/decor/butterfly_02.png" alt="decor">
                </div>
              </div>
            </div>
          </section>
        </section>


      </div>
    </div>
  </section>
</section>
