import {Component, Injector} from '@angular/core';
import {ProductVariantState, Store} from './state/store';
import {ProductVariant} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {ProductVariantQuery} from './state/query';
import {ProductVariantService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class ProductVariantListComponent extends EntityListComponent<ProductVariant, ProductVariantState, Store> {

  translationDomain = 'productVariant';

  constructor(
    public injector: Injector,
    protected service: ProductVariantService,
    query: ProductVariantQuery,
    private ProductVariantFilterService: FilterService) {
    super(injector, service, query, ProductVariantFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
