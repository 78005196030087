import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {Observable, of} from "rxjs";
import {Cart} from "../../../webshop/cart/state/model";
import {CartService} from "../../../webshop/cart/state/service";
import {AuthService} from "../../../authentication/service/auth.service";
import {User} from "../../../authentication/user/state/model";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {InvoiceAddressService} from "../../../webshop/invoice-address/state/service";
import {map, switchMap, tap} from "rxjs/operators";
import {InvoiceAddress} from "../../../webshop/invoice-address/state/model";
import {FormService} from "../../../components/service/form.service";
import {EditAddressComponent} from "../../editAddress";

@Component({
  selector: 'app-checkout-billing-address',
  templateUrl: './billing-address.html'
})
export class BillingAddressComponent {

  @ViewChild(EditAddressComponent) editAddress: EditAddressComponent;

  copyControl: FormControl;
  cart$: Observable<Cart>;
  user$: Observable<User> = this.authService.user$;
  addresses$: Observable<InvoiceAddress[]> = this.user$.pipe(
    switchMap(user => this.addressService.getByUser(user)),
    tap(addresses => {
      const _def = addresses.find(address => address.isDefault);
      if (_def) {
        this.addressControl.setValue(_def);
      }
    })
  );
  addressControl: FormControl;
  newAddress: InvoiceAddress = new InvoiceAddress({});
  @Output() next = new EventEmitter();

  constructor(
    private cartService: CartService,
    private authService: AuthService,
    private addressService: InvoiceAddressService,
    private fb: FormBuilder,
    public formService: FormService
  ) {
    this.copyControl = fb.control(true);
    this.addressControl = fb.control(new InvoiceAddress({}), this.formService.bindValidations([this.formService.getValidations(['required'])]));
    this.cart$ = this.addresses$.pipe(
      switchMap(addresses => this.cartService.cart$.pipe(
        map(cart => {
          if (cart.billingAddress) {
            const _address = addresses.find(address =>
              address.country.id === cart.billingCountry.id &&
              address.address.city === cart.billingAddress.city &&
              address.address.street === cart.billingAddress.street
            );
            if (_address) {
              this.addressControl.setValue(_address);
            }
          }
          return cart;
        })
      ))
    );
  }

  save(cart: Cart) {
    if (this.addressControl.value.id) {
      cart.billingAddress = this.addressControl.value.address;
      cart.billingCountry = this.addressControl.value.country;
      this.cartService.update(cart.id, cart)
        .pipe(
          tap(() => this.next.emit())
        )
        .subscribe();
    } else {
      if (this.editAddress.getAddress()) {
        this.user$.pipe(
          switchMap(user => {
            const address = this.editAddress.getAddress();
            address.user = user;
            return this.addressService.add(address).pipe(
              switchMap((_address: InvoiceAddress) => {
                cart.billingAddress = _address.address;
                cart.billingCountry = _address.country;
                return this.cartService.update(cart.id, cart)
                  .pipe(
                    tap(() => this.next.emit())
                  )
              })
            )
          })
        ).subscribe();
      }
    }
  }

}
