<app-webshop-cookie></app-webshop-cookie>
<footer class="l-siteFooter l-offContentBackground">
  <div class="l-toTheTop g-bottom">
    <a (click)="toTop()" data-smooth-scroll data-threshold="0" data-animation-easing="swing">{{ 'webshop.fel a tetejére' | transloco }}</a>
  </div>
  <div class="row align-center">
    <div class="small-12 large-4 column">
      <nav class="menuSocial">
        <ul>
          <li class="menuSocial-facebook" aria-label="facebook"><a href="https://www.facebook.com/BomoArtBudapest" target="_blank"></a></li>
          <li class="menuSocial-vimeo" aria-label="vimeo"><a href="https://vimeo.com/user3226358" target="_blank"></a></li>
          <li class="menuSocial-instagram" aria-label="instagram"><a href="https://www.instagram.com/bomoartbudapestofficial/" target="_blank"></a></li>
          <li class="menuSocial-tumblr" aria-label="tumblr"><a href="https://bomoartbudapest.tumblr.com/" target="_blank"></a></li>
        </ul>
      </nav>
      <nav class="menuSecondary g-top">
        <ul>
          <li><a [routerLink]="['/page', 'vasarlasi-feltetelek'] | localize">{{ 'webshop.vásárlási feltételek' | transloco }}</a></li>
          <li><a [routerLink]="['/page', 'szallitasi-informaciok'] | localize">{{ 'webshop.szállítási információk' | transloco }}</a></li>
          <li><a [routerLink]="['/page', 'visszakuldesi-feltetelek'] | localize">{{ 'webshop.visszaküldési feltételek' | transloco }}</a></li>
          <li><a [routerLink]="['/page', 'adatvedelmi-tajekoztato'] | localize">{{ 'webshop.adatvédelmi tájékoztató' | transloco }}</a></li>
          <li><a [routerLink]="['/page', 'dolgozz-velunk'] | localize">{{ 'webshop.dolgozz velünk' | transloco }}</a></li>
          <li><a href="mailto:shop@bomoart.com">{{ 'webshop.shop@bomoart.com' | transloco }}</a></li>
          <li><a href="tel:+ 36703620631">{{ 'webshop.tel.: + 36 70 362 0631' | transloco }}</a></li>
        </ul>
      </nav>
    </div>
    <div class="g-top g-top-hide-large-up small-12 large-4 column">
      <div class="logoBlock">
        <img class="logoBlock-bomoAngel" src="/assets/images/bomo_angel.png" alt="Bomo Angel">
        <img class="logoBlock-bomoArt" src="/assets/images/bomo_art_logo.svg" alt="Bomo Art">
        <p class="g-top">{{ "Uniquely Local and\n Universally European" | transloco }}</p>
        <img class="logoBlock-since" src="/assets/images/since.svg" alt="since 1997">
      </div>
    </div>
    <div class="small-12 medium-10 large-4 column">
      <div class="g-top-thick g-top-hide-large-up text-center text-left-large-up">
        <img src="/assets/images/payment_logos.png" alt="payment">
      </div>
      <form class="form form--offContent form--signUpNewsletter g-top" novalidate>
        <h2>{{ 'webshop.Hírlevél' | transloco }}</h2>
        <p>{{ 'webshop.IRATKOZZ FEL, ÉS LEGYÉL AZ ELSŐ, AKI ÉRTESÜL LEGFRISSEBB HÍREINKRŐL, LEGÚJABB TERMÉKEINRŐL, EXKLUZÍV AJÁNLATAINKRÓL, AKCIÓINKRÓL ÉS WORKSHOPJAINKRÓL.' | transloco }}</p>

        <div class="input-group" [formGroup]="group">
          <input type="text" [placeholder]="'webshop.Vezetéknév' | transloco" [formControl]="lastNameControl">
          <input type="text" [placeholder]="'webshop.Keresztnév' | transloco" [formControl]="firstNameControl">
        </div>
        <div class="input-group input-group--submitRow">
          <input type="text" [placeholder]="'E-mail cím' | transloco" [formControl]="emailControl">
          <div class="input-group-button">
            <button (click)="subscribeNewsletter()" class="btn btn--light">{{ 'webshop.Feliratkozás' | transloco }}</button>
          </div>
        </div>
        <p [innerHTML]="'A FELIRATKOZÁS gombra kattintással elfogadod az Adatvédelmi Tájékoztatóban foglaltakat.' | transloco"></p>

        <a class="btn btn--light" [routerLink]="['/newsletters'] | localize">{{ 'webshop.Korábbi hírlevelek' | transloco }}</a>
      </form>
    </div>
  </div>
  <div class="row align-center">
    <div class="small-12 shrink column">
      <nav class="menuTertiary g-top-thick">
        <ul>
          <li><a [routerLink]="['/webshop'] | localize">{{ 'webshop.Webshop' | transloco }}</a></li>
          <li><a [routerLink]="['/products'] | localize">{{ 'webshop.Termékek' | transloco }}</a></li>
          <li><a [routerLink]="['/shops'] | localize">{{ 'webshop.Üzletek' | transloco }}</a></li>
          <li><a [routerLink]="['/wholesale'] | localize">{{ 'webshop.Viszonteladás' | transloco }}</a></li>
          <li><a [routerLink]="['/page', 'bomo-sztori'] | localize">{{ 'webshop.Sztori' | transloco }}</a></li>
          <li><a [routerLink]="['/page', 'bomo-muhely'] | localize">{{ 'webshop.Műhely' | transloco }}</a></li>
          <li><a [routerLink]="['/news'] | localize">{{ 'webshop.Hírek' | transloco }}</a></li>
          <li><a [routerLink]="['/blog'] | localize">{{ 'webshop.Blog' | transloco }}</a></li>
          <li><a [routerLink]="['/press'] | localize">{{ 'webshop.Sajtó' | transloco }}</a></li>
          <li><a [routerLink]="['/contact'] | localize">{{ 'webshop.Kapcsolat' | transloco }}</a></li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="small-12 column">
      <div class="l-copyright g-top-thin">
        <div>{{ 'webshop.© 2017 Bomo Art Budapest - all rights reserved' | transloco }} | <span class="h-upperCase">web:</span> Mentha | <span class="h-upperCase">arculat:</span> Creativon</div>
      </div>
    </div>
  </div>
</footer>
