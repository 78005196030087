import {Component, EventEmitter, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Cart} from "../../../webshop/cart/state/model";
import {CartService} from "../../../webshop/cart/state/service";
import {User} from "../../../authentication/user/state/model";
import {AuthService} from "../../../authentication/service/auth.service";
import {Transporter} from "../../../webshop/transporter/state/model";
import {TransporterService} from "../../../webshop/transporter/state/service";
import {StoreService} from "../../../webshop/store/state/service";
import {Store} from "../../../webshop/store/state/model";
import {map, switchMap, tap} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {FormService} from "../../../components/service/form.service";

@Component({
  selector: 'app-checkout-delivery-mode',
  templateUrl: './delivery-mode.html'
})
export class DeliveryModeComponent {
  cart$: Observable<Cart>;
  user$: Observable<User> = this.authService.user$;
  stores$: Observable<Store[]> = this.storeService.stores$;
  transporters: Transporter[] = [];
  @Output() next = new EventEmitter();
  modeControl: FormControl;
  storeControl: FormControl;
  group: FormGroup;

  constructor(
    private cartService: CartService,
    private authService: AuthService,
    private storeService: StoreService,
    private transporterService: TransporterService,
    private fb: FormBuilder,
    private formService: FormService
  ) {
    this.group = fb.group({});
    this.modeControl = fb.control(new Transporter({}));
    this.storeControl = fb.control(new Store({}));

    this.group.addControl('mode', this.modeControl);
    this.group.addControl('store', this.storeControl);

    this.cart$ = this.cartService.cart$.pipe(
      switchMap(cart => this.stores$.pipe(
        switchMap(stores => this.transporterService.getForCart$(cart).pipe(
            tap(transporters => {

              this.transporters = transporters;

              /*if (cart.shippingCountry.name === 'Magyarország') {
                this.transporters = transporters.filter(transporter => !transporter.title.toLowerCase().includes('fedex'))
              }*/

              if (cart.shippingMode) {
                this.modeControl.setValue(transporters.find(tr => tr.id === cart.shippingMode.id));
              }
              if (cart.store) {
                this.storeControl.setValue(stores.find(tr => tr.id === cart.store.id));
              }
            }),
            map(() => cart)
          )
        )))
    );
  }

  save(cart: Cart) {

    if (this.modeControl.value.id) {
      cart.shippingMode = this.modeControl.value;
      if (this.storeControl.value?.id) {
        cart.store = this.storeControl.value;
      }
      this.cartService.update(cart.id, cart)
        .pipe(
          tap(() => this.next.emit())
        )
        .subscribe();
    }
  }
}
