import {Entity, Image} from '../../../components/entity';
import {Gallery} from "../../gallery/state/model";

export class GalleryImage extends Entity {
  url: Image;
  id: string;
  gallery: Gallery;

  constructor({
                url,
                id,
                gallery,
                createdAt,
                updatedAt
              }: Partial<GalleryImage>) {
    super({id, createdAt, updatedAt});

    this.url = new Image(url ?? {});
    this.id = id;
    this.gallery = new Gallery(gallery ?? {});
  }

  getNameProperty(): string {
    return 'id';
  }
}
