import {Injectable} from '@angular/core';
import {InvoiceFileState, Store as InvoiceFileStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {InvoiceFile} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {InvoiceFileQuery} from './query';
import {Observable, of} from "rxjs";
import {AuthService} from "../../../authentication/service/auth.service";
import {User} from "../../../authentication/user/state/model";
import {Order} from "../../order/state/model";

@Injectable({providedIn: 'root'})
export class InvoiceFileService extends EntityService<InvoiceFileStore, InvoiceFileState> {

  addresses$ = this.authService.user$.pipe(
    switchMap(() => this.get()),
    map(items => items.map(item => new InvoiceFile(item))),
    shareReplay(1)
  );

  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('country.entityTitle'),
    fields: [

      FieldConfig.create({
        type: 'input',
        label: of(''),
        inputType: 'text',
        name: 'order',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: of(''),
        inputType: 'text',
        name: 'invoice',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: of(''),
        inputType: 'text',
        name: 'country',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: of(''),
        inputType: 'text',
        name: 'isDefault',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: InvoiceFileQuery,
    private authService: AuthService,
    protected store: InvoiceFileStore) {
    super(store, InvoiceFile);
  }

  getByOrder(order: Order): Observable<InvoiceFile[]> {
    return this.getHttp().get<InvoiceFile[]>(this.api + '/detail?q[]=order:eq:' + order.id)
      .pipe(
        map(items => {
          return (items ?? []).map(_item => new this._constructor(_item) as InvoiceFile);
        }),
        take(1),
        shareReplay(1),
        tap(entity => {
          //this.store.upsertMany([entity as getEntityType<St>]);
        }),
      );
  }

  get resourceName(): string {
    return 'webshop/order-invoice';
  }

}
