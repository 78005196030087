import {Component} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {UserService} from "../../../authentication/user/state/service";
import {Observable} from "rxjs";
import {switchMap} from "rxjs/operators";
import {User} from "../../../authentication/user/state/model";
import {AuthService} from "../../../authentication/service/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";

@Component({
  templateUrl: './component.html'
})
export class OneTimeComponent {
  login$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private localizeRouter: LocalizeRouterService,
    private router: Router
  ) {
    this.login$ = route.params.pipe(
      switchMap(params => this.authService.onetime(new User({id: params.userId}), params.token)),
      switchMap(() => this.authService.isAuthenticated()),
      switchMap(() => router.navigate([this.localizeRouter.translateRoute('/profile')]))
    );
  }

}
