import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Gallery} from './model';

export interface GalleryState extends EntityState<Gallery> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Gallery' })
export class Store extends EntityStore<GalleryState> {

  constructor() {
    super();
  }

}
