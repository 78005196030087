import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {FormService} from "../../../components/service/form.service";
import {UserService} from "../../../authentication/user/state/service";
import {User} from "../../../authentication/user/state/model";
import {of, tap} from "rxjs";
import {catchError} from "rxjs/operators";
import {TranslocoService} from "@ngneat/transloco";


export function RepeatPasswordValidator(control: FormControl) {
  const group = control.parent as FormGroup;
  if (!group) {
    return null;
  }
  const password = group.controls.password?.value;
  const passwordConfirmation = group.controls.password2?.value;

  return password === passwordConfirmation ? null : {passwordsNotEqual: true};
}

@Component({
  templateUrl: './component.html'
})
export class RegistrationComponent {

  group: FormGroup;

  emailValidators: any[] = [];
  passwordValidators: any[] = [];
  registered: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private formService: FormService,
    private translocoService: TranslocoService
  ) {
    this.group = fb.group({});
    this.group.addControl('firstName', fb.control('', formService.bindValidations(this.formService.getValidations(['required']))));
    this.group.addControl('lastName', fb.control('', formService.bindValidations(this.formService.getValidations(['required']))));
    this.group.addControl('email', fb.control('', formService.bindValidations(this.formService.getValidations(['required', 'email']))));
    this.group.addControl('phone', fb.control('', formService.bindValidations(this.formService.getValidations(['required']))));
    this.group.addControl('password', fb.control('', formService.bindValidations(this.formService.getValidations(['required']))));
    this.group.addControl('password2', fb.control('', formService.bindValidations(this.formService.getValidations(['required']).concat([{
      name: 'passwordsNotEqual',
      validator: RepeatPasswordValidator,
      message$: this.translocoService.selectTranslate('form.password-match')
    }]))));

  }

  registration() {
    if (this.group.valid) {
      const user = new User({
        email: this.group.get('email').value,
        password: this.group.get('password').value,
        firstName: this.group.get('firstName').value,
        lastName: this.group.get('lastName').value,
        phone: this.group.get('phone').value
      });
      this.userService.add(user).pipe(
        tap(() => this.registered = true),
        catchError((e) => {
          this.group.get('email').setErrors({owned: true});
          return e;
        })
      ).subscribe();
    } else {
      this.formService.validateAllFormFields(this.group);
    }
  }
}
