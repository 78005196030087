import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {PressUser} from './model';

export interface PressUserState extends EntityState<PressUser> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'PressUser' })
export class Store extends EntityStore<PressUserState> {

  constructor() {
    super();
  }

}
