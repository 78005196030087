import {Injectable} from '@angular/core';
import {CouponState, Store as CouponStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Coupon} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {CouponQuery} from './query';
import {ProductService} from "../../../products/product/state/service";
import {CategoryService} from "../../category/state/service";
import {TransporterService} from "../../transporter/state/service";

@Injectable({providedIn: 'root'})
export class CouponService extends EntityService<CouponStore, CouponState> {

  coupons$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Coupon(item))),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('coupon.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('coupon.code'),
        inputType: 'text',
        name: 'code',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('coupon.name'),
        inputType: 'text',
        name: 'name',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('coupon.isPercent'),
        inputType: 'text',
        name: 'isPercent',
        listed: false,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('coupon.isActive'),
        inputType: 'text',
        name: 'isActive',
        listed: false,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('coupon.amount'),
        inputType: 'number',
        name: 'amount',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('coupon.usableCount'),
        inputType: 'number',
        name: 'usableCount',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('coupon.minPrice'),
        inputType: 'number',
        name: 'minPrice',
        listed: false,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.categoryService.categories$,
        listed: false,
        multiple: true,
        label: this.translocoService.selectTranslate('coupon.categories'),
        name: 'categories',
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productService.products$,
        listed: false,
        multiple: true,
        label: this.translocoService.selectTranslate('coupon.products'),
        name: 'products',
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.transporterService.transporters$,
        listed: false,
        multiple: false,
        label: this.translocoService.selectTranslate('coupon.freeShppingTransporter'),
        name: 'freeShippingTransporter',
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('coupon.validFrom'),
        name: 'validFrom',
        listed: false,
        editable: true,
        validations: []
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('coupon.validTo'),
        name: 'validTo',
        listed: false,
        editable: true,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    protected productService: ProductService,
    protected categoryService: CategoryService,
    protected transporterService: TransporterService,
    private query: CouponQuery,
    protected store: CouponStore) {
    super(store, Coupon);
  }

  get resourceName(): string {
    return 'webshop/coupon';
  }

}
