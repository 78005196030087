<section class="l-siteContent" id="theTop" *ngIf="cart$ | async as cart">
  <section class="cart">
    <div class="row">
      <div class="small-12 column">
        <header class="sectionHeader g-bottom">
          <h1><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Kosár' | transloco }}<img
            src="/assets/images/heading_divider_right.svg" alt="decor"></h1>
        </header>
        <div class="h-relative">
          <img class="anim--fromRight decorImg decorImg--checkoutSum" src="/assets/images/decor/bird.png" alt="decor">
        </div>
        <form class="form form--greyInput form--cart" data-abide novalidate (ngSubmit)="submit()">
          <div data-abide-error class="alert callout h-hidden">
            <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
          </div>
          <ul class="list list--dividedItems list--gutterNormal">
            <li *ngFor="let cartItem of orderByCreatedAt(cart.items)">
              <div class="cartItem">
                <div class="cartItem-img">
                  <!-- image width: small: 200px, large: 150px -->
                  <img *ngIf="cartItem.product.image1" [src]="cartItem.product.image1.product_image_thumb" alt="Napló - Vászon Kötéssel">
                </div>
                <div class="cartItem-content">
                  <div class="cartItem-details" *ngIf="cartItem.productVariant.productParameterTypeCounts">
                    <!--div class="cartItem-productType cartItem-productType--discount">
                        Akciós termék!
                    </div-->
                    <div class="cartItem-name">
                      {{ cartItem.product.name}} </div>
                    <ul class="cartItem-detailsListing"
                        *ngIf="cartItem.productVariant.productParameterTypeCounts.length">
                      <ng-container
                        *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                        <li
                          *ngIf="cartItem.product.selectorProductParameter && cartItem.product.setCount && cartItem.product.selectorProductParameter.id !== productParameterTypeCount.productParameterType.productParameter.id">
                          <span>{{ productParameterTypeCount.productParameterType.productParameter.displayName }}</span>
                            – {{ productParameterTypeCount.productParameterType.name }}
                        </li>
                      </ng-container>
                      <ng-container
                        *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                        <li
                          *ngIf="cartItem.product.selectorProductParameter.id && !cartItem.product.setCount">
                          <span>{{ productParameterTypeCount.productParameterType.name }}</span>
                        </li>
                      </ng-container>

                      <ng-container
                        *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                        <li *ngIf="!cartItem.product.selectorProductParameter.id">
                          <span>{{ productParameterTypeCount.productParameterType.productParameter.displayName }}</span>
                          – {{ productParameterTypeCount.productParameterType.name }}
                        </li>
                      </ng-container>

                      <li *ngIf="cartItem.caption">
                        <span>{{ 'webshop.Felirat' | transloco }} - </span> {{ cartItem.caption }}
                      </li>
                      <li *ngIf="cartItem.captionSpecial">
                        <span>{{ 'webshop.Felirat2' | transloco }} - </span> {{ cartItem.captionSpecial }}
                      </li>
                    </ul>
                  </div>
                  <div class="cartItem-divider"></div>
                  <div class="cartItem-quantity">
                    <input type="text" [value]="cartItem.quantity">
                    <div class="cartItem-quantity-adjust">
                      <div>
                        <button (click)="incOrderItem(cartItem, cart)" class="cartItem-quantity-inc" type="button"
                                aria-label="Plus"></button>
                      </div>
                      <div>
                        <button (click)="decOrderItem(cartItem, cart)" class="cartItem-quantity-dec" type="button"
                                aria-label="Minus"></button>
                      </div>
                    </div>
                    db
                  </div>
                  <div class="cartItem-divider"></div>
                  <div class=""><!--cartItem-sumPrice--discount-->
                    <div>{{ cartItem.price * cartItem.quantity  | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</div>
                  </div>
                  <div class="cartItem-divider"></div>
                  <div class="cartItem-delete">
                    <button type="button" (click)="removeProduct(cartItem, cart)">
                      <span>{{ 'webshop.Tétel törlése' | transloco }}</span></button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="cartSum">
            <li class="cartSum-subtotal">{{ 'webshop.Részösszeg' | transloco }}:
              <span>{{ cart.sumPrice | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
            <li *ngIf="cart.productsDiscount > 0">{{ 'webshop.Akciós kedvezmény' | transloco }}:
              <span>-{{ cart.productsDiscount | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
            <li *ngIf="cart.giftDiscount > 0">{{ 'webshop.Ajándékutalvány' | transloco }}:
              <span>-{{ cart.giftDiscount | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
            <li *ngIf="cart.coupon?.id">{{ 'webshop.Kuponkód kedvezmény' | transloco }} |
              <button (click)="deleteCoupon(cart)">{{ 'webshop.Kupon törlése' | transloco }}</button>
              :
              <span *ngIf="cart.couponDiscount > 0">-{{ cart.couponDiscount | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span>
              <span *ngIf="cart.couponDiscount <= 0 && cart.coupon.freeShippingTransporter">{{ 'webshop.Ingyenes szállítás' | transloco}}</span>
            </li>
            <li *ngIf="cart.loyaltyDiscount > 0">{{ 'webshop.Törzsvásárlói kedvezmény' | transloco }}:
              <span>-{{ cart.loyaltyDiscount | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
            <li class="cartSum-total">{{ 'webshop.Fizetendő' | transloco }}:
              <span>{{ cart.sumDiscountedPrice | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
          </ul>
          <div class="row g-top-extrathick">
            <div class="small-12 large-5 column">
              <div class="form-row-code" *ngIf="!cart.coupon?.id">
                <label>{{ 'webshop.Kuponkód' | transloco }}
                  <input type="text" [formControl]="couponControl" placeholder="xxx-xxx-xxx">
                </label>
                <button class="btn btn--basic" type="button"
                        (click)="addCoupon(cart)">{{ 'webshop.Küldés' | transloco }}</button>
              </div>
              <span *ngIf="couponError" class="form-error"
                    style="margin-top: 10px; display: block;">{{'Hibás kuponkód' | transloco }}</span>

              <div class="form-row-code" *ngIf="false">
                <label>{{ 'webshop.Ajándékutalvány' | transloco }}
                  <input type="text" value="" [placeholder]="'xxx-xxx-xxx'">
                </label>
                <button class="btn btn--basic" type="button">{{ 'webshop.Küldés' | transloco }}</button>
              </div>
            </div>
            <div class="small-12 large-7 column">
              <div class="cart-toPay">
                <div class="g-bottom-thick">
                  <a class="btn btn--moreBig"
                     [routerLink]="['/products'] | localize">{{ 'webshop.Vásárlás folytatása' | transloco }}</a>
                </div>
                <div>
                  <img class="l-hand" src="/assets/images/bomo_hand_gray.svg" alt="tovább">
                  <button class="btn btn--basic" type="submit">{{ 'webshop.Tovább a fizetéshez' | transloco }}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</section>
