import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";
import {FormBuilder, FormControl} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-webshop-pager',
  templateUrl: './component.html',
  styleUrls: ['component.scss']
})
export class WebshopPagerComponent implements AfterViewInit {

  @Input() currentPage: number;
  @Input() maxPage: number = 99999;

  @Output() paging = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private localizeRouter: LocalizeRouterService
  ) {
  }


  toTop() {
    window.scroll(0, 0);
  }

  ngAfterViewInit() {
    this.paging.emit(1);
  }

  next() {
    if (this.currentPage < this.maxPage) {
      this.paging.emit(this.currentPage + 1);
      this.toTop();
    }
  }

  previous() {
    if (this.currentPage > 0) {
      this.paging.emit(this.currentPage - 1);
      this.toTop();
    }
  }

  toPage(num: number) {
    if (num > 0 && num <= this.maxPage) {
      this.paging.emit(num);
      this.toTop();
    }
  }
}
