import {Component} from '@angular/core';
import {Blog} from "../../../content/blog/state/model";
import {BlogService} from "../../../content/blog/state/service";
import {ActivatedRoute} from "@angular/router";
import {Observable, of} from "rxjs";
import {switchMap} from "rxjs/operators";

@Component({
  templateUrl: './view.html'
})
export class WebshopBlogViewComponent {
  public item$: Observable<Blog>;

  constructor(
    service: BlogService,
    route: ActivatedRoute
  ) {
    this.item$ = route.params.pipe(
      switchMap(params => service.getBySlug(params.slug))
    );

  }

}
