import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable} from 'rxjs';
import {Entity} from '../../entity';
import {TranslocoService} from '@ngneat/transloco';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-autocomplete',
  styleUrls: ['common.scss', 'autocomplete.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'autocomplete.html'
})
export class AutocompleteComponent implements OnInit {

  field: FieldConfig;
  group: FormGroup;
  parentEntity$ = new Observable<Entity>();
  customContent$ = new Observable<string>();
  filteredOptions$: Observable<Entity[]> | undefined;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public translationService: TranslocoService
  ) {
    this.group = new FormGroup({});
    this.field = new FieldConfig();

  }

  ngOnInit() {
    const callable = this.field.entityConditionalOptions as (form?: (FormGroup | undefined), parent?: FormGroup) => Observable<Entity[]>;
    const group = this.group.parent ?? this.group;
    this.filteredOptions$ =
    this.group.get(this.field.name)?.valueChanges.pipe(
      distinctUntilChanged(),
      filter(item => item.length > 2),
      debounceTime(500),
      switchMap((value) => {
        return callable(this.group.get(this.field.name) as unknown as FormGroup, this.group);
      })
    );
  }
}
