import {Injectable} from '@angular/core';
import {ExpressionService} from './state/service';
import {ExpressionQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Expression} from './state/model';
import {ExpressionState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Expression, ExpressionState, ExpressionQuery, Store> {
  constructor(protected query: ExpressionQuery, protected api: ExpressionService) {
    super(query, api);
  }
}
