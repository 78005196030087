import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {UserState} from "./store";

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'listuser' })
export class ListStore extends EntityStore<UserState> {

  constructor() {
    super();
  }

}
