import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '.anim'
})
export class InsideViewportDirective implements OnInit {
  visible: boolean = false;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.onScrollBy();
  }

  @HostListener('window:scroll', ['$event'])
  public onScrollBy(): any {
    const windowHeight = window.innerHeight;
    const boundedRect = this.elementRef.nativeElement.getBoundingClientRect();

    if (boundedRect.top >= 0 && boundedRect.top <= windowHeight && !this.visible) {
      this.visible = !this.visible;
      this.renderer.addClass(this.elementRef.nativeElement, 'inView');
      this.changeDetectorRef.detectChanges();
    } else if(this.visible) {
      //this.visible = !this.visible;
      //this.renderer.removeClass(this.elementRef.nativeElement, 'inView');
    }
  }
}
