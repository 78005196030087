import {Component, Injector} from '@angular/core';
import {PressNewsState, Store as PressNewsStore} from './state/store';
import {PressNews} from './state/model';
import {PressNewsService as PressNewsService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {PressNewsQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class PressNewsDetailComponent extends EntityDetailComponent<PressNews, PressNewsState, PressNewsStore> {

  constructor(
    public injector: Injector,
    service: PressNewsService,
    protected query: PressNewsQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
