import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {Page} from "../../../content/page/state/model";
import {PageService} from "../../../content/page/state/service";

@Component({
  templateUrl: './view.html'
})
export class WebshopPageViewComponent {

  public item$: Observable<Page>;

  constructor(
    route: ActivatedRoute,
    service: PageService
  ) {
    this.item$ = route.params.pipe(
      switchMap(params => service.getBySlug(params.slug))
    );
  }
}
