import {Entity} from '../../../components/entity';

export class PressUser extends Entity {
  username: string;
  password: string;

  constructor({id, username, password, createdAt, updatedAt}: Partial<PressUser>) {
    super({id, createdAt, updatedAt});
    this.username = username ?? '';
    this.password = password ?? '';
  }

  getNameProperty(): string {
    return 'username';
  }
}
