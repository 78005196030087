import {Component} from '@angular/core';
import {AuthService} from '../../authentication/service/auth.service';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {FormService} from "../../components/service/form.service";
import {NewsletterService} from "../../newsletter/service/newsletter.service";
import {tap} from "rxjs";
import {NotificationComponent} from "../../notifications/notification/component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-webshop-footer',
  styleUrls: ['component.scss'],
  templateUrl: './component.html'
})
export class WebshopFooterComponent {
  group: FormGroup;
  emailControl: FormControl;
  firstNameControl: FormControl;
  lastNameControl: FormControl;

  constructor(
    public authService: AuthService,
    private formService: FormService,
    private fb: FormBuilder,
    private newsletterService: NewsletterService,
    private snackBar: MatSnackBar,
    private translationService: TranslocoService,
  ) {
    this.group = fb.group({});
    this.emailControl = fb.control('', formService.bindValidations(formService.getValidations(['required', 'email'])));
    this.firstNameControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.lastNameControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));

    this.group.addControl('email', this.emailControl);
    this.group.addControl('firstName', this.firstNameControl);
    this.group.addControl('lastName', this.lastNameControl);
  }

  toTop() {
    window.scroll(0, 0);
  }

  subscribeNewsletter() {
    if (this.group.valid) {
      this.newsletterService.subscribe$(this.firstNameControl.value, this.lastNameControl.value, this.emailControl.value).pipe(
        tap(() => {
          const snackBarRef = this.snackBar.openFromComponent(NotificationComponent, {duration: 9999999});
          snackBarRef.instance.class = 'success';
          snackBarRef.instance.message$ = this.translationService.selectTranslate('notification.subscribed');
        })
      ).subscribe();
    } else {
      this.formService.validateAllFormFields(this.group);
    }
  }
}
