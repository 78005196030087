import {Component} from '@angular/core';
import {UserService} from "../../../authentication/user/state/service";
import {ActivatedRoute, Router} from "@angular/router";
import {map, switchMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {AuthService} from "../../../authentication/service/auth.service";
import {User} from "../../../authentication/user/state/model";

@Component({
  templateUrl: './component.html'
})
export class ActivateComponent {

  login$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.login$ = route.params.pipe(
      switchMap(params => this.authService.onetime(new User({id: params.userId}), params.token)),
      switchMap(() => this.authService.isAuthenticated())
    );
  }

}
