import {Injectable} from '@angular/core';
import {PressNewsState, Store as PressNewsStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {PressNews} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {PressNewsQuery} from './query';
import {GalleryService} from "../../gallery/state/service";
import {FormService} from "../../../components/service/form.service";

@Injectable({ providedIn: 'root' })
export class PressNewsService extends EntityService<PressNewsStore, PressNewsState> {

  items$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new PressNews(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('pressNews.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('pressNews.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('pressNews.slug'),
        inputType: 'text',
        name: 'slug',
        listed: false,
        editable: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('pressNews.content'),
        name: 'content',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('pressNews.content2'),
        name: 'content2',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        label: this.translocoService.selectTranslate('pressNews.image1'),
        name: 'image1',
        listed: false,
        editable: true,
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'image',
        label: this.translocoService.selectTranslate('pressNews.image2'),
        name: 'image2',
        listed: false,
        editable: true,
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.galleryService.galleries$,
        listed: false,
        label: this.translocoService.selectTranslate('pressNews.gallery'),
        name: 'gallery',
        validations: []
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('common.createdAt'),
        name: 'createdAt',
        listed: true,
        editable: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: PressNewsQuery,
    private formService: FormService,
    private galleryService: GalleryService,
    protected store: PressNewsStore) {
    super(store, PressNews);
  }

  get resourceName(): string {
    return 'content/press-news';
  }

}
