import {Injectable} from '@angular/core';
import {ProductService} from './state/service';
import {ProductQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Product} from './state/model';
import {ProductState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Product, ProductState, ProductQuery, Store> {
  constructor(protected query: ProductQuery, protected api: ProductService) {
    super(query, api);
  }
}
