import {Entity, Image} from '../../../components/entity';
import {ProductParameterType} from "../../product-parameter-type/state/model";
import {ProductParameterFormat} from "../../product-parameter-format/state/model";

export class ProductParameterFormatImage extends Entity {
  productParameterType: ProductParameterType;
  format: ProductParameterFormat;
  image: Image;

  constructor({
                id,
                format,
                productParameterType,
                image,
                createdAt, updatedAt
              }: Partial<ProductParameterFormatImage>) {
    super({id, createdAt, updatedAt});
    this.productParameterType = new ProductParameterType(productParameterType ?? {});
    this.format = new ProductParameterFormat(format ?? {});
    this.image = new Image(image ?? {});
  }

  getNameProperty(): string {
    return 'productParameterType';
  }
}
