import {Component} from '@angular/core';
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";
import {FormBuilder, FormControl} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-webshop-search',
  templateUrl: './component.html'
})
export class WebshopSearchComponent {
  opened: boolean = false;
  termControl: FormControl;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private localizeRouter: LocalizeRouterService
  ) {
    this.termControl = fb.control('');
  }

  search() {
    this.toggle();
    this.router.navigate([this.localizeRouter.translateRoute('/products/' + this.termControl.value)]).then();
  }

  toggle() {
    this.opened = !this.opened;
  }
}
