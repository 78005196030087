import {Component, Injector} from '@angular/core';
import {PressUserState, Store} from './state/store';
import {PressUser} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {PressUserQuery} from './state/query';
import {PressUserService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class PressUserListComponent extends EntityListComponent<PressUser, PressUserState, Store> {

  translationDomain = 'pressUser';

  constructor(
    public injector: Injector,
    protected service: PressUserService,
    query: PressUserQuery,
    private PressUserFilterService: FilterService) {
    super(injector, service, query, PressUserFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
