import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslocoService} from "@ngneat/transloco";

@Injectable({providedIn: 'root'})
export class LanguageInterceptor implements HttpInterceptor {

  public constructor(private translationService: TranslocoService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.translationService.getActiveLang()) {
      return next.handle(req.clone({ headers: req.headers.set('X-Language', this.translationService.getActiveLang()) }));
    }
    return next.handle(req);
  }
}
