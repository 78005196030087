import {Component, EventEmitter, Output} from '@angular/core';
import {AuthService} from '../../authentication/service/auth.service';
import {LanguageService} from "../../transloco/language/state/service";
import {TranslocoService} from "@ngneat/transloco";
import {map, switchMap} from "rxjs/operators";
import {Language} from "../../transloco/language/state/model";
import {CartService} from "../../webshop/cart/state/service";

@Component({
  selector: 'app-webshop-header',
  templateUrl: './component.html'
})
export class WebshopHeaderComponent {

  @Output() menuToggle = new EventEmitter();
  @Output() searchToggle = new EventEmitter();
  @Output() loginToggle = new EventEmitter();

  languages$ = this.languageService.languages$.pipe(
    switchMap(languages => this.translocoService.langChanges$.pipe(map(() => languages))),
    map(languages => languages.filter(language =>  language.code !== this.translocoService.getActiveLang()))
  );
  cart$ = this.cartService.cart$;

  constructor(
    public authService: AuthService,
    private languageService: LanguageService,
    private translocoService: TranslocoService,
    private cartService: CartService
  ) {
  }

  switchLanguage(language: Language) {
    this.translocoService.setActiveLang(language.code);
  }
}
