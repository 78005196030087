<section class="l-siteContent" id="theTop" *ngIf="items$ | async as items">
  <section>
    <header class="l-headFrizDecor">
      <h1>{{ 'webshop.Videók' | transloco }}</h1>
    </header>
    <div class="row">
      <div class="small-12 column">
        <div class="row small-up-1 medium-up-2">
          <div class="column" *ngFor="let video of items">
            <div class="blockCard">
              <a class="blockCard-img" [href]="safeUrl(video.url)" target="_blank">
                <img [src]="video.image.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [href]="safeUrl(video.url)" target="_blank">
                {{ video.title }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
