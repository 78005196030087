import {Injectable} from '@angular/core';
import {CurrencyService} from './state/service';
import {CurrencyQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Currency} from './state/model';
import {CurrencyState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Currency, CurrencyState, CurrencyQuery, Store> {
  constructor(protected query: CurrencyQuery, protected api: CurrencyService) {
    super(query, api);
  }
}
