import {Component, Injector} from '@angular/core';
import {NewsState, Store} from './state/store';
import {News} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {NewsQuery} from './state/query';
import {NewsService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class NewsListComponent extends EntityListComponent<News, NewsState, Store> {

  translationDomain = 'news';

  constructor(
    public injector: Injector,
    protected service: NewsService,
    query: NewsQuery,
    private NewsFilterService: FilterService) {
    super(injector, service, query, NewsFilterService);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.sort.sort({
      id: 'createdAt',
      start: 'desc',
      disableClear: false
    });
  }
}
