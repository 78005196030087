import {Entity, Image} from '../../../components/entity';

export class Wallpaper extends Entity {
  title: string;
  slug: string;
  color: string;
  subtitle: string;
  description: string;
  image: Image;
  image1: Image;
  image2: Image;
  image3: Image;
  image4: Image;
  from: Date | null;

  constructor({
                id,
                title,
                subtitle,
                slug,
                color,
                description,
                image,
                image1,
                image2,
                image3,
                image4,
                from,
                createdAt, updatedAt
              }: Partial<Wallpaper>) {
    super({id, createdAt, updatedAt});
    this.slug = slug ?? '';
    this.color = color ?? '';
    this.title = title ?? '';
    this.subtitle = subtitle ?? '';
    this.description = description ?? '';
    this.from = new Date(Date.parse(from as unknown as string));

    this.image = new Image(image ?? {});
    this.image1 = new Image(image1 ?? {});
    this.image2 = new Image(image2 ?? {});
    this.image3 = new Image(image3 ?? {});
    this.image4 = new Image(image4 ?? {});
  }

  getNameProperty(): string {
    return 'title';
  }
}
