import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {Reseller} from "../../../webshop/reseller/state/model";
import {ResellerService} from "../../../webshop/reseller/state/service";

@Component({
  templateUrl: './reseller.html'
})
export class ShopsResellerComponent {
  public item$: Observable<Reseller>;

  constructor(
    route: ActivatedRoute,
    service: ResellerService
  ) {
    this.item$ = route.params.pipe(
      switchMap(params => service.getBySlug(params.slug))
    );
  }

  isOuterUrl(url: string) {
    return url.startsWith('http');
  }
}
