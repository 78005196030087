import {Entity, Image} from '../../../components/entity';
import {Gallery} from "../../gallery/state/model";

export class PressNews extends Entity {
  image1: Image;
  image2: Image;
  content: string;
  title: string;
  gallery: Gallery;
  slug: string;
  content2: string;

  constructor({
                id, slug,
                title,
                content,
                image1,
                image2,
                gallery,
                content2,
                createdAt, updatedAt
              }: Partial<PressNews>) {
    super({id, createdAt, updatedAt});
    this.slug = slug ?? '';
    this.content2 = content2 ?? '';
    this.image1 = new Image(image1 ?? {});
    this.image2 = new Image(image2 ?? {});

    this.title = title ?? '';
    this.content = content ?? '';

    this.gallery = new Gallery(gallery ?? {});
  }

  getNameProperty(): string {
    return 'title';
  }
}
