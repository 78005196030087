<section class="g-top-thick" *ngIf="(user$ | async) as user">
  <div class="checkout-deliveryModeDecor-left"></div>
  <div class="checkout-deliveryModeDecor-right"></div>
  <div class="row align-center" *ngIf="(cart$ | async) as cart">
    <div class="shrink column">
      <div class="h-relative">
        <img class="anim anim--fromLeft decorImg decorImg--checkoutDeliveryModeLeft"
             src="/assets/images/decor/aeroplane.png" alt="decor">
        <img class="anim anim--fromRight decorImg decorImg--checkoutDeliveryModeRight"
             src="/assets/images/decor/car.png" alt="decor">
      </div>
      <header class="l-headFrizDecor">
        <h2>{{ 'webshop.Szállítási mód' | transloco }}</h2>
      </header>
      <form class="form form--deliveryMode" data-abide novalidate (ngSubmit)="save(cart)" [formGroup]="group">
        <div data-abide-error class="alert callout" *ngIf="modeControl.hasError('required') && modeControl.touched">
          <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
        </div>
        <div class="row collapse align-center">
          <div class="shrink column">
            <div *ngFor="let transporter of transporters">
              <input [formControlName]="'mode'" type="radio" [attr.id]="transporter.id" [value]="transporter">
              <label [attr.for]="transporter.id">{{ transporter.title }}</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="modeControl.value?.id === '1b033ffa-33b3-4291-b94f-003ea45bbdfd'">
          <header class="l-headFrizDecor">
            <h3>{{ 'webshop.Üzlet' | transloco }}</h3>
          </header>
          <div class="row collapse align-center">
            <div class="shrink column">
              <div *ngFor="let store of (stores$ | async)">
                <input [formControl]="storeControl"
                       type="radio" name="store" [attr.id]="store.id" [value]="store">
                <label [attr.for]="store.id">{{ store.title }}</label>
              </div>
            </div>
          </div>
        </ng-container>
        <hr class="l-divider l-divider--flexCard">
        <div class="g-top-extrathick text-center">
          <img class="l-hand" src="/assets/images/bomo_hand_gray.svg" alt="tovább">
          <button class="btn btn--basic" type="submit">{{ 'webshop.Tovább' | transloco }}</button>
        </div>
      </form>
    </div>
  </div>
</section>
