import {Component} from '@angular/core';
import {Observable, tap} from "rxjs";
import {Cart} from "../../../webshop/cart/state/model";
import {CartService} from "../../../webshop/cart/state/service";
import {User} from "../../../authentication/user/state/model";
import {AuthService} from "../../../authentication/service/auth.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";
import {FormService} from "../../../components/service/form.service";
import {catchError} from "rxjs/operators";
import {UserService} from "../../../authentication/user/state/service";

@Component({
  selector: 'app-checkout-login',
  templateUrl: './login.html'
})
export class CheckoutLoginComponent {
  cart$: Observable<Cart> = this.cartService.cart$;
  user$: Observable<User> = this.authService.user$;

  loginEmail: FormControl;
  loginPassword: FormControl;
  loginGroup: FormGroup;

  registrationEmail: FormControl;
  registrationPhone: FormControl;
  registrationPassword: FormControl;
  registrationPasswordAgain: FormControl;
  registrationGroup: FormGroup;
  registrationFirstName: FormControl;
  registrationLastName: FormControl;

  constructor(
    private cartService: CartService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private localizeRouter: LocalizeRouterService,
    private fb: FormBuilder,
    private formService: FormService
  ) {

    this.loginGroup = fb.group({});
    this.loginEmail = fb.control('');
    this.loginPassword = fb.control('');

    this.loginGroup.addControl('email', this.loginEmail);
    this.loginGroup.addControl('password', this.loginPassword);

    this.registrationPhone = fb.control('', this.formService.bindValidations(this.formService.getValidations(['required'])));
    this.registrationEmail = fb.control('', this.formService.bindValidations(this.formService.getValidations(['required', 'email'])));
    this.registrationPassword = fb.control('');
    this.registrationPasswordAgain = fb.control('');
    this.registrationLastName = fb.control('', this.formService.bindValidations(this.formService.getValidations(['required'])));
    this.registrationFirstName = fb.control('', this.formService.bindValidations(this.formService.getValidations(['required'])));
    this.registrationGroup = fb.group({});

    this.registrationGroup.addControl('firstName', this.registrationFirstName);
    this.registrationGroup.addControl('lastName', this.registrationLastName);
    this.registrationGroup.addControl('email', this.registrationEmail);
    this.registrationGroup.addControl('phone', this.registrationPhone);
    this.registrationGroup.addControl('password', this.registrationPassword);
    this.registrationGroup.addControl('passwordAgain', this.registrationPasswordAgain);
  }

  registration() {
    if (this.registrationGroup.valid) {
      const user = new User({
        email: this.registrationGroup.get('email').value,
        password: this.registrationGroup.get('password').value,
        firstName: this.registrationGroup.get('firstName').value,
        lastName: this.registrationGroup.get('lastName').value,
        phone: this.registrationGroup.get('phone').value
      });
      this.userService.add(user).pipe(
        tap(() => {
          this.router.navigate([this.localizeRouter.translateRoute('/checkout/registration-completed')]).then(() => {});
        }),
        catchError((e) => {
          this.registrationGroup.get('email').setErrors({owned: true});
          return e;
        })
      ).subscribe();
    } else {
      this.formService.validateAllFormFields(this.registrationGroup);
    }
  }

  login() {
    this.authService.login(new User({
      username: this.loginEmail.value,
      email: this.loginEmail.value,
      password: this.loginPassword.value
    })).pipe(
      tap(() => {
        this.router.navigate([this.localizeRouter.translateRoute('/checkout')]);

      })
    )
      .subscribe();
  }
}
