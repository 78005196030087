import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Blog} from './model';

export interface BlogState extends EntityState<Blog> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Blog' })
export class Store extends EntityStore<BlogState> {

  constructor() {
    super();
  }

}
