import {Component, Injector} from '@angular/core';
import {TransporterState, Store as TransporterStore} from './state/store';
import {Transporter} from './state/model';
import {TransporterService as TransporterService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TransporterQuery} from './state/query';
import {Country} from "../country/state/model";
import {CountryService} from "../country/state/service";
import {Observable} from "rxjs";
import {map, shareReplay, switchMap, tap} from "rxjs/operators";
import {TransporterPrice} from "../transporter-price/state/model";
import {FormService} from "../../components/service/form.service";
import {TransporterPriceService} from "../transporter-price/state/service";
import {MatTabChangeEvent} from "@angular/material/tabs";

interface WeightControl {
  weight: number;
  country: Country;
  control: FormControl;
}

@Component({
  templateUrl: 'detail.html',
  styleUrls: [
    '../../components/entity-detail/component.scss',
    'detail.scss'
  ]
})
export class TransporterDetailComponent extends EntityDetailComponent<Transporter, TransporterState, TransporterStore> {
  activeTab = 'main';
  pricesGroup: FormGroup;
  controls: WeightControl[] = [];
  countries$: Observable<Country[]>;
  prices: TransporterPrice[] = [];

  weightCategories = [
    0.5,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    20,
    25,
    30,
    40
  ];
  weightCategories$: Observable<number[]>;
  selectedCountryIndex: number = 0;

  constructor(
    public injector: Injector,
    private countryService: CountryService,
    service: TransporterService,
    private transporterPriceService: TransporterPriceService,
    protected query: TransporterQuery,
    private formService: FormService,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);

    this.pricesGroup = formBuilder.group({});

    this.weightCategories$ = this.entity$.pipe(
      switchMap(transporter => service.getPrices(transporter as Transporter).pipe(
          switchMap(prices => countryService.countries$.pipe(
              tap(countries => {
                this.prices = prices;
                this.weightCategories.forEach(wc => {
                  countries.forEach(country => {
                    const price = prices.find(_price => _price.type === 'g' + wc.toString() && _price.country.id === country.id);
                    if (price) {
                      this.getControl(wc, country).setValue(price.price);
                    }
                  });
                });
              })
            )
          )
        )
      ),
      map(() => this.weightCategories),
      shareReplay(1)
    );

    this.countries$ = this.entity$.pipe(
      switchMap(transporter => this.countryService.countries$.pipe(
        map(countries => {
          switch (transporter.handlerClass) {
            case 'App:Classes:PersonalShipping:Shipping':
            case 'App:Classes:FedExShipping:Shipping':
              return countries.filter(() => false);
              break;
            case 'App:Classes:GLSPointShipping:Shipping':
            case 'App:Classes:GLSShipping:Shipping':
              return countries.filter((country => country.name === 'Magyarország'));
              break;
          }

          return countries.sort((a, b) => a.name > b.name ? 1 : -1);
        })
      )));
  }


  getControl(weight: number, country: Country) {
    let ctrl = this.controls.find(_ctrl => _ctrl.weight === weight && _ctrl.country.id === country.id);
    if (!ctrl) {
      ctrl = {
        weight,
        country,
        control: this.formBuilder.control('')
      }

      this.controls.push(ctrl);
    }
    return ctrl.control;
  }

  savePrices() {
    if (this.pricesGroup.valid) {
      //process existing
      this.prices.forEach(item => {
        const _price = this.getControl(parseInt(item.type.replace('g', '')), item.country);
        if (_price.value === '' && item.id) {
          this.transporterPriceService.delete(item.id).subscribe();
        } else {
          if (parseFloat(_price.value) !== item.price) {
            const __price = new TransporterPrice(item);
            __price.price = parseFloat(_price.value);
            this.transporterPriceService.update(item.id, __price).subscribe();
          }
        }
      });

      //search new ones
      this.controls.forEach(control => {

        if (!this.prices.find(_price => _price.country.id === control.country.id && parseInt(_price.type.replace('g', '')) === control.weight)) {
          if (parseFloat(control.control.value)) {
            const __price = new TransporterPrice({
              price: parseFloat(control.control.value),
              country: control.country,
              type: 'g' + control.weight.toString(),
            });

            this.entity$.pipe(
              switchMap(transporter => {
                __price.transporter = transporter;
                return this.transporterPriceService.add(__price);
              })).subscribe();
          }
        }
      });
    } else {
      this.formService.validateAllFormFields(this.pricesGroup);
    }
  }

  selectCountry($event: MatTabChangeEvent) {
    this.selectedCountryIndex = $event.index;
  }
}
