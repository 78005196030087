import {Injectable} from '@angular/core';
import {BlogService} from './state/service';
import {BlogQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Blog} from './state/model';
import {BlogState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Blog, BlogState, BlogQuery, Store> {
  constructor(protected query: BlogQuery, protected api: BlogService) {
    super(query, api);
  }
}
