import {Address, Entity} from '../../../components/entity';
import {Coupon} from "../../coupon/state/model";
import {OrderItem} from "../../order/state/model";
import {Country} from "../../country/state/model";
import {PaymentMode} from "../../payment-mode/state/model";
import {Transporter} from "../../transporter/state/model";
import {Store} from "../../store/state/model";

export class Cart extends Entity {
  weight: number;
  description: string;
  name: string;
  coupon: Coupon;
  sumDiscountedPrice: number;
  loyaltyDiscount: number;
  couponDiscount: number;
  giftDiscount: number;
  productsDiscount: number;
  sumPrice: number;
  shippingPrice: number;
  items: OrderItem[];
  shippingAddress: Address | null;
  billingAddress: Address | null;
  billingCountry: Country;
  shippingCountry: Country;
  paymentMode: PaymentMode;
  store: Store;
  shippingMode: Transporter;

  constructor({
                id,
                name,
                weight,
                shippingPrice,
                description,
                store,
                coupon,
                loyaltyDiscount,
                sumPrice,
                productsDiscount,
                items,
                shippingAddress,
                billingAddress,
                shippingCountry,
                billingCountry,
                shippingMode,
                paymentMode,
                sumDiscountedPrice,
                couponDiscount,
                createdAt, updatedAt
              }: Partial<Cart>) {
    super({id, createdAt, updatedAt});
    this.coupon = new Coupon(coupon ?? {});
    this.weight = weight ?? 0;
    this.description = description ?? '';
    this.name = name ?? '';
    this.items = (items ?? []).map(item => new OrderItem(item ?? {}));

    this.sumDiscountedPrice = sumDiscountedPrice ?? 0;
    this.store = new Store(store ?? {});
    this.shippingPrice = shippingPrice ?? 0;
    this.shippingAddress = shippingAddress ? new Address(shippingAddress) : null;
    this.billingAddress = billingAddress ? new Address(billingAddress) : null;
    this.billingCountry = new Country(billingCountry ?? {});
    this.shippingCountry = new Country(shippingCountry ?? {});
    this.shippingMode = new Transporter(shippingMode ?? {});
    this.paymentMode = new PaymentMode(paymentMode ?? {});

    this.loyaltyDiscount = loyaltyDiscount ?? 0;
    this.sumPrice = sumPrice ?? 0;
    this.productsDiscount = productsDiscount ?? 0;
    this.couponDiscount = couponDiscount ?? 0;
  }

  itemCount() {
    return this.items.reduce((agg, curr) => agg + curr.quantity, 0);
  }

  calculatedDiscountedPrice(multiplier: number) {
    return this.calculatedSumPrice(multiplier) - this.couponDiscount * multiplier;
  }

  calculatedSumPrice(multiplier: number) {
    return this.items.reduce((agg, curr) => agg + curr.quantity * (curr.price * multiplier), 0);
  }

  getNameProperty(): string {
    return 'name';
  }
}
