import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";
import {AuthService} from "../authentication/service/auth.service";

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private transloco: TranslocoService,
    private snackBar: MatSnackBar,
    private localizeRouter: LocalizeRouterService,
    private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(map((response: boolean) => {
      if (response) {
        return true;
      }
      this.router.navigate([this.localizeRouter.translateRoute('/checkout/login')]);
      return false;
    }), catchError((error) => {
      this.router.navigate([this.localizeRouter.translateRoute('/checkout/login')]);
      return of(false);
    }));
  }
}
