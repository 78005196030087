import {Component, Injector} from '@angular/core';
import {ProductParameterState, Store as ProductParameterStore} from './state/store';
import {ProductParameter} from './state/model';
import {ProductParameterService as ProductParameterService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ProductParameterQuery} from './state/query';
import {FormService} from "../../components/service/form.service";
import {ProductParameterFormat} from "../product-parameter-format/state/model";
import {CaptionService} from "../caption/state/service";
import {Observable, of, switchMap} from "rxjs";
import {shareReplay, take, tap} from "rxjs/operators";
import {Caption} from "../caption/state/model";

interface FormatControl {
  format: ProductParameterFormat;
  control: FormControl;
  type: string;
}

@Component({
  templateUrl: 'detail.html',
  styleUrls: [
    'detail.scss',
    '../../components/entity-detail/component.scss'
  ]
})
export class ProductParameterDetailComponent extends EntityDetailComponent<ProductParameter, ProductParameterState, ProductParameterStore> {
  activeTab = 'main';
  captionsGroup: FormGroup;
  captions$: Observable<Caption[]>;
  captions: Caption[] = [];
  controls: FormatControl[] = [];
  productParameter: ProductParameter;

  constructor(
    public injector: Injector,
    service: ProductParameterService,
    private captionService: CaptionService,
    protected query: ProductParameterQuery,
    public formService: FormService,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);

    this.captionsGroup = formBuilder.group({});

    this.captions$ = this.entity$.pipe(
      tap(pp => this.productParameter = pp),
      switchMap(pp => this.captionService.getByProductParameter(pp)),
      tap(captions => {
        this.captions = captions;

        captions.forEach(caption => {
          ['positionX', 'positionY', 'rotate', 'fontSize', 'orientation'].forEach(type => {
            const control = this.getControl(caption.format, type);
            control.setValue(caption[type]);
          });
        });
      }),
      shareReplay(1)
    )
  }

  navigateToParent() {
    this.router.navigate(['../' + this.savedEntity?.id], {relativeTo: this.route}).then(() => {
      window.location.reload();
    });
  }

  getControl(format: ProductParameterFormat, type: string) {
    let ctrl = this.controls.find(_ctrl => _ctrl.format.id === format.id && _ctrl.type === type);
    if (!ctrl) {
      ctrl = {
        format,
        type,
        control: this.formBuilder.control('')
      }

      this.controls.push(ctrl);
    }
    return ctrl.control;
  }

  saveCaptions() {

    const captions = this.captions.map(caption => new Caption(caption));

    this.controls.forEach(control => {
      let relCaption = captions.find(caption => control.format.id === caption.format.id);

      if (!relCaption && control.format.id) {
        relCaption = new Caption({
          format: control.format
        });

        captions.push(relCaption);
      }

      relCaption.productParameter = this.productParameter;

      if (control.format.id) {
        relCaption.format = control.format;
      }

      if (control.type !== 'format') {
        relCaption[control.type] = control.control.value ?? '';
      }
    });

    let links: Observable<any> = of('');
    captions.filter(cap => cap.format.id).forEach(caption => {
      if (caption.id) {
        if (caption.orientation === 0) {
          links = links.pipe(
            switchMap(() => this.captionService.delete(caption))
          );
        } else {
          links = links.pipe(
            switchMap(() => this.captionService.update(caption.id, caption))
          );
        }
      } else {
        if (caption.orientation === 0) {
          /*links = links.pipe(
            switchMap(() => this.captionService.delete(caption))
          );*/
        } else {
          links = links.pipe(
            switchMap(() => this.captionService.add(caption))
          );
        }
      }

      links.pipe(
        take(1)
      ).subscribe();
    });
  }
}
