<section class="l-siteContent" id="theTop">
	<div class="row align-center">
		<div class="small-12 medium-12 large-12 xxlarge-12 column">
			<div class="row l-dividerDecorative hide-for-large">
				<div class="column"></div>
				<div class="shrink column"></div>
				<div class="column"></div>
			</div>
			<section>
				<header class="l-headFrizDecor">
					<h2>{{ 'webshop.Szeretnél Bomo Art viszonteladó lenni?' | transloco }}</h2>
				</header>
				<article>
					<p>{{ 'webshop.Amennyiben saját üzlete van, és úgy érzi, a Bomo Art termékei is illeszkednének a kínálatba, írjon nekünk a bomo.sales@bomoart.com email címre, vagy hívjon minket a +36 70 362 0631 telefonszámon. Minden információt, ami a Franchise, vagy bármilyen együttműködési feltétellel kapcsolatos, kérésére elküldjük Önnek. Amennyiben saját üzlete van, és úgy érzi, a Bomo Art termékei is illeszkednének a kínálatba, írjon nekünk a bomo.sales@bomoart.com email címre, vagy hívjon minket a +36 70 362 0631 telefonszámon. Minden információt, ami a Franchise, vagy bármilyen együttműködési feltétellel kapcsolatos, kérésére elküldjük Önnek.' | transloco }}</p>
				</article>
        <article *ngIf="submitted">
          <p>{{ 'webshop.Köszönjük, hogy üzenetet küldtél nekünk. Hamarosan kapcsolatba lépünk veled!' | transloco }}</p>
        </article>

        <form class="form form--signUpWholesale form--inputTextTransformNone" *ngIf="!submitted"
					  data-abide novalidate>
					<div data-abide-error class="alert callout h-hidden">
						<p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
					</div>
					<div class="row">
						<div class="small-12 medium-6 large-12 xlarge-6 column">
							<label>{{ 'webshop.Ide írd a neved' | transloco }}
								<input [formControl]="nameControl" type="text" [placeholder]="'webshop.Név' | transloco">
								<span class="form-error" *ngIf="nameControl.hasError('required') && nameControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
							</label>
						</div>
						<div class="small-12 medium-6 large-12 xlarge-6 column">
							<label>{{ 'webshop.Ide írd a nevét, ha van már boltod' | transloco }}
								<input [formControl]="storeControl" type="text" [placeholder]="'webshop.Bolt neve' | transloco">
								<span class="form-error" *ngIf="storeControl.hasError('required') && storeControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
							</label>
						</div>
					</div>
					<div class="row">
						<div class="small-12 medium-6 large-12 xlarge-6 column">
							<label>{{ 'webshop.Ide írd az email címed' | transloco }}
								<input [formControl]="emailControl" type="text" [placeholder]="'webshop.E-mail' | transloco">
								<span class="form-error" *ngIf="emailControl.hasError('email') && emailControl.touched">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco }}</span>
                <span class="form-error" *ngIf="emailControl.hasError('required') && emailControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
              </label>
						</div>
						<div class="small-12 medium-6 large-12 xlarge-6 column">
							<label>{{ 'webshop.Honnan ismered a Bomot? ' | transloco }}
								<select [formControl]="knownControl">
									<option value="Instagram">{{ 'webshop.Instagram' | transloco }}</option>
									<option value="Facebook">{{ 'webshop.Facebook' | transloco }}</option>
									<option value="Internet">{{ 'webshop.Internet' | transloco }}</option>
									<option value="Magazinban olvastam róla">{{ 'webshop.Magazinban olvastam róla' | transloco }}</option>
									<option value="Kiállítás">{{ 'webshop.Kiállítás' | transloco }}</option>
									<option value="Vásár">{{ 'webshop.Vásár' | transloco }}</option>
									<option value="Vásárlóimtól hallottam">{{ 'webshop.Vásárlóimtól hallottam' | transloco }}</option>
									<option value="Ismerőstől halottam">{{ 'webshop.Ismerőstől halottam' | transloco }}</option>
								</select>
                <span class="form-error" *ngIf="knownControl.hasError('required') && knownControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
              </label>
						</div>
					</div>
					<div class="row">
						<div class="small-12 column">
							<label>{{ 'webshop.Kérjük, ossz meg velünk pár sort magadról' | transloco }}
								<textarea [formControl]="descriptionControl" name="name" rows="6" cols="80" [placeholder]="'Megjegyzés' | transloco"></textarea>
                <span class="form-error" *ngIf="descriptionControl.hasError('required') && descriptionControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>

              </label>
						</div>
					</div>
					<div class="row g-top-thick">
						<div class="small-12 medium-6 large-12 xlarge-6 column">
							<div class="text-right">
								<img class="l-hand" src="/assets/images/bomo_hand_gray.svg" alt="tovább">
								<button class="btn btn--basic" type="submit" (click)="sendMail()">{{ 'webshop.Küldés' | transloco }}</button>
							</div>
						</div>
					</div>
				</form>
			</section>
		</div>
	</div>
</section>
