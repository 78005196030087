import {Injectable} from '@angular/core';
import {BlogState, Store as BlogStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Blog} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {BlogQuery} from './query';
import {BlogCategoryService} from "../../blog-category/state/service";
import {ProductService} from "../../../products/product/state/service";
import {GalleryService} from "../../gallery/state/service";
import {Observable} from "rxjs";
import {getEntityType} from "@datorama/akita";
import {FormService} from "../../../components/service/form.service";

@Injectable({providedIn: 'root'})
export class BlogService extends EntityService<BlogStore, BlogState> {

  blogs$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Blog(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('Blog.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('blog.slug'),
        inputType: 'text',
        name: 'slug',
        listed: false,
        editable: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.blogCategoryService.blogCategories$,
        listed: true,
        label: this.translocoService.selectTranslate('blog.category'),
        name: 'category',
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('blog.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('blog.content'),
        inputType: 'text',
        name: 'content',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('blog.content2'),
        inputType: 'text',
        name: 'content2',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.galleryService.galleries$,
        listed: true,
        label: this.translocoService.selectTranslate('blog.gallery'),
        name: 'gallery',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productService.products$,
        listed: false,
        label: this.translocoService.selectTranslate('blog.product'),
        name: 'product',
        validations: []
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('blog.image1'),
        name: 'image1',
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('blog.image2'),
        name: 'image2',
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('common.createdAt'),
        name: 'createdAt',
        listed: true,
        editable: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: BlogQuery,
    private blogCategoryService: BlogCategoryService,
    private productService: ProductService,
    private galleryService: GalleryService,
    private formService: FormService,
    protected store: BlogStore) {
    super(store, Blog);
  }

  get resourceName(): string {
    return 'content/blog';
  }

  getLastOnes(limit: number): Observable<Blog[]> {
    return this.getHttp().get<Blog[]>(this.api + '/detail?c=' + limit + '&s=createdAt')
      .pipe(
        map(items => items.map(_blog => new Blog(_blog ?? {}))),
        take(1),
        shareReplay(1),
        tap(entities => {
          this.store.upsertMany(entities);
        }),
      );
  }

  getByCategorySlug(slug: string): Observable<Blog[]> {
    return this.getHttp().get<Blog[]>(this.api + '/detail?q[]=category.slug:eq:' + slug)
      .pipe(
        map(items => items.map(_blog => new Blog(_blog ?? {}))),
        take(1),
        shareReplay(1),
        tap(entities => {
          this.store.upsertMany(entities);
        }),
      );
  }

}
