import {Injectable} from '@angular/core';
import {TransporterPriceState, Store as TransporterPriceStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {TransporterPrice} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {TransporterPriceQuery} from './query';
import {TransporterService} from "../../transporter/state/service";
import {getEntityType} from "@datorama/akita";
import {Entity} from "../../../components/entity";

@Injectable({providedIn: 'root'})
export class TransporterPriceService extends EntityService<TransporterPriceStore, TransporterPriceState> {

  transporterPrices$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new TransporterPrice(item))),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('transporter-price.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('transporter-price.percent'),
        inputType: 'number',
        name: 'price',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('transporter-price.minPrice'),
        inputType: 'number',
        name: 'type',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.transporterService.transporters$,
        listed: false,
        label: this.translocoService.selectTranslate('transporter-price.transporter'),
        name: 'country',
        validations: [{
          name: 'required',
          validator: Validators.required,
          message$: this.translocoService.selectTranslate('form.required')
        }]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('transporter-price.validFrom'),
        name: 'transporter',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('transporter-price.validTo'),
        name: 'validTo',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: TransporterPriceQuery,
    private transporterService: TransporterService,
    protected store: TransporterPriceStore) {
    super(store, TransporterPrice);
  }

  get resourceName(): string {
    return 'webshop/transporter-price';
  }

  clearEntity(entity: TransporterPrice): TransporterPrice {
    return entity;
  }
}
