import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable} from 'rxjs';
import {Entity} from '../../entity';

@Component({
  selector: 'app-button', template: `
    <div>
      <button type="submit" mat-raised-button color="primary">{{ field.label | async | default: '' }}</button>
    </div>`, styles: []
})
export class ButtonComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  parentEntity$ = new Observable<Entity>();

  constructor() {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }

  ngOnInit() {
  }
}
