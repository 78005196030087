import {Component} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {Newsletter} from "../../../content/newsletter/state/model";
import {NewsletterService} from "../../../content/newsletter/state/service";
import {BlogService} from "../../../content/blog/state/service";
import {ActivatedRoute} from "@angular/router";
import {map, switchMap} from "rxjs/operators";
import {Blog} from "../../../content/blog/state/model";
import {HashMap} from "@datorama/akita";
import {HttpResponse} from "@angular/common/http";
import {HttpGetConfig} from "@datorama/akita-ng-entity-service/lib/types";

@Component({
  templateUrl: './list.html'
})
export class WebshopNewsletterListComponent {
  items$: Observable<Newsletter[]> = of([]);
  years: number[] = [];
  page$ = new BehaviorSubject<number>(1);
  maxPage$ = new BehaviorSubject<number>(999);
  perPage$ = new BehaviorSubject<number>(15);

  constructor(
    private service: NewsletterService,
    route: ActivatedRoute
  ) {
    this.items$ = route.params.pipe(
      switchMap(() => this.page$.pipe(
        switchMap(page => this.perPage$.pipe(
          switchMap((perPage) => this.service.get<Newsletter[]>(undefined, {
              params: {
                c: perPage,
                p: page,
                s: '-createdAt'
              } as HashMap<any>,
              mapResponseFn: (res: HttpResponse<Newsletter[]>): Newsletter[] | null => {
                this.maxPage$.next(Math.ceil((Number(res.headers.get('X-Total-Count'))) / perPage));
                return res.body;
              },
              observe: 'response',
            } as HttpGetConfig<Newsletter[]>).pipe(
              map(items => {
                return items;
              }))
          )
        ))
      )),
    );
  }

  paging($event: number) {
    this.page$.next($event);
  }

  isOuterUrl(url: string) {
    return url.startsWith('http');
  }
}
