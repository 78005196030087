import {Injectable} from '@angular/core';
import {StoreService} from './state/service';
import {StoreQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Store as StoreModel} from './state/model';
import {Store, StoreState} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<StoreModel, StoreState, StoreQuery, Store> {
  constructor(protected query: StoreQuery, protected api: StoreService) {
    super(query, api);
  }
}
