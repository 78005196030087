<section class="l-siteContent" id="theTop">
    <section class="feedbackMessage">
        <div class="row align-center">
            <div class="small-12 medium-10 large-6 xlarge-4 column">
                <div class="h-relative">
                    <img class="anim anim--fromLeft decorImg decorImg--404Left" src="/assets/images/decor/rocket.png" alt="decor">
                    <img class="anim anim--fromRight decorImg decorImg--404Right" src="/assets/images/decor/robot.png" alt="decor">
                </div>
                <div class="l-404">{{ 'webshop.404' | transloco }}</div>
                <h1>{{ 'webshop.Az oldal nem található' | transloco }}</h1>
                <img src="/assets/images/menu_heading_decor.svg" alt="decor">
                <div class="feedbackMessage-content g-top">
                    <span class="feedbackMessage-signature">{{ 'webshop.Bomo Art csapata' | transloco }}</span>
                </div>
            </div>
        </div>
    </section>
</section>
