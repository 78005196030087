import {Component, ViewChild} from '@angular/core';
import {Observable, tap} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {first, switchMap} from "rxjs/operators";
import {Store} from "../../../webshop/store/state/model";
import {StoreService} from "../../../webshop/store/state/service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {GoogleMap} from "@angular/google-maps";
import {FormService} from "../../../components/service/form.service";
import {GoogleService} from "../../../google-maps/service/maps.service";

@Component({
  templateUrl: './view.html',
  styleUrls: [
    'view.scss'
  ]
})
export class WebshopContactComponent {
  public items$: Observable<Store[]>;
  @ViewChild(GoogleMap) googleMap: GoogleMap | undefined;
  options: google.maps.MapOptions = {
    zoom: 15,
    styles: [{
      "featureType": "all",
      "elementType": "geometry.fill",
      "stylers": [{
        "weight": "2.00"
      }]
    }, {
      "featureType": "all",
      "elementType": "geometry.stroke",
      "stylers": [{
        "color": "#9c9c9c"
      }]
    }, {
      "featureType": "all",
      "elementType": "labels.text",
      "stylers": [{
        "visibility": "on"
      }]
    }, {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [{
        "color": "#f2f2f2"
      }]
    }, {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#ffffff"
      }]
    }, {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#ffffff"
      }]
    }, {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "road",
      "elementType": "all",
      "stylers": [{
        "saturation": -100
      }, {
        "lightness": 45
      }]
    }, {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#eeeeee"
      }]
    }, {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#7b7b7b"
      }]
    }, {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [{
        "color": "#ffffff"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [{
        "visibility": "simplified"
      }]
    }, {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "water",
      "elementType": "all",
      "stylers": [{
        "color": "#46bcec"
      }, {
        "visibility": "on"
      }]
    }, {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#c8d7d4"
      }]
    }, {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#070707"
      }]
    }, {
      "featureType": "water",
      "elementType": "labels.text.stroke",
      "stylers": [{
        "color": "#ffffff"
      }]
    }]
  };

  group: FormGroup;
  nameControl: FormControl;
  emailControl: FormControl;
  contentControl: FormControl;
  contactForm: FormGroup;
  submitted: boolean = false;

  constructor(
    service: StoreService,
    route: ActivatedRoute,
    private formService: FormService,
    fb: FormBuilder,
    private http: HttpClient,
    public googleApi: GoogleService,
  ) {

    this.contentControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.nameControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.emailControl = fb.control('', formService.bindValidations(formService.getValidations(['required', 'email'])));
    this.contactForm = fb.group({});

    this.contactForm.addControl('name', this.nameControl);
    this.contactForm.addControl('email', this.emailControl);
    this.contactForm.addControl('content', this.contentControl);

    this.items$ = service.storesWithGallery$;
  }

  center(store: Store): google.maps.LatLngLiteral {
    return {lat: store.latitude, lng: store.longitude};
  }

  sendMail() {
    if (this.contactForm.valid) {
      this.http.post(environment.apiUrl + '/contact', {
        email: this.emailControl.value,
        content: this.contentControl.value,
        name: this.nameControl.value
      }).pipe(
        first(),
        tap(() => this.submitted = true)
      ).subscribe();
    } else {
      this.formService.validateAllFormFields(this.contactForm);
    }
  }
}
