import {Component, Injector} from '@angular/core';
import {ResellerState, Store as ResellerStore} from './state/store';
import {Reseller} from './state/model';
import {ResellerService as ResellerService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {ResellerQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class ResellerDetailComponent extends EntityDetailComponent<Reseller, ResellerState, ResellerStore> {

  constructor(
    public injector: Injector,
    service: ResellerService,
    protected query: ResellerQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
