import {Entity} from '../../../components/entity';
import {Transporter} from "../../transporter/state/model";

export class TransporterDiscount extends Entity {
  percent: number;
  minPrice: number;
  validFrom: Date;
  validTo: Date;
  transporter: Transporter;

  constructor({
                id,
                percent,
                minPrice,
                validFrom,
                validTo,
                transporter,
                createdAt, updatedAt
              }: Partial<TransporterDiscount>) {
    super({id, createdAt, updatedAt});
    this.percent = percent ?? 0;
    this.minPrice = minPrice ?? 0;

    this.transporter = new Transporter(transporter ?? {});

    this.validFrom = validFrom ? new Date(validFrom) : null;
    this.validTo = validTo ? new Date(validTo) : null;

  }

  getNameProperty(): string {
    return 'transporter';
  }
}
