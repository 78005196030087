import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {CommonModule, formatCurrency, getCurrencySymbol} from '@angular/common';
import {RoutingModule} from './routing';
import {MaterialModule} from '../material/material.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {Module as ComponentsModule} from '../components/module';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {StoreViewComponent} from "./store/view";
import {StoreListComponent} from "./store/list";
import {StoreDetailComponent} from "./store/detail";
import {ResellerListComponent} from "./reseller/list";
import {ResellerDetailComponent} from "./reseller/detail";
import {ResellerViewComponent} from "./reseller/view";
import {ShopListComponent} from "./shop/list";
import {ShopDetailComponent} from "./shop/detail";
import {ShopViewComponent} from "./shop/view";
import {OrderViewComponent} from "./order/view";
import {OrderListComponent} from "./order/list";
import {OrderDetailComponent} from "./order/detail";
import {CouponListComponent} from "./coupon/list";
import {CouponViewComponent} from "./coupon/view";
import {CouponDetailComponent} from "./coupon/detail";
import {CategoryDetailComponent} from "./category/detail";
import {CategoryListComponent} from "./category/list";
import {CategoryViewComponent} from "./category/view";
import {ConfigComponent} from "./config/detail";
import {TagListComponent} from "./tag/list";
import {TagDetailComponent} from "./tag/detail";
import {PaymentModeDetailComponent} from "./payment-mode/detail";
import {PaymentModeListComponent} from "./payment-mode/list";
import {PaymentModeViewComponent} from "./payment-mode/view";
import {CountryListComponent} from "./country/list";
import {CountryDetailComponent} from "./country/detail";
import {LanguageListComponent} from "./language/list";
import {LanguageDetailComponent} from "./language/detail";
import {CurrencyListComponent} from "./currency/list";
import {CurrencyDetailComponent} from "./currency/detail";
import {ExpressionDetailComponent} from "./expression/detail";
import {ExpressionListComponent} from "./expression/list";
import {TransporterListComponent} from "./transporter/list";
import {TransporterDetailComponent} from "./transporter/detail";
import {ManufacturableExportComponent} from "./order/manufacturableExport";
import {ExcelExportComponent} from "./order/excelExport";
import {WebshopClientModule} from "../webshop-client/module";
import {TransporterDiscountDetailComponent} from "./transporter-discount/detail";
import {TransporterDiscountViewComponent} from "./transporter-discount/view";
import {TransporterDiscountListComponent} from "./transporter-discount/list";
import {FedexModule} from "../fedex/module";
import {ConfigService} from "./config/state/service";
import {Observable, of} from "rxjs";


@Pipe({
  name: 'adminCurrency',
})
export class AdminCurrencyPipe implements PipeTransform {

  constructor(
    private configService: ConfigService,
    private translatorService: TranslocoService) {
  }

  transform(
    value: number,
    currencyCode: string = 'Ft',
    digitsInfo: string = '1.2-2',
    locale: string = 'hu',
  ): string {

    return formatCurrency(
      value,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    );
  }
}

@NgModule({
  declarations: [
    AdminCurrencyPipe,
    StoreViewComponent,
    StoreListComponent,
    StoreDetailComponent,
    ResellerListComponent,
    ResellerDetailComponent,
    ResellerViewComponent,
    ShopListComponent,
    ShopDetailComponent,
    ShopViewComponent,
    OrderViewComponent,
    OrderListComponent,
    OrderDetailComponent,
    CouponListComponent,
    CouponViewComponent,
    CouponDetailComponent,
    CategoryDetailComponent,
    CategoryListComponent,
    CategoryViewComponent,
    ConfigComponent,
    TagListComponent,
    TagDetailComponent,
    PaymentModeDetailComponent,
    PaymentModeListComponent,
    PaymentModeViewComponent,
    CountryListComponent,
    CountryDetailComponent,
    LanguageListComponent,
    LanguageDetailComponent,
    CurrencyListComponent,
    CurrencyDetailComponent,
    ExpressionDetailComponent,
    ExpressionListComponent,
    TransporterListComponent,
    TransporterDetailComponent,
    ManufacturableExportComponent,
    ExcelExportComponent,
    TransporterDiscountDetailComponent,
    TransporterDiscountListComponent,
    TransporterDiscountViewComponent
  ],
  exports: [
    AdminCurrencyPipe
  ],
    imports: [
        ComponentsModule,
        CommonModule,
        MaterialModule,
        RoutingModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        TranslocoModule,
        WebshopClientModule,
        FedexModule,
    ],
})
export class WebshopModule {
}
