import {Injectable} from '@angular/core';
import {PopupService} from './state/service';
import {PopupQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Popup} from './state/model';
import {PopupState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Popup, PopupState, PopupQuery, Store> {
  constructor(protected query: PopupQuery, protected api: PopupService) {
    super(query, api);
  }
}
