import {Entity, Image} from '../../../components/entity';
import {Gallery} from '../../gallery/state/model';
import {Product} from "../../../products/product/state/model";
import {BlogCategory} from "../../blog-category/state/model";

export class Blog extends Entity {
  title: string;
  category: BlogCategory;
  gallery: Gallery;
  slug: string;
  content: string;
  content2: string;
  product: Product;

  image1: Image;
  image2: Image;

  constructor({id, slug, image1, image2, category, content, content2, gallery, title, product, createdAt, updatedAt}: Partial<Blog>) {
    super({id, createdAt, updatedAt});
    this.slug = slug ?? '';
    this.title = title ?? '';
    this.content = content ?? '';
    this.content2 = content2 ?? '';

    this.image1 = new Image(image1 ?? {});
    this.image2 = new Image(image2 ?? {});

    this.gallery = new Gallery(gallery ?? {});
    this.category = new BlogCategory(category ?? {});
    this.product = new Product(product ?? {});
  }

  getNameProperty(): string {
    return 'slug';
  }
}
