import {Injectable} from '@angular/core';
import {PriceListService} from './state/service';
import {PriceListQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {PriceList} from './state/model';
import {PriceListState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<PriceList, PriceListState, PriceListQuery, Store> {
  constructor(protected query: PriceListQuery, protected api: PriceListService) {
    super(query, api);
  }
}
