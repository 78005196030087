import {Component, Injector} from '@angular/core';
import {GalleryState, Store} from './state/store';
import {Gallery} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {GalleryQuery} from './state/query';
import {GalleryService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class GalleryListComponent extends EntityListComponent<Gallery, GalleryState, Store> {

  translationDomain = 'gallery';

  constructor(
    public injector: Injector,
    protected service: GalleryService,
    query: GalleryQuery,
    private GalleryFilterService: FilterService) {
    super(injector, service, query, GalleryFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
