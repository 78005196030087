import {Component} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable, of, switchMap} from "rxjs";
import {catchError, first, map, shareReplay, tap} from "rxjs/operators";
import {GoogleAnalyticsService} from "../../../google-analytics/service/analytics.service";
import {OrderService} from "../../../webshop/order/state/service";
import {Entity, IObjectKeys} from "../../../components/entity";

@Component({
  templateUrl: './component.html'
})
export class PaymentComponent {

  isValid$: Observable<boolean>;
  orderId: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private orderService: OrderService,
    private googleService: GoogleAnalyticsService
  ) {
    this.isValid$ = route.params.pipe(
      switchMap(params => route.queryParams.pipe(
        switchMap(queryParams => {
          this.orderId = params.orderId;
          const paymentMode = params.paymentMode;

          if (paymentMode === 'borgun') {
            return of(queryParams.status !== 'failed');
          }
          return this.validatePayapal$(queryParams);
        }),
        tap(success => {
          if (success) {


            /*
             gtag("event", "purchase", {
                transaction_id: "T_12345_1",
                affiliation: "Google Merchandise Store",
                value: 25.42,
                tax: 4.90,
                shipping: 5.99,
                currency: "USD",
                coupon: "SUMMER_SALE",
                items: [
                // If someone purchases more than one item,
                // you can add those items to the items array
                 {
                  item_id: "SKU_12345",
                  item_name: "Stan and Friends Tee",
                  affiliation: "Google Merchandise Store",
                  coupon: "SUMMER_FUN",
                  currency: "USD",
                  discount: 2.22,
                  index: 0,
                  item_brand: "Google",
                  item_category: "Apparel",
                  item_category2: "Adult",
                  item_category3: "Shirts",
                  item_category4: "Crew",
                  item_category5: "Short sleeve",
                  item_list_id: "related_products",
                  item_list_name: "Related Products",
                  item_variant: "green",
                  location_id: "L_12345",
                  price: 9.99,
                  quantity: 1
                }]
            });

             */

            this.orderService.getById(this.orderId).pipe(
              tap(order => {
                this.googleService.eventEmitter('purchase', {
                  transaction_id: order.id,
                  affiliation: "Webshop",
                  value: order.sumDiscountedPrice,
                  tax: order.billingCountry.vat / 100 * order.sumDiscountedPrice,
                  shipping: order.shippingPrice,
                  currency: order.currency,
                  coupon: order.coupon.code,
                  items: order.items.map(item => {

                    let params: IObjectKeys = {
                      item_id: item.product.barCode ?? item.product.id,
                      item_name: item.product.name,
                      currency: order.currency,
                      item_list_id: item.product.name,
                      item_list_name: item.product.name,
                      item_variant: item.productVariant.productParameterTypeCounts.find(ppt => ppt.productParameterType.productParameter.isControl)?.productParameterType.name,
                      price: item.price,
                      quantity: item.quantity
                    };
                    let counter = 1;
                    item.productVariant.productParameterTypeCounts.forEach(pptc => {
                      if (counter === 1) {
                        params.item_category = pptc.productParameterType.name;
                      } else {
                        params['item_category' + counter] = pptc.productParameterType.name;
                      }
                      counter++;
                    });

                    return params;
                  })
                });
              }),
              first(),
            ).subscribe();
          }
        })
      )),
      shareReplay(1)
    );
  }

  validatePayapal$(queryParams: Params) {
    return this.http.get(environment.apiUrl + '/paypal/confirm/' + queryParams.token).pipe(
      map(() => true),
      catchError(err => of(false)),
    );
  }

}
