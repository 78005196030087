import {Component, Input} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'app-image',
  templateUrl: './image.html',
  styleUrls: ['image.scss']
})
export class ImageComponent {

  @Input() small: string;
  @Input() medium: string;
  @Input() large: string;
  @Input() xlarge: string;
  @Input() xxlarge: string;

  small$ = this.breakPointObserver.observe(Breakpoints.XSmall);
  medium$ = this.breakPointObserver.observe(Breakpoints.Small);
  large$ = this.breakPointObserver.observe(Breakpoints.Medium);
  xlarge$ = this.breakPointObserver.observe(Breakpoints.Large);
  xxlarge$ = this.breakPointObserver.observe(Breakpoints.XLarge);

  constructor(
    public breakPointObserver: BreakpointObserver
  ) {

  }

}
