import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable} from 'rxjs';
import {Entity} from '../../entity';

@Component({
  selector: 'app-checkbox',
  template: `
    <div *ngIf="field.name" [formGroup]="group">
      <mat-checkbox [formControlName]="field.name">{{ field.label | async | default: '' }}</mat-checkbox>
    </div>`,
  styleUrls: ['common.scss', 'checkbox.scss'],
})
export class CheckboxComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  parentEntity$ = new Observable<Entity>();

  constructor() {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }

  ngOnInit() {
  }
}
