import {Entity} from '../../../components/entity';

export class Expression extends Entity {

  title: string;

  constructor({
                id,
                title,
                createdAt, updatedAt
              }: Partial<Expression>) {
    super({id, createdAt, updatedAt});

    this.title = title ?? '';
  }

  getNameProperty(): string {
    return 'title';
  }
}
