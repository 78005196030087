import {HttpAddConfig, HttpUpdateConfig, mapResponse, NgEntityService} from '@datorama/akita-ng-entity-service';
import {EntityState, EntityStore, getEntityType, getIDType} from '@datorama/akita';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay, take, tap} from 'rxjs/operators';
import {HttpGetConfig} from '@datorama/akita-ng-entity-service/lib/types';
import {Entity} from './entity';
import {DynamicFormComponent, FormTab, FormTabInterface} from './form/dynamic-form';
import {FieldConfig} from './form/field.interface';
// @ts-ignore
import * as moment from "moment-timezone";

export type Constructor<T extends {} = {}> = new (...args: any[]) => T;

export interface FieldListInterface {
  fields: FieldConfig[];
  formTitle?: Observable<string>;
  entityTitle?: Observable<string>;
  cancelTitle?: Observable<string>;
  submitTitle?: Observable<string>;
  exportPath?: string;
}

export class EntityService<S extends EntityStore<St>, St extends EntityState> extends NgEntityService<St> {

  protected editForm: FieldListInterface = {fields: []};
  protected tabs: Map<string, FormTab> = new Map<string, FormTab>();

  constructor(
    protected store: S,
    protected _constructor: Constructor<{}>
  ) {
    super(store);
  }

  getOrCreateTab(tab: FormTabInterface) {
    if (this.tabs.has(tab.id)) {
      return this.tabs.get(tab.id);
    }

    this.tabs.set(tab.id, tab);
    return this.tabs.get(tab.id);
  }

  getEntityTitle() {
    return this.editForm.entityTitle;
  }

  getExportPath() {
    return this.api + '/export/xls';
  }

  getFields() {
    return this.editForm.fields;
  }

  getListingColumns() {
    return this.editForm?.fields?.map(fieldConfigs => fieldConfigs.listed ? fieldConfigs.name : '').filter(param => param !== '') ?? ['id'];
  }

  generateEditForm(form: DynamicFormComponent, entity$: Observable<Entity>, subForm = false): DynamicFormComponent {
    form.fields = this.editForm.fields.filter(item => item.editable) ?? [];
    form.formTitle$ = this.editForm.formTitle ?? entity$.pipe(map(entity => entity.toString() as string));
    form.cancelTitle$ = this.editForm.cancelTitle ?? of('');
    form.submitTitle$ = this.editForm.submitTitle ?? of('');
    form.entity$ = entity$;
    form.subForm = subForm;

    return form;
  }

  generateSearchForm(form: DynamicFormComponent): DynamicFormComponent {
    return form;
  }

  getEntityParams() {
    return Object.getOwnPropertyDescriptors(new this._constructor({}));
  }

  getEntityNameParam(attrib: string): string {
    const _attrib = ((new this._constructor({})) as Entity)[attrib];
    if (_attrib instanceof Entity) {
      return _attrib.getNameProperty();
    }
    return '';
  }

  factory(partial?: Partial<getEntityType<St>>): Entity {
    return new this._constructor(partial ?? {}) as getEntityType<St>;
  }

  getAll(): Observable<getEntityType<St>[]> {
    return super.get<St[]>().pipe(
      map(items => items.map(item => new this._constructor(item) as getEntityType<St>)),
      tap(entities => {
        this.store.upsertMany(entities);
      }),
    );
  }

  getOne<T extends Entity>(config?: HttpGetConfig<T>): Observable<T> {
    return super.get(config).pipe(
      map(item => new this._constructor(item) as T),
      tap(entities => {
        // @ts-ignore
        this.store.upsertMany([entities]);
      }),
    );
  }

  get<T>(id?: getIDType<St>, config?: HttpGetConfig<T>) {
    if (id) {
      return this.getHttp().get<getEntityType<St>[]>(this.api + '/' + id, config)
        .pipe(
          mapResponse(config),
          map(item => {
            return new this._constructor(item) as getEntityType<St>;
          }),
          take(1),
          shareReplay(1),
          tap(entities => {
            this.store.upsertMany([entities]);
          }),
          map(item => [item])
        );
    } else {
      return this.getHttp().get<getEntityType<St>[]>(this.api, config)
        .pipe(
          mapResponse(config),
          map(items => {
            return (items as getEntityType<St>[]).map(item => new this._constructor(item) as getEntityType<St>);
          }),
          tap(entities => {
            // const _state = this.store.getValue() as EntityState<any>;
            if (entities.length) {
              this.store.upsertMany(entities);
            }
          })
        );
    }
  }

  clearEntity(entity: Partial<getEntityType<St>>) {
    const _entity = entity as unknown as Entity;
    this.editForm.fields.forEach(field => {
      const fieldName = field.name;
      if (fieldName && fieldName in _entity) {

        if (field.type === 'image' && _entity[field.name] !== null) {
          if (!Object.getOwnPropertyNames(_entity[field.name]).some(propName => _entity[field.name][propName] !== "")) {
            _entity[field.name] = null;
            //delete _entity[field.name];
          }
        } else {

          if (_entity[fieldName] === null || _entity[fieldName] === undefined || (field.type === 'date' && !_entity[fieldName])/* || (Array.isArray(_entity[fieldName]) && (_entity[fieldName] as Array<any>).length < 1)*/) {
            if (field.type === 'entitySelect' && !field.multiple) {
              _entity[fieldName] = null;
            } else {
              delete _entity[fieldName];
            }
          } else {
            if (field.type === 'entitySelect' && !field.multiple && _entity[fieldName]?.hasOwnProperty('id')) {
              if (!(_entity[fieldName] as Entity).id) {
                _entity[fieldName] = null;
              } else {
                _entity[fieldName] = {id: (_entity[fieldName] as Entity).id};
              }
            }
            if (field.type === 'input' && field.inputType === 'number') {
              _entity[fieldName] = parseFloat(_entity[fieldName] as string);
            }
            if (field.type === 'date') {
              const date = moment.tz(_entity[fieldName] as string, 'Europe/Budapest');
              _entity[fieldName] = date.toISOString(true);
            }
          }
        }
      }
    });

    for (const entityKey in _entity) {
      if (!this.editForm.fields.find(field => field.name === entityKey) && _entity.hasOwnProperty(entityKey) && entityKey !== 'id') {
        delete _entity[entityKey];
      }
      if (entityKey === 'id' && !_entity[entityKey]) {
        delete _entity[entityKey];
      }

      if (Array.isArray(_entity[entityKey])) {
        _entity[entityKey] = Object.assign([], _entity[entityKey]);
        for (const _entityKey in (_entity[entityKey] as Entity[])) {
          _entity[entityKey][_entityKey] = this.simplifyEntity(_entity[entityKey][_entityKey]);
        }
      }
    }
    return _entity;
  }

  simplifyEntity(entity: Entity) {
    if (!entity) {
      return entity;
    }
    if (entity.hasOwnProperty('id')) {
      if (!entity.id) {
        delete entity['id'];
      } else {
        entity = {id: entity.id} as unknown as Entity;
      }
    } else {
      const names = Object.getOwnPropertyNames(entity);
      for (const _entityKey in names) {
        if (typeof entity[names[_entityKey]] === 'object') {
          entity[names[_entityKey]] = this.simplifyEntity(entity[names[_entityKey]] as Entity);
        } else {
        }
      }
    }

    return entity;
  }

  /**
   * @inheritDoc
   */
  update<T>(id: getIDType<St>, entity: Partial<getEntityType<St>>, config?: HttpUpdateConfig<T>): Observable<T> {
    const _entity = this.clearEntity(entity);
    _entity.id = id;
    return super.update<T>(id, _entity as unknown as Partial<getEntityType<St>>, config)
      .pipe(
        map(item => {
          return new this._constructor(item) as getEntityType<St>;
        }),
        map(item => {
          item.id = id;
          this.store.upsert(item.id, item);
          return item as T;
        })
      );
  }

  add<T>(entity: getEntityType<St>, config?: HttpAddConfig<T>): Observable<T> {
    const _entity = this.clearEntity(entity);
    return super.add(_entity as unknown as getEntityType<St>, config).pipe(
      map(item => new this._constructor(item) as getEntityType<St>),
      tap(item => this.store.upsertMany([item])),
      map(item => item as T)
    );
  }

  getBySlug(slug: string): Observable<getEntityType<St>> {
    return this.getHttp().get<getEntityType<St>[]>(this.api + '/detail?q[]=slug:eq:' + slug)
      .pipe(
        map(items => {
          if (items.length > 0) {
            return new this._constructor(items.pop()) as getEntityType<St>;
          } else {
            return new this._constructor({}) as getEntityType<St>;
          }
        }),
        take(1),
        shareReplay(1),
        tap(entity => {
          this.store.upsertMany([entity as getEntityType<St>]);
        }),
      );
  }

  getByAttribute(attrib: {name: string, value: string}): Observable<getEntityType<St>> {
    return this.getHttp().get<getEntityType<St>[]>(this.api + '/detail?q[]=' + attrib.name + ':eq:' + attrib.value)
      .pipe(
        map(items => {
          if (items.length > 0) {
            return new this._constructor(items.pop()) as getEntityType<St>;
          } else {
            return new this._constructor({}) as getEntityType<St>;
          }
        }),
        take(1),
        shareReplay(1),
        tap(entity => {
          this.store.upsertMany([entity as getEntityType<St>]);
        }),
      );
  }

  getById(id: string): Observable<getEntityType<St>> {
    return this.getHttp().get<getEntityType<St>[]>(this.api + '/' + id)
      .pipe(
        map(item => {
          /*if (items.length > 0) {
            return new this._constructor(items.pop()) as getEntityType<St>;
          } else {
            return new this._constructor({}) as getEntityType<St>;
          }*/
          return new this._constructor(item) as getEntityType<St>;
        }),
        take(1),
        shareReplay(1),
        tap(entity => {
          this.store.upsertMany([entity as getEntityType<St>]);
        }),
      );
  }

  downloadXLSFile(data: BlobPart, fileName = 'export') {
    //Download type xls
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    //Download type: CSV
    const blob = new Blob([data], {type: contentType});
    const url = window.URL.createObjectURL(blob);
    //Open a new window to download
    //window.open(url);
    //return;

    //Download by dynamically creating a tag
    const a = document.createElement('a');
    a.href = url;
    // a.download = fileName;
    a.download = fileName + '.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }


  getStore() {
    return this.store;
  }

}
