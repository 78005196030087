import {AfterViewInit, Component, Injector} from '@angular/core';
import {ProductState, Store as ProductStore} from './state/store';
import {Product} from './state/model';
import {ProductService as ProductService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ProductQuery} from './state/query';
import {ProductParameter} from "../product-parameter/state/model";
import {forkJoin, merge, Observable, of, startWith, switchMap, tap} from "rxjs";
import {
  productParameterControl,
  productParameterTypeImageQuery,
  productParameterTypeQuery
} from "../../webshop-client/webshop/products/component";
import {ProductParameterTypeService} from "../product-parameter-type/state/service";
import {FormService} from "../../components/service/form.service";
import {ProductVariant} from "../product-variant/state/model";
import {ProductParameterTypeCount} from "../product-parameter-type-count/state/model";
import {ProductParameterFormat} from "../product-parameter-format/state/model";
import {ProductParameterType} from "../product-parameter-type/state/model";
import {FieldConfig} from "../../components/form/field.interface";
import {distinctUntilChanged, map, shareReplay, take} from "rxjs/operators";
import {ProductParameterFormatImageService} from "../product-parameter-format-image/state/service";
import {ProductParameterFormatImage} from "../product-parameter-format-image/state/model";
import {ProductParameterImage} from "../product-parameter-image/state/model";
import {Image} from "../../components/entity";
import {BarcodeService} from "../barcode/state/service";
import {Barcode} from "../barcode/state/model";
import {ArrayDataSource, DataSource} from "@angular/cdk/collections";

interface imageControl {
  productParameterType: ProductParameterType,
  format: ProductParameterFormat,
  control: FormControl
}

interface barcodeControl {
  productParameterTypes: ProductParameterType[],
  control: FormControl
}

@Component({
  templateUrl: 'detail.html',
  styleUrls: [
    'detail.scss',
    '../../components/entity-detail/component.scss'
  ]
})
export class ProductDetailComponent extends EntityDetailComponent<Product, ProductState, ProductStore> implements AfterViewInit {

  activeTab = 'main';
  imageControls: imageControl[] = [];
  product: Product | undefined;

  productParameterTypes: productParameterTypeQuery[] = [];
  defaultVariantGroup: FormGroup;
  controls: productParameterControl[] = [];
  productParameterTypeImages: productParameterTypeImageQuery[] = [];

  customImagesGroup: FormGroup;
  customImages: ProductParameterImage[];
  customImages$ = this.entity$.pipe(
    switchMap(product => this.productService.getImagesByProduct(product)),
    tap(images => {
      this.customImages = images;
    }),
    shareReplay(1)
  );
  barcodes$: Observable<ArrayDataSource<Barcode>> = of(new ArrayDataSource([] as Barcode[]));
  actualBarcodes: Barcode[] = [];
  origBarcodes: Barcode[] = [];
  barcodesGroup: FormGroup;
  barcodeProductParameterControl: FormControl;
  barcodeControls: barcodeControl[] = [];
  barcodeColumns: string[] = [];

  constructor(
    public injector: Injector,
    private productService: ProductService,
    protected query: ProductQuery,
    public formService: FormService,
    private productParameterTypeService: ProductParameterTypeService,
    private imageService: ProductParameterFormatImageService,
    private barcodeService: BarcodeService,
    protected formBuilder: FormBuilder) {
    super(injector, productService, query);

    this.defaultVariantGroup = formBuilder.group({});
    this.barcodesGroup = formBuilder.group({});
    this.customImagesGroup = formBuilder.group({});
    this.barcodeProductParameterControl = formBuilder.control([]);
    this.barcodesGroup.addControl('productParameterTypes', this.barcodeProductParameterControl);

    this.entity$ = this.entity$.pipe(
      tap(entity => {
        this.product = entity
      }),
      shareReplay(1)
    );

  }


  navigateToParent() {
    if (this.product.id && !this.savedEntity?.id) {
      this.savedEntity = this.product;
    }
    this.router.navigate(['../' + this.savedEntity?.id], {relativeTo: this.route}).then(() => {
      window.location.reload();
    });
  }

  ngAfterViewInit() {
    this.barcodes$ = this.entity$.pipe(
      switchMap(product => {
        if (product.productParameters.length) {
          return forkJoin(product.productParameters.map(ppt => this.parameterTypes$(ppt, product))).pipe(
            map(() => product)
          ).pipe(
            switchMap(product => this.barcodeService.getByProduct(product)),
          );
        }
        return this.barcodeService.getByProduct(product);
      }),
      take(1),
      tap(barcodes => {
        const pps = [];

        this.origBarcodes = barcodes;
        barcodes.forEach(bc => {
          bc.productParameterTypes.forEach(ppt => {
            if (!pps.find(_pp => _pp.id === ppt.productParameter.id)) {
              pps.push(ppt.productParameter);
            }
          });
        });

        let _ppts = [];
        pps.forEach(item => {
          _ppts = _ppts.concat(this.productParameterTypes.find(_item => _item.productParameter.id === item.id)?.types ?? []);
        });
        const ppts = this.mixProductParameterTypes(_ppts, []);

        ppts.forEach(item => {
          if (!item.length) {
            return;
          }
          if (!barcodes.find(barcode => barcode.productParameterTypes.every(ppty => item.find(_item => _item.id === ppty.id)))) {
            barcodes.push(new Barcode({
              product: this.product,
              productParameterTypes: item,
              barcode: ''
            }));
          }
        });

        barcodes = barcodes.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.productParameterTypes.every(ppt => value.productParameterTypes.find(_ppt => _ppt.id === ppt.id)
            ))
        ));
        this.barcodeProductParameterControl.setValue(pps)
      }),
      switchMap(barcodes => this.barcodeProductParameterControl.valueChanges.pipe(
        startWith(barcodes.reduce((agg, curr) => {
          curr.productParameterTypes.forEach(_ppt => {
            if (!agg.find(ppt => ppt.id === _ppt.productParameter.id)) {
              agg.push(_ppt.productParameter);
            }
          });
          return agg;
        }, [] as ProductParameter[])),
        distinctUntilChanged(),
        map(value => value.filter(_value => {
          return !Array.isArray(_value);
        })),
        switchMap((value: ProductParameter[]) => {
          if (!value.length) {

            this.barcodeColumns = [];
            return of([]);
          }
          this.barcodeColumns = [];
          (value as ProductParameter[]).forEach(item => this.barcodeColumns.push((item.id as string)));
          this.barcodeColumns.push('barcode');
          this.barcodeColumns.push('stock');
          return forkJoin(value.map(item => this.parameterTypes$(item, this.product))).pipe(
            map(() => value)
          )
        }),
        map((value: ProductParameter[]) => {
          if (this.actualBarcodes.length) {
            barcodes = this.actualBarcodes;
          }
          let current = barcodes.reduce((agg, curr) => {
            curr.productParameterTypes.forEach(_ppt => {
              if (!agg.find(ppt => ppt.id === _ppt.productParameter.id)) {
                agg.push(_ppt.productParameter);
              }
            });
            return agg;
          }, [] as ProductParameter[]);

          if (value.length !== current.length) {
            barcodes = [];
            let _ppts = [];
            value.forEach(item => {
              _ppts = _ppts.concat(this.productParameterTypes.find(_item => _item.productParameter.id === item.id)?.types ?? []);
            });
            const ppts = this.mixProductParameterTypes(_ppts, []);

            ppts.forEach(item => {
              if (!item.length) {
                return;
              }
              barcodes.push(new Barcode({
                product: this.product,
                productParameterTypes: item,
                barcode: ''
              }));
            });

            this.barcodeControls = [];
          } else {
            let _ppts = [];
            value.forEach(item => {
              _ppts = _ppts.concat(this.productParameterTypes.find(_item => _item.productParameter.id === item.id)?.types ?? []);
            });
            const ppts = this.mixProductParameterTypes(_ppts, []);

            if (barcodes.length < ppts.length) {
              const oldCodes = barcodes;
              barcodes = [];
              ppts.forEach(item => {
                if (!item.length) {
                  return;
                }
                barcodes.push(new Barcode({
                  product: this.product,
                  productParameterTypes: item,
                  barcode: oldCodes.find(bc => bc.productParameterTypes.every(_ppts => item.find(__ppts => __ppts.id === _ppts.id)) && item.every(_ppts => bc.productParameterTypes.find(__ppts => __ppts.id === _ppts.id)))?.barcode ?? ''
                }));
              });
            }
          }

          this.actualBarcodes = barcodes;
          return new ArrayDataSource(barcodes.filter((item, index, self) => {
            return index === self.findIndex((other) => this.barcodesEqual(item, other));
          }));
        })
      )),
      shareReplay(1)
    );
  }

  mixProductParameterTypes(_productParameterTypes: ProductParameterType[], _result: ProductParameterType[][]) {
    const productParameters = [];
    _productParameterTypes.forEach(type => {
      if (!productParameters.find(pp => pp.id === type.productParameter.id)) {
        productParameters.push(type.productParameter);
      }
    });

    let result = [];
    productParameters.forEach(pp => {
      const productParameterTypes = _productParameterTypes.filter(ppt => ppt.productParameter.id === pp.id);
      const remaining = _productParameterTypes.filter(ppt => ppt.productParameter.id !== pp.id);

      if (_result.length === 0) {
        result = productParameterTypes.map(pp => [pp]);
      } else {
        result = [];

        _result.forEach(types => {
          productParameterTypes.forEach(ppt => {
            const _clone = Object.assign([], types);
            _clone.push(ppt);
            result.push(_clone);
          });
        });
      }

      if (remaining.length) {
        result = this.mixProductParameterTypes(remaining, result);
      }
    });

    return result;

  }

  parameterTypes$(parameter: ProductParameter, product: Product) {
    if (!this.productParameterTypes.find(index => index.productParameter.id === parameter.id)) {
      this.productParameterTypes.push({
        productParameter: parameter,
        types: [],
        query$: this.productParameterTypeService.getByProductParameter(parameter).pipe(
          tap(types => {
            const ppty = product.defaultVariant.productParameterTypeCounts.find(ptc => ptc.productParameterType.productParameter.id === parameter.id);
            if (ppty) {
              this.getControl(parameter).setValue(ppty.productParameterType);
            }
            if (types.length) {
              const _item = this.productParameterTypes.find(item => item.productParameter.id === types[0].productParameter.id)
              if (_item) {
                _item.types = types;
              }
            }
          }),
          shareReplay(1)
        )
      });
    }

    return this.productParameterTypes.find(index => index.productParameter.id === parameter.id).query$;
  }

  getControl(parameter: ProductParameter) {
    let ctrl = this.controls.find(_ctrl => _ctrl.productParameter.id === parameter.id);
    if (!ctrl) {
      ctrl = {
        productParameter: parameter,
        control: this.formBuilder.control('')
      }
      if (parameter.isControl && parameter.formats.length) {
      }

      this.controls.push(ctrl);
    }
    return ctrl.control;
  }

  saveProductVariant(_product: Product) {
    const product = new Product(_product);
    const productVariant = new ProductVariant({});
    product.productParameters.forEach(ppm => {
      const control = this.getControl(ppm);
      if (control.value) {
        productVariant.productParameterTypeCounts.push(new ProductParameterTypeCount({
          productParameterType: control.value,
          quantity: null
        }))
      }
    });

    product.defaultVariant = productVariant;

    this.service.update(product.id, product).subscribe();
  }

  getImageControl$(format: ProductParameterFormat, type: ProductParameterType) {
    let ctrl = this.imageControls.find(_ctrl => _ctrl.productParameterType.id === type.id && _ctrl.format.id === format.id);
    if (!ctrl) {
      ctrl = {
        productParameterType: type,
        format: format,
        control: this.formBuilder.control('')
      }

      this.imageControls.push(ctrl);
    }

    return this.customImages$.pipe(
      switchMap(images => {
        const _img = images.find(item => item.format.id === format.id && item.productParameterType.id === type.id);
        if (_img) {
          ctrl.control.setValue(_img.image);
          return of('');
        }
        return this.parameterTypeFormatImages$(type).pipe(
          tap(images => {
            const _img = images.find(item => item.format.id === format.id);
            if (_img && !ctrl.control.value.uploaded) {
              ctrl.control.setValue(_img.image);
              return '';
            }
            return '';
          })
        )
      }),
      map(() => ctrl.control),
      shareReplay(1)
    );
  }

  getFieldConfig(format: ProductParameterFormat, type: ProductParameterType) {
    return FieldConfig.create({
      name: type.name + ' (' + format.name + ')',
      label: of(type.name + ' (' + format.name + ')'),
      type: "image"
    })
  }


  parameterTypeFormatImages$(parameter: ProductParameterType) {
    if (!this.productParameterTypeImages.find(index => index.productParameterType.id === parameter.id)) {
      this.productParameterTypeImages.push({
        images: [],
        productParameterType: parameter,
        query$: this.productParameterTypeService.getImagesByProductParameterType(parameter).pipe(
        )
      });
    }

    return this.productParameterTypeImages.find(index => index.productParameterType.id === parameter.id).query$;
  }

  saveImages() {
    let obs: Observable<any> = of('');

    this.imageControls.forEach(item => {

      if (item.control.value.uploaded) {
        const orig = this.customImages.find(img => img.productParameterType.id === item.productParameterType.id && img.format.id === item.format.id);
        if (orig) {
          orig.image = item.control.value;
          orig.product = this.product;
          obs = obs.pipe(switchMap(() => this.imageService.update(orig.id, orig as ProductParameterFormatImage)));
        } else {
          const ent = new ProductParameterImage({
            format: item.format,
            productParameterType: item.productParameterType,
            product: this.product,
            image: item.control.value
          });
          obs = obs.pipe(switchMap(() => this.imageService.add(ent)));
        }
      } else {
      }
    });


    this.customImages.forEach(img => {
      const item = this.imageControls.find(item =>img.productParameterType.id === item.productParameterType.id && img.format.id === item.format.id);
      if (!item.control.value.original) {
        obs = obs.pipe(switchMap(() => this.imageService.delete(img.id)));
      }
    });
    obs.pipe(tap(() => this.navigateToParent())).subscribe();

  }

  saveBarCodes() {
    let barcodes = this.actualBarcodes;
    let savedBarcodeIds = [];
    let obs: Observable<any> = of('');
    this.barcodeControls.forEach(barcode => {
      let _barcode = this.origBarcodes.filter(bc => bc.barcode !== '').find(bc => (bc.productParameterTypes.length > 0 && bc.productParameterTypes.every(type => barcode.productParameterTypes.find(_type => _type.id === type.id)) || (bc.productParameterTypes.length === 0 && barcode.productParameterTypes.length === 0)));
      if (barcode.control.value) {
        if (_barcode && _barcode.id) {
          if (barcode.control.value !== _barcode.barcode) {
            _barcode.barcode = barcode.control.value;
            _barcode.product = this.product;
            _barcode.productParameterTypes = barcode.productParameterTypes;
            obs = obs.pipe(switchMap(() => this.barcodeService.update(_barcode.id, _barcode)));
          }
          savedBarcodeIds.push(_barcode.id);
        } else {
          _barcode = new Barcode({
            product: this.product,
            productParameterTypes: barcode.productParameterTypes,
            barcode: barcode.control.value
          });
          obs = obs.pipe(switchMap(() => this.barcodeService.add(_barcode)));
        }
      }
    });

    this.origBarcodes.filter(bc => !savedBarcodeIds.includes(bc.id)).forEach(bc => {
      if (bc.id) {
        obs = obs.pipe(switchMap(() => this.barcodeService.delete(bc.id)));
      }
    });

    obs.pipe(tap(() => {
      this.savedEntity = this.product;
      this.navigateToParent();
    })).subscribe();
  }

  getBarcodeControl(ppts?: ProductParameterType[]): FormControl {
    let barcodes = this.actualBarcodes;

    if (!ppts) {
      ppts = [];
    }
    let ctrl = this.barcodeControls.find(_ctrl => (_ctrl.productParameterTypes.length > 0 && _ctrl.productParameterTypes.every(type => ppts.find(_type => _type.id === type.id)) || (_ctrl.productParameterTypes.length === 0 && ppts.length === 0)));
    if (!ctrl) {

      const barcode = barcodes.find(bc => (bc.productParameterTypes.length > 0 && bc.productParameterTypes.every(type => ppts.find(_type => _type.id === type.id)) || (bc.productParameterTypes.length === 0 && ppts.length === 0)));

      const code = barcode ? barcode.barcode : '';
      ctrl = {
        productParameterTypes: ppts,
        control: this.formBuilder.control(code ?? '')
      }

      this.barcodeControls.push(ctrl);
    }

    return ctrl.control;

  }

  getBarcodeColumns() {
    return this.barcodeColumns;
  }

  getProductParameterFromBarcode(barcodeRow: Barcode, pp: ProductParameter) {
    return barcodeRow.productParameterTypes.find(ppt => (ppt?.productParameter?.id) === pp.id) ?? new ProductParameterType({});
  }

  getString(id: string | number): string {
    return (id as string);
  }

  webshopPage() {
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/' + this.translationService.getActiveLang() + '/product/' + this.product.slug])
    );

    window.open(url, '_blank');
  }

  barcodesEqual(barcode1: Barcode, barcode2: Barcode) {
    return (barcode1.productParameterTypes.length>0 && barcode1.productParameterTypes.every(ppt => barcode2.productParameterTypes.find(_ppt => _ppt.id === ppt.id))) || (barcode1.productParameterTypes.length === 0 && barcode2.productParameterTypes.length === 0 && barcode1.barcode === barcode2.barcode);
  }
}
