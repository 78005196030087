import {Component, Injector} from '@angular/core';
import {OrderState, Store} from './state/store';
import {Order} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {OrderQuery} from './state/query';
import {OrderService} from './state/service';

@Component({
  templateUrl: 'list.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class OrderListComponent extends EntityListComponent<Order, OrderState, Store> {

  translationDomain = 'order';

  constructor(
    public injector: Injector,
    protected service: OrderService,
    query: OrderQuery,
    private OrderFilterService: FilterService) {
    super(injector, service, query, OrderFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }


  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.sort.sort({
      id: 'createdAt',
      start: 'desc',
      disableClear: false
    });
  }
}
