<mat-toolbar-row>
  {{ service.getEntityTitle() | async }} {{ 'form.editing' | transloco }}
</mat-toolbar-row>
<mat-toolbar-row *ngIf="(entity$ | async)?.isControl">
  <button style="margin-right: 15px" mat-raised-button [color]="activeTab === 'main' ? 'primary' : 'accent'"  (click)="activeTab = 'main'">Termék adatlap
  </button>
  <button mat-raised-button [color]="activeTab === 'caption' ? 'primary' : 'accent'"  (click)="activeTab = 'caption'">
    Feliratok
  </button>
</mat-toolbar-row>
<div style="
  display: flex;
  justify-content: center;">
  <div *ngIf="activeTab === 'main'" formHost [entity$]="entity$" [entityService]="service" [subForm]="false"
       (submit)="onSubmit($event)"
       (cancel)="onCancel()">
  </div>
  <ng-container *ngIf="activeTab === 'caption'">
    <mat-card *ngIf="(captions$ | async) || true">
      <mat-card-content *ngIf="(entity$ | async) as productParameter" [formGroup]="captionsGroup">
        <mat-tab-group>
          <mat-tab *ngFor="let format of productParameter.formats" [label]="format.name">
            <p>
              <mat-form-field appearance="fill">
                <mat-label>{{ ('caption.orientation') | transloco }}</mat-label>
                <mat-select [placeholder]="''" [formControl]="getControl(format, 'orientation')">
                  <mat-option [value]="0">[Nincs]</mat-option>
                  <mat-option [value]="1">Vízszintes</mat-option>
                  <mat-option [value]="2">Függőleges</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
            <p *ngFor="let type of ['positionX', 'positionY', 'fontSize']">
              <mat-form-field appearance="fill">
                <mat-label>{{ ('caption.' + type) | transloco }}</mat-label>
                <input matInput [formControl]="getControl(format, type)"
                       [placeholder]="''"
                       [type]="'number'">
              </mat-form-field>
            </p>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="saveCaptions()">
          Mentés
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</div>
