import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {InvoiceAddress} from './model';

export interface InvoiceAddressState extends EntityState<InvoiceAddress> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'InvoiceAddress' })
export class Store extends EntityStore<InvoiceAddressState> {

  constructor() {
    super();
  }

}
