import {Injectable} from '@angular/core';
import {CategoryState, Store as CategoryStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Category} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {CategoryQuery} from './query';
import {FormService} from "../../../components/service/form.service";

@Injectable({ providedIn: 'root' })
export class CategoryService extends EntityService<CategoryStore, CategoryState> {

  categories$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Category(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('category.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('category.name'),
        inputType: 'text',
        name: 'name',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('category.slug'),
        inputType: 'text',
        name: 'slug',
        listed: false,
        editable: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('category.description'),
        inputType: 'text',
        name: 'description',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        label: this.translocoService.selectTranslate('category.image'),
        name: 'image',
        listed: false,
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('category.weight'),
        name: 'weight',
        listed: false,
        inputType: 'number',
        validations: [
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: CategoryQuery,
    private formService: FormService,
    protected store: CategoryStore) {
    super(store, Category);
  }

  get resourceName(): string {
    return 'webshop/category';
  }

}
