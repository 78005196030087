import {Component, Injector} from '@angular/core';
import {ConfigState, Store as ConfigStore} from './state/store';
import {Config} from './state/model';
import {ConfigService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {ConfigQuery} from './state/query';
import {tap} from "rxjs/operators";

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class ConfigComponent extends EntityDetailComponent<Config, ConfigState, ConfigStore> {

  constructor(
    public injector: Injector,
    service: ConfigService,
    protected query: ConfigQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);

    this.entity$ = service.config$.pipe(
      tap(config => {
        this.id = config.id as string;
      })
    );
  }

  navigateToParent() {
    this.router.navigate(['.'], {relativeTo: this.route});
  }
}
