import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Role} from './model';

export interface RoleState extends EntityState<Role> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'role' })
export class Store extends EntityStore<RoleState> {

  constructor() {
    super();
  }

}
