<section class="g-top-thick" *ngIf="(cart$ | async) as cart">
  <div class="h-relative">
    <img class="anim anim--fromRight decorImg decorImg--checkoutSum" src="/assets/images/decor/bird.png" alt="decor">
  </div>
  <header class="l-headFrizDecor">
    <h2>{{ 'webshop.Összegzés' | transloco }}</h2>
  </header>
  <section class="checkout-sum-head">
    <div class="row">
      <div class="small-12 medium-6 column">
        <h2>{{ 'webshop.Számlázási cím' | transloco }}</h2>
        <p>
          <span *ngIf="cart.billingAddress.isCompany">{{ cart.billingAddress.companyName }}</span>
          <span *ngIf="!cart.billingAddress.isCompany">{{ cart.billingAddress.lastName
            }} {{ cart.billingAddress.firstName }}</span>
          <br> {{ cart.billingAddress.postalCode}} {{ cart.billingAddress.city
          }}, {{ cart.billingAddress.street }}</p>
      </div>
      <div class="small-12 medium-6 column">
        <div class="g-top g-top-small-only">
          <h2>{{ 'webshop.Szállítási cím' | transloco }}</h2>
          <p>
            <span *ngIf="cart.shippingAddress.isCompany">{{ cart.shippingAddress.companyName }}</span>
            <span *ngIf="!cart.shippingAddress.isCompany">{{ cart.shippingAddress.lastName
              }} {{ cart.shippingAddress.firstName }}</span>
            <br> {{ cart.shippingAddress.postalCode}}
            {{ cart.shippingAddress.city }}, {{ cart.shippingAddress.street }}</p>
        </div>
      </div>
    </div>
  </section>
  <section class="g-top-extrathick">
    <div class="cart">
      <div class="g-row">
        <ul class="list list--dividedItems list--gutterNormal">
          <li *ngFor="let cartItem of orderByProductName(cart.items)">

            <div class="cartItem">
              <div class="anim cartItem-img">
                <!-- image width: small: 200px, large: 150px -->
                <img src="{{ cartItem.product.image1.product_image_thumb}}"
                     alt="Napló - Vászon Kötéssel">
              </div>
              <div class="cartItem-content">
                <div class="cartItem-details">
                  <!--div class="cartItem-productType cartItem-productType--discount">
                    Akciós termék!
                  </div-->
                  <div class="cartItem-name">
                    {{ cartItem.product.name}}
                  </div>
                  <ul class="cartItem-detailsListing" *ngIf="cartItem.productVariant.productParameterTypeCounts.length">
                    <ng-container
                      *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                      <li
                        *ngIf="!productParameterTypeCount.quantity && cartItem.product.selectorProductParameter && cartItem.product.setCount">
                        <span>{{ productParameterTypeCount.productParameterType.productParameter.displayName }}</span>
                        <p
                          *ngIf="cartItem.product.selectorProductParameter.id != productParameterTypeCount.productParameterType.productParameter.id">
                          – {{ productParameterTypeCount.productParameterType.name }}
                      </li>
                    </ng-container>
                    <ng-container
                      *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                      <li
                        *ngIf="!productParameterTypeCount.quantity && cartItem.product.selectorProductParameter && !cartItem.product.setCount">
                        <span>{{ productParameterTypeCount.productParameterType.name }}</span>
                      </li>
                    </ng-container>

                    <ng-container
                      *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                      <li *ngIf="!productParameterTypeCount.quantity && !cartItem.product.selectorProductParameter">
                        <span>{{ productParameterTypeCount.productParameterType.productParameter.displayName }}</span>
                        – {{ productParameterTypeCount.productParameterType.name }}
                      </li>
                    </ng-container>

                    <ng-container
                      *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                      <li *ngIf="productParameterTypeCount.quantity">
                        <span>{{ productParameterTypeCount.quantity }}</span>
                        – {{ productParameterTypeCount.productParameterType.name }}
                      </li>
                    </ng-container>

                    <li *ngIf="cartItem.caption">
                      <span>{{ 'webshop.Felirat' | transloco }} - </span> {{ cartItem.caption }}
                    </li>
                    <li *ngIf="cartItem.captionSpecial">
                      <span>{{ 'webshop.Felirat2' | transloco }} - </span> {{ cartItem.captionSpecial }}
                    </li>
                  </ul>
                </div>
                <div class="cartItem-divider"></div>
                <div class="cartItem-quantity">
                  <input type="text" value="{{ cartItem.quantity }}">
                  db
                </div>
                <div class="cartItem-divider"></div>
                <div class="">
                  <div>{{ cartItem.price * cartItem.quantity | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="cartSum cartSum--checkout">
          <li class="cartSum-subtotal">{{ 'webshop.Részösszeg' | transloco }}:
            <span>{{ cart.sumPrice | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
          <li *ngIf="cart.productsDiscount > 0">{{ 'webshop.Akciós kedvezmény' | transloco }}:
            <span>-{{ cart.productsDiscount | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
          <li *ngIf="cart.giftDiscount > 0">{{ 'webshop.Ajándékutalvány' | transloco }}:
            <span>-{{ cart.giftDiscount | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
          <li *ngIf="cart.coupon.id && (cart.couponDiscount > 0 || (cart.couponDiscount <= 0 && cart.coupon.freeShippingTransporter && (cart.coupon.freeShippingTransporter.id === cart.shippingMode.id || !cart.shippingMode.id)))">{{ 'webshop.Kuponkód kedvezmény' | transloco }}:
            <span *ngIf="cart.couponDiscount > 0">-{{ cart.couponDiscount | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span>
            <span *ngIf="cart.couponDiscount <= 0 && cart.coupon.freeShippingTransporter && (cart.coupon.freeShippingTransporter.id === cart.shippingMode.id || !cart.shippingMode.id)">{{ 'webshop.Ingyenes szállítás' | transloco}}</span>
          </li>
          <li *ngIf="cart.loyaltyDiscount > 0">{{ 'webshop.Törzsvásárlói kedvezmény (10%)' | transloco }}:
            <span>-{{ cart.loyaltyDiscount | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
          <li>{{ 'webshop.Szállítási költség' | transloco }}: <span>{{ cart.shippingPrice | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
          <li class="cartSum-total">{{ 'webshop.Fizetendő' | transloco }}:
            <span>{{ cart.sumDiscountedPrice + cart.shippingPrice | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}</span></li>
        </ul>
      </div>
    </div>
  </section>
  <form  [formGroup]="group">
    <section>
      <div class="row">
        <div class="small-12 large-6 column">
          <h2>{{ 'webshop.Megjegyzés' | transloco }}</h2>
          <p>{{ 'webshop.Ide írhatja a rendeléssel kapcsolatos szükséges információkat' | transloco }}</p>
          <textarea name="name" rows="4" [placeholder]="'webshop.Megjegyzés' | transloco"
                    [formControl]="descriptionControl"></textarea>
        </div>
        <div class="small-12 large-6 column">
          <div class="checkout-order">
            <div>
              <label
                for="policy_accept">{{ 'webshop.Tudomásul veszem a vásárlási feltételekben foglaltakat' | transloco}}</label>
              <input type="checkbox" id="policy_accept" [formControl]="policyControl" [value]="1">
              <span class="form-error is-visible shown" *ngIf="policyControl.hasError('required') && policyControl.touched">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>

            </div>
            <div class="g-bottom-thick">
              <a class="btn btn--moreBig" target="_blank" [routerLink]="['/page', 'vasarlasi-feltetelek'] | localize">{{ 'webshop.Vásárlási feltételek' | transloco }}</a>
            </div>
            <div class="g-top-thick">
              <img class="l-hand" src="/assets/images/bomo_hand_gray.svg" alt="tovább">
              <button class="btn btn--basic btn--more" type="submit" (click)="order()">
                {{ 'webshop.Megrendelem' | transloco }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="payment"></div>
    </section>
  </form>
</section>
