import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {ProductParameterFormat} from './model';

export interface ProductParameterFormatState extends EntityState<ProductParameterFormat> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ProductParameterFormat' })
export class Store extends EntityStore<ProductParameterFormatState> {

  constructor() {
    super();
  }

}
