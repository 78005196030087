<div>
<mat-form-field *ngIf="field.name && group?.get(field.name)" [formGroup]="group" appearance="fill">
  <mat-label>{{ field.label | async | default: '' }}</mat-label>
  <input type="text" [formControlName]="field.name" [attr.disabled]="field.disabled"
         matInput
         [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
      {{option.toString()}} - {{option.address?.toString()}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
</div>
