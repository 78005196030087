import {Entity} from '../../../components/entity';
import {Order} from "../../order/state/model";

export class InvoiceFile extends Entity {
  order: Order;
  invoice: { uploaded: any } | string;

  constructor({
                id,
                order,
                invoice,
                createdAt, updatedAt
              }: Partial<InvoiceFile>) {
    super({id, createdAt, updatedAt});

    this.invoice = invoice ?? '';
    this.order = new Order(order ?? {});

  }

  getNameProperty(): string {
    return 'order';
  }

  toString(): string {
    return this.order.toString();
  }
}
