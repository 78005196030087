import {Component, Injector} from '@angular/core';
import {PopupState, Store} from './state/store';
import {Popup} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {PopupQuery} from './state/query';
import {PopupService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class PopupListComponent extends EntityListComponent<Popup, PopupState, Store> {

  translationDomain = 'popup';

  constructor(
    public injector: Injector,
    protected service: PopupService,
    query: PopupQuery,
    private PopupFilterService: FilterService) {
    super(injector, service, query, PopupFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
