import {Injectable} from '@angular/core';
import {ProductParameterTypeService} from './state/service';
import {ProductParameterTypeQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {ProductParameterType} from './state/model';
import {ProductParameterTypeState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<ProductParameterType, ProductParameterTypeState, ProductParameterTypeQuery, Store> {
  constructor(protected query: ProductParameterTypeQuery, protected api: ProductParameterTypeService) {
    super(query, api);
  }
}
