<header class="l-topBar l-siteHeader">
  <div class="row align-justify align-middle l-topBar">
    <div class="l-shrink-small-only column">
      <div class="row align-justify align-middle">
        <div class="shrink column">
          <button class="menuMain-open" id="menuMainOpen" type="button" (click)="menuToggle.emit(true)">
            <span>{{ 'webshop.Menü' | transloco }}</span></button>
        </div>
        <div class="column show-for-large">
          <button class="btn btn-icon btn--search searchMainOpen" (click)="searchToggle.emit(true)" type="button">
            <span>{{ 'webshop.Keresés' | transloco }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="l-shrink-small-only column">
      <div class="l-logo text-center">
        <a [routerLink]="['/'] | localize"><img src="/assets/images/bomo_art_logo.svg" alt="Bomo Art"></a>
      </div>
    </div>
    <div class="column">
      <nav>
        <ul class="menuUser">
          <li class="menuUser-cart" *ngIf="(cart$ | async) as cart">
            <a class="btn btn--icon btn--icon--light btn--cart cartPreview-mobile" [routerLink]="['/cart'] | localize"></a>
            <span class="btn btn--icon btn--icon--light btn--cart cartPreview">
            <div class="counter" *ngIf="cart.items.length">
                ({{ cart.items.length }})
            </div>
            <img class="cartPreview-arrow" src="/assets/images/cart_arrow.svg" alt="decor">
            <div class="cartPreview-content" *ngIf="cart.items.length">
                <div>
                    <ul class="cartPreview-items">
                        <li *ngFor="let item of cart.items">
                            <div class="cartPreview-item-title">{{ item.product.name }}</div>
                            <div class="row">
                                <div class="column cartPreview-item-piece">
                                    {{ item.quantity }} db
                                </div>
                                <div class="column cartPreview-item-price">
                                    {{ item.price * item.quantity | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async }}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="cartPreview-sum">
                        <div class="row collapse ">
                            <div class="column cartPreview-sum-piece">
                                {{ cart.itemCount() }} <span>db</span>
            </div>
            <div class="column cartPreview-sum-price">
                {{ cart.sumPrice | webshopCurrency: '' : 'symbol' : '0.0-2' : 0 | async}}
            </div>
        </div>
      </div>
      <div class="cartPreview-nav">
          <div class="row">
              <div class="column">
                  <a class="btn btn--basic" [routerLink]="['/cart'] | localize">{{ 'webshop.Fizetés' | transloco }}</a>
              </div>
          </div>
      </div>
    </div>
    </div>
    </span>
          </li>
          <li class="menuUser-login" *ngIf="(authService.user$ | async) as user">
            <a *ngIf="!(authService.isAuthenticated() | async)" class="btn btn--icon btn--icon--light btn--user"
               data-toggle="offCanvasTop"
               (click)="loginToggle.emit(true)"><span>{{ 'webshop.Belépés' | transloco }}</span></a>
            <a *ngIf="(authService.isAuthenticated() | async)" class="btn btn--icon btn--icon--light btn--user"
               [routerLink]="['/profile'] | localize"><span>{{ user.firstName ? user.firstName : ('Profil' | transloco) }}</span></a>
          </li>
          <li class="show-for-large" *ngFor="let language of (languages$ | async)"><a
            class="btn btn--icon btn--icon--light btn--lang" (click)="switchLanguage(language)">{{ language.slug }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>
