import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Gallery} from "../content/gallery/state/model";
import {GalleryImageService} from "../content/gallery-image/state/service";
import {Observable, tap} from "rxjs";
import {GalleryImage} from "../content/gallery-image/state/model";
import * as PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import * as PhotoSwipe from "photoswipe";
import {LightboxAdapter} from "@fnxone/ngx-photoswipe";

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.html',
  styleUrls: ['gallery.scss']
})
export class GalleryComponent implements OnInit, OnChanges {

  @ViewChild('ngxLightbox') el: ElementRef;
  images$: Observable<GalleryImage[]>;
  images: GalleryImage[];
  @Input() gallery: Gallery;

  pswp: PhotoSwipe<any>;


  constructor(
    protected adapter: LightboxAdapter,
    private galleryImageService: GalleryImageService
  ) {
  }

  ngOnInit() {
    this.images$ = this.galleryImageService.getByGalleryId(this.gallery.id as string).pipe(
      tap(images => this.images = images)
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    this.images$ = this.galleryImageService.getByGalleryId(this.gallery.id as string).pipe(
      tap(images => this.images = images)
    )
  }

  onClickImage(image: GalleryImage, type: string, galleryType: number) {
    this.openPhotoSwipe(image, type, galleryType);
    return false;
  }

  protected openPhotoSwipe(image: GalleryImage, type: string, galleryType: number): boolean {
    this.adapter.galleryUID = Number(
      0//this.galleryElement.nativeElement.getAttribute('data-pswp-uid')
    );
    this.adapter.index = this.images.findIndex(item => item.id === image.id);

    const PSWP: HTMLElement = this.el.nativeElement as HTMLElement;
    this.pswp = new PhotoSwipe(
      PSWP,
      PhotoSwipeUI_Default,
      this.getImagesAsPhotoSwipe(type, galleryType),
      this.adapter
    );

    this.pswp.init();
    setTimeout(() => this.pswp.goTo(this.adapter.index), 500);
    return false;
  }

  protected getImagesAsPhotoSwipe(type: string, galleryType: number): any[] {

    return this.images.map((image, key) => {
      let currentType = type;
      switch (galleryType + 1) {
        case 1:
          currentType = 'landscape';
          break;
        case 2:
          currentType = 'landscape';
          break;
        case 3:
          currentType = 'portrait';
          break;
        case 4:
          currentType = (key % 7 == 0) || (key % 7 == 1) ? 'portrait' : 'landscape';
          break;
        case 5:
          currentType = (key % 5 == 1) ? 'portrait' : 'landscape';
          break;
      }
      return ({
        src: currentType === 'landscape' ? image.url.gallery_landscape_xxlarge : image.url.gallery_portrait_xxlarge,
        w: currentType === 'landscape' ? 1600 : 797,
        h: currentType === 'landscape' ? 898 : 898,
        pid: image.id,
        title: '',
      })
    });
  }

}
