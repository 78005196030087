import {Component, Injector} from '@angular/core';
import {LanguageState, Store as LanguageStore} from './state/store';
import {Language} from './state/model';
import {LanguageService as LanguageService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {LanguageQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class LanguageDetailComponent extends EntityDetailComponent<Language, LanguageState, LanguageStore> {

  constructor(
    public injector: Injector,
    service: LanguageService,
    protected query: LanguageQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
