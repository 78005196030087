<!-- popup -->
<ng-container *ngIf="item$ | async as item"></ng-container>
<ng-template #popupModal>
<!--div data-reveal data-close-on-click="true" data-animation-in="slide-in-down ease" data-animation-out="slide-out-up" -->
  <div class="messageModal-outterBorder" *ngIf="item$ | async as popup">
    <div class="messageModal-innerBorder">
      <div>
        <div class="messageModal-corner messageModal-corner--topLeft"></div>
        <div class="messageModal-corner messageModal-corner--topRight"></div>
        <div class="messageModal-corner messageModal-corner--bottomLeft"></div>
        <div class="messageModal-corner messageModal-corner--bottomRight"></div>
        <div class="messageModal-sideDecor messageModal-sideDecor--left"></div>
        <div class="messageModal-sideDecor messageModal-sideDecor--right"></div>
        <div class="messageModal-content">
          <button class="messageModal-close" data-close type="button" (click)="closeReveal()"></button>
          <h1 [innerHTML]="popup.title" ></h1>
          <p [innerHTML]="popup.description" ></p>
          <img [attr.src]="popup.image.original">
        </div>
      </div>
    </div>
  </div>
<!--/div-->
</ng-template>
