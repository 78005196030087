import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-not-found',
  templateUrl: './component.html'
})
export class WebshopNotFoundComponent {

  constructor(
    private router: Router
  ) {
  }

}
