import {Component} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {News} from "../../../content/news/state/model";
import {NewsService} from "../../../content/news/state/service";
import {ActivatedRoute} from "@angular/router";
import {map, switchMap} from "rxjs/operators";
import {HashMap} from "@datorama/akita";
import {HttpResponse} from "@angular/common/http";
import {HttpGetConfig} from "@datorama/akita-ng-entity-service/lib/types";

@Component({
  templateUrl: './list.html'
})
export class WebshopNewsListComponent {
  posts$: Observable<News[]>;
  page$ = new BehaviorSubject<number>(1);
  maxPage$ = new BehaviorSubject<number>(999);
  perPage$ = new BehaviorSubject<number>(15);

  constructor(
    private newsService: NewsService,
    route: ActivatedRoute
  ) {
    this.posts$ = route.params.pipe(
      switchMap(() => this.page$.pipe(
        switchMap(page => this.perPage$.pipe(
          switchMap((perPage) => this.newsService.get<News[]>(undefined, {
              params: {
                c: perPage,
                p: page,
                s: '-createdAt'
              } as HashMap<any>,
              mapResponseFn: (res: HttpResponse<News[]>): News[] | null => {
                this.maxPage$.next(Math.ceil((Number(res.headers.get('X-Total-Count'))) / perPage));
                return res.body;
              },
              observe: 'response',
            } as HttpGetConfig<News[]>).pipe(
              map(items => {
                return items;
              }))
          )
        ))
      )),
    );
  }

  paging($event: number) {
    this.page$.next($event);
  }
}
