import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Country} from './model';

export interface CountryState extends EntityState<Country> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Country' })
export class Store extends EntityStore<CountryState> {

  constructor() {
    super();
  }

}
