import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {PressVideo} from './model';

export interface PressVideoState extends EntityState<PressVideo> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'PressVideo' })
export class Store extends EntityStore<PressVideoState> {

  constructor() {
    super();
  }

}
