import {Component} from '@angular/core';
import {NewsService} from "../../../content/news/state/service";
import {News} from "../../../content/news/state/model";
import {Observable} from "rxjs";
import {switchMap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: './view.html'
})
export class WebshopNewsViewComponent {

  public item$: Observable<News>;

  constructor(
    route: ActivatedRoute,
    service: NewsService
  ) {
    this.item$ = route.params.pipe(
      switchMap(params => service.getBySlug(params.slug))
    );
  }

}
