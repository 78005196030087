import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Choice, FieldConfig} from '../field.interface';
import {Observable, of} from 'rxjs';
import {Entity} from '../../entity';

@Component({
  selector: 'app-select',
  template: `
    <mat-form-field *ngIf="field.name" [formGroup]="group">
      <mat-select [placeholder]="field.label | async | default: ''" [formControlName]="field.name">
        <mat-option *ngFor="let item of getOptions$() | async" [value]="item.value">{{item.name$ | async}}</mat-option>
      </mat-select>
      <ng-container ngProjectAs="mat-error">
        <mat-error
          *ngIf="group?.get(field.name)?.hasError('custom')">{{group?.get(field.name)?.getError('custom').message}}</mat-error>
      </ng-container>
    </mat-form-field>`,
  styleUrls: ['common.scss'],
})
export class SelectComponent {
  field: FieldConfig;
  group: FormGroup;
  parentEntity$ = new Observable<Entity>();

  constructor() {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }

  getOptions$(): Observable<Choice[]> {
    if (this.field.conditionalOptions) {
      return this.field.conditionalOptions(this.group);
    }

    return of(this.field.options);
  }
}
