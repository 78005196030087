import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoggedInLayoutComponent} from '../layout/logged-in/component';
import {AuthGuard} from '../authentication/auth.guard';
import {BlogListComponent} from './blog/list';
import {NewsletterListComponent} from './newsletter/list';
import {PageListComponent} from './page/list';
import {NewsListComponent} from "./news/list";
import {NewsDetailComponent} from "./news/detail";
import {BlogCategoryListComponent} from "./blog-category/list";
import {BlogCategoryDetailComponent} from "./blog-category/detail";
import {BlogDetailComponent} from "./blog/detail";
import {NewsletterDetailComponent} from "./newsletter/detail";
import {PageDetailComponent} from "./page/detail";
import {WallpaperListComponent} from "./wallpaper/list";
import {WallpaperDetailComponent} from "./wallpaper/detail";
import {PopupListComponent} from "./popup/list";
import {PopupDetailComponent} from "./popup/detail";
import {LocalizeRouterModule} from "@penleychan/ngx-transloco-router";
import {PressNewsListComponent} from "./press-news/list";
import {PressNewsDetailComponent} from "./press-news/detail";
import {PressDownloadListComponent} from "./press-download/list";
import {PressVideoListComponent} from "./press-video/list";
import {PressVideoDetailComponent} from "./press-video/detail";
import {PressDownloadDetailComponent} from "./press-download/detail";
import {PressDetailComponent} from "./press/detail";
import {GalleryListComponent} from "./gallery/list";
import {GalleryDetailComponent} from "./gallery/detail";
import {PressUserListComponent} from "./press-user/list";
import {PressUserDetailComponent} from "./press-user/detail";

const routes = [
  {
    path: 'admin/content/blog',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BlogListComponent
      },
      {
        path: ':id',
        component: BlogDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/press-news',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PressNewsListComponent
      },
      {
        path: ':id',
        component: PressNewsDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/press-config',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PressDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/press-download',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PressDownloadListComponent
      },
      {
        path: ':id',
        component: PressDownloadDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/press-video',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PressVideoListComponent
      },
      {
        path: ':id',
        component: PressVideoDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/press-user',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PressUserListComponent
      },
      {
        path: ':id',
        component: PressUserDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/newsletter',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: NewsletterListComponent
      },
      {
        path: ':id',
        component: NewsletterDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/page',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PageListComponent
      },
      {
        path: ':id',
        component: PageDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/news',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: NewsListComponent
      },
      {
        path: ':id',
        component: NewsDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/popup',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PopupListComponent
      },
      {
        path: ':id',
        component: PopupDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/wallpaper',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: WallpaperListComponent
      },
      {
        path: ':id',
        component: WallpaperDetailComponent
      },
    ]
  },
  {
    path: 'admin/content/blog-category',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BlogCategoryListComponent
      },
      {
        path: ':id',
        component: BlogCategoryDetailComponent
      },
    ]
  },
  {
    path: 'admin/products/gallery',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: GalleryListComponent
      },
      {
        path: ':id',
        component: GalleryDetailComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), LocalizeRouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}

