import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LocalizeRouterModule} from "@penleychan/ngx-transloco-router";

const routes: Routes = [
];

@NgModule({
  imports: [RouterModule.forChild(routes), LocalizeRouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}

