<section class="l-siteContent" id="theTop" *ngIf="(items$ | async) as items">
  <div class="row">
    <div class="small-12 column" data-scroll='someId'>
      <div class="blockList blockList--divided grid-x small-up-1 medium-up-2 large-up-3">
        <div class="cell" *ngFor="let item of (isCategory ? (items | orderByWeight: 'name') : (items | orderBy: 'name'))">
          <a [routerLink]="['/product', item.slug] | localize" class="anim productPrevivew" [ngClass]="{
                        'productPrevivew--noHoverImg' : !item.image2.product_image_thumb
                          }">
            <div class="productPrevivew-img" *ngIf="item.image2.product_image_thumb">
              <img class="productPrevivew-img-hover" [src]="item.image2.product_image_thumb"
                   [alt]="item.name">
              <img [src]="item.image1.product_image_thumb" [alt]="item.name">
            </div>
            <div class="productPrevivew-img" *ngIf="!item.image2.product_image_thumb">
              <img [src]="item.image1.product_image_thumb" [alt]="item.name">
            </div>
            <div class="productPrevivew-title">
              {{ item.name }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
