import {ComponentFactoryResolver, Directive, Input, OnInit, Type, ViewContainerRef} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldConfig} from './field.interface';
import {InputComponent} from './form-elements/input';
import {ButtonComponent} from './form-elements/button';
import {SelectComponent} from './form-elements/select';
import {DateComponent} from './form-elements/datepicker';
import {RadiobuttonComponent} from './form-elements/radio';
import {CheckboxComponent} from './form-elements/checkbox';
import {EntitySelectComponent} from './form-elements/entitySelect';
import {SubEntityComponent} from './form-elements/subEntity';
import {Observable} from 'rxjs';
import {Entity} from '../entity';
import {DateRangeComponent} from './form-elements/daterange-picker';
import {WorkflowComponent} from './form-elements/workflow';
import {WysiwygComponent} from './form-elements/wysiwyg';
import {AddressComponent} from './form-elements/address';
import {PhoneComponent} from './form-elements/phone';
import {ChipsComponent} from './form-elements/chips';
import {CustomComponent} from './form-elements/custom';
import {AutocompleteComponent} from './form-elements/autocomplete';
import {ImageComponent} from "./form-elements/image";
import {ColorComponent} from "./form-elements/color";

export const componentMapper = {
  input: InputComponent,
  button: ButtonComponent,
  select: SelectComponent,
  entitySelect: EntitySelectComponent,
  subEntity: SubEntityComponent,
  workflow: WorkflowComponent,
  date: DateComponent,
  dateRange: DateRangeComponent,
  radiobutton: RadiobuttonComponent,
  checkbox: CheckboxComponent,
  image: ImageComponent,
  wysiwyg: WysiwygComponent,
  address: AddressComponent,
  phone: PhoneComponent,
  chips: ChipsComponent,
  custom: CustomComponent,
  autocomplete: AutocompleteComponent,
  color: ColorComponent,

  getByType(type: string | undefined): Type<any> {
    switch (type) {
      case 'address':
        return this.address;
      case 'input':
        return this.input;
      case 'button':
        return this.button;
      case 'select':
        return this.select;
      case 'entitySelect':
        return this.entitySelect;
      case 'workflow':
        return this.workflow;
      case 'subEntity':
        return this.subEntity;
      case 'date':
        return this.date;
      case 'dateRange':
        return this.dateRange;
      case 'radiobutton':
        return this.radiobutton;
      case 'wysiwyg':
        return this.wysiwyg;
      case 'phone':
        return this.phone;
      case 'chips':
        return this.chips;
      case 'color':
        return this.color;
      case 'custom':
        return this.custom;
      case 'autocomplete':
        return this.autocomplete;
      case 'image':
        return this.image;
      default:
        return this.checkbox;
    }
  }
};
@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements OnInit {
  @Input() field: FieldConfig | undefined;
  @Input() group: FormGroup | undefined;
  @Input() parentEntity$: Observable<Entity> = new Observable<Entity>();
  componentRef: any;
  constructor(
    private formBuilder: FormBuilder,
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}
  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper.getByType(this.field?.type)
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
    this.componentRef.instance.parentEntity$ = this.parentEntity$;
  }
}
