import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ProductParameterInnerState, Store} from './store';

@Injectable({ providedIn: 'root' })
export class ProductParameterInnerQuery extends QueryEntity<ProductParameterInnerState> {

  constructor(protected store: Store) {
    super(store);
  }

}
