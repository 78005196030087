import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../service/auth.service';
import {User} from '../user/state/model';
import {TranslocoService} from '@ngneat/transloco';
import {FieldConfig} from '../../components/form/field.interface';
import {Observable, of} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class LogoutComponent {
  title$ = of('');
  cancelTitle = of('');
  fields: FieldConfig[] = [];
  entity$: Observable<User>;
  group: FormGroup;
  emailValidators: any[] = [];
  passwordValidators: any[] = [];



  constructor(
    private fb: FormBuilder,
    public translationService: TranslocoService,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
    ) {

    this.authService.logout();
    this.router.navigate(['/']);

  }

}
