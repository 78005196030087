import {Injectable} from '@angular/core';
import {ProductVariantState, Store as ProductVariantStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {ProductVariant} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {ProductVariantQuery} from './query';

@Injectable({ providedIn: 'root' })
export class ProductVariantService extends EntityService<ProductVariantStore, ProductVariantState> {

  ProductVariants$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new ProductVariant(item))),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('productVariant.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productVariant.itemNumber'),
        inputType: 'text',
        name: 'itemNumber',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productVariant.slug'),
        inputType: 'text',
        name: 'slug',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('productVariant.quantitativeUnit'),
        inputType: 'text',
        name: 'quantitativeUnit',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('productVariant.createdAt'),
        name: 'createdAt',
        listed: true,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('productVariant.updatedAt'),
        name: 'updatedAt',
        listed: true,
        editable: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: ProductVariantQuery,
    protected store: ProductVariantStore) {
    super(store, ProductVariant);
  }

  get resourceName(): string {
    return 'products/product-variant';
  }

}
