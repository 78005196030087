<section class="l-siteContent" id="theTop" *ngIf="(posts$ | async) as posts">
  <section>
    <header class="sectionHeader g-bottom">
      <h1><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Blog' | transloco }}<img
        src="/assets/images/heading_divider_right.svg" alt="decor"></h1>
    </header>
    <ul class="list list--flexFlip">
      <li *ngFor="let item of posts | orderBy: '-createdAt'">
        <div class="flexCard">
          <div class="flexCard-img">
            <!--
image width:
small: (480)px
medium: (768)px
large: (1024 )px
xlarge: (1440 )px
xxlarge: (1920)px
-->
            <a class="flexCard-img-pic" [routerLink]="['/blog', item.slug] | localize">
              <app-image
                [small]="item.image1.default_image_small"
                [medium]="item.image1.default_image_medium"
                [large]="item.image1.default_image_large"
                [xlarge]="item.image1.default_image_xlarge"
              ></app-image>
            </a>
          </div>
          <div class="flexCard-content">
            <div>
              <div class="flexCard-content-text">
                <time>{{ item.createdAt | date:'longDate'}}</time>
                <h2>{{ item.title }}</h2>
                <div class="l-divider l-divider--flexCard"></div>
                <p [innerHTML]="item.content"></p>
              </div>
              <div class="g-row">
                <a class="btn btn--basic" [routerLink]="['/blog', item.slug] | localize">{{ 'webshop.Megnézem' | transloco }}</a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>

  <app-webshop-pager [currentPage]="this.page$ | async" (paging)="paging($event)" [maxPage]="this.maxPage$ | async"></app-webshop-pager>
</section>
