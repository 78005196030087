import {Injectable} from '@angular/core';
import {TagService} from './state/service';
import {TagQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Tag} from './state/model';
import {Store, TagState} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Tag, TagState, TagQuery, Store> {
  constructor(protected query: TagQuery, protected api: TagService) {
    super(query, api);
  }
}
