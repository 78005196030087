import {Injectable} from '@angular/core';
import {TransporterState, Store as TransporterStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Transporter} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {TransporterQuery} from './query';
import {getEntityType} from "@datorama/akita";
import {TransporterPrice} from "../../transporter-price/state/model";
import {Observable} from "rxjs";
import {Cart} from "../../cart/state/model";

@Injectable({providedIn: 'root'})
export class TransporterService extends EntityService<TransporterStore, TransporterState> {

  transporters$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Transporter(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('transporter.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('transporter.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'select',
        label: this.translocoService.selectTranslate('transporter.handlerClass'),
        options: [
          {
            name$: this.translocoService.selectTranslate('transporter.handlerClassOption.FedEx'),
            value: 'App:Classes:FedExShipping:Shipping'
          },
          {
            name$: this.translocoService.selectTranslate('transporter.handlerClassOption.GLS'),
            value: 'App:Classes:GLSShipping:Shipping'
          },
          {
            name$: this.translocoService.selectTranslate('transporter.handlerClassOption.GLSPoint'),
            value: 'App:Classes:GLSPointShipping:Shipping'
          },
          {
            name$: this.translocoService.selectTranslate('transporter.handlerClassOption.Common'),
            value: 'App:Classes:CommonShipping:Shipping'
          },
          {
            name$: this.translocoService.selectTranslate('transporter.handlerClassOption.Personal'),
            value: 'App:Classes:PersonalShipping:Shipping'
          },
        ],
        name: 'handlerClass',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('transporter.packageCost'),
        inputType: 'number',
        name: 'packageCost',
        listed: false,
        validations: [
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: TransporterQuery,
    protected store: TransporterStore) {
    super(store, Transporter);
  }

  getPrices(transporter: Transporter) {
    return this.getHttp().get<TransporterPrice[]>(this.api + '/' + transporter.id + '/prices')
      .pipe(
        map(items => {
          return (items ?? []).map(_item => {
            const price = new TransporterPrice(_item);
            price.transporter = transporter;
            return price;
          });
        }),
        take(1),
        shareReplay(1),
      );
  }

  getForCart$(cart: Cart): Observable<Transporter[]> {
    return this.getHttp().get<TransporterPrice[]>(this.api + '/for-cart/' + cart.id)
      .pipe(
        map(items => {
          return (items ?? []).map(_item => new Transporter(_item));
        }),
        take(1),
        shareReplay(1),
      );
  }

  get resourceName(): string {
    return 'webshop/transporter';
  }

}
