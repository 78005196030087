import {Component, Injector} from '@angular/core';
import {Store as UserStore, UserState} from './state/store';
import {User} from './state/model';
import {UserService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {UserQuery} from './state/query';
import {TranslocoService} from '@ngneat/transloco';
import {AuthService} from '../service/auth.service';
import {tap} from 'rxjs/operators';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class ProfileComponent extends EntityDetailComponent<User, UserState, UserStore> {

  constructor(
    public injector: Injector,
    service: UserService,
    protected query: UserQuery,
    private translocoService: TranslocoService,
    private authService: AuthService
  ) {
    super(injector, service, query);

    this.entity$ = this.authService.getUser().pipe(tap(entity => this.id = entity.id as string));
  }
}
