import {Entity} from '../../../components/entity';

export class PaymentMode extends Entity {
  title: string;

  constructor({ id, title, createdAt, updatedAt }: Partial<PaymentMode>) {
    super({ id, createdAt, updatedAt });
    this.title = title ?? '';
  }

  getNameProperty(): string {
    return 'title';
  }
}
