import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn, Validators,} from '@angular/forms';
import {FieldConfig} from './field.interface';
import {Observable, of, Subscription} from 'rxjs';
import {Entity} from '../entity';
import {take} from 'rxjs/operators';
import {FormService} from "../service/form.service";

export interface FormTabInterface {
  id: string;
  name$: Observable<string>;
}

export class FormTab implements FormTabInterface {
  id = '';
  name$: Observable<string> = new Observable<string>();

  static create({id, name$}: FormTabInterface) {
    const formTab = new FormTab();
    formTab.id = id;
    formTab.name$ = name$;
    return formTab;
  }
}

@Component({
  exportAs: 'dynamicForm',
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.html',
  styleUrls: ['dynamic-form.scss'],
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() fields: FieldConfig[] = [];
  @Input() formTitle$: Observable<string>;
  @Input() cancelTitle$: Observable<string>;
  @Input() submitTitle$: Observable<string>;
  @Input() entity$: Observable<Entity>;
  @Input() subForm: boolean;

  @Output() submit: EventEmitter<Entity> = new EventEmitter<Entity>();
  @Output() cancel: EventEmitter<Entity> = new EventEmitter<Entity>();

  entitySubscription: Subscription = new Subscription();

  form: FormGroup | undefined;

  get value() {
    return this.form?.value;
  }

  constructor(
    private fb: FormBuilder,
    private formService: FormService
  ) {
    this.formTitle$ = of('');
    this.cancelTitle$ = of('');
    this.submitTitle$ = of('');
    this.subForm = false;
    this.entity$ = new Observable<Entity>();
  }

  getFormTabs() {
    const filtered = this.fields.filter(item => item.tab && item.visible(this.form)).map(item => item.tab);
    return filtered.filter((n, i) => filtered.indexOf(n) === i);
  }

  getFieldsByTab(tab?: FormTabInterface) {
    if (tab) {
      return this.fields.filter(item => item.tab?.id === tab.id);
    } else {
      return this.fields.filter(item => item.tab === undefined);
    }
  }

  ngOnInit() {
    this.createControl();
    this.entitySubscription = this.entity$
      .pipe(
        take(1)
      ).subscribe((entity) => {
          this.fields.forEach(field => {
            const fieldName = field.name;
            if (fieldName && fieldName in entity) {
              if (entity[fieldName] !== null && this.form?.get(fieldName) && !(Array.isArray(entity[fieldName]) && field.type === 'subEntity') && field.type !== 'address' && field.type !== 'partnerAddress') {
                this.form?.get(fieldName)?.setValue(entity[fieldName]);
              }
              if (field.type === 'subEntity' && Array.isArray(entity[fieldName])) {
              }
            }
          });
      });
  }

  ngOnDestroy() {
    this.entitySubscription.unsubscribe();
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.form) {
      return;
    }

    if (this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.formService.validateAllFormFields(this.form);
    }
  }

  onCancel(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.cancel.emit();
  }

  createControl() {
    if (!this.form) {
      this.form = this.fb.group({});
    }

    this.fields.forEach(field => {
      if (field.type === 'button' || !field.name) {
        return;
      }
      if (field.type === 'subEntity') {
        const _group = this.fb.array([]);
        this.form?.addControl(field.name, _group);
      } else if (field.type === 'address') {
        const _group = this.fb.group({});
        this.form?.addControl(field.name, _group);
      } else if (field.type === 'partnerAddress') {
        const _group = this.fb.group({});
        this.form?.addControl(field.name, _group);
      }  else {
        const control = this.fb.control(
          field.value,
          this.formService.bindValidations(field.validations || [])
        );
        this.form?.addControl(field.name, control);
      }
    });
    return this.form;
  }

  hasTabError(tab: FormTab | undefined) {
    return this.getFieldsByTab(tab).some(item => (!this.form?.get(item.name)?.valid && this.form?.get(item.name)?.touched));
  }
}
