import {Entity} from "../../../components/entity";

export class Language extends Entity {
  slug: string;
  code: string;

  constructor({ id, slug, code }: Partial<Language>) {
    super({ id });
    this.slug = slug ?? '';
    this.code = code ?? '';
  }

  getNameProperty(): string {
    return 'slug';
  }
}
