import {marker} from '@ngneat/transloco-keys-manager/marker';

export class Image implements IObjectKeys {
  [key: string]: string | number | object | [] | boolean;

  reseller_image_small: string;
  reseller_image_medium: string;
  reseller_image_large: string;
  reseller_image_xlarge: string;
  reseller_image_xxlarge: string;

  default_image_small: string;
  default_image_medium: string;
  default_image_large: string;
  default_image_xlarge: string;

  image_thumb: string;
  original: string;

  gallery_portrait_small: string;
  gallery_portrait_medium: string;
  gallery_portrait_large: string;
  gallery_portrait_xlarge: string;
  gallery_portrait_xxlarge: string;


  gallery_landscape_small: string;
  gallery_landscape_medium: string;
  gallery_landscape_large: string;
  gallery_landscape_xlarge: string;
  gallery_landscape_xxlarge: string;

  slideshow_small: string;
  slideshow_medium: string;
  slideshow_large: string;
  slideshow_xlarge: string;
  slideshow_xxlarge: string;

  product_image_thumb: string;

  wallpaper_standard_small: string;
  wallpaper_standard_normal: string;
  wallpaper_standard_large: string;
  wallpaper_standard_xlarge: string;

  wallpaper_widescreen_small: string;
  wallpaper_widescreen_normal: string;
  wallpaper_widescreen_large: string;
  wallpaper_widescreen_xlarge: string;

  wallpaper_hd_small: string;
  wallpaper_hd_normal: string;
  wallpaper_hd_large: string;
  wallpaper_hd_xlarge: string;

  wallpaper_tablet_small: string;
  wallpaper_tablet_normal: string;
  wallpaper_tablet_large: string;
  wallpaper_tablet_xlarge: string;

  uploaded: string;

  public constructor({
                       reseller_image_small,
                       reseller_image_medium,
                       reseller_image_large,
                       reseller_image_xlarge,

                       default_image_small,
                       default_image_medium,
                       default_image_large,
                       default_image_xlarge,

                       image_thumb,
                       original,
                       uploaded,

                       gallery_portrait_small,
                       gallery_portrait_medium,
                       gallery_portrait_large,
                       gallery_portrait_xlarge,
                       gallery_portrait_xxlarge,


                       gallery_landscape_small,
                       gallery_landscape_medium,
                       gallery_landscape_large,
                       gallery_landscape_xlarge,
                       gallery_landscape_xxlarge,

                       slideshow_small,
                       slideshow_medium,
                       slideshow_large,
                       slideshow_xlarge,
                       slideshow_xxlarge,

                       wallpaper_hd_large,
                       wallpaper_hd_normal,
                       wallpaper_hd_small,
                       wallpaper_hd_xlarge,
                       wallpaper_standard_large,
                       wallpaper_standard_normal,
                       wallpaper_standard_small,
                       wallpaper_standard_xlarge,
                       wallpaper_tablet_large,
                       wallpaper_tablet_normal,
                       wallpaper_tablet_small,
                       wallpaper_tablet_xlarge,
                       wallpaper_widescreen_large,
                       wallpaper_widescreen_normal,
                       wallpaper_widescreen_small,
                       wallpaper_widescreen_xlarge,

                       product_image_thumb,

                     }: Partial<Image>) {
    this.reseller_image_small = reseller_image_large ?? '';
    this.reseller_image_medium = reseller_image_medium ?? '';
    this.reseller_image_large = reseller_image_large ?? '';
    this.reseller_image_xlarge = reseller_image_xlarge ?? '';

    this.default_image_small = default_image_small ?? '';
    this.default_image_medium = default_image_medium ?? '';
    this.default_image_large = default_image_large ?? '';
    this.default_image_xlarge = default_image_xlarge ?? '';

    this.image_thumb = image_thumb ?? '';
    this.original = original ?? '';

    this.gallery_portrait_small = gallery_portrait_small ?? '';
    this.gallery_portrait_medium = gallery_portrait_medium ?? '';
    this.gallery_portrait_large = gallery_portrait_large ?? '';
    this.gallery_portrait_xlarge = gallery_portrait_xlarge ?? '';
    this.gallery_portrait_xxlarge = gallery_portrait_xxlarge ?? '';


    this.gallery_landscape_small = gallery_landscape_small ?? '';
    this.gallery_landscape_medium = gallery_landscape_medium ?? '';
    this.gallery_landscape_large = gallery_landscape_large ?? '';
    this.gallery_landscape_xlarge = gallery_landscape_xlarge ?? '';
    this.gallery_landscape_xxlarge = gallery_landscape_xxlarge ?? '';

    this.slideshow_small = slideshow_small ?? '';
    this.slideshow_medium = slideshow_medium ?? '';
    this.slideshow_large = slideshow_large ?? '';
    this.slideshow_xlarge = slideshow_xlarge ?? '';
    this.slideshow_xxlarge = slideshow_xxlarge ?? '';

    this.product_image_thumb = product_image_thumb ?? '';


    this.wallpaper_standard_small = wallpaper_standard_small ?? '';
    this.wallpaper_standard_normal = wallpaper_standard_normal ?? '';
    this.wallpaper_standard_large = wallpaper_standard_large ?? '';
    this.wallpaper_standard_xlarge = wallpaper_standard_xlarge ?? '';

    this.wallpaper_widescreen_small = wallpaper_widescreen_small ?? '';
    this.wallpaper_widescreen_normal = wallpaper_widescreen_normal ?? '';
    this.wallpaper_widescreen_large = wallpaper_widescreen_large ?? '';
    this.wallpaper_widescreen_xlarge = wallpaper_widescreen_xlarge ?? '';

    this.wallpaper_hd_small = wallpaper_hd_small ?? '';
    this.wallpaper_hd_normal = wallpaper_hd_normal ?? '';
    this.wallpaper_hd_large = wallpaper_hd_large ?? '';
    this.wallpaper_hd_xlarge = wallpaper_hd_xlarge ?? '';

    this.wallpaper_tablet_small = wallpaper_tablet_small ?? '';
    this.wallpaper_tablet_normal = wallpaper_tablet_normal ?? '';
    this.wallpaper_tablet_large = wallpaper_tablet_large ?? '';
    this.wallpaper_tablet_xlarge = wallpaper_tablet_xlarge ?? '';

    this.uploaded = uploaded ?? '';
  }
}

export class Gps implements IObjectKeys {
  [key: string]: string | number | object | [] | boolean;

  latitude: number;
  longitude: number;

  public constructor({latitude, longitude}: Partial<Gps>) {
    this.latitude = latitude ?? 0;
    this.longitude = longitude ?? 0;
  }
}

export class Address implements IObjectKeys {
  [key: string]: string | number | object | [] | boolean;

  firstName: string;
  lastName: string;
  companyName: string;
  vatNumber: string;
  isCompany: boolean;
  phone: string;
  city: string;
  street: string;
  county: string;
  postalCode: string;

  public constructor(
    {
      firstName,
      lastName,
      companyName,
      vatNumber,
      isCompany,
      phone,
      county,
      postalCode,
      city,
      street
    }: Partial<Address>) {
    this.firstName = firstName ?? '';
    this.lastName = lastName ?? '';
    this.companyName = companyName ?? '';
    this.vatNumber = vatNumber ?? '';
    this.phone = phone ?? '';
    this.postalCode = postalCode ?? '';
    this.isCompany = isCompany ?? false;
    this.city = city ?? '';
    this.street = street ?? '';
    this.county = county ?? '';
  }

  toString() {
    return (this.isCompany ? this.companyName : (this.lastName + ' ' + this.firstName)) + ' ' + this.postalCode + ' ' + this.city + ' ' + this.street;
  }
}

export class BaseEntity implements IObjectKeys {
  [key: string]: string | number | object | [] | Entity[] | boolean | null;

  public constructor({id, createdAt, updatedAt}: Partial<Entity>) {
    this.id = id ?? '';
    if (!createdAt) {
      this.createdAt = new Date();
    } else {
      if (typeof createdAt === 'object') {
        this.createdAt = createdAt as Date;
      } else {
        this.createdAt = new Date(Date.parse(createdAt ?? '0' as unknown as string));
      }
    }

    if (!updatedAt) {
      this.updatedAt = new Date();
    } else {
      if (typeof updatedAt === 'object') {
        this.updatedAt = updatedAt as Date;
      } else {
        this.updatedAt = new Date(Date.parse(updatedAt ?? '0' as unknown as string));
      }
    }
  }

  id: number | string;
  createdAt: Date;
  updatedAt: Date;

  static translations() {
    marker('id');
  }

  public getNameProperty() {
    return '';
  }

  toString() {
    return this[this.getNameProperty()] as string;
  }
}

export abstract class Entity extends BaseEntity {

  public abstract getNameProperty(): string;

  toString() {
    return this[this.getNameProperty()] as string;
  }
}

export interface IObjectKeys {
  [key: string]: string | number | object | [] | boolean | null;
}
