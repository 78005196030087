import {Injectable} from '@angular/core';
import {NewsletterState, Store as NewsletterStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Newsletter} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {NewsletterQuery} from './query';

@Injectable({ providedIn: 'root' })
export class NewsletterService extends EntityService<NewsletterStore, NewsletterState> {

  newsletters$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Newsletter(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('newsletter.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('newsletter.slug'),
        inputType: 'text',
        name: 'slug',
        listed: false,
        editable: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('newsletter.url'),
        inputType: 'text',
        name: 'url',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('newsletter.title'),
        inputType: 'text',
        name: 'title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('newsletter.content'),
        inputType: 'text',
        name: 'content',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('newsletter.sent'),
        name: 'sent',
        listed: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: NewsletterQuery,
    protected store: NewsletterStore) {
    super(store, Newsletter);
  }

  get resourceName(): string {
    return 'content/newsletter';
  }

}
