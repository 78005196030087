import {Component, Injector} from '@angular/core';
import {CategoryState, Store} from './state/store';
import {Category} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {CategoryQuery} from './state/query';
import {CategoryService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class CategoryListComponent extends EntityListComponent<Category, CategoryState, Store> {

  translationDomain = 'category';

  constructor(
    public injector: Injector,
    protected service: CategoryService,
    query: CategoryQuery,
    private CategoryFilterService: FilterService) {
    super(injector, service, query, CategoryFilterService);
  }
}
