import {Injectable} from '@angular/core';

export interface FilterCache {
  id: string;
  parameters: { name: string, value: any }[];
}

@Injectable({providedIn: 'root'})
export class FilterCacheService {

  filters: FilterCache[] = [];

  constructor() {
  }

  getFilters(id: string) {
    if (!this.filters.find(item => item.id === id)) {
      this.filters.push({
        id,
        parameters: []
      });
    }

    return this.filters.find(item => item.id === id) as FilterCache;
  }

  saveParameter(id: string, name: string, value: any): void {
    const filter = this.filters.find(item => item.id === id);

    if (filter) {
      const _parameter = filter.parameters.find(item => item.name === name);
      if (_parameter) {
        _parameter.value = value;
      } else {
        filter.parameters.push({
          name,
          value
        });
      }
    }
  }
}
