import {Component, Injector} from '@angular/core';
import {GalleryState, Store as GalleryStore} from './state/store';
import {Gallery} from './state/model';
import {GalleryService as GalleryService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {GalleryQuery} from './state/query';
import {BehaviorSubject, delay, Observable, of, startWith, switchMap, tap} from "rxjs";
import {GalleryImage} from "../gallery-image/state/model";
import {GalleryImageService} from "../gallery-image/state/service";
import {debounceTime, distinctUntilChanged, map, shareReplay} from "rxjs/operators";
import {FieldConfig} from "../../components/form/field.interface";

@Component({
  templateUrl: 'detail.html',
  styleUrls: [
    '../../components/entity-detail/component.scss',
    'detail.scss'
  ]
})
export class GalleryDetailComponent extends EntityDetailComponent<Gallery, GalleryState, GalleryStore> {

  tab = 'gallery';
  entityImages$: Observable<GalleryImage[]>;
  customImagesGroup: FormGroup;
  controls: FormControl[];
  entityImages = new BehaviorSubject<GalleryImage[]>([]);
  _entityImages$ = this.entityImages.asObservable();
  originalImages: GalleryImage[];
  gallery: Gallery;

  constructor(
    public injector: Injector,
    service: GalleryService,
    private galleryImageService: GalleryImageService,
    protected query: GalleryQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);

    this.customImagesGroup = formBuilder.group({});

    this.entityImages$ = this.entity$.pipe(
      switchMap(gallery => this.galleryImageService.getByGalleryId(gallery.id).pipe(
        tap(images => {
          this.originalImages = images;
          this.gallery = gallery;
          this.entityImages.next(images);
        })
      )),
      switchMap(() => this.customImagesGroup.valueChanges.pipe(
        startWith([]),
        distinctUntilChanged(),
        switchMap(() => {
          let images = this.entityImages.value;

          const removed = [];
          images.forEach((image, index) => {
            const control = this.getControl(index, image);
            if (!(control.value.original || control.value.uploaded)) {
              removed.push(image);
            } else {
              image.url = control.value;
            }
          });

          images = images.filter(item => !removed.includes(item));
          images.push(new GalleryImage({
            gallery: this.gallery
          }));

          images.forEach((image, index) => {
            this.getControl(index, image).setValue(image.url, {emitEvent: false});
          });
          this.entityImages.next(images);
          return this._entityImages$;
        })
      )),
      shareReplay(1)
    )
  }


  getFieldConfig(index) {
    return FieldConfig.create({
      name: 'image' + index,
      label: of('Kép ' + (index + 1)),
      type: "image"
    })
  }

  getControl(index: number, image: GalleryImage) {
    if (!this.customImagesGroup.get(index.toString())) {
      this.customImagesGroup.addControl(index.toString(), this.formBuilder.control(image.url));
    }
    return this.customImagesGroup.get(index.toString()) as FormControl;
  }

  saveImages() {
    let sub: Observable<any> = of('');
    this.entityImages.value.forEach(item => {
      item.gallery = this.gallery;

      if (item.id && item.url.uploaded) {
        sub = sub.pipe(delay(1000),switchMap(() => this.galleryImageService.update(item.id, item)));
      }

      if (!item.id && item.url.uploaded) {
        sub = sub.pipe(delay(1000),switchMap(() => this.galleryImageService.add(item)));
      }
    });

    this.originalImages.filter(item => !this.entityImages.value.find(image => image.id === item.id && item.id)).forEach(item => {
      sub = sub.pipe(switchMap(() => this.galleryImageService.delete(item.id)));
    });

    sub.pipe(tap(() => {
      window.location.reload();
    })).subscribe();
  }
}
