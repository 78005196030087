import {Injectable} from '@angular/core';
import {TransporterDiscountState, Store as TransporterDiscountStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {TransporterDiscount} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {TransporterDiscountQuery} from './query';
import {TransporterService} from "../../transporter/state/service";

@Injectable({providedIn: 'root'})
export class TransporterDiscountService extends EntityService<TransporterDiscountStore, TransporterDiscountState> {

  transporterDiscounts$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new TransporterDiscount(item))),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('transporter-discount.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.transporterService.transporters$,
        listed: true,
        label: this.translocoService.selectTranslate('transporter-discount.transporter'),
        name: 'transporter',
        validations: [{
          name: 'required',
          validator: Validators.required,
          message$: this.translocoService.selectTranslate('form.required')
        }]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('transporter-discount.percent'),
        inputType: 'number',
        name: 'percent',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('transporter-discount.minPrice'),
        inputType: 'number',
        name: 'minPrice',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('transporter-discount.validFrom'),
        name: 'validFrom',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('transporter-discount.validTo'),
        name: 'validTo',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: TransporterDiscountQuery,
    private transporterService: TransporterService,
    protected store: TransporterDiscountStore) {
    super(store, TransporterDiscount);
  }

  get resourceName(): string {
    return 'webshop/transporter-discount';
  }

}
