import {Entity, Image} from '../../../components/entity';

export class Popup extends Entity {
  title: string;
  description: string;
  image: Image;
  from: Date | null;
  to: Date | null;
  urls: string[];

  constructor({
                id,
                title,
                description,
                image,
                from,
                to,
                urls,
                createdAt, updatedAt
              }: Partial<Popup>) {
    super({id, createdAt, updatedAt});

    this.urls = [];
    (urls ?? []).forEach(url => this.urls.push(url));
    this.to = to ? new Date(to) : null;
    this.from = from ? new Date(from) : null;
    this.title = title ?? '';
    this.description = description ?? '';
    this.image = new Image(image ?? {});
  }

  getNameProperty(): string {
    return 'title';
  }
}
