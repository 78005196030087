import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Store as StoreModel} from './model';

export interface StoreState extends EntityState<StoreModel> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Store' })
export class Store extends EntityStore<StoreState> {

  constructor() {
    super();
  }

}
