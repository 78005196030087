import {Entity} from '../../../components/entity';

export class Currency extends Entity {
  amount: number;
  code: string;
  validFrom: Date;
  validTo: Date;
  isPercent: boolean;
  isActive: boolean;
  usableCount: number;
  name: string;
  minPrice: number;

  constructor({
                id,
                amount,
                code,
                validFrom,
                validTo,
                isPercent,
                isActive,
                usableCount,
                name,
                minPrice,
                createdAt, updatedAt
              }: Partial<Currency>) {
    super({id, createdAt, updatedAt});

    this.amount = amount ?? 0;
    this.code = code ?? '';
    this.validFrom = new Date(validFrom ?? '');
    this.validTo = new Date(validTo ?? '');
    this.isPercent = isPercent ?? false;
    this.isActive = isActive ?? false;
    this.usableCount = usableCount ?? 0;
    this.name = name ?? '';
    this.minPrice = minPrice ?? 0;
  }

  getNameProperty(): string {
    return 'slug';
  }
}
