import {Injectable} from '@angular/core';
import {RoleState, Store as RoleStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Role} from './model';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {FieldConfig} from '../../../components/form/field.interface';

@Injectable({ providedIn: 'root' })
export class RoleService extends EntityService<RoleStore, RoleState> {

  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('role.slug'),
        inputType: 'text',
        name: 'slug',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      })
    ]
  };

  constructor(protected store: RoleStore, private translocoService: TranslocoService) {
    super(store, Role);
  }

  get resourceName(): string {
    return 'role';
  }

}
