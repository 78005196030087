import {Entity} from '../../../components/entity';
import {Category} from '../../../webshop/category/state/model';
import {GalleryImage} from "../../gallery-image/state/model";

export class Gallery extends Entity {
  title: string;
  images: GalleryImage[];
  type: number;

  constructor({
                id,
                title,
                images,
                type,
                createdAt, updatedAt
              }: Partial<Gallery>) {
    super({id, createdAt, updatedAt});
    this.title = title ?? '';
    this.type = type ?? 0;
    this.images = images ? images.map(image => new GalleryImage(image)) : [];
  }

  getNameProperty(): string {
    return 'title';
  }
}
