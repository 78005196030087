import {Injectable} from '@angular/core';
import {WallpaperService} from './state/service';
import {WallpaperQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Wallpaper} from './state/model';
import {Store, WallpaperState} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Wallpaper, WallpaperState, WallpaperQuery, Store> {
  constructor(protected query: WallpaperQuery, protected api: WallpaperService) {
    super(query, api);
  }
}
