<mat-toolbar>
  {{ name }}
</mat-toolbar>
<div style="
  display: flex;
  justify-content: center;">
  <form class="dynamic-form" [formGroup]="group" (submit)="exportXls()">
    <mat-card>
      <mat-card-subtitle>
      </mat-card-subtitle>
      <mat-card-content>
        <p>
          <app-date-range [field]="field" [group]="group"></app-date-range>
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button mat-raised-button [color]="'primary'">Letöltés</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
