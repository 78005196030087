import {Entity} from '../../../components/entity';
import {ProductParameterType} from "../../product-parameter-type/state/model";
import {Product} from "../../product/state/model";

export class Barcode extends Entity {
  product: Product;
  productParameterTypes: ProductParameterType[];
  barcode: string;
  stock: number;

  constructor({
                id,
                createdAt,
                updatedAt,
                barcode,
                stock,
                product,
                productParameterTypes,
              }: Partial<Barcode>) {
    super({id, createdAt, updatedAt});
    this.barcode = barcode ?? '';
    this.stock = stock ?? 0;
    this.product = new Product(product ?? {});
    if (productParameterTypes && productParameterTypes.length) {
      this.productParameterTypes = (productParameterTypes).map(item => new ProductParameterType(item ?? {}));
    } else {
      this.productParameterTypes = [];
    }
  }

  getNameProperty(): string {
    return "id";
  }
}
