import {Injectable} from '@angular/core';
import {Gtag} from "angular-gtag";


@Injectable({providedIn: 'root'})
export class GoogleAnalyticsService {

  constructor(private gtag: Gtag) {
  }

  //create our event emitter to send our data to Google Analytics
  public eventEmitter(eventCategory: string,
                      eventValue: any = null) {
    this.gtag.event(eventCategory, eventValue);
  }
}
