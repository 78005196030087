<section class="instaBlock">
  <h1 class="l-sectionHeading" [innerHTML]="'webshop.Fedezd fel a Bomo Art világát az \<span\>Instagramon\<\/span\> is!' | transloco"></h1>
  <div class="row">
    <div class="small-12 column">
      <div class="instaBlock-default">
        <button class="instaBlock-more" type="button" name="button"><span class="instaBlock-more-text">{{ 'webshop.Még több kép' | transloco }}</span><span class="instaBlock-more-lessText">Kevesebb kép</span></button>
        <div class="instaBlock-images">
          <div class="row" id="instafeed"></div>
          <ngx-insta [config]="config"></ngx-insta>
        </div>
      </div>
    </div>
  </div>
</section>
