import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoutingModule} from './routing';
import {MaterialModule} from '../material/material.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {Module as ComponentsModule} from '../components/module';
import {TranslocoModule} from '@ngneat/transloco';
import {LoginComponent} from './login/component';
import {UserListComponent} from './user/list';
import {UserDetailComponent} from './user/detail';
import {AuthService} from './service/auth.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './service/auth.interceptor';
import {RoleListComponent} from './role/list';
import {RoleDetailComponent} from './role/detail';
import {ProfileComponent} from './user/profile';
import {UserViewComponent} from './user/view';
import {LogoutComponent} from "./logout/component";

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    UserListComponent,
    UserDetailComponent,
    RoleListComponent,
    RoleDetailComponent,
    ProfileComponent,
    UserViewComponent
  ],
  exports: [],
  imports: [
    ComponentsModule,
    CommonModule,
    MaterialModule,
    RoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslocoModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class Module {
}
