import {Component, Injector} from '@angular/core';
import {PressDownloadState, Store} from './state/store';
import {PressDownload} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {PressDownloadQuery} from './state/query';
import {PressDownloadService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class PressDownloadListComponent extends EntityListComponent<PressDownload, PressDownloadState, Store> {

  translationDomain = 'pressDownload';

  constructor(
    public injector: Injector,
    protected service: PressDownloadService,
    query: PressDownloadQuery,
    private PressDownloadFilterService: FilterService) {
    super(injector, service, query, PressDownloadFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
