<section class="l-siteContent l-siteContent--fullHeight" id="theTop" *ngIf="configService.config$ | async as config">
  <section>
    <div class="l-hero">
      <div class="swiper swiper-container">
        <carousel [showIndicators]="true" class="swiper-wrapper">
          <slide>
            <a *ngIf="config.slider1Title && config.slider1Image.slideshow_small" class="swiperHero swiper-slide"
               [href]="config.slider1Url">
              <app-image
                [small]="config.slider1Image.slideshow_small"
                [medium]="config.slider1Image.slideshow_medium"
                [large]="config.slider1Image.slideshow_large"
                [xlarge]="config.slider1Image.slideshow_xlarge"
              ></app-image>
              <div class="swiperHero-details">
                <div class="row align-center">
                  <div class="medium-10 large-8 column">
                    <h2>”{{ config.slider1Title }}„</h2>
                    <div class="l-divider"></div>
                    <p>{{ config.slider1Subtitle }}</p>
                    <img src="/assets/images/just_click.svg" alt="just click">
                  </div>
                </div>
              </div>
            </a>
          </slide>
          <slide>
            <a *ngIf="config.slider2Title && config.slider2Image.slideshow_small"
               class="swiperHero swiper-slide" [href]="config.slider2Url">
              <app-image
                [small]="config.slider2Image.slideshow_small"
                [medium]="config.slider2Image.slideshow_medium"
                [large]="config.slider2Image.slideshow_large"
                [xlarge]="config.slider2Image.slideshow_xlarge"
              ></app-image>
              <div class="swiperHero-details">
                <div class="row align-center">
                  <div class="medium-10 large-8 column">
                    <h2>”{{ config.slider2Title }}„</h2>
                    <div class="l-divider"></div>
                    <p>{{ config.slider2Subtitle }}</p>
                    <img src="/assets/images/just_click.svg" alt="just click">
                  </div>
                </div>
              </div>
            </a>
          </slide>
          <slide>
            <a *ngIf="config.slider3Title && config.slider3Image.slideshow_small"
               class="swiperHero swiper-slide" [href]="config.slider3Url">
              <app-image
                [small]="config.slider3Image.slideshow_small"
                [medium]="config.slider3Image.slideshow_medium"
                [large]="config.slider3Image.slideshow_large"
                [xlarge]="config.slider3Image.slideshow_xlarge"
              ></app-image>
              <div class="swiperHero-details">
                <div class="row align-center">
                  <div class="medium-10 large-8 column">
                    <h2>”{{ config.slider3Title }}„</h2>
                    <div class="l-divider"></div>
                    <p>{{ config.slider3Subtitle }}</p>
                    <img src="/assets/images/just_click.svg" alt="just click">
                  </div>
                </div>
              </div>
            </a>
          </slide>
        </carousel>
        <a class="swiper-nextPage" (click)="to(carausel2)" aria-label="Következő oldal" data-smooth-scroll="" data-threshold="0" data-animation-easing="swing"></a>
      </div>
    </div>
  </section>
  <section #carausel2>
    <div class="l-headerHeightForScrollAnchor">
      <div id="recommend_01"></div>
    </div>
    <ul class="list">
      <li>

        <div class="flexCard flexCard--slider">
          <div class="flexCard-img">
            <carousel [showIndicators]="true" [interval]="0">
              <slide class="swiper-slide" *ngFor="let image of (getImages$(config.offer1Gallery.id) | async)">
                <a class="flexCard-img-slidePic" [href]="config.offer1Url">
                  <app-image
                    [small]="image.url.gallery_landscape_small"
                    [medium]="image.url.gallery_landscape_medium"
                    [large]="image.url.gallery_landscape_large"
                    [xlarge]="image.url.gallery_landscape_xlarge"
                  ></app-image>
                </a>
              </slide>
            </carousel>
          </div>
          <div class="flexCard-content">
            <div>
              <div class="flexCard-content-text">
                <h2>
                  {{ config.offer1Title }}
                </h2>
                <div class="l-divider l-divider--flexCard"></div>
                <p>{{ config.offer1Subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>

        <div class="flexCard flexCard--slider">
          <div class="flexCard-img">
            <carousel [showIndicators]="true" [interval]="0">
              <slide class="swiper-slide" *ngFor="let image of (getImages$(config.offer2Gallery.id) | async)">
                <a class="flexCard-img-slidePic" [href]="config.offer2Url">
                  <app-image
                    [small]="image.url.gallery_landscape_small"
                    [medium]="image.url.gallery_landscape_medium"
                    [large]="image.url.gallery_landscape_large"
                    [xlarge]="image.url.gallery_landscape_xlarge"
                  ></app-image>
                </a>
              </slide>
            </carousel>
          </div>
          <div class="flexCard-content">
            <div>
              <div class="flexCard-content-text">
                <h2>
                  {{ config.offer2Title }}
                </h2>
                <div class="l-divider l-divider--flexCard"></div>
                <p>{{ config.offer2Subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
  <section #news>
    <ul class="list list--noTopPadding">
      <li>
        <div class="flexCard flexCard--reverse">
          <div class="flexCard-img">
            <a class="flexCard-img-pic" [href]="config.news1Url">
              <app-image
                [small]="config.news1Image.default_image_small"
                [medium]="config.news1Image.default_image_medium"
                [large]="config.news1Image.default_image_large"
                [xlarge]="config.news1Image.default_image_xlarge"
              ></app-image>
            </a>
          </div>
          <div class="flexCard-content">
            <div>
              <div class="flexCard-content-text">
                <h2>
                  {{ config.news1Title }} </h2>
                <div class="l-divider l-divider--flexCard"></div>
                <p>{{ config.news1Subtitle }}</p>
              </div>
              <div class="g-row">
                <a class="btn btn--basic" [href]="config.news1Url">{{ 'webshop.Megnézem' | transloco }}</a>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>

        <div class="flexCard flexCard--reverse">
          <div class="flexCard-img">
            <a class="flexCard-img-pic" [href]="config.news2Url">
              <app-image
                [small]="config.news2Image.default_image_small"
                [medium]="config.news2Image.default_image_medium"
                [large]="config.news2Image.default_image_large"
                [xlarge]="config.news2Image.default_image_xlarge"
              ></app-image>
            </a>
          </div>
          <div class="flexCard-content">
            <div>
              <div class="flexCard-content-text">
                <h2>
                  {{ config.news2Title }} </h2>
                <div class="l-divider l-divider--flexCard"></div>
                <p>{{ config.news2Subtitle }}</p>
              </div>
              <div class="g-row">
                <a class="btn btn--basic" [href]="config.news2Url">{{ 'webshop.Megnézem' | transloco }}</a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
  <app-webshop-wallpaper></app-webshop-wallpaper>
  <app-webshop-shops></app-webshop-shops>
  <app-webshop-instagram></app-webshop-instagram>
</section>
