import {Component} from '@angular/core';
import {Observable, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {first, map} from "rxjs/operators";
import {Press} from "../../../content/press/state/model";
import {PressService} from "../../../content/press/state/service";
import {DomSanitizer} from "@angular/platform-browser";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {FormService} from "../../../components/service/form.service";
import {LocalizeRouterService} from "@penleychan/ngx-transloco-router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {PressUser} from "../../../content/press-user/state/model";

@Component({
  templateUrl: './view.html'
})
export class WebshopPressViewComponent {

  public item$: Observable<Press>;
  submitted: boolean = false;

  group: FormGroup;
  usernameControl: FormControl;
  passwordControl: FormControl;
  invalid: boolean = false;


  nameControl: FormControl;
  emailControl: FormControl;
  contentControl: FormControl;
  contactForm: FormGroup;

  constructor(
    fb: FormBuilder,
    private formService: FormService,
    private http: HttpClient,
    route: ActivatedRoute,
    service: PressService,
    private router: Router,
    private localizeRouter: LocalizeRouterService,
    private sanitizer: DomSanitizer
  ) {
    this.item$ = service.getAll().pipe(map(items => items[0]));

    this.group = fb.group({});
    this.usernameControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.passwordControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));

    this.group.addControl('username', this.usernameControl);
    this.group.addControl('password', this.passwordControl);

    this.contentControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.nameControl = fb.control('', formService.bindValidations(formService.getValidations(['required'])));
    this.emailControl = fb.control('', formService.bindValidations(formService.getValidations(['required', 'email'])));
    this.contactForm = fb.group({});

    this.contactForm.addControl('name', this.nameControl);
    this.contactForm.addControl('email', this.emailControl);
    this.contactForm.addControl('content', this.contentControl);

  }

  auth() {
    if (this.group.valid) {

      this.http.get<PressUser[]>(environment.apiUrl + '/content/press-user?q[]=username:eq:' + this.usernameControl.value + '&q[]=password:eq:' + this.passwordControl.value).pipe(
        tap((items) => {
            if (items.length && items.find(_user => _user.username === this.usernameControl.value && _user.password === this.passwordControl.value)) {
              localStorage.setItem('pressDownloads', '1');
              this.router.navigate([this.localizeRouter.translateRoute('/press/downloads')]).then();
            } else {
              this.invalid = true;
            }
          }
        ),
      ).subscribe();

    } else {
      this.formService.validateAllFormFields(this.group);
    }

  }

  sendMail() {
    if (this.contactForm.valid) {
      this.http.post(environment.apiUrl + '/contact/press', {
        email: this.emailControl.value,
        content: this.contentControl.value,
        name: this.nameControl.value
      }).pipe(
        first(),
        tap(() => this.submitted = true)
      ).subscribe();
    } else {
      this.formService.validateAllFormFields(this.contactForm);
    }
  }

  safeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
