import {Injectable} from '@angular/core';
import {PressState, Store as PressStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Press} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {PressQuery} from './query';
import {PressVideoService} from "../../press-video/state/service";
import {PressNewsService} from "../../press-news/state/service";

@Injectable({ providedIn: 'root' })
export class PressService extends EntityService<PressStore, PressState> {

  config$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Press(item))),
    map(items => items[0]),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('Press.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressVideoService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressVideo1'),
        name: 'offerVideo1',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressVideoService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressVideo2'),
        name: 'offerVideo2',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressVideoService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressVideo3'),
        name: 'offerVideo3',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressVideoService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressVideo4'),
        name: 'offerVideo4',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressVideoService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressVideo5'),
        name: 'offerVideo5',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressVideoService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressVideo6'),
        name: 'offerVideo6',
        validations: []
      }),

      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressNewsService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressNews1'),
        name: 'offerNews1',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressNewsService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressNews2'),
        name: 'offerNews2',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressNewsService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressNews3'),
        name: 'offerNews3',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressNewsService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressNews4'),
        name: 'offerNews4',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressNewsService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressNews5'),
        name: 'offerNews5',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.pressNewsService.items$,
        listed: true,
        label: this.translocoService.selectTranslate('press.pressNews6'),
        name: 'offerNews6',
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private pressVideoService: PressVideoService,
    private pressNewsService: PressNewsService,
    private query: PressQuery,
    protected store: PressStore) {
    super(store, Press);
  }

  get resourceName(): string {
    return 'content/press-config';
  }

}
