import {Component, Injector} from '@angular/core';
import {PriceListState, Store as PriceListStore} from './state/store';
import {PriceList} from './state/model';
import {PriceListService as PriceListService} from './state/service';
import {EntityDetailComponent} from '../../components/entity-detail/component';
import {FormBuilder} from '@angular/forms';
import {PriceListQuery} from './state/query';

@Component({
  templateUrl: '../../components/entity-detail/component.html',
  styleUrls: ['../../components/entity-detail/component.scss']
})
export class PriceListDetailComponent extends EntityDetailComponent<PriceList, PriceListState, PriceListStore> {

  constructor(
    public injector: Injector,
    service: PriceListService,
    protected query: PriceListQuery,
    protected formBuilder: FormBuilder) {
    super(injector, service, query);
  }
}
