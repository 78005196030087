import {Component, Injector} from '@angular/core';
import {LanguageState, Store} from './state/store';
import {Language} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {LanguageQuery} from './state/query';
import {LanguageService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class LanguageListComponent extends EntityListComponent<Language, LanguageState, Store> {

  translationDomain = 'language';

  constructor(
    public injector: Injector,
    protected service: LanguageService,
    query: LanguageQuery,
    private LanguageFilterService: FilterService) {
    super(injector, service, query, LanguageFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
