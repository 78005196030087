import {Entity, Image} from '../../../components/entity';
import {Gallery} from "../../../content/gallery/state/model";

export class Store extends Entity {
  title: string;
  address: string;
  description: string;
  openingTime: string;
  latitude: number;
  longitude: number;
  gallery: Gallery;
  image1: Image;
  phoneNumber: string;

  constructor({
                id,
                title,
                address,
                description,
                openingTime,
                latitude,
                longitude,
                phoneNumber,
                image1,
                gallery,
                createdAt, updatedAt
              }: Partial<Store>) {
    super({id, createdAt, updatedAt});

    this.title = title ?? '';
    this.description = description ?? '';
    this.address = address ?? '';
    this.openingTime = openingTime ?? '';
    this.longitude = longitude ?? 0;
    this.latitude = latitude ?? 0;
    this.phoneNumber = phoneNumber ?? '';
    this.gallery = new Gallery(gallery ?? {});
    this.gallery.type = 5;
    this.image1 = new Image(image1 ?? {});
  }

  getNameProperty(): string {
    return 'title';
  }
}
