<section class="g-top-thick">
	<div class="row align-center">
		<div class="small-12 large-6 column" *ngIf="(cart$ | async) as cart">
			<form autocomplete="off" class="form" (ngSubmit)="save(cart)" data-abide novalidate>
				<div class="h-relative">
					<img class="anim anim--fromRight decorImg decorImg--checkoutDeliveryAddress"
						 src="/assets/images/decor/porter.png" alt="decor">
				</div>
				<header class="l-headFrizDecor">
					<h2>{{ 'webshop.Szállítási cím' | transloco }}</h2>
				</header>
        <select [formControl]="addressControl" [compareWith]="formService.compareById">
					<option *ngFor="let address of (addresses$ | async)" [ngValue]="address">
            {{ address }}
					</option>
					<option [ngValue]="newAddress">{{ 'webshop.Új szállítási címet veszek fel' | transloco }}</option>
				</select>
				<span class="form-error is-visible shown" *ngIf="false">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>

				<div *ngIf="!addressControl.value.id">
					<p><i>{{ 'webshop.Ha új címet adsz meg, az automatikusan bekerül a címek közé.' | transloco }}</i></p>
					<div class="form form--deliveryAddress">
						<app-edit-address [address]="newAddress" [addToAnotherType]="true"></app-edit-address>
					</div>
				</div>
				<div class="text-right">
					<img class="l-hand" src="/assets/images/bomo_hand_gray.svg" alt="tovább">
					<button class="btn btn--basic" type="submit">{{ 'webshop.Tovább' | transloco }}</button>
				</div>
			</form>
		</div>
	</div>
</section>
