<section class="l-siteContent l-siteContent--fullHeight" id="theTop" *ngIf="(config$ | async) as main">
  <section class="shopHero">
    <!--
image width
small: 480px,
medium: 768px,
xxlarge: 1024,
-->
    <a class="shopHero-content" [href]="main.hero1Url">
      <app-image
        [small]="main.hero1Image.default_image_small"
        [medium]="main.hero1Image.default_image_medium"
        [large]="main.hero1Image.default_image_large"
        [xlarge]="main.hero1Image.default_image_xlarge"
      ></app-image>
      <div class="shopHero-content-details">
        <h2>{{ main.hero1Title }}</h2>
        <div class="l-divider"></div>
        <p>{{ main.hero1Subtitle }}</p>
      </div>
      <img src="/assets/images/just_click.svg" alt="just click">
    </a>
    <a class="shopHero-content" [href]="main.hero2Url">
      <app-image
        [small]="main.hero2Image.default_image_small"
        [medium]="main.hero2Image.default_image_medium"
        [large]="main.hero2Image.default_image_large"
        [xlarge]="main.hero2Image.default_image_xlarge"
      ></app-image>
      <div class="shopHero-content-details">
        <h2>{{ main.hero2Title}}</h2>
        <div class="l-divider"></div>
        <p>{{ main.hero2Subtitle }}</p>
      </div>
      <img src="/assets/images/just_click.svg" alt="just click">
    </a>
  </section>
  <section>
    <div class="row">
      <div class="small-12 column">
        <ul class="list">
          <li *ngFor="let category of ((categories$ | async) | orderByWeight: 'name')">
            <div class="categoryPreview">
              <div class="categoryPreview-content">
                <h1>{{ category.name}}</h1>
                {{ category.description }}
                <div
                  class="g-row">
                  <a class="btn btn--basic"
                     [routerLink]="['/category', category.slug] | localize">{{ 'webshop.Megnézem' | transloco }}</a>
                </div>
              </div>
              <div class="categoryPreview-img"><a class=""
                                                  [routerLink]="['/category', category.slug] | localize">
                <img [src]="category.image.image_thumb" [alt]="category.name"></a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</section>
