<section class="cookieConsent" *ngIf="visible">
  <div class="row">
    <div class="column small-12 large-expand shrink g-top-thin">
      <div class="cookieConsent-img">
        <img src="/assets/images/bomo_hand_gray_mono.svg" alt="cookie consent">
      </div>
    </div>
    <div class="column small-12 large-expand g-top g-top-hide-large-up">
      {{ 'webshop.A www.bomoart.hu webáruházának felületén süti (cookie) fájlokat használ. Ezeket a fájlokat a gépeden tárolja a rendszer. A cookie-k személyek azonosítására nem alkalmasak, szolgáltatásaink biztosításához szükségesek. Az oldal használatával beleegyezel a cookie-k használatába. További információért kérjük olvasd el adatvédelmi elveinket.' | transloco }}
    </div>
    <div class="column small-12 large-expand shrink g-top-thin">
      <a class="btn btn--basic" [routerLink]="['/page', 'adatvedelmi-tajekoztato'] | localize">{{ 'webshop.Adatvédelmi elvek' | transloco }}</a>
    </div>
    <div class="column small-12 large-expand shrink g-top-thin">
      <button class="btn btn--basic" id="closeCookieConsent" (click)="close()">{{ 'webshop.Bezár' | transloco }}</button>
    </div>
  </div>
</section>
