import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Category} from './model';

export interface CategoryState extends EntityState<Category> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Category' })
export class Store extends EntityStore<CategoryState> {

  constructor() {
    super();
  }

}
