import {Entity} from '../../../components/entity';

export class ProductParameterInner extends Entity {
  name: string;

  constructor({ id,
                name,
                createdAt, updatedAt }: Partial<ProductParameterInner>) {
    super({ id, createdAt, updatedAt });
    this.name = name ?? '';
  }

  getNameProperty(): string {
    return 'name';
  }
}
