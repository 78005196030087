import {Injectable} from '@angular/core';
import {BlogCategoryService} from './state/service';
import {BlogCategoryQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {BlogCategory} from './state/model';
import {BlogCategoryState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<BlogCategory, BlogCategoryState, BlogCategoryQuery, Store> {
  constructor(protected query: BlogCategoryQuery, protected api: BlogCategoryService) {
    super(query, api);
  }
}
