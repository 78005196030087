<section class="l-siteContent" id="theTop">
  <section class="checkout">
    <div class="row">
      <div class="small-12 column">
        <header class="sectionHeader g-bottom">
          <h1><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Fizetés' | transloco }}<img
            src="/assets/images/heading_divider_right.svg" alt="decor"></h1>
        </header>
        <ul class="checkout-breadcrumb" *ngIf="active==='delivery_address' || active === 'billing_address'">
          <li [ngClass]="{'checkout-breadcrumb-item--selected': true}">
            <span>{{ 'webshop.Adatok megadása' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Szállítási mód' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Fizetési mód' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Összegzés' | transloco }}</span></li>
        </ul>
        <ul class="checkout-breadcrumb" *ngIf="active==='delivery_mode'">
          <li><span style="cursor: pointer"
                    (click)="setActive('delivery_address')">{{ 'webshop.Adatok megadása' | transloco }}</span>
          </li>
          <li class="checkout-breadcrumb-item--selected">
            <span>{{ 'webshop.Szállítási mód' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Fizetési mód' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Összegzés' | transloco }}</span></li>
        </ul>
        <ul class="checkout-breadcrumb" *ngIf="active==='billing_mode'">
          <li>
            <span style="cursor: pointer"
                  (click)="setActive('delivery_address')">{{ 'webshop.Adatok megadása' | transloco }}</span></li>
          <li>
            <span style="cursor: pointer"
                  (click)="setActive('delivery_mode')">{{ 'webshop.Szállítási mód' | transloco }}</span></li>
          <li
            [ngClass]="{'checkout-breadcrumb-item--selected':active==='billing_mode'}">
            <span>{{ 'webshop.Fizetési mód' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Összegzés' | transloco }}</span></li>
        </ul>
        <ul class="checkout-breadcrumb" *ngIf="active==='sum'">
          <li><span style="cursor: pointer"
                    (click)="setActive('delivery_address')">{{ 'webshop.Adatok megadása' | transloco }}</span>
          </li>
          <li><span style="cursor: pointer"
                    (click)="setActive('delivery_mode')">{{ 'webshop.Szállítási mód' | transloco }}</span>
          </li>
          <li><span style="cursor: pointer"
                    (click)="setActive('billing_mode')">{{ 'webshop.Fizetési mód' | transloco }}</span>
          </li>
          <li [ngClass]="{'checkout-breadcrumb-item--selected':active=='sum'}">
            <span>{{ 'webshop.Összegzés' | transloco }}</span></li>
        </ul>
        <app-checkout-billing-address (next)="next()" *ngIf="active==='billing_address'"></app-checkout-billing-address>
        <app-checkout-billing-mode (next)="next()" *ngIf="active==='billing_mode'"></app-checkout-billing-mode>
        <app-checkout-delivery-address (next)="next()" *ngIf="active==='delivery_address'"></app-checkout-delivery-address>
        <app-checkout-delivery-mode (next)="next()" *ngIf="active==='delivery_mode'"></app-checkout-delivery-mode>
        <app-checkout-login *ngIf="active==='login'"></app-checkout-login>
        <app-checkout-sum (next)="next()" *ngIf="active==='sum'"></app-checkout-sum>
      </div>
    </div>
  </section>
</section>
