import {Injectable} from '@angular/core';
import {OrderService} from './state/service';
import {OrderQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Order} from './state/model';
import {OrderState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Order, OrderState, OrderQuery, Store> {
  constructor(protected query: OrderQuery, protected api: OrderService) {
    super(query, api);
  }
}
