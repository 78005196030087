import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Config} from './model';

export interface ConfigState extends EntityState<Config> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Config' })
export class Store extends EntityStore<ConfigState> {

  constructor() {
    super();
  }

}
