import {Entity, Image} from '../../../components/entity';
import {Gallery} from '../../../content/gallery/state/model';
import {ProductParameter} from '../../product-parameter/state/model';
import {ProductVariant} from '../../product-variant/state/model';
import {Category} from "../../../webshop/category/state/model";
import {ProductParameterType} from "../../product-parameter-type/state/model";
import {ProductParameterFormat} from "../../product-parameter-format/state/model";
import {ManufacturingCategory} from "../../manufacturing-category/state/model";
import {ProductParameterFormatImage} from "../../product-parameter-format-image/state/model";
import {Tag} from "../../../webshop/tag/state/model";

export class Product extends Entity {
  gallery: Gallery;
  name: string;
  price: number;
  slug: string;
  description: string;
  weight: number | null;
  mass: number | null;
  captionPrice: number;
  fedExPackage: number;
  selectorProductParameter: ProductParameter;
  specialCaption: boolean;
  defaultVariant: ProductVariant;
  image1: Image;
  image2: Image;
  multipart: boolean;
  setCount: number;
  format: number;
  availableWebshop: boolean;
  categories: Category[];
  productParameterImages: ProductParameterFormatImage[];
  tags: Tag[];
  productParameters: ProductParameter[];
  manufacturingCategory: ManufacturingCategory;

  constructor({
                id,
                gallery,
                name,
                price,
                slug,
                description,
                weight,
                setCount,
                fedExPackage,
                selectorProductParameter,
                specialCaption,
                defaultVariant,
                categories,
                availableWebshop,
                multipart,
                mass,
                image1,
                image2,
                productParameters,
                tags,
                manufacturingCategory,
                productParameterImages,
                createdAt, updatedAt
              }: Partial<Product>) {
    super({id, createdAt, updatedAt});
    this.slug = slug ?? '';
    this.gallery = new Gallery(gallery ?? {});
    this.name = name ?? '';
    this.price = price ?? 0;
    this.description = description ?? '';
    this.weight = weight ?? null;
    this.mass = mass ?? null;
    this.fedExPackage = fedExPackage ?? 0;
    this.selectorProductParameter = new ProductParameter(selectorProductParameter ?? {});
    this.specialCaption = specialCaption ?? false;
    this.defaultVariant = new ProductVariant(defaultVariant ?? {});
    this.categories = categories ? categories.map(category => new Category(category)) : [];
    this.availableWebshop = availableWebshop ?? false;
    this.productParameterImages = productParameterImages ?? [];
    this.productParameters = productParameters ? productParameters.map(item => new ProductParameter(item ?? {})) : [];

    this.tags = tags ? tags.map(tag => new Tag(tag)) : [];
    this.setCount = setCount ?? 0;
    this.multipart = multipart ?? false;
    this.manufacturingCategory = new ManufacturingCategory(manufacturingCategory ?? {});
    this.image1 = new Image(image1 ?? {});
    this.image2 = new Image(image2 ?? {});

    this.specialCaption = this.productParameters.some(pp => pp.isControl && pp.captions.length);
  }

  fullPrice() {
    return this.price;
  }

  getNameProperty(): string {
    return 'name';
  }
}
