<section class="l-siteContent" id="theTop" *ngIf="!submitted">
  <div class="row align-center">
    <div class="small-12 medium-10 large-6 xlarge-5 column">
      <h1 class="l-headDecor"><img src="/assets/images/bomo_hand_gray_mono.svg" alt="Elfelejtett jelszó">{{ 'webshop.Elfelejtett jelszó' | transloco }}</h1>
      <p>{{ 'webshop.Add meg a regisztrációkor használt e-mail címed. Hamarosan levelt kapsz a belépéssel kapcsolatban.' | transloco}}<br> {{ 'webshop.Belépés után ne feledd el megváltoztatni jelszavad!' | transloco }}</p>
      <form class="form form--forgotPassword" data-abide novalidate>
        <img class="anim anim--fromLeft decorImg decorImg--forgotPassword" src="/assets/images/decor/fish.png" alt="decor">
        <div data-abide-error class="alert callout h-hidden">
          <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
        </div>
        <label>{{ 'webshop.E-mail' | transloco }}
          <input type="text" [placeholder]="'E-mail' | transloco" [formControl]="emailControl">
          <span class="form-error" *ngIf="emailControl.hasError('email')">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco }}</span>
        </label>
        <div class="text-right">
          <button class="btn btn--basic" type="submit" (click)="forgottenPassword()">{{ 'webshop.Küldés' | transloco }}</button>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="l-siteContent" id="theTop" *ngIf="submitted">
  <section class="feedbackMessage">
    <div class="row align-center">
      <div class="small-12 medium-10 large-6 xlarge-4 column">
        <h1>{{ 'webshop.Jelezted nekünk, hogy elfelejtetted a jelszavadat.' | transloco}}</h1>
        <img src="/assets/images/menu_heading_decor.svg" alt="decor">
        <div class="feedbackMessage-content g-top">
          <p>{{ 'webshop.Nemsokára kapsz tőlünk egy emailt. Kérjük, kattints a benne található linkre, és változtasd meg jelszavadat a profilodban. ' | transloco}}</p>
          <div class="g-top">
            <p>{{ 'webshop.Köszönjük:' | transloco}}</p>
            <span class="feedbackMessage-signature">{{ 'webshop.Bomo Art csapata' | transloco}}</span>
          </div>
          <img class="anim anim--fromLeft decorImg decorImg--signUpAfterLeft" src="/assets/images/decor/balloon_01.png" alt="decor">
          <img class="anim anim--fromRight decorImg decorImg--signUpAfterRight" src="/assets/images/decor/balloon_02.png" alt="decor">
        </div>
      </div>
    </div>
  </section>
</section>
