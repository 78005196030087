import {
  ComponentFactoryResolver,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DynamicFormComponent} from './dynamic-form';
import {Observable} from 'rxjs';
import {Entity} from '../entity';
import {EntityService} from '../entity-service';

@Directive({
  selector: '[formHost]',
})
export class DynamicFormHostDirective implements OnInit {
  @Input() group: FormGroup | undefined;
  @Input() entity$ = new Observable<Entity>();
  @Input() entityService: EntityService<any, any> | undefined;
  @Input() subForm = false;

  @Output() submit: EventEmitter<Entity> = new EventEmitter<Entity>();
  @Output() cancel: EventEmitter<Entity> = new EventEmitter<Entity>();

  constructor(
    private formBuilder: FormBuilder,
    private resolver: ComponentFactoryResolver,
    public container: ViewContainerRef
  ) {
  }

  ngOnInit(): void {
    if (!this.group) {
      this.group = this.formBuilder.group({});
    }
    const factory = this.resolver.resolveComponentFactory(DynamicFormComponent);
    const componentRef = this.container.createComponent(factory);
    componentRef.instance.form = this.group;

    componentRef.instance.submit = this.submit;
    componentRef.instance.cancel = this.cancel;
    this.entityService?.generateEditForm(componentRef.instance, this.entity$, this.subForm);
  }
}
