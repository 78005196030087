import {Injectable} from '@angular/core';
import {ConfigState, Store as ConfigStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Config} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {first, map, shareReplay, switchMap} from 'rxjs/operators';
import {ConfigQuery} from './query';
import {Observable} from "rxjs";
import {GalleryService} from "../../../content/gallery/state/service";

@Injectable({providedIn: 'root'})
export class ConfigService extends EntityService<ConfigStore, ConfigState> {

  config$: Observable<Config> = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Config(item))[0]),
    first(),
    shareReplay(1),
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('config.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('config.webshopOpen'),
        inputType: 'text',
        name: 'webshopOpen',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.slider1Title'),
        inputType: 'text',
        name: 'slider1Title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.slider1Subtitle'),
        inputType: 'text',
        name: 'slider1Subtitle',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.slider1Url'),
        inputType: 'text',
        name: 'slider1Url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('config.slider1Image'),
        name: 'slider1Image',
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.slider2Title'),
        inputType: 'text',
        name: 'slider2Title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.slider2Subtitle'),
        inputType: 'text',
        name: 'slider2Subtitle',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.slider2Url'),
        inputType: 'text',
        name: 'slider2Url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('config.slider2Image'),
        name: 'slider2Image',
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.slider3Title'),
        inputType: 'text',
        name: 'slider3Title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.slider3Subtitle'),
        inputType: 'text',
        name: 'slider3Subtitle',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.slider3Url'),
        inputType: 'text',
        name: 'slider3Url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('config.slider3Image'),
        name: 'slider3Image',
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.offer1Title'),
        inputType: 'text',
        name: 'offer1Title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.offer1Subtitle'),
        inputType: 'text',
        name: 'offer1Subtitle',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.offer1Url'),
        inputType: 'text',
        name: 'offer1Url',
        listed: true,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.galleryService.galleries$,
        label: this.translocoService.selectTranslate('config.offer1Gallery'),
        name: 'offer1Gallery',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.offer2Title'),
        inputType: 'text',
        name: 'offer2Title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.offer2Subtitle'),
        inputType: 'text',
        name: 'offer2Subtitle',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.offer2Url'),
        inputType: 'text',
        name: 'offer2Url',
        listed: true,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.galleryService.galleries$,
        label: this.translocoService.selectTranslate('config.offer2Gallery'),
        name: 'offer2Gallery',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.news1Title'),
        inputType: 'text',
        name: 'news1Title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.news1Subtitle'),
        inputType: 'text',
        name: 'news1Subtitle',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.news1Url'),
        inputType: 'text',
        name: 'news1Url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('config.news1Image'),
        name: 'news1Image',
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.news2Title'),
        inputType: 'text',
        name: 'news2Title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.news2Subtitle'),
        inputType: 'text',
        name: 'news2Subtitle',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.news2Url'),
        inputType: 'text',
        name: 'news2Url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('config.news2Image'),
        name: 'news2Image',
        validations: []
      }),


      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.hero1Title'),
        inputType: 'text',
        name: 'hero1Title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.hero1Subtitle'),
        inputType: 'text',
        name: 'hero1Subtitle',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.hero1Url'),
        inputType: 'text',
        name: 'hero1Url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('config.hero1Image'),
        name: 'hero1Image',
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.hero2Title'),
        inputType: 'text',
        name: 'hero2Title',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.hero2Subtitle'),
        inputType: 'text',
        name: 'hero2Subtitle',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.hero2Url'),
        inputType: 'text',
        name: 'hero2Url',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('config.hero2Image'),
        name: 'hero2Image',
        validations: []
      }),

      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.captionPrice'),
        inputType: 'number',
        name: 'captionPrice',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('config.eur'),
        inputType: 'number',
        name: 'eur',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: ConfigQuery,
    private galleryService: GalleryService,
    protected store: ConfigStore) {
    super(store, Config);
  }

  get resourceName(): string {
    return 'config';
  }

}
