import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Entity} from '../../entity';
import {Observable, of} from 'rxjs';
import {Engine} from '../../flowy/Engine';
import {TranslocoService} from '@ngneat/transloco';

declare var flowy: any;

export interface Action {
  type: string;
  icon: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-entity-select',
  template: `
    <div id="leftcard">
      <mat-toolbar>
        <button mat-raised-button color="primary" routerLink="./../../">{{ 'form.submit' | transloco }}</button>
      </mat-toolbar>
      <div id="blocklist">
        <div *ngFor="let action of actions" class="blockelem create-flowy noselect">
          <input type="hidden" name='blockelemtype' class="blockelemtype" [value]="action.type">
          <div class="grabme">
            <img src="assets/flowy/grabme.svg">
          </div>
          <div class="blockin">
            <div class="blockico">
              <span></span>
              <mat-icon>{{ action.icon }}</mat-icon>
            </div>
            <div class="blocktext">
              <p class="blocktitle">{{ action.title }}</p>
              <p class="blockdesc">{{ action.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div #canvas id="canvas" class="canvas"></div>
    <dynamic-form *ngIf="selectedBlock" (cancel)="onCancel()" (submit)="onCancel()" [id]="'rightcard'" [fields]="fields" [subForm]="false" [formTitle$]="formTitle$" [cancelTitle$]="cancelTitle$" [submitTitle$]="submitTitle$"></dynamic-form>
  `,
  styleUrls: []
})
export class WorkflowComponent {


  constructor(
    private translocoService: TranslocoService
  ) {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }
  @ViewChild('canvas', {static: false}) public canvas!: ElementRef;
  field: FieldConfig;
  group: FormGroup;
  parentEntity$ = new Observable<Entity>();
  selectedBlock: any = false;

  fields = [
    FieldConfig.create({
      type: 'input',
      label: of('Filter'),
      inputType: 'text',
      name: 'text',
      listed: false,
      validations: [
        {
          name: 'required',
          validator: Validators.required,
          message$: this.translocoService.selectTranslate('form.required')
        }
      ]
    }),
    FieldConfig.create({
      type: 'wysiwyg',
      label: of('Text'),
      inputType: 'text',
      name: 'text',
      listed: false,
      validations: [
        {
          name: 'required',
          validator: Validators.required,
          message$: this.translocoService.selectTranslate('form.required')
        }
      ]
    }),
  ];
  formTitle$ = of('Edit node');
  cancelTitle$ = of('Cancel');
  submitTitle$ = of('Submit');

  spacingx = 40;
  spacingy = 40;
  engine!: Engine;

  actions: Action[] = [
    {
      type: '1',
      icon: 'notes',
      title: 'Static communication',
      description: ''
    },
    {
      type: '2',
      icon: 'image_search',
      title: 'Filter result',
      description: ''
    },
    {
      type: '3',
      icon: 'ballot',
      title: 'Choice',
      description: ''
    },
    {
      type: '4',
      icon: 'mark_chat_unread',
      title: 'Live chat',
      description: ''
    }
  ];

  onCancel() {
    this.selectedBlock = false;
  }

  ngAfterViewInit(): void {
    this.engine = new Engine(
      this.canvas.nativeElement,
      this.spacingx,
      this.spacingy,
      this.onGrab,
      this.onRelease,
      this.onSnap.bind(this)
    );

    window.addEventListener('click', this.onSelect.bind(this));
  }

  onGrab(block: any) {

  }

  onRelease() {

  }

  onSnap(drag: any) {
    const grab = drag.querySelector('.grabme');
    grab.parentNode.removeChild(grab);
    const blockin = drag.querySelector('.blockin');
    blockin.parentNode.removeChild(blockin);
    drag.innerHTML += this.getPlacedElement(drag.querySelector('.blockelemtype').value);
    return true;
  }

  onSelect(event: { target: any; which?: any; }) {
    if (event.target.classList.contains('blockyright')) {
      this.selectedBlock = 1;
    }
  }

  getPlacedElement(type: string) {
    const foundType = this.actions.find(action => action.type === type);
    return `<div class="blockyleft">
    <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">${foundType?.icon}</mat-icon>
      <p class="blockyname">${foundType?.title}</p>
    </div>
    <div class="blockyright">
      <img src="assets/flowy/more.svg">
    </div>
    <div class="blockydiv"></div>
    <div class="blockyinfo"></div>`;
  }
}
