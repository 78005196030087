import {Entity} from '../../../components/entity';

export class Transporter extends Entity {
  title: string;
  handlerClass: string;
  packageCost: number;

  constructor({
                id,
                title,
                handlerClass,
                packageCost,
                createdAt, updatedAt
              }: Partial<Transporter>) {
    super({id, createdAt, updatedAt});
    this.title = title ?? '';
    this.handlerClass = handlerClass ?? '';
    this.packageCost = packageCost ?? 0;
  }

  getNameProperty(): string {
    return 'title';
  }
}
