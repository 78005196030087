import {Entity, Image} from '../../../components/entity';

export class Category extends Entity {
  weight: number | null;
  description: string;
  slug: string;
  name: string;
  image: Image;

  constructor({
                id,
                name,
                weight,
                description,
                image,
                slug,
                createdAt, updatedAt
              }: Partial<Category>) {
    super({id, createdAt, updatedAt});
    this.slug = slug ?? '';
    this.weight = weight ?? null;
    this.description = description ?? '';
    this.name = name ?? '';
    this.image = new Image(image ?? {});
  }

  getNameProperty(): string {
    return 'name';
  }
}
