import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors, Validator, ValidatorFn, Validators} from "@angular/forms";
import {of} from "rxjs";
import {TranslocoService} from "@ngneat/transloco";
import {Entity} from "../entity";


export function ImageValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (!control.value) {
      return { 'requiredImage': true };
    }
    if (!(control.value.original || control.value.uploaded)) {
      return { 'requiredImage': true };
    }
    return null;

  }
}

@Injectable({providedIn: 'root'})
export class FormService {

  validations = [
    {
      name: 'required',
      validator: Validators.required,
      message$: this.translateService.selectTranslate('form.required')
    },
    {
      name: 'requiredImage',
      validator: ImageValidator(),
      message$: this.translateService.selectTranslate('form.required')
    },
    {
      name: 'email',
      validator: Validators.email,
      message$: this.translateService.selectTranslate('form.email-required')
    }
  ]

  constructor(
    private translateService: TranslocoService
  ) {

  }

  compareById(entity: Entity, entity2: Entity): boolean {
    if (!entity || !entity2) {
      return false;
    }
    return entity.id === entity2.id;
  }


  getValidations(names: string[]) {
    return this.validations.filter(validation => names.includes(validation.name));
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList: ValidatorFn[] = [];
      validations.forEach((valid: { validator: ValidatorFn; }) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        control.markAsTouched();
        Object.keys(control.controls).forEach(_field => {
          (control)?.get(_field)?.markAsTouched({onlySelf: true});
        });
      } else {
        control?.markAsTouched({onlySelf: true});
      }
    });
  }
}
