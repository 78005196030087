import {Component, Injector} from '@angular/core';
import {ExpressionState, Store} from './state/store';
import {Expression} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {ExpressionQuery} from './state/query';
import {ExpressionService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class ExpressionListComponent extends EntityListComponent<Expression, ExpressionState, Store> {

  translationDomain = 'expression';

  constructor(
    public injector: Injector,
    protected service: ExpressionService,
    query: ExpressionQuery,
    private ExpressionFilterService: FilterService) {
    super(injector, service, query, ExpressionFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }
}
