import {Component} from '@angular/core';
import {ConfigService} from "../../../webshop/config/state/service";
import {CategoryService} from "../../../webshop/category/state/service";
import {Observable} from "rxjs";
import {Category} from "../../../webshop/category/state/model";

@Component({
  templateUrl: './component.html',
  styleUrls: ['component.scss']
})
export class WebshopWebshopComponent {

  config$ = this.configService.config$;
  categories$: Observable<Category[]> = this.categoryService.categories$;

  constructor(
    private configService: ConfigService,
    private categoryService: CategoryService
  ) {
  }

}
