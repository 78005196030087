import {Entity} from '../../../components/entity';

export class Tag extends Entity {
  name: string;

  constructor({ id, name, createdAt, updatedAt }: Partial<Tag>) {
    super({ id, createdAt, updatedAt });
    this.name = name ?? '';
  }

  getNameProperty(): string {
    return 'name';
  }
}
