import {Injectable} from '@angular/core';
import {LanguageService} from './state/service';
import {LanguageQuery} from './state/query';
import {FilterService as BaseService} from '../../components/filter.service';
import {Language} from './state/model';
import {LanguageState, Store} from './state/store';


@Injectable({providedIn: 'root'})
export class FilterService extends BaseService<Language, LanguageState, LanguageQuery, Store> {
  constructor(protected query: LanguageQuery, protected api: LanguageService) {
    super(query, api);
  }
}
