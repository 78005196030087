import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable, Subscription} from 'rxjs';
import {Entity} from '../../entity';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-address',
  styleUrls: ['address.scss', 'common.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div>
      <h3>{{ field.label | async | default: '' }}</h3>
      <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="hasError(validation.name)">{{validation.message$ | async}}</mat-error>
      </ng-container>
      <ng-container ngProjectAs="mat-error">
        <mat-error
          *ngIf="group?.get(field.name)?.hasError('custom')">{{group?.get(field.name)?.getError('custom').message}}</mat-error>
      </ng-container>
      <div>
        <mat-form-field [formGroup]="setGroup" appearance="fill" style="margin-left: 2px">
          <input matInput [formControlName]="'zip'"
                 [placeholder]="translationService.selectTranslate('form.address.zip') | async | default: ''">
        </mat-form-field>
        <mat-form-field [formGroup]="setGroup" appearance="fill" style="margin-left: 2px">

          <input matInput [formControlName]="'city'"
                 [placeholder]="translationService.selectTranslate('form.address.city') | async | default: ''">
        </mat-form-field>
        <mat-form-field [formGroup]="setGroup" appearance="fill" style="margin-left: 2px">

          <input matInput [formControlName]="'street'"
                 [placeholder]="translationService.selectTranslate('form.address.street') | async | default: ''">
        </mat-form-field>
        <mat-form-field [formGroup]="setGroup" appearance="fill" style="margin-left: 2px">

          <input matInput [formControlName]="'number'"
                 [placeholder]="'Házszám'">
        </mat-form-field>
      </div>
    </div>`,
  styles: []
})
export class AddressComponent implements OnInit, OnDestroy, AfterViewInit {

  field: FieldConfig;
  group: FormGroup;
  setGroup: FormGroup;
  gpsGroup: FormGroup;
  parentEntity$ = new Observable<Entity>();
  entitySubscription: Subscription = new Subscription();
  changeSubscription: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    public translationService: TranslocoService
  ) {
    this.group = new FormGroup({});
    this.setGroup = new FormGroup({});
    this.gpsGroup = new FormGroup({});
    this.field = new FieldConfig();
  }

  hasError(type: string): boolean {
    const group = this.group.get(this.field.name) as FormGroup;

    const attribs = ['zip', 'city', 'street', 'number'];
    return attribs.some(attrib => {
      return group.get(attrib)?.hasError(type);
    });
  }

  ngAfterViewInit() {
  }

  ngOnInit() {

    (this.group.get(this.field.name) as FormGroup).addControl('zip', new FormControl('', this.bindValidations(this.field.validations || [])));
    (this.group.get(this.field.name) as FormGroup).addControl('city', new FormControl('', this.bindValidations(this.field.validations || [])));
    (this.group.get(this.field.name) as FormGroup).addControl('street', new FormControl('', this.bindValidations(this.field.validations || [])));
    (this.group.get(this.field.name) as FormGroup).addControl('number', new FormControl('', this.bindValidations(this.field.validations || [])));
    (this.group.get(this.field.name) as FormGroup).addControl('gps', this.formBuilder.group({}));
    ((this.group.get(this.field.name) as FormGroup).get('gps') as FormGroup).addControl('latitude', new FormControl());
    ((this.group.get(this.field.name) as FormGroup).get('gps') as FormGroup).addControl('longitude', new FormControl());

    (this.group.get(this.field.name) as FormGroup).get('zip')?.setValue('');
    (this.group.get(this.field.name) as FormGroup).get('city')?.setValue('');
    (this.group.get(this.field.name) as FormGroup).get('street')?.setValue('');
    (this.group.get(this.field.name) as FormGroup).get('number')?.setValue('');


    ((this.group.get(this.field.name) as FormGroup).get('gps') as FormGroup).get('latitude')?.setValue(0);
    ((this.group.get(this.field.name) as FormGroup).get('gps') as FormGroup).get('longitude')?.setValue(0);

    this.setGroup = (this.group.get(this.field.name) as FormGroup);
    this.gpsGroup = ((this.group.get(this.field.name) as FormGroup).get('gps') as FormGroup);

    this.entitySubscription = this.parentEntity$.subscribe(entity => {
      const fieldName = this.field.name;
      if (fieldName && fieldName in entity) {
        if (entity[fieldName] && this.group.get(fieldName)) {
          this.group.get(fieldName)?.setValue(entity[fieldName]);
        }
      }
    });
  }

  ngOnDestroy() {
    this.entitySubscription.unsubscribe();
  }

  getAddressString() {
    return 'Hungary ' + (this.group.get(this.field.name) as FormGroup).get('zip')?.value + ' ' +
      (this.group.get(this.field.name) as FormGroup).get('city')?.value + ' ' +
      (this.group.get(this.field.name) as FormGroup).get('street')?.value + ' ' +
      (this.group.get(this.field.name) as FormGroup).get('number')?.value
      ;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList: ValidatorFn[] = [];
      validations.forEach((valid: { validator: ValidatorFn; }) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }
}
