<section class="l-siteContent" id="theTop" *ngIf="items$ | async as items">
  <header class="sectionHeader g-bottom">
    <h1><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Bomo blog' | transloco }}<img
      src="/assets/images/heading_divider_right.svg" alt="decor"></h1>
  </header>
  <section class="g-bottom-thick">
    <div class="row">
      <div class="small-12 column">
        <button class="tagSelectorToggle">{{ 'webshop.Témakörök' | transloco }}</button>
        <ul class="tagSelector tagSelector--small" id="tagSelector">
          <li [ngClass]="{'tagSelector-selected': activeCategory === null}"><a (click)="setActive(null)">{{ 'webshop.Összes' | transloco }}</a>
          </li>          <li [ngClass]="{'tagSelector-selected': activeCategory?.id === category.id}"
              *ngFor="let category of (categories$ | async)"><a (click)="setActive(category)">{{ category.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section>
    <div class="row" *ngIf="items.length > 0">
      <div class="small-12 column">
        <div class="postsHighlight" *ngIf="items[0] as item">
          <div class="decorBorder">
            <div>
              <div>
                <!-- small: 450px, medium: 750px, large: 1010px, xlarge: 1185px -->
                <app-image
                  [small]="item.image1.default_image_small"
                  [medium]="item.image1.default_image_medium"
                  [large]="item.image1.default_image_large"
                  [xlarge]="item.image1.default_image_xlarge"
                ></app-image>
                <a class="postsHighlight-title" [routerLink]="['/blog', item.slug] | localize">
                  <div [innerHTML]="item.title">
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="postsHighlight-text" [innerHTML]="item.content">
          </div>
          <div class="postsHighlight-more">
            <a [routerLink]="['/blog', item.slug] | localize">{{ 'webshop.Tovább' | transloco }}</a>
          </div>
        </div>
        <hr class="l-dividerHighlight">
        <div class="row small-up-1 medium-up-2 large-up-3">
          <ng-container *ngFor="let item of items; index as i">
            <div class="column" *ngIf="i > 0">
              <div class="blockCard">
                <a class="blockCard-img" [routerLink]="['/blog', item.slug] | localize">
                  <app-image
                    [small]="item.image1.default_image_small"
                    [medium]="item.image1.default_image_medium"
                    [large]="item.image1.default_image_large"
                    [xlarge]="item.image1.default_image_xlarge"
                  ></app-image>
                </a>
                <a class="blockCard-title" [routerLink]="['/blog', item.slug] | localize">
                  {{ item.title }}  </a>
                <div class="blockCard-text" [innerHTML]="item.content">
                </div>
                <a class="blockCard-more" [routerLink]="['/blog', item.slug] | localize">{{ 'webshop.Tovább' | transloco }}</a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
  <div class="text-center g-top-extrathick">
    <a class="l-moreBig l-moreBig--highlight" [routerLink]="['/blog/list'] | localize">{{ 'webshop.További blogbejegyzések' | transloco }}</a>
  </div>
  <hr class="l-dividerHighlight">
  <section *ngIf="false">
    <header class="l-headFrizDecor">
      <h1>{{ 'webshop.Archívum' | transloco }}</h1>
    </header>
    <div class="row">
      <div class="small-12 column">
        <ul class="tagSelector">
          <li><a href="#">2018</a></li>
          <li class="tagSelector-selected"><a href="#">2017</a></li>
          <li><a href="#">2016</a></li>
          <li><a href="#">2015</a></li>
        </ul>
      </div>
    </div>
  </section>
</section>
