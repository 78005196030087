import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoggedInLayoutComponent} from "../layout/logged-in/component";
import {AuthGuard} from "../authentication/auth.guard";
import {StoreListComponent} from "./store/list";
import {StoreDetailComponent} from "./store/detail";
import {CategoryListComponent} from "./category/list";
import {CategoryDetailComponent} from "./category/detail";
import {ConfigComponent} from "./config/detail";
import {CouponListComponent} from "./coupon/list";
import {CouponDetailComponent} from "./coupon/detail";
import {OrderListComponent} from "./order/list";
import {OrderDetailComponent} from "./order/detail";
import {ResellerListComponent} from "./reseller/list";
import {ResellerDetailComponent} from "./reseller/detail";
import {TagListComponent} from "./tag/list";
import {TagDetailComponent} from "./tag/detail";
import {CurrencyListComponent} from "./currency/list";
import {CurrencyDetailComponent} from "./currency/detail";
import {LanguageListComponent} from "./language/list";
import {LanguageDetailComponent} from "./language/detail";
import {ExpressionListComponent} from "./expression/list";
import {ExpressionDetailComponent} from "./expression/detail";
import {CountryListComponent} from "./country/list";
import {CountryDetailComponent} from "./country/detail";
import {LocalizeRouterModule} from "@penleychan/ngx-transloco-router";
import {TransporterListComponent} from "./transporter/list";
import {TransporterDetailComponent} from "./transporter/detail";
import {ExcelExportComponent} from "./order/excelExport";
import {ManufacturableExportComponent} from "./order/manufacturableExport";
import {TransporterDiscountListComponent} from "./transporter-discount/list";
import {TransporterDiscountDetailComponent} from "./transporter-discount/detail";
import {PaymentModeListComponent} from "./payment-mode/list";
import {PaymentModeDetailComponent} from "./payment-mode/detail";

const routes: Routes = [
  {
    path: 'admin/content/tag',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: TagListComponent
      },
      {
        path: ':id',
        component: TagDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/webshop/category',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CategoryListComponent
      },
      {
        path: ':id',
        component: CategoryDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/webshop/config',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ConfigComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/webshop/coupon',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CouponListComponent
      },
      {
        path: ':id',
        component: CouponDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/webshop/order',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OrderListComponent
      },
      {
        path: ':id',
        component: OrderDetailComponent
      },
      {
        path: 'export',
        children: [
          {
            path: 'excel',
            component: ExcelExportComponent
          },
          {
            path: 'manufacturable',
            component: ManufacturableExportComponent
          }
        ]
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/webshop/reseller',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ResellerListComponent
      },
      {
        path: ':id',
        component: ResellerDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/webshop/store',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: StoreListComponent
      },
      {
        path: ':id',
        component: StoreDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/webshop/transporter',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: TransporterListComponent
      },
      {
        path: ':id',
        component: TransporterDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/webshop/payment-mode',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PaymentModeListComponent
      },
      {
        path: ':id',
        component: PaymentModeDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/webshop/transporter-discount',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: TransporterDiscountListComponent
      },
      {
        path: ':id',
        component: TransporterDiscountDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/currency',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CurrencyListComponent
      },
      {
        path: ':id',
        component: CurrencyDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/language',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: LanguageListComponent
      },
      {
        path: ':id',
        component: LanguageDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/expression',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ExpressionListComponent
      },
      {
        path: ':id',
        component: ExpressionDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/country',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CountryListComponent
      },
      {
        path: ':id',
        component: CountryDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/config',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ConfigComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), LocalizeRouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}

