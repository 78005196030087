import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LOCALIZE_ROUTER_CONFIG, localizeRouterConfig, LocalizeRouterModule} from "@penleychan/ngx-transloco-router";
import {WebshopNotFoundComponent} from "./webshop-client/not-found/component";
import {WebshopLayoutComponent} from "./layout/webshop/component";

const routes: Routes = [

  {
    path: '**',
    pathMatch: 'full',
    component: WebshopLayoutComponent,
    children: [{
      path: '',
      component: WebshopNotFoundComponent
    }]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'}),
    LocalizeRouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule,
    LocalizeRouterModule
  ],
  providers: [
    {
      provide: LOCALIZE_ROUTER_CONFIG,
      useValue: localizeRouterConfig({
        translateRoute: false
      })
    }
  ]
})
export class AppRoutingModule {
}
