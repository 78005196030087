import {Entity, Image} from '../../../components/entity';
import {ProductParameterFormat} from "../../product-parameter-format/state/model";
import {ProductParameter} from "../../product-parameter/state/model";

export class Caption extends Entity {
  positionX: number;
  positionY: number;
  rotate: number;
  fontSize: number;
  orientation: number;
  format: ProductParameterFormat;
  productParameter: ProductParameter;

  constructor({
                id,
                createdAt,
                updatedAt,
                positionX,
                positionY,
                rotate,
                fontSize,
                format,
                orientation,
                productParameter,
              }: Partial<Caption>) {
    super({id, createdAt, updatedAt});
    this.positionX = positionX ?? 0;
    this.positionY = positionY ?? 0;
    this.fontSize = fontSize ?? 10;
    this.rotate = rotate ?? 0;
    this.orientation = orientation ?? 0;
    this.format = new ProductParameterFormat(format ?? {});
    this.productParameter = new ProductParameter(productParameter ?? {});
  }

  getNameProperty(): string {
    return "id";
  }
}
