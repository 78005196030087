import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoutingModule} from './routing';
import {MaterialModule} from '../material/material.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {Module as ComponentsModule} from '../components/module';
import {TranslocoModule} from '@ngneat/transloco';
import {FedexOrderComponent} from "./order/fedex-order";

@NgModule({
  declarations: [
    FedexOrderComponent
  ],
  exports: [
    FedexOrderComponent
  ],
    imports: [
        ComponentsModule,
        CommonModule,
        MaterialModule,
        RoutingModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        TranslocoModule,
    ],
})
export class FedexModule {
}
