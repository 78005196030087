import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material/material.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {Module as ComponentsModule} from '../components/module';
import {TranslocoModule} from '@ngneat/transloco';
import {RouterModule} from '@angular/router';
import {NotificationComponent} from './notification/component';

@NgModule({
  declarations: [
    NotificationComponent
  ],
  exports: [],
  imports: [
    ComponentsModule,
    CommonModule,
    MaterialModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslocoModule,
    RouterModule,
  ],
})
export class Module {
}
