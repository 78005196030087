import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {InvoiceFile} from './model';

export interface InvoiceFileState extends EntityState<InvoiceFile> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'InvoiceFile' })
export class Store extends EntityStore<InvoiceFileState> {

  constructor() {
    super();
  }

}
