import {Component, Injector} from '@angular/core';
import {Store, WallpaperState} from './state/store';
import {Wallpaper} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {WallpaperQuery} from './state/query';
import {WallpaperService} from './state/service';

@Component({
  templateUrl: '../../components/entity-list/component.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class WallpaperListComponent extends EntityListComponent<Wallpaper, WallpaperState, Store> {

  translationDomain = 'wallpaper';

  constructor(
    public injector: Injector,
    protected service: WallpaperService,
    query: WallpaperQuery,
    private WallpaperFilterService: FilterService) {
    super(injector, service, query, WallpaperFilterService);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.sort.sort({
      id: 'from',
      start: 'desc',
      disableClear: false
    });
  }
}
