<section class="l-siteContent" id="theTop" *ngIf="item$ | async as press">
  <section>
    <div class="row align-center">
      <div class="small-12 medium-9 large-5 xxlarge-4 column">
        <header class="l-headFrizDecor">
          <h2><span>{{ 'webshop.Belépés' | transloco }}</span><br>{{ 'webshop.a letöltésekhez' | transloco }}</h2>
        </header>
        <form class="form form--signInPress" data-abide novalidate>
          <div data-abide-error class="alert callout" *ngIf="invalid">
            <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
          </div>
          <label>{{ 'webshop.Azonosító' | transloco }}
            <input type="text" [placeholder]="'webshop.Azonosító' | transloco" [formControl]="usernameControl">
            <span class="form-error"
                  *ngIf="usernameControl.hasError('required') && usernameControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
          </label>
          <label>{{ 'webshop.Jelszó' | transloco }}
            <input type="password" [placeholder]="'webshop.Jelszó' | transloco" [formControl]="passwordControl">
            <span class="form-error"
                  *ngIf="passwordControl.hasError('required') && passwordControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
          </label>
          <div class="g-top text-center">
            <a class="btn btn--basic" type="submit"
               (click)="auth()">{{ 'webshop.Belépés' | transloco }}</a>
          </div>
        </form>
        <div class="g-top-extrathick text-center">
          <img class="anim" src="/assets/images/decor/key.png" alt="decor">
        </div>
      </div>
      <div class="small-12 large-1 column">
        <div class="l-dividerDecorativeVertical show-for-large">
          <div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="small-12 medium-9 large-6 xxlarge-7 column">
        <div class="row l-dividerDecorative hide-for-large">
          <div class="column"></div>
          <div class="shrink column"></div>
          <div class="column"></div>
        </div>
        <header class="l-headFrizDecor">
          <h1>
            <span>{{ 'webshop.Szeretnél' | transloco }}</span><br>{{ 'webshop.kapcsolatba lépni velünk?' | transloco }}
          </h1>
        </header>

        <p
          *ngIf="submitted">{{ 'webshop.Köszönjük, hogy üzenetet küldtél nekünk. Hamarosan kapcsolatba lépünk veled!' | transloco }}</p>


        <form class="form form--contact form--inputTextTransformNone" data-abide novalidate
              *ngIf="!submitted">
          <div data-abide-error class="alert callout h-hidden">
            <p>Hibás kitöltés!</p>
          </div>
          <label>{{ 'webshop.Név' | transloco }}
            <input type="text" [placeholder]="'webshop.Név' | transloco" [formControl]="nameControl">
            <span class="form-error"
                  *ngIf="nameControl.hasError('required') && emailControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
          </label>
          <label>E-mail
            <input type="text" [placeholder]="'E-mail' | transloco" [formControl]="emailControl">
            <span class="form-error"
                  *ngIf="emailControl.hasError('required') && emailControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
            <span class="form-error"
                  *ngIf="emailControl.hasError('email') && emailControl.touched">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco }}</span>
          </label>
          <label>{{ 'webshop.Megjegyzés' | transloco }}
            <textarea [formControl]="contentControl" rows="8" [placeholder]="'webshop.Megjegyzés' | transloco"></textarea>
            <span class="form-error"
                  *ngIf="contentControl.hasError('required') && emailControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
          </label>
          <div class="g-top text-right">
            <img class="l-hand" src="/assets/images/bomo_hand_gray.svg" alt="tovább">
            <button class="btn btn--basic" (click)="sendMail()">{{ 'webshop.Küldés' | transloco }}</button>
          </div>
        </form>
      </div>
    </div>
  </section>
  <hr class="l-dividerHighlight">
  <section>
    <header class="l-headFrizDecor">
      <h1>{{ 'webshop.Fontosabb megjelenések' | transloco }}</h1>
    </header>
    <div class="row">
      <div class="small-12 column">
        <div class="row small-up-1 medium-up-2 large-up-3">
          <div class="column" *ngIf="press.offerNews1.slug">
            <div class="blockCard">
              <a class="blockCard-img" [routerLink]="['/press/news/', press.offerNews1.slug] | localize">
                <img [src]="press.offerNews1.image1.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [routerLink]="['/press/news/', press.offerNews1.slug] | localize">
                {{ press.offerNews1.title }}</a>
              <div class="blockCard-text" [innerHTML]="press.offerNews1.content">
                <a class="blockCard-more"
                   [routerLink]="['/press/news/', press.offerNews1.slug] | localize">{{ 'webshop.Tovább' | transloco }}</a>
              </div>
            </div>
          </div>
          <div class="column" *ngIf="press.offerNews2.slug">
            <div class="blockCard">
              <a class="blockCard-img" [routerLink]="['/press/news/', press.offerNews2.slug] | localize">
                <img [src]="press.offerNews2.image1.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [routerLink]="['pressNewsView({slug: press.offerNews2.slug})'] | localize">
                {{ press.offerNews2.title }}</a>
              <div class="blockCard-text" [innerHTML]="press.offerNews2.content">
                <a class="blockCard-more"
                   [routerLink]="['/press/news', press.offerNews2.slug] | localize">{{ 'webshop.Tovább' | transloco }}</a>
              </div>
            </div>
          </div>
          <div class="column" *ngIf="press.offerNews3.slug">
            <div class="blockCard">
              <a class="blockCard-img" [routerLink]="['/press/news/', press.offerNews3.slug] | localize">
                <img [src]="press.offerNews3.image1.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [routerLink]="['pressNewsView({slug: press.offerNews3.slug})'] | localize">
                {{ press.offerNews3.title }}</a>
              <div class="blockCard-text" [innerHTML]="press.offerNews3.content">
                <a class="blockCard-more"
                   [routerLink]="['/press/news', press.offerNews3.slug] | localize">{{ 'webshop.Tovább' | transloco }}</a>
              </div>
            </div>
          </div>
          <div class="column" *ngIf="press.offerNews4.slug">
            <div class="blockCard">
              <a class="blockCard-img" [routerLink]="['/press/news/', press.offerNews4.slug] | localize">
                <img [src]="press.offerNews4.image1.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [routerLink]="['/press/news', press.offerNews4.slug] | localize">
                {{ press.offerNews4.title }}</a>
              <div class="blockCard-text" [innerHTML]="press.offerNews4.content">
                <a class="blockCard-more"
                   [routerLink]="['/press/news', press.offerNews4.slug] | localize">{{ 'webshop.Tovább' | transloco }}</a>
              </div>
            </div>
          </div>
          <div class="column" *ngIf="press.offerNews5.slug">
            <div class="blockCard">
              <a class="blockCard-img" [routerLink]="['/press/news/', press.offerNews5.slug] | localize">
                <img [src]="press.offerNews5.image1.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [routerLink]="['/press/news', press.offerNews5.slug]">
                {{ press.offerNews5.title }}</a>
              <div class="blockCard-text" [innerHTML]="press.offerNews5.content">
                <a class="blockCard-more"
                   [routerLink]="['/press/news', press.offerNews5.slug] | localize">{{ 'webshop.Tovább' | transloco }}</a>
              </div>
            </div>
          </div>
          <div class="column" *ngIf="press.offerNews6.slug">
            <div class="blockCard">
              <a class="blockCard-img" [routerLink]="['/press/news/', press.offerNews6.slug] | localize">
                <img [src]="press.offerNews6.image1.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [routerLink]="['/press/news', press.offerNews6.slug] | localize">
                {{ press.offerNews6.title }}</a>
              <div class="blockCard-text" [innerHTML]="press.offerNews6.content">
                <a class="blockCard-more"
                   [routerLink]="['/press/news', press.offerNews6.slug] | localize">{{ 'webshop.Tovább' | transloco }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="g-top text-center">
      <a class="btn btn--basic"
         [routerLink]="['/press/news'] | localize">{{ 'webshop.Tovább az összes megjelenésre' | transloco }}</a>
    </div>
  </section>
  <hr class="l-dividerHighlight">
  <section>
    <header class="l-headFrizDecor">
      <h1>{{ 'webshop.Videók' | transloco }}</h1>
    </header>
    <div class="row">
      <div class="small-12 column">
        <div class="row small-up-1 medium-up-2">
          <div class="column" *ngIf="press.offerVideo1.url">
            <div class="blockCard">
              <a class="blockCard-img" [href]="safeUrl(press.offerVideo1.url)" target="_blank">
                <img [src]="press.offerVideo1.image.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [href]="safeUrl(press.offerVideo1.url)" target="_blank">
                {{ press.offerVideo1.title }}</a>
            </div>
          </div>
          <div class="column" *ngIf="press.offerVideo2.url">
            <div class="blockCard">
              <a class="blockCard-img" [href]="safeUrl(press.offerVideo2.url)" target="_blank">
                <img [src]="press.offerVideo2.image.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [href]="safeUrl(press.offerVideo2.url)" target="_blank">
                {{ press.offerVideo2.title }}</a>
            </div>
          </div>
          <div class="column" *ngIf="press.offerVideo3.url">
            <div class="blockCard">
              <a class="blockCard-img" [href]="safeUrl(press.offerVideo3.url)" target="_blank">
                <img [src]="press.offerVideo3.image.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [href]="safeUrl(press.offerVideo3.url)" target="_blank">
                {{ press.offerVideo3.title }}</a>
            </div>
          </div>
          <div class="column" *ngIf="press.offerVideo4.url">
            <div class="blockCard">
              <a class="blockCard-img" [href]="safeUrl(press.offerVideo4.url)" target="_blank">
                <img [src]="press.offerVideo4.image.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [href]="safeUrl(press.offerVideo4.url)" target="_blank">
                {{ press.offerVideo4.title }}</a>
            </div>
          </div>
          <div class="column" *ngIf="press.offerVideo5.url">
            <div class="blockCard">
              <a class="blockCard-img" [href]="safeUrl(press.offerVideo5.url)" target="_blank">
                <img [src]="press.offerVideo5.image.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [href]="safeUrl(press.offerVideo5.url)" target="_blank">
                {{ press.offerVideo5.title }}</a>
            </div>
          </div>
          <div class="column" *ngIf="press.offerVideo6.url">
            <div class="blockCard">
              <a class="blockCard-img" [href]="safeUrl(press.offerVideo6.url)" target="_blank">
                <img [src]="press.offerVideo6.image.default_image_medium" alt="kép címe">
              </a>
              <a class="blockCard-title" [href]="safeUrl(press.offerVideo6.url)" target="_blank">
                {{ press.offerVideo6.title }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="g-top text-center">
      <a class="btn btn--basic"
         [routerLink]="['/press/videos'] | localize">{{ 'webshop.Tovább az összes videóra' | transloco }}</a>
    </div>
  </section>
</section>
