import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoutingModule} from './routing';
import {MaterialModule} from '../material/material.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {Module as ComponentsModule} from '../components/module';
import {TranslocoModule} from '@ngneat/transloco';
import {PriceListDetailComponent} from "./price-list/detail";
import {PriceListListComponent} from "./price-list/list";
import {PriceListViewComponent} from "./price-list/view";
import {ProductListComponent} from "./product/list";
import {ProductViewComponent} from "./product/view";
import {ProductDetailComponent} from "./product/detail";
import {ProductParameterListComponent} from "./product-parameter/list";
import {ProductParameterViewComponent} from "./product-parameter/view";
import {ProductParameterDetailComponent} from "./product-parameter/detail";
import {ProductParameterTypeDetailComponent} from "./product-parameter-type/detail";
import {ProductParameterTypeViewComponent} from "./product-parameter-type/view";
import {ProductParameterTypeListComponent} from "./product-parameter-type/list";
import {ProductVariantDetailComponent} from "./product-variant/detail";
import {ProductVariantListComponent} from "./product-variant/list";
import {ProductVariantViewComponent} from "./product-variant/view";
import {ProductParameterFormatDetailComponent} from "./product-parameter-format/detail";
import {ProductParameterFormatListComponent} from "./product-parameter-format/list";

@NgModule({
  declarations: [
    PriceListDetailComponent,
    PriceListListComponent,
    PriceListViewComponent,
    ProductListComponent,
    ProductViewComponent,
    ProductDetailComponent,
    ProductParameterListComponent,
    ProductParameterViewComponent,
    ProductParameterDetailComponent,
    ProductParameterTypeDetailComponent,
    ProductParameterTypeViewComponent,
    ProductParameterTypeListComponent,
    ProductVariantDetailComponent,
    ProductVariantListComponent,
    ProductVariantViewComponent,
    ProductParameterFormatDetailComponent,
    ProductParameterFormatListComponent,
  ],
  exports: [],
  imports: [
    ComponentsModule,
    CommonModule,
    MaterialModule,
    RoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslocoModule,
  ],
})
export class ProductsModule {
}
