import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './login/component';
import {LoggedInLayoutComponent} from '../layout/logged-in/component';
import {AuthGuard} from './auth.guard';
import {UserListComponent} from './user/list';
import {UserDetailComponent} from './user/detail';
import {RoleListComponent} from './role/list';
import {RoleDetailComponent} from './role/detail';
import {ProfileComponent} from './user/profile';
import {GuestLayoutComponent} from '../layout/guest/component';
import {LogoutComponent} from "./logout/component";
import {LocalizeRouterModule} from "@penleychan/ngx-transloco-router";

const routes = [
  {
    path: 'login',
    component: GuestLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'logout',
    component: GuestLayoutComponent,
    children: [
      {
        path: '',
        component: LogoutComponent
      },
    ],
  },
  {
    path: 'admin/users/profile',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProfileComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/users/user',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: UserListComponent
      },
      {
        path: ':id',
        component: UserDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/users/role',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: RoleListComponent
      },
      {
        path: ':id',
        component: RoleDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), LocalizeRouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}

