import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Order} from './model';

export interface OrderState extends EntityState<Order> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Order' })
export class Store extends EntityStore<OrderState> {

  constructor() {
    super();
  }

}
