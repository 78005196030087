import {AfterViewInit, Component, Injector, OnDestroy, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortHeader} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {AkitaMatDataSource} from 'akita-filters-plugin/datasource';
import {EntityState, EntityStore, getIDType, QueryEntity} from '@datorama/akita';
import {EntityService} from '../entity-service';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../confirmation-dialog/component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';
import {FilterService} from '../filter.service';
import {Entity} from '../entity';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldConfig} from '../form/field.interface';
import {debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {merge, Observable, of, Subscription} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {UserService} from '../../authentication/user/state/service';
import {FilterCache, FilterCacheService} from "./cache.service";
import {ActivatedRoute, Router} from "@angular/router";
import * as moment from 'moment';

@Component({
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class EntityListComponent<T extends Entity, S extends EntityState, St extends EntityStore<S>> implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatTable) table: MatTable<T> | undefined;
  selection = new SelectionModel<Entity>(true, []);

  dataSource: AkitaMatDataSource<S> | undefined;
  dialog: MatDialog;
  snackBar: MatSnackBar;
  translationService: TranslocoService;
  fields: FieldConfig[] = [];
  filterForm: FormGroup;
  formBuilder: FormBuilder;

  protected userService: UserService;
  cacheService: FilterCacheService;
  filterCache: FilterCache = {id: '', parameters: []};
  private pagerSubscription: Subscription | undefined;
  private filterSubscription: Subscription | undefined;

  private router: Router;
  private activatedRoute: ActivatedRoute;

  translationDomain = '';
  displayedColumns: string[] = [];
  initTriggered = false;

  public constructor(
    public injector: Injector,
    protected service: EntityService<St, S>,
    private query: QueryEntity<S>,
    protected filter: FilterService<T, S, QueryEntity<S>, St>,
  ) {
    this.dialog = injector.get(MatDialog);
    this.snackBar = injector.get(MatSnackBar);
    this.formBuilder = injector.get(FormBuilder);
    this.translationService = injector.get(TranslocoService);
    this.userService = injector.get(UserService);
    this.cacheService = injector.get(FilterCacheService);

    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);

    this.displayedColumns = service.getListingColumns();

    this.fields = service.getFields();
    this.filterForm = this.formBuilder.group({});
    this.filterSubscription = this.filterSubscription$();
  }

  public hasRole(role: string) {
    return !!this.userService.currentUser?.permissions.find(perm => perm.slug === role);
  }

  protected filterSubscription$() {
    return of('').pipe(
      switchMap(() => this.createControl().valueChanges
        .pipe(
          distinctUntilChanged(),
          debounceTime(800)
        ))
    )
      .subscribe(change => {
        //this.filter.clearFilters();
        this.fields.forEach(field => {

          if (field.type == 'date') {
            if (change[field.name + 'From'] || change[field.name + 'To']) {

              const filter = this.filter.getFilters().find(filter => filter.id == field.name);

              let from = change[field.name + 'From'] ? (moment(change[field.name + 'From'])) : (filter ? filter.value.from : null);
              let to = change[field.name + 'To'] ? (moment(change[field.name + 'To'])) : (filter ? filter.value.to : null);

              if (field.inputType === 'dateDay' && to) {
                to = to.startOf('day').add(1, 'day');
              }
              this.cacheService.saveParameter(this.getFilterId(), field.name + 'From', change[field.name + 'From']);
              this.cacheService.saveParameter(this.getFilterId(), field.name + 'To', change[field.name + 'To']);

              /*if (from) {
                from = moment(from).subtract(2, 'hours').toISOString();
              }
              if (to) {
                to = moment(to).add(2, 'hours').toISOString();
              }*/

              this.filter.setFilter({
                id: field.name,
                value: {
                  from: from,
                  to: to,
                },
                order: 20,
              });

            } else {
              this.cacheService.saveParameter(this.getFilterId(), field.name + 'From', undefined);
              this.cacheService.saveParameter(this.getFilterId(), field.name + 'To', undefined);

              this.filter.removeFilter(field.name);
            }
          } else {
            if (change[field.name] || change[field.name] === 0) {

              this.cacheService.saveParameter(this.getFilterId(), field.name, change[field.name]);

              this.filter.setFilter({
                id: field.name,
                value: change[field.name],
                order: 20,
              });
            } else {

              this.cacheService.saveParameter(this.getFilterId(), field.name, undefined);

              this.filter.removeFilter(field.name);
            }
          }
        });
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    return this.selection.selected.length === this.dataSource?.getCount() && this.dataSource?.getCount() > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.query.getAll().forEach(row => {
        this.selection.select(row as Entity);
      });
  }

  getService() {
    return this.service;
  }

  ngOnDestroy() {
    this.filterSubscription?.unsubscribe();
    this.pagerSubscription?.unsubscribe();
  }

  ngAfterViewInit() {

    if (this.initTriggered)
      return;

    this.initTriggered = true;
    this.dataSource = new AkitaMatDataSource<S>(this.query, this.filter).withOptions({
      serverPagination: true,
      debounceTimeBetweenTwoChanges: 60,
    });

    const direction = this.filterCache.parameters.find(item => item.name === '#direction')?.value;
    const sort = this.filterCache.parameters.find(item => item.name === '#sort')?.value;
    const page = this.filterCache.parameters.find(item => item.name === '#page')?.value;
    const pageSize = this.filterCache.parameters.find(item => item.name === '#pageSize')?.value;

    console.log(direction, sort, page, pageSize);
    if (this.sort && this.paginator && this.table && this.dataSource) {
      this.table.dataSource = this.dataSource;
      this.filter.total$.subscribe((total) => this.dataSource ? this.dataSource.total = total : 0);

      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      if (direction && sort) {
        setTimeout(() => {
            this.sort.sort({
              id: sort,
              start: direction,
              disableClear: false
            });

            const sortHeader: MatSortHeader = this.sort.sortables.get(this.sort.active) as MatSortHeader;
            sortHeader._setAnimationTransitionState({fromState: sortHeader._arrowDirection, toState: 'active'});

            this.sort.direction = direction;
            this.sort.sortChange.emit({
              active: sort,
              direction: direction
            });
          }
        )
      }
      this.paginator.pageSize = pageSize ?? 50;
      this.paginator._changePageSize(pageSize ?? 50);

      setTimeout(() => {
        if (this.paginator) {
          console.log('SET', page, pageSize);
          this.paginator.pageSize = pageSize ?? 50;
          this.paginator.pageIndex = page ?? 0;
        }
      }, 900);

      this.paginator.pageIndex = page ?? 0;
      //this.paginator.firstPage();
      this.pagerSubscription = merge(
        (this.sort as MatSort).sortChange.pipe(
          tap(data => {
            this.cacheService.saveParameter(this.filterCache.id, '#sort', data.active);
            this.cacheService.saveParameter(this.filterCache.id, '#direction', data.direction);
          }),
          map(() => true)
        ), (this.paginator as MatPaginator).page.pipe(
          tap(data => {
            this.cacheService.saveParameter(this.filterCache.id, '#page', data.pageIndex);
            this.cacheService.saveParameter(this.filterCache.id, '#pageSize', data.pageSize);
          }),
          map(() => true)
        )
      ).subscribe();

    }

  }

  protected addCustomFilterControls() {

  }


  protected getFilterId() {
    return this.service.api;
  }

  private createControl() {
    this.filterCache = this.cacheService.getFilters(this.getFilterId());

    this.fields.forEach(field => {
      if (field.type === 'button' || !field.name) {
        return;
      }

      if (field.type === 'subEntity') {
        const _group = this.formBuilder.array([]);
        this.filterForm?.addControl(field.name, _group);
      } else if (field.type === 'date') {

        const control = this.formBuilder.control(
          this.filterCache.parameters.find(obj => obj.name === 'From' + field.name)?.value ?? field.value,
          []
        );
        this.filterForm?.addControl(field.name + 'From', control);
        const control2 = this.formBuilder.control(
          this.filterCache.parameters.find(obj => obj.name === 'To' + field.name)?.value ?? field.value,
          []
        );
        this.filterForm?.addControl(field.name + 'To', control2);
      } else {
        const control = this.formBuilder.control(
          this.filterCache.parameters.find(obj => obj.name === field.name)?.value ?? field.value,
          []
        );
        this.filterForm?.addControl(field.name, control);
      }

      if (field.filterConfig?.blank) {
        const blankControl = this.formBuilder.control(
          this.filterCache.parameters.find(obj => obj.name === field.name)?.value ?? field.value,
          []
        );
        this.filterForm?.addControl(field.name + 'blank', blankControl);
      }
    });

    this.addCustomFilterControls();
    this.filter.clearFilters();

    if (this.filterCache.parameters.find(item => item.value)) {
      setTimeout(() => {
        this.filterForm.updateValueAndValidity();
      });
    }

    return this.filterForm;
  }

  fieldConfig(name: string): FieldConfig | undefined {
    const type = FieldConfig.create(Object.assign({}, this.fields.find(item => item.name === name)) as FieldConfig);

    type.disabled = false;
    type.editable = true;
    type.creatable = null;
    type.entityConditionalOptions = null;
    switch (type.type) {
      case 'date':
        type.type = 'dateRange';
        break;
      case 'address':
      case 'partnerAddress':
        type.type = 'input';
        type.inputType = 'text';
        break;
      case 'autocomplete':
        type.type = 'input';
        type.inputType = 'text';
        break;
      case 'phone':
        type.type = 'input';
        type.inputType = 'text';
        break;
    }
    return type;
  }

  resetFilter() {
    this.fields.forEach(field => {
      if (field.type === 'subEntity') {
      } else {
        this.filterForm?.get(field.name)?.reset();
      }
    });

    this.filterForm.reset();
  }

  batchDelete() {

    const dialogData = new ConfirmDialogModel();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {

        this.selection.selected.forEach(item => {
          this.service.delete(item.id as getIDType<S>).subscribe(() => {
            this.filter.deletedItem();
            this.snackBar.open(this.translationService.translate('dialog.deleted'), '', {
                duration: 1000,
                panelClass: ['background-success']
              }
            );
          });
        });
      }
    });
  }

  delete(id: getIDType<S>) {

    const dialogData = new ConfirmDialogModel();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.service.delete(id).subscribe(() => {
          this.filter.deletedItem();
          this.filter.refresh();
          this.snackBar.open(this.translationService.translate('dialog.deleted'), '', {
              duration: 1000,
              panelClass: ['background-success']
            }
          );
        });
      }
    });

  }

  getAllColumns(): string[] {
    const ret = Object.assign([], this.displayedColumns);
    ret.unshift('select');
    ret.push('actions');

    return ret;
  }

  getFilterColumns(): string[] {
    const ret = Object.assign([], this.displayedColumns);
    ret.unshift('select');
    ret.push('actions');

    return ret.map(item => 'filter_' + item);
  }

  canEdit() {
    return true;
  }

  canView() {
    return false;
  }

  reloadComponent() {
    this.router.navigate(['./'], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
  }

  of(row) {
    return of(row);
  }
}
