import {Entity} from '../../../components/entity';
import {ProductParameterSize} from "../../product-parameter-size/state/model";
import {ProductParameterType} from "../../product-parameter-type/state/model";
import {ProductParameterFormat} from "../../product-parameter-format/state/model";
import {Caption} from "../../caption/state/model";
import {ProductParameterInner} from "../../product-parameter-inner/state/model";

export class ProductParameter extends Entity {
  name: string;
  displayName: string;
  inner: ProductParameterInner;
  caption: boolean;
  captions: Caption[];
  upper: boolean;
  isControl: boolean;
  size: ProductParameterSize;
  tagTitle: string;
  perPage: number;
  types: ProductParameterType[];
  formats: ProductParameterFormat[];


  constructor({
                id,
                name,
                displayName,
                inner,
                perPage,
                caption,
                upper,
                isControl,
                captions,
                tagTitle,
                types,
                size,
                formats,
                createdAt, updatedAt
              }: Partial<ProductParameter>) {
    super({id, createdAt, updatedAt});
    this.name = name ?? '';
    this.displayName = displayName ?? '';
    this.tagTitle = tagTitle ?? '';
    this.inner = new ProductParameterInner(inner ?? {});
    this.upper = upper ?? false;
    this.isControl = isControl ?? false;

    this.size = new ProductParameterSize(size ?? {});
    this.caption = caption ?? false;
    this.types = types ? types.map(type => new ProductParameterType(type ?? {})) : [];

    this.captions = (captions ?? []).map(caption => new Caption(caption ?? {}));
    this.formats = (formats ?? []).map(format => new ProductParameterFormat(format ?? {}));
    this.perPage = perPage ?? 6;
  }

  getNameProperty(): string {
    return 'name';
  }
}
