import {Injectable} from '@angular/core';
import {CurrencyState, Store as CurrencyStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Currency} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {CurrencyQuery} from './query';

@Injectable({providedIn: 'root'})
export class CurrencyService extends EntityService<CurrencyStore, CurrencyState> {

  currencies$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Currency(item))),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('currency.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('currency.code'),
        inputType: 'text',
        name: 'code',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('currency.name'),
        inputType: 'text',
        name: 'name',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('currency.isPercent'),
        inputType: 'text',
        name: 'isPercent',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('currency.isActive'),
        inputType: 'text',
        name: 'isActive',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('currency.amount'),
        inputType: 'number',
        name: 'amount',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('currency.usableCount'),
        inputType: 'number',
        name: 'usableCount',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('currency.minPrice'),
        inputType: 'number',
        name: 'minPrice',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('currency.validFrom'),
        name: 'validFrom',
        listed: false,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('currency.validTo'),
        name: 'validTo',
        listed: false,
        editable: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: CurrencyQuery,
    protected store: CurrencyStore) {
    super(store, Currency);
  }

  get resourceName(): string {
    return 'currency';
  }

}
