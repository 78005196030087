import {Entity} from '../../../components/entity';
import {PressVideo} from "../../press-video/state/model";
import {PressNews} from "../../press-news/state/model";

export class Press extends Entity {

  offerVideo1: PressVideo;
  offerVideo2: PressVideo;
  offerVideo3: PressVideo;
  offerVideo4: PressVideo;
  offerVideo5: PressVideo;
  offerVideo6: PressVideo;

  offerNews1: PressNews;
  offerNews2: PressNews;
  offerNews3: PressNews;
  offerNews4: PressNews;
  offerNews5: PressNews;
  offerNews6: PressNews;

  constructor({
                id,
                offerVideo1,
                offerVideo2,
                offerVideo3,
                offerVideo4,
                offerVideo5,
                offerVideo6,
                offerNews1,
                offerNews2,
                offerNews3,
                offerNews4,
                offerNews5,
                offerNews6,
                createdAt, updatedAt
              }: Partial<Press>) {
    super({id, createdAt, updatedAt});

    this.offerNews1 = new PressNews(offerNews1 ?? {});
    this.offerNews2 = new PressNews(offerNews2 ?? {});
    this.offerNews3 = new PressNews(offerNews3 ?? {});
    this.offerNews4 = new PressNews(offerNews4 ?? {});
    this.offerNews5 = new PressNews(offerNews5 ?? {});
    this.offerNews6 = new PressNews(offerNews6 ?? {});

    this.offerVideo1 = new PressVideo(offerVideo1 ?? {});
    this.offerVideo2 = new PressVideo(offerVideo2 ?? {});
    this.offerVideo3 = new PressVideo(offerVideo3 ?? {});
    this.offerVideo4 = new PressVideo(offerVideo4 ?? {});
    this.offerVideo5 = new PressVideo(offerVideo5 ?? {});
    this.offerVideo6 = new PressVideo(offerVideo6 ?? {});
  }

  getNameProperty(): string {
    return '';
  }
}
