import {Injectable} from '@angular/core';
import {ProductState, Store as ProductStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Product} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {ProductQuery} from './query';
import {Category} from "../../../webshop/category/state/model";
import {Observable} from "rxjs";
import {CategoryService} from "../../../webshop/category/state/service";
import {TagService} from "../../../webshop/tag/state/service";
import {GalleryService} from "../../../content/gallery/state/service";
import {ManufacturingCategoryService} from "../../manufacturing-category/state/service";
import {ProductParameterService} from "../../product-parameter/state/service";
import {Entity} from "../../../components/entity";
import {FormService} from "../../../components/service/form.service";
import {ProductVariant} from "../../product-variant/state/model";
import {ProductParameterFormatImage} from "../../product-parameter-format-image/state/model";
import {ProductParameterImage} from "../../product-parameter-image/state/model";

@Injectable({ providedIn: 'root' })
export class ProductService extends EntityService<ProductStore, ProductState> {

  products$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Product(item)).sort((u1, u2) => u1.toString() > u2.toString() ? 1 : -1)),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('product.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('product.name'),
        inputType: 'text',
        name: 'name',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.categoryService.categories$,
        listed: true,
        multiple: true,
        label: this.translocoService.selectTranslate('product.categories'),
        name: 'categories',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.manufacturingCategoryService.manufacturingCategories$,
        listed: true,
        label: this.translocoService.selectTranslate('product.manufacturingCategory'),
        name: 'manufacturingCategory',
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'select',
        options: [
          {
            name$: this.translocoService.selectTranslate('FedEx box'),
            value: 0
          },
          {
            name$: this.translocoService.selectTranslate('FedEx tube'),
            value: 1
          },
          {
            name$: this.translocoService.selectTranslate('Egyedi'),
            value: 2
          },
        ],
        listed: false,
        label: this.translocoService.selectTranslate('product.fedExPackage'),
        name: 'fedExPackage',
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.productParameterService.productParameters$,
        listed: false,
        multiple: true,
        label: this.translocoService.selectTranslate('product.productParameters'),
        name: 'productParameters',
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityConditionalOptions: (group: FormGroup) => group.get('productParameters').valueChanges.pipe(
          map(productParameters => {
            return productParameters;
          }),
          shareReplay(1)
        ),
        listed: false,
        label: this.translocoService.selectTranslate('product.selectorProductParameter'),
        name: 'selectorProductParameter',
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'wysiwyg',
        label: this.translocoService.selectTranslate('product.description'),
        name: 'description',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('product.image1'),
        name: 'image1',
        validations: this.formService.getValidations(['requiredImage'])
      }),
      FieldConfig.create({
        type: 'image',
        listed: false,
        label: this.translocoService.selectTranslate('product.image2'),
        name: 'image2',
        validations: this.formService.getValidations([])
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.tagService.tags$,
        listed: false,
        multiple: true,
        label: this.translocoService.selectTranslate('product.tags'),
        name: 'tags',
        validations: []
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('product.multipart'),
        inputType: 'text',
        name: 'multipart',
        listed: false,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'checkbox',
        label: this.translocoService.selectTranslate('product.availableWebshop'),
        inputType: 'text',
        name: 'availableWebshop',
        listed: false,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('product.price'),
        inputType: 'number',
        name: 'price',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('product.weight'),
        inputType: 'number',
        name: 'weight',
        listed: false,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('product.mass'),
        inputType: 'number',
        name: 'mass',
        listed: false,
        validations: [
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.galleryService.galleries$,
        listed: false,
        label: this.translocoService.selectTranslate('product.gallery'),
        name: 'gallery',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.galleryService.galleries$,
        listed: false,
        editable: false,
        label: this.translocoService.selectTranslate('product.gallery'),
        name: 'defaultVariant',
        validations: []
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('product.createdAt'),
        name: 'createdAt',
        listed: true,
        editable: false,
        validations: []
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private categoryService: CategoryService,
    private manufacturingCategoryService: ManufacturingCategoryService,
    private tagService: TagService,
    private galleryService: GalleryService,
    private query: ProductQuery,
    private formService: FormService,
    private productParameterService: ProductParameterService,
    protected store: ProductStore) {
    super(store, Product);
  }

  get resourceName(): string {
    return 'products/product';
  }

  clearEntity(_entity: Product): Entity {

    const entity = new Product(_entity);

    const defaultVariant = entity.defaultVariant;
    const _prod = super.clearEntity(entity);

    _prod.defaultVariant = defaultVariant;

    if (!defaultVariant.id) {
      delete defaultVariant.id;
    }
    if (!defaultVariant.productParameterTypeCounts.length) {
      delete _prod.defaultVariant;
    }
    return _prod;
  }

  getByCategory(category: Category): Observable<Product[]> {
    return this.getHttp().get<Product[]>(this.api + '?q[]=categories.id:eq:' + category.id)
      .pipe(
        map(items => {
          return items.map(item => new this._constructor(item) as Product);
        }),
        take(1),
        shareReplay(1)
      );
  }

  getByTerm(term: string): Observable<Product[]> {
    return this.getHttp().get<Product[]>(this.api + '/search/' + term)
      .pipe(
        map(items => {
          return items.map(item => new this._constructor(item) as Product);
        }),
        take(1),
        shareReplay(1)
      );
  }

  getImagesByProduct(product: Product): Observable<ProductParameterImage[]> {
    return this.getHttp().get<ProductParameterImage[]>(this.api + '/' + product.id + '/images')
      .pipe(
        take(1),
        shareReplay(1)
      );
  }
}
