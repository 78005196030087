import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';
import {Observable} from 'rxjs';
import {Entity} from '../../entity';

@Component({selector: 'app-radiobutton',
  template: `
    <div *ngIf="field.name" [formGroup]="group"><label class="radio-label-padding">{{field.label | async | default: ''}}
      :</label>
      <mat-radio-group [formControlName]="field.name">
        <mat-radio-button *ngFor="let item of field.options" [value]="item">{{item}}</mat-radio-button>
      </mat-radio-group>
    </div>`,
  styleUrls: ['common.scss'],
})
export class RadiobuttonComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  parentEntity$ = new Observable<Entity>();

  constructor() {
    this.group = new FormGroup({});
    this.field = new FieldConfig();
  }

  ngOnInit() {
  }
}
