import {Entity} from '../../../components/entity';

export class Country extends Entity {
  name: string;
  vat: number;
  code: string;

  constructor({
                id,
                name,
                vat,
                code,
                createdAt, updatedAt
              }: Partial<Country>) {
    super({id, createdAt, updatedAt});

    this.name = name ?? '';
    this.code = code ?? '';
    this.vat = vat ?? 27;
  }

  getNameProperty(): string {
    return 'name';
  }
}
