import {Component} from '@angular/core';
import {ConfigService} from "../../../webshop/config/state/service";
import {GalleryImageService} from "../../../content/gallery-image/state/service";
import {Observable} from "rxjs";
import {GalleryImage} from "../../../content/gallery-image/state/model";

interface Gallery {
  id: string | number;
  images$: Observable<GalleryImage[]>;
}

@Component({
  templateUrl: './component.html',
  styleUrls: ['component.scss']
})
export class IndexComponent {

  galleries: Gallery[] = [];

  constructor(
    public configService: ConfigService,
    public galleryImageService: GalleryImageService
  ) {
  }

  getImages$(galleryId: any) {
    if (!this.galleries.find(gallery => gallery.id === galleryId)) {
      this.galleries.push({
        id: galleryId,
        images$: this.galleryImageService.getByGalleryId(galleryId)
      });
    }

    return this.galleries.find(gallery => gallery.id === galleryId).images$;
  }

  to(element: HTMLElement) {
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
