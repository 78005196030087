import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoggedInLayoutComponent} from '../layout/logged-in/component';
import {AuthGuard} from '../authentication/auth.guard';
import {PriceListListComponent} from "./price-list/list";
import {PriceListDetailComponent} from "./price-list/detail";
import {ProductListComponent} from "./product/list";
import {ProductDetailComponent} from "./product/detail";
import {ProductParameterListComponent} from "./product-parameter/list";
import {ProductParameterDetailComponent} from "./product-parameter/detail";
import {ProductParameterTypeListComponent} from "./product-parameter-type/list";
import {ProductParameterTypeDetailComponent} from "./product-parameter-type/detail";
import {ProductVariantListComponent} from "./product-variant/list";
import {ProductVariantDetailComponent} from "./product-variant/detail";
import {ProductParameterFormatListComponent} from "./product-parameter-format/list";
import {ProductParameterFormatDetailComponent} from "./product-parameter-format/detail";
import {LocalizeRouterModule} from "@penleychan/ngx-transloco-router";

const routes: Routes = [

  {
    path: 'admin/products/price-list',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PriceListListComponent
      },
      {
        path: ':id',
        component: PriceListDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/products/product',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductListComponent
      },
      {
        path: ':id',
        component: ProductDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/products/product-parameter',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductParameterListComponent
      },
      {
        path: ':id',
        component: ProductParameterDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/products/product-parameter-type',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductParameterTypeListComponent
      },
      {
        path: ':id',
        component: ProductParameterTypeDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/products/product-variant',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductVariantListComponent
      },
      {
        path: ':id',
        component: ProductVariantDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
  {
    path: 'admin/products/product-parameter-format',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductParameterFormatListComponent
      },
      {
        path: ':id',
        component: ProductParameterFormatDetailComponent
      },
    ],
    data: {
      reuse: true,
      roles: ['ROLE_ADMIN']
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), LocalizeRouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}

